import React, { useEffect, useState } from 'react';
import { Modal, Button, Space, Drawer, Form, Row, Col, Switch, Upload, Select, Input, Image, Tabs, Tag, message } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import DynamicSelect from '../../../../common/Select';
import ReactQuill from 'react-quill';
import { validateMaximum, validateMinimum } from '../../../../common/validate';
import { getData, postData, uploadFile } from '../../../../../actions/middleware';
import { routes } from '../../../../../actions/api-routes';
import { decryptData, encryptData } from '../../../../../actions/crypto';
import { error, success } from '../../../../common/Notify';
const required = { required: true, message: 'This field is required' }

const UpdateBulkRequierment = ({ isVisible, onClose, data, reloadPage }) => {
    const [form] = Form.useForm()
    const [changeLocation, setChangeLocation] = useState('');
    const [typeOfRequirment, setTypeOfRequirment] = useState('Text');
    const [interval, setInterval] = useState('')
    const [requirmentData, setRequirmentData] = useState([])
    const [fileList, setFileList] = useState([]);
    const [fileResponse, setFileResponse] = useState();

    const handleCancel = () => {
        onClose(false);
    };

    const onSubmit = () => {
        form.submit();
    }

    useEffect(() => {
        if (data) {
            getReuirments(data._id)
        }
    }, [data])

    const onFinish = async (values) => {
        if (typeOfRequirment == 'Image') {
            values['requirmentFile'] = fileResponse
        }
        const encryptedData = encryptData({ ...values, typeOfRequirment: typeOfRequirment, bulkJobId: data._id });
        const resData = await postData(routes.jobs.bulk_job, { data: encryptedData });
        if (resData && resData.code == 200) {
            form.resetFields();
            setFileResponse(null)
            setTypeOfRequirment('Text')
            success("Success", "Details updated successfully")
            getReuirments(data._id)
        } else {
            error("Failed", "Something went wrong please try again later")
        }
    };

    const onDelete = () => {
        setFileResponse('')
        form.setFieldValue('photo', '');
    }

    const getReuirments = async (id) => {
        const resData = await getData(routes.jobs.bulk_job + encryptData(id));
        if (resData && resData.code == 200) {
            setRequirmentData(decryptData(resData.data))
        }
    }

    const beforeUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('You can only upload image files!');
            return false
        }
        return isImage;
    };

    const customRequest = async ({ onSuccess, onError, file }) => {
        const formData = new FormData();
        formData.append('file', file);
        const resData = await uploadFile(routes.fileUpload, formData)
        if (resData && resData.status == 'SUCCESS') {
            form.setFieldValue('photo', resData.files[0]);
            form.setFields([{ name: 'photo', errors: [] }]);
            setFileResponse(resData.files[0])
            message.success(`${file.name} uploaded successfully`);
            onSuccess();
        } else {
            message.error(`${file.name} Failed to upload`);
        }
    };

    const getSuffix = (text) => {
        switch (text) {
            case 'Monthly':
                return 'LAKH'
            case 'Yearly':
                return 'LAKH'
            case 'Hourly':
                return 'LAKH'
            case 'Weekly':
                return 'LAKH'
            case 'Fixed':
                return 'LAKH'
            default:
                return 'LAKH'
        }
    }

    return (
        <div>
            <Drawer
                title="Update Requirement"
                open={isVisible}
                onClose={handleCancel}
                width={'100%'}
                footer={<>
                    <div style={{ float: 'right' }}>
                        <Space>
                            <Button type='default' onClick={handleCancel}>Cancel</Button>
                            <Button type='primary' onClick={() => onSubmit()}>Save</Button>
                        </Space>
                    </div>
                </>}
            >
                <Row gutter={16}>
                    <Col span={8}>
                        {data?.fileType == 'text' && <div dangerouslySetInnerHTML={{ __html: data?.jobDiscription }}>
                        </div>}
                        {data?.fileType == "images" && <div className=''>
                            <Image src={routes.fileServer + data?.file} />
                        </div>}
                        {data?.fileType == "pdf_doc_excel" && <div style={{ width: '100%', height: '100%' }}>
                            {data?.file.endsWith('.pdf') ?
                                <iframe src={routes.fileServer + data.file} style={{ width: '100%', height: '100%' }}></iframe>
                                : <div >
                                    File Doesn't Support <a href={routes.fileServer + data.file} className='link' target='_blank'>Click here download file</a>
                                </div>}
                        </div>}
                    </Col>
                    <Col span={16}>
                        <Tag color='magenta'>Total job Added: {requirmentData.length}</Tag>
                        <Form
                            form={form}
                            name="dynamic_form_nest_item"
                            onFinish={onFinish}
                            layout='vertical'
                            className='requirment-form'
                            scrollToFirstError={true}
                        >
                            <Row gutter={16} justify={'space-around'} >
                                <Col span={10}>
                                    <DynamicSelect
                                        label="Job Title"
                                        name="jobTitle"
                                        keyName={"jobTitle"}
                                        rules={[required]}
                                    />
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Job Location Type"
                                        name="jobLocationType"
                                        rules={[required]}
                                    >
                                        <Select onChange={setChangeLocation}>
                                            {['Remote', 'Onsite', 'Hybrid'].map(ele => <Option key={ele} value={ele}>{ele}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {changeLocation && changeLocation !== 'Remote' && <Row gutter={16} justify={'space-around'}>
                                <Col span={10}>
                                    <DynamicSelect
                                        label="Work Location"
                                        name="workLocation"
                                        keyName={"workLocation"}
                                        required={true}
                                    />
                                </Col>
                                <Col span={10}></Col>
                            </Row>}
                            <Row gutter={16} justify={'space-around'}>
                                <Col span={10}>
                                    <DynamicSelect
                                        label="Employment Type"
                                        name="employmentType"
                                        keyName={"employmentType"}
                                        rules={[required]}
                                    />
                                </Col>
                                <Col span={10}>
                                    <DynamicSelect
                                        label="Mandatory Technical Skills"
                                        name="skils"
                                        multiple={true}
                                        keyName={"skillName"}
                                        rules={[required]}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16} justify={'space-around'}>
                                <Col span={10}>
                                    <Form.Item
                                        label="Years of Experience"
                                        name="experiance"
                                        rules={[required]}
                                    >
                                        <Input type='number' suffix={'YEAR'} />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Interview Rounds"
                                        name="interviewRounds"
                                    >
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} justify={'space-around'}>
                                <Col span={14}>
                                    <Row gutter={16}>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Currency"
                                                name="currency"
                                            >
                                                <Select>
                                                    {['INR', 'USD'].map(ele => <Option key={ele} value={ele}>{ele}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Interval"
                                                name="interval"
                                            >
                                                <Select >
                                                    {['Monthly', 'Yearly', 'Hourly', 'Weekly', 'Fixed'].map(ele => <Option key={ele} value={ele}>{ele}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Minimum"
                                                name="minimum"
                                                rules={[{ validator: (_, value) => validateMinimum(_, value, form.getFieldValue('maximum')) }]}
                                            >
                                                <Input type='number' suffix={getSuffix(interval)} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Maximum"
                                                name="maximum"
                                                rules={[{ validator: (_, value) => validateMaximum(_, value, form.getFieldValue('minimum')) }]}
                                            >
                                                <Input type='number' suffix={getSuffix(interval)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}></Col>
                            </Row>

                            <Row gutter={16} justify={'space-around'}>
                                <Col span={10}>
                                    <Form.Item
                                        label={
                                            <div className='lable-class-view'>
                                                <div>Job Description</div>
                                                <div className='switch-button'>
                                                    <Switch
                                                        checkedChildren="Text"
                                                        unCheckedChildren="Image"
                                                        defaultChecked={typeOfRequirment}
                                                        onChange={(e) => setTypeOfRequirment(e ? 'Text' : 'Image')}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        name="jobDescription"
                                        rules={[required]}
                                    >
                                        {typeOfRequirment == 'Text' && <ReactQuill
                                            theme='snow'
                                            placeholder='Enter jd...'
                                        />}
                                        {typeOfRequirment == 'Image' && <div className='avatar-container-requiremnt'>
                                            <img className="avatar-image" src={fileResponse ? routes.fileServer + fileResponse : require('./../../../../../assets/images/logo/logov1.png')}
                                                onError={(e) => { e.target.src = require('./../../../../../assets/images/logo/logov1.png') }} alt="Profile Avatar" />
                                            {!fileResponse ? <Upload
                                                beforeUpload={beforeUpload}
                                                customRequest={customRequest}
                                                fileList={fileList}
                                                showUploadList={false}
                                            >
                                                <Button icon={<UploadOutlined />} className="upload-button-requirment" />
                                            </Upload> :
                                                <Button icon={<DeleteOutlined />} onClick={onDelete} className="upload-button-requirment" />
                                            }
                                        </div>}
                                    </Form.Item>
                                </Col>
                                <Col span={10}></Col>
                            </Row>
                        </Form >
                    </Col>
                </Row>
            </Drawer>
        </div >
    );
};

export default UpdateBulkRequierment;
