import { Avatar, Button, Col, Row, Space, Tabs } from 'antd'
import './index.css'
import LabelCard from '../../../common/label-card';

const VendorsContacts = () => {
    return <>
        <div>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <LabelCard lable={'Email'} text={'skanfade@gmail.com'} />
                </Col>
                <Col span={8}>
                    <LabelCard lable={'Phone'} text={'+91 7874787878'} />
                </Col>
            </Row>
        </div>
    </>
}

export default VendorsContacts;