// AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import JobDiscriptions from '../interview/job-discriptions/JobDiscriptions';
import LoginEmployee from '../interview/login';
import ApplicantHome from '../interview/home';
import OfferLetter from '../interview/offer';

const InterviewRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginEmployee />} />
        <Route path="/applicant" element={<ApplicantHome />} >
          <Route path='status/:id' element={<JobDiscriptions />} />
          <Route path='offer' element={<OfferLetter />} />
        </Route>
        <Route path="/:id" element={<JobDiscriptions />} />
      </Routes>
    </>
  );
};

export default InterviewRoutes;
