import { useEffect, useState } from 'react'
import './index.css'
import { Button, Space, Table, Modal, message, Upload } from 'antd'
import Actions from '../../actions/table-configs/actions'
import { VendorConfig } from './config/testConfig'
import DrawerView from '../common/drawer/drawer'
import VendorsDetails from './vendorDetails'
import { CloudUploadOutlined, UserAddOutlined, UploadOutlined } from '@ant-design/icons';
import LabelCard from '../common/label-card'
import { getData, uploadFile } from '../../actions/middleware'
import { routes } from '../../actions/api-routes'
import { decryptData, encryptData } from '../../actions/crypto';

const Vendors = () => {
    const [isOpenDetails, setOpenDetails] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [vendorLists, setVindorLists] = useState([]);
    const [pagination, setPagignation] = useState({});


    const [isImport, setImport] = useState(false);
    const refreshData = () => { }

    useEffect(() => {
        getVendorList();
    }, [])


    const getVendorList = async () => {
        const vendorList = await getData(routes.vendorInvite.invite)
        if (vendorList.data) {
            setVindorLists(decryptData(vendorList.data));
            setPagignation(vendorList.pagination)
        }
    }


    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList.filter(file => validateFile(file)))
    }

    const uploadCSV = async () => {
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();
            fileList.forEach(ele => {
                formData.append('file', ele.originFileObj);
            })
            try {
                const resData = await uploadFile(routes.vendorInvite.invite, formData);
                if (resData && resData.status === 'SUCCESS') {
                    resolve(resData.files)
                    setImport(false)
                    setFileList([])
                } else {
                    reject()
                    message.error(`${file.name} Failed to upload`);
                }
            } catch (error) {
                reject()
                message.error(`${file.name} Failed to upload`);
            }
        })
    };

    const beforeUpload = (file) => {
        const isExcel = ['.xls', '.xlsx', '.csv', 'xls', 'xlsx', 'csv'].includes(file.name.slice(-4).toLowerCase()); // Adjusted to -4

        if (!isExcel) {
            message.error('You can only upload XLS, or csv files!');
            return false;
        }
        return false;
    };

    const validateFile = (file) => {
        const isExcel = ['.xls', '.xlsx', '.csv', 'xls', 'xlsx', 'csv'].includes(file.name.slice(-4).toLowerCase()); // Adjusted to -4

        if (!isExcel) {
            message.error('You can only upload XLS, or csv files!');
            return false;
        }
        return true;
    };

    const onSave = () => {
        uploadCSV()
    }

    const onClose = () => {
        setImport(false)
    }

    const onCloseAddProfile = () => {
        setOpenDetails(false)
    }


    const onSaveAddProfile = () => {

    }

    const onAction = (type, data) => {
        console.log('type, datatype, data', type, data)
        if (type == 'view') {
            setOpenDetails(true)
        } else if (type == 'edit') {

        } else if (type == 'delete') {

        }
    }

    return <>
        <div className='test-allot'>
            <DrawerView
                width={'60%'}
                isVisible={isOpenDetails}
                onClose={() => onCloseAddProfile()}
                onSave={() => onSaveAddProfile()}
                isView={true}
                title={'Vendor profile'}
            >
                <VendorsDetails />
            </DrawerView>

            <div className='buttons-import_export'>
                <Space align='end'>
                    <Button icon={<UserAddOutlined />}>Invite</Button>
                    <Button icon={<CloudUploadOutlined />} onClick={() => setImport(true)}>Import</Button>
                </Space>
            </div>
            <Modal
                open={isImport}
                title={'UPLOAD CSV'}
                onCancel={() => onClose()}
                onOk={() => onSave()}
            >
                <p>
                    Upload a CSV file in the correct format. There are a few mandatory fields: company, email, phone, name, and website. </p>
                <p>
                    Each entry in the CSV file will trigger a separate email. In each email, the recipient will receive their user ID and password, along with a login link
                </p>

                <div className='upload-section-csv'>
                    <LabelCard lable={'Select a file to upload'} text={
                        <div>
                            <Upload
                                listType="text"
                                fileList={fileList}
                                accept=".xls,.xlsx,.csv"
                                multiple
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {fileList.length >= 1 ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
                            </Upload>
                        </div>}
                    />
                </div>
            </Modal>

            <Table
                className="custom-table"
                dataSource={vendorLists}
                columns={VendorConfig(onAction, ['view', 'edit', 'delete'], () => refreshData(), () => { },)}
                pagination={false}
                scroll={{ y: 1000 }}
            />
        </div>
    </>
}

export default Vendors;