import { Avatar, Button, Col, Row, Space, Tabs } from 'antd'
import './index.css'
import LabelCard from '../../../common/label-card';

const VendorsDevelopers = () => {

    return <>
        <div className='formView vendor-details'>
            No Data Found
        </div>
    </>
}

export default VendorsDevelopers;