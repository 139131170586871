import React, { useEffect, useState } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import { Button, Form, Space } from 'antd';
import { UnorderedListOutlined, OrderedListOutlined } from '@ant-design/icons';
import { stateToHTML } from 'draft-js-export-html';

const RichTextEditor = ({ form, parentKey, restField, className, fieldKey, label, name, validation }) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const handlePlainTextExtract = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const plainText = rawContentState.blocks.map(block => {
            return block.text;
        }).join('\n');
        return plainText
    };

    function convertArrayToNestedObject(arr, value) {
        const result = {};
        let current = result;
        for (let i = 0; i < arr.length - 1; i++) {
            current = current[arr[i]] = {};
        }
        current[arr[arr.length - 1]] = value;
        return result;
    }

    useEffect(() => {
        if (Array.isArray(name)) {
            if (handlePlainTextExtract()) {
                form.setFieldsValue({ [parentKey]: convertArrayToNestedObject(name, JSON.stringify(getEditorHtml())) });
            } else {
                form.setFieldsValue({ [parentKey]: convertArrayToNestedObject(name, '') });
            }
        } else {
            if (handlePlainTextExtract()) {
                form.setFieldsValue({ [name]: JSON.stringify(getEditorHtml()) });
            } else {
                form.setFieldsValue({ [name]: '' });
            }
        }

    }, [editorState]);

    const onToggleInlineStyle = (inlineStyle) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const isInlineStyleActive = (style) => {
        return editorState.getCurrentInlineStyle().has(style);
    };


    const getEditorHtml = () => {
        const contentState = editorState.getCurrentContent();
        const html = stateToHTML(contentState);
        return html;
    };

    return (
        <div>
            <Form.Item
                label={label}
                name={name}
                rules={validation}
                className={className}
                fieldKey={fieldKey}
            >
                <div style={{ border: '1px solid #ccc', padding: '10px', paddingBottom: '0px', minHeight: '67px', overflow: 'auto' }}>
                    <Editor
                        {...restField}
                        editorState={editorState}
                        handleKeyCommand={handleKeyCommand}
                        onChange={setEditorState}
                        fieldKey={fieldKey}
                    />
                    <Space>
                        <Button
                            title='Bold'
                            type='text'
                            onClick={() => onToggleInlineStyle('BOLD')}
                            style={{ fontWeight: isInlineStyleActive('BOLD') ? 'bold' : 'normal' }}
                        >
                            B
                        </Button>
                        <Button
                            title='Italic'
                            type='text'
                            onClick={() => onToggleInlineStyle('ITALIC')}
                            style={{ fontStyle: 'italic', fontWeight: isInlineStyleActive('ITALIC') ? 'bold' : 'normal' }}
                        >
                            I
                        </Button>
                    </Space>
                </div>
            </Form.Item>
        </div>
    );
};

export default RichTextEditor;
