// AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Register from '../auth/register';
import Login from '../auth/login';
import ForgotPassword from '../auth/forgot-password';
import ChangePassword from '../auth/change-password';
import OTPLogin from '../auth/otp.login';

const AuthRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-otp" element={<OTPLogin />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} /> */}
      </Routes>
    </>
  );
};

export default AuthRoutes;
