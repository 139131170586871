import React from 'react';
import { Avatar, Button, Col, Pagination, Radio, Row, Table, Tabs, Tag, Image, Switch } from 'antd';
import './index.css';

const Settings = ({ job }) => {

    const onChange = (e) => {

    }

    return (
        <div className='box-setting'>
            <Row gutter={[16, 16]}>
                <Col span={16}>
                    <div className='setting-view'>
                        <div className='heading-2'>Desktop notifications.</div>
                        <div className='setting-item'>
                            <div>
                                <div className='heading-3'>Receive notification based on newly received profiles.</div>
                                <div className='sub-heading-3'>You will receive notification once the vendor shares a profile based on the job description.</div>
                            </div>
                            <div>
                                <Switch defaultChecked onChange={onChange} />
                            </div>
                        </div>
                        <div className='setting-item'>
                            <div>
                                <div className='heading-3'>Receive a notification if my shortlisted profile is hired by someone else.</div>
                                <div className='sub-heading-3'>We cannot hold profiles for an extended period, as the same profile might be shared with multiple vendors. If this option is enabled, we will notify you once your shortlisted profile is hired by someone else, and it will be removed from your shortlisted profiles.</div>
                            </div>
                            <div>
                                <Switch defaultChecked onChange={onChange} />
                            </div>
                        </div>
                    </div>
                    <div className='setting-view'>
                        <div className='heading-2'>Mail Notification</div>
                        <div className='setting-item'>
                            <div>
                                <div className='heading-3'>Receive mail based on newly received profiles.</div>
                                <div className='sub-heading-3'>You will receive mail once the vendor shares a profile based on the job description.</div>
                            </div>
                            <div>
                                <Switch defaultChecked onChange={onChange} />
                            </div>
                        </div>
                        <div className='setting-item'>
                            <div>
                                <div className='heading-3'>Receive a mail if my shortlisted profile is hired by someone else.</div>
                                <div className='sub-heading-3'>We cannot hold profiles for an extended period, as the same profile might be shared with multiple vendors. If this option is enabled, we will notify you once your shortlisted profile is hired by someone else, and it will be removed from your shortlisted profiles.</div>
                            </div>
                            <div>
                                <Switch defaultChecked onChange={onChange} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Settings;
