import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Space, Select, Row, Col, DatePicker, Upload, message } from 'antd';
const { Option } = Select;
import { MinusCircleOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import './index.css';
import { validateMaximum, validateMinAndMax, validateMinimum } from '../../common/validate';
import { putData, uploadFile } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import { useNavigate } from 'react-router-dom';
import { success } from '../../common/Notify';
import { encryptData } from '../../../actions/crypto';
const { TextArea } = Input;

const required = { required: true, message: 'This field is required' }

const FinishForm = ({ nextCall, onSubmitFromHeader, onClear, data }) => {
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const publicValue = queryParams.get('public');

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [fileResponse, setFileResponse] = useState();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
      setFileResponse(data.photo)
    }
  }, [data])

  useEffect(() => {
    if (onSubmitFromHeader == 'FINISH') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])

  const onFinish = async (values) => {
    try {
      const resData = await putData(`/vendor/developers/${encryptData(data._id)}`, { data: encryptData({ ...values, genratePDF: true }) });
      if (resData && resData.code == 200) {
        success('Profile Successfully added', "Your developer profile has been successfully added. Please wait for some time, and then you can check the 'View PDF' option in the profile list.")
        navigate('/admin/profiles/developers')
      } else {
        error('Fix Error', resData.message)
      }
    } catch (_error) {
      error('Fix Error', _error.message)
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    return isImage;
  };

  const customRequest = async ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append('file', file);
    const resData = await uploadFile(routes.fileUpload, formData)
    if (resData && resData.status == 'SUCCESS') {
      form.setFieldValue('photo', resData.files[0]);
      form.setFields([{ name: 'photo', errors: [] }]);
      setFileResponse(resData.files[0])
      message.success(`${file.name} uploaded successfully`);
      onSuccess();
    } else {
      message.error(`${file.name} Failed to upload`);
    }
  };

  const onFileChange = (info) => {
    setFileList(info.fileList);
  };

  const onDelete = () => {
    setFileResponse('')
    form.setFieldValue('photo', '');
  }

  return (
    <>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        layout='vertical'
      >
        <div className='form-view'>
          <Row span={16} justify={'space-around'}>
            <Col span={22}>
              <div>
                <h6 className='heading'>Set up your professional profile</h6>
                <p className='sub-heading'>These will help us match you with relevant work, more skills are better</p>
              </div>
              <Row gutter={16}>
                <Col span={4}>
                  <Form.Item
                    name="photo"
                    label={"Profile Photo"}
                    getValueFromEvent={onFileChange}
                    rules={[{ required: true, message: 'Please upload a photo' }]}
                  >
                    <div className='avatar-container'>
                      <img className="avatar-image" src={fileResponse ? routes.fileServer + fileResponse : require('./../../../assets/images/logo/logov1.png')}
                        onError={(e) => { e.target.src = require('./../../../assets/images/logo/logov1.png') }} alt="Profile Avatar" />
                      {!fileResponse ? <Upload
                        beforeUpload={beforeUpload}
                        customRequest={customRequest}
                        fileList={fileList}
                        showUploadList={false}
                      >
                        <Button icon={<UploadOutlined />} className="upload-button" />
                      </Upload> :
                        <Button icon={<DeleteOutlined />} onClick={onDelete} className="upload-button" />
                      }
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <p className='padding-15'>
                    Please upload a high-quality profile photo
                    Professional photos are prioritized and see more jobs with Accelgrowth clients.
                    Minimum resolution: 500 x 500 pixels
                    Maximum file size: 10MB
                  </p>
                </Col>
              </Row>
              {!publicValue && <Row gutter={16}>
                <Col span={24}>
                  <div>
                    <h6 className='heading'>Price per month (160 hours)</h6>
                    <p className='sub-heading'>These will help us match you with relevant work, more skills are better</p>
                  </div>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="Currency"
                    name="currency"
                    style={{ width: '100%' }}
                  >
                    <Select defaultValue={'INR'}>
                      <Select.Option value={"INR"}>{'INR'}</Select.Option>
                      <Select.Option value={"USD"}>{'USD'}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Minimum"
                    name="minimum"
                    style={{ width: '100%' }}
                    rules={[{
                      validator: (_, value) => {
                        return validateMinimum(_, value, form.getFieldValue('maximum'))
                      }
                    }]}
                  >
                    <Input type='number' onChange={() => form.validateFields(['maximum'])} suffix={'LPM'} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Maximum"
                    name="maximum"
                    style={{ width: '100%' }}
                    rules={[{
                      validator: (_, value) => {
                        return validateMaximum(_, value, form.getFieldValue('minimum'))
                      }
                    }]}
                  >
                    <Input type='number' onChange={() => form.validateFields(['minimum'])} suffix={'LPM'} />
                  </Form.Item>
                </Col>
              </Row>}
              <Row gutter={[16, 16]}>
                <Col span={16}>
                  <Form.Item
                    label="Short bio"
                    name="shortBio"
                    style={{ width: '100%' }}
                    rules={[required, ...validateMinAndMax(100, 1000)]}
                  >
                    <TextArea placeholder="bio"
                      count={{
                        show: true,
                        max: 1000,
                      }}
                    />
                  </Form.Item>
                </Col>

              </Row>
              <Row gutter={[16, 16]}>
                <Col span={10}>
                  <Form.Item
                    label="Developer Email"
                    name="developerEmail"
                    style={{ width: '100%' }}
                    rules={[required]}
                  >
                    <Input />
                  </Form.Item>
                  <p className='sub-heading'>AccelGrowth/Client won't connect via this email. The developer will receive a test link. Only after completing the test will their profile be approved. </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default FinishForm;
