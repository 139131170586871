import React, { useEffect, useState } from 'react';
import { Upload, Button, message, Modal, Row, Col, notification, Space, Icon } from 'antd';
import { FilePdfOutlined, FileWordOutlined, FileExcelOutlined, UploadOutlined } from '@ant-design/icons';
import { routes } from '../../../../actions/api-routes';
import { postData, uploadFile } from '../../../../actions/middleware';
import './index.css';
import { encryptData } from '../../../../actions/crypto';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });



const FileIcon = ({ fileType }) => {
    let IconComponent;
    const isDocOrPdf = ['.doc', '.docx', 'doc', 'docx'].includes(fileType.slice(-4).toLowerCase());
    const isExcel = ['.xls', '.xlsx', '.csv', 'xls', 'xlsx', 'csv'].includes(fileType.slice(-4).toLowerCase()); // Adjusted to -4

    if (isDocOrPdf) {
        IconComponent = FileWordOutlined;
    }

    if (isExcel) {
        IconComponent = FileExcelOutlined;
    }

    return IconComponent ? <IconComponent /> : null;
};


const FileUpload = ({ children, onClose }) => {
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const onFinish = async () => {
        try {
            if (fileList.length > 0) {
                customRequest().then(async (fileResData) => {
                    const payload = {
                        fileType: 'pdf_doc_excel',
                        files: fileResData
                    }
                    const resData = await postData(routes.jobs.bulk_upload, { data: encryptData(payload) });
                    if (resData && resData.code == 200) {
                        notification.success({ message: 'Success', description: 'Job added successfully. It will be posted within 30 to 60 minutes.' })
                    }
                }).catch(error => {
                    notification.success({ message: 'Error', description: 'An error occurred. Please try again later.' })
                }).finally(() => {
                    setPreviewOpen(false)
                    onClose()
                })

            } else {
                notification.error({ message: 'Validation Failed', description: 'Please upload at least 1 image.' })
                setPreviewOpen(false)
                onClose()
            }
        } catch (error) {
            notification.error({ message: 'Error', description: 'An error occurred. Please try again later.' })
        } finally {
            setPreviewOpen(false)
            onClose()
        }
    };

    const customRequest = async () => {
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();
            fileList.forEach(ele => {
                formData.append('file', ele.originFileObj);
            })
            try {
                const resData = await uploadFile(routes.fileUpload, formData);
                if (resData && resData.status === 'SUCCESS') {
                    resolve(resData.files)
                } else {
                    reject()
                    message.error(`${file.name} Failed to upload`);
                }
            } catch (error) {
                reject()
                message.error(`${file.name} Failed to upload`);
            }
        })
    };
    const beforeUpload = (file) => {
        console.log("filefilefile", file, file.name.slice(-4).toLowerCase())
        const isDocOrPdf = ['.pdf', '.doc', '.docx', 'pdf', 'doc', 'docx'].includes(file.name.slice(-4).toLowerCase());
        const isExcel = ['.xls', '.xlsx', '.csv', 'xls', 'xlsx', 'csv'].includes(file.name.slice(-4).toLowerCase()); // Adjusted to -4

        if (!isDocOrPdf && !isExcel) {
            message.error('You can only upload PDF, DOC, DOCX, XLS, or XLSX files!');
            return false;
        }
        return false;
    };

    const validateFile = (file) => {
        const isDocOrPdf = ['.pdf', '.doc', '.docx', 'pdf', 'doc', 'docx'].includes(file.name.slice(-4).toLowerCase());
        const isExcel = ['.xls', '.xlsx', '.csv', 'xls', 'xlsx', 'csv'].includes(file.name.slice(-4).toLowerCase()); // Adjusted to -4

        if (!isDocOrPdf && !isExcel) {
            message.error('You can only upload PDF, DOC, DOCX, XLS, or XLSX files!');
            return false;
        }
        return true;
    };

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        const isDocOrPdf = ['.pdf', 'pdf'].includes(file.name.slice(-4).toLowerCase());
        if (isDocOrPdf) {
            setPreviewImage(file.url || file.preview);
        } else {
            setPreviewImage(null)
        }
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => {
        const fileResponse = newFileList.filter(file => validateFile(file));
        setFileList(fileResponse)
        if (fileResponse.length > 0) {
            handlePreview(fileResponse[0])
        }
    };


    return (
        <div className='pdc_doc_excel'>
            <Upload
                listType="text"
                fileList={fileList}
                accept=".pdf,.doc,.docx,.xls,.xlsx,.csv"
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
                maxCount={1}
                showUploadList={false}
            >
                {children}
            </Upload>
            <Modal open={previewOpen} title={'Post File'} footer={
                <Space>
                    <Button type='default' onClick={handleCancel}>Cancel</Button>
                    <Button type='primary' onClick={onFinish}>Post</Button>
                </Space>
            } onCancel={handleCancel}>
                {previewImage && <iframe
                    title="File Preview"
                    width="100%"
                    height="500px"
                    src={previewImage}
                />}
                {!previewImage && <div className='file-list'>
                    <div className='file-icon'><FileIcon fileType={previewTitle} /></div> {previewTitle}
                </div>}
            </Modal>
        </div >
    );
};

export default FileUpload;
