import React, { useState, useEffect } from 'react';
import { Slider, Select, Radio, Row, Col, Input, Button } from 'antd';
import './index.css';

const { Option } = Select;

const TextToSpeech = () => {
    const [text, setText] = useState('');
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [pitch, setPitch] = useState(1);
    const [rate, setRate] = useState(1);
    const [volume, setVolume] = useState(1);
    const [emotion, setEmotion] = useState('normal');
    const [audioUrl, setAudioUrl] = useState('');
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioChunks, setAudioChunks] = useState([]);

    useEffect(() => {
        const fetchVoices = () => {
            const availableVoices = window.speechSynthesis.getVoices();
            setVoices(availableVoices);
        };

        window.speechSynthesis.onvoiceschanged = fetchVoices;

        fetchVoices();
        return () => {
            window.speechSynthesis.onvoiceschanged = null;
        };
    }, []);

    const handleVoiceChange = (value) => {
        setSelectedVoice(value);
    };

    const handlePitchChange = (value) => {
        setPitch(value);
    };

    const handleRateChange = (value) => {
        setRate(value);
    };

    const handleVolumeChange = (value) => {
        setVolume(value);
    };

    const handleEmotionChange = (e) => {
        setEmotion(e.target.value);
        // Adjust pitch, rate, and volume based on the selected emotion
        switch (e.target.value) {
            case 'angry':
                setPitch(2);
                setRate(1.5);
                setVolume(0.8);
                break;
            case 'sad':
                setPitch(0.8);
                setRate(0.8);
                setVolume(0.6);
                break;
            case 'happy':
                setPitch(1.5);
                setRate(1.2);
                setVolume(1);
                break;
            case 'normal':
            default:
                setPitch(1);
                setRate(1);
                setVolume(1);
                break;
        }
    };

    const speak = () => {
        const utterance = new SpeechSynthesisUtterance(text);
        if (selectedVoice) {
            const selectedVoiceObject = voices.find(voice => voice.name === selectedVoice);
            if (selectedVoiceObject) {
                utterance.voice = selectedVoiceObject;
            }
        }
        utterance.pitch = pitch;
        utterance.rate = rate;
        utterance.volume = volume;

        window.speechSynthesis.speak(utterance);
    };

    const handleStartRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const recorder = new MediaRecorder(stream);
                recorder.ondataavailable = handleDataAvailable;
                recorder.start();
                setMediaRecorder(recorder);
                setIsRecording(true);
            })
            .catch(err => console.error('Error initializing MediaRecorder: ', err));
    };

    const handleStopRecording = () => {
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
            setIsRecording(false);
        }
    };

    const handleDataAvailable = (event) => {
        setAudioChunks(prevChunks => [...prevChunks, event.data]);
    };

    const playAudio = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        setAudioUrl(URL.createObjectURL(audioBlob));
    };

    const handleDownload = () => {
        if (audioChunks.length > 0) {
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
            const url = URL.createObjectURL(audioBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'recorded_audio.wav';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };

    return (
        <div className='bg-text-speech'>
            <div className='speech-box'>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <div className='voice-setting'>
                            <div>
                                <Select value={selectedVoice} onChange={handleVoiceChange} style={{ width: 200 }}>
                                    <Option value="">Select Voice</Option>
                                    {voices.map((voice, index) => (
                                        <Option key={index} value={voice.name}>
                                            {voice.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <Radio.Group value={emotion} onChange={handleEmotionChange}>
                                    <Radio value="normal">Normal</Radio>
                                    <Radio value="angry">Angry</Radio>
                                    <Radio value="sad">Sad</Radio>
                                    <Radio value="happy">Happy</Radio>
                                </Radio.Group>
                            </div>
                            <div>
                                <Slider
                                    value={pitch}
                                    min={0.1}
                                    max={2}
                                    step={0.1}
                                    onChange={handlePitchChange}
                                />
                                <span>Pitch: {pitch}</span>
                            </div>
                            <div>
                                <Slider
                                    value={rate}
                                    min={0.1}
                                    max={10}
                                    step={0.1}
                                    onChange={handleRateChange}
                                />
                                <span>Rate: {rate}</span>
                            </div>
                            <div>
                                <Slider
                                    value={volume}
                                    min={0}
                                    max={1}
                                    step={0.1}
                                    onChange={handleVolumeChange}
                                />
                                <span>Volume: {volume}</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={18}>
                        <div>
                            <Input.TextArea placeholder='Enter text...' rows={10} value={text} onChange={(e) => setText(e.target.value)} />
                            {audioUrl && <audio controls src={audioUrl} />}
                        </div>
                        <div className='button-voice'>
                            <Button type='primary' onClick={playAudio}>Play Audio</Button>
                            <Button type='primary' onClick={speak}>Speak</Button>
                            {isRecording ?
                                <Button type='primary' onClick={handleStopRecording}>Stop Recording</Button> :
                                <Button type='primary' onClick={handleStartRecording}>Start Recording</Button>
                            }
                            <Button type='primary' onClick={handleDownload}>Download</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TextToSpeech;
