import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useEffect, useState } from 'react';
import './modal/index.css'

function PdfViewer({ isOpen, onClose, children, title, pdfUrl, ...props }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      onOpenModal();
    }
  }, [isOpen]);

  const onOpenModal = () => {
    setOpen(true)
  };
  const onCloseModal = () => {
    setOpen(false)
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        style={{maxWidth: '100% !important'}}
        onClose={onCloseModal}
        center
      >
        <div className='modal-head heading'>{title}</div>
        <div className='divider'></div>
        <iframe src={pdfUrl} style={{ width: '100%', height: '90vh' }}>
        </iframe>
      </Modal >
    </div>
  )
}

export default PdfViewer;