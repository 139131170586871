import React from 'react';
import { DownOutlined, EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
const items = [
  {
    key: 'view',
    label: 'View',
    icon: <EyeOutlined />
  },
  {
    key: 'edit',
    label: 'Edit',
    icon: <EditOutlined />
  },
  {
    key: 'View Pdf',
    label: 'View Pdf',
    icon: <EyeOutlined />
  },
  {
    key: 'delete',
    label: 'Delete',
    icon: <DeleteOutlined />
  },

];

const Actions = ({ onChangeMenu, config = ['view', 'edit', 'delete'] }) => {
  return <Dropdown menu={{ items: items.filter(ele => config.includes(ele.key)), onClick: onChangeMenu }} >
    <a onClick={(e) => e.preventDefault()}>
      <div className='more-button'>
        <Space>
          More
          <DownOutlined size={'small'} />
        </Space>
      </div>
    </a>
  </Dropdown>
};
export default Actions;