import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Alert, Button, Col, Image, Row, Space, Tag, Modal } from 'antd';
import './index.css';
import { getData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import ResourcesFilter from './filter';
import { decryptData, encryptData } from '../../../actions/crypto';
import ProfileViewModel from './viewProfile';
import { CloseCircleOutlined } from '@ant-design/icons';
import SelectedProfiles from './selectedProfiles';
const { confirm } = Modal

const Resources = ({ }) => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [profiles, setProfiles] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [isVisible, setVisible] = useState(false);
    const [selectedData, setSelectedData] = useState(null)
    const [shortlistedProfiles, setShortlistedProfiles] = useState([])

    const [page, setPage] = useState(1);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (hasMore) {
            getProfiles();
        }

        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [page, hasMore])

    const getProfiles = async (skils = []) => {
        const resData = await getData(routes.profiles.adminList, { current: page, listFor: encryptData('approved') });
        if (resData.code == 200) {
            const data = decryptData(resData.data)
            if (page == resData.pagination.current) {
                if (data.length < 14) {
                    setHasMore(false);
                    setProfiles(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                } else {
                    setProfiles(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                }
            }
        }
    }

    const handleScroll = () => {
        const container = containerRef.current;
        if (
            container.scrollTop + container.clientHeight === container.scrollHeight
        ) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const totalSize = (yourArray) => {
        if (yourArray.length > 2) {
            return yourArray.length - 2;
        } else {
            return 0
        }
    }

    const onSelectData = (data) => {
        setSelectedData(data)
        setVisible(true)
    }

    const onSelectToHire = (item) => {
        setShortlistedProfiles(prevData => {
            const newData = [item].filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
            return [...prevData, ...newData];
        });
    }

    const onRemove = (id) => {
        confirm({
            title: 'Are you sure want to remove profile?',
            onOk: () => onCloseTag(id)
        })
    }

    const onCloseTag = (e) => {
        let prevData = []
        if (e) {
            prevData = shortlistedProfiles.filter(ele => ele._id != e)
        }
        setShortlistedProfiles(prevData)
    }

    const checkSelected = (id) => {
        return shortlistedProfiles.filter(ele => ele._id == id).length > 0
    }

    return (
        <div className='profile-wrapper' ref={containerRef}>
            <ProfileViewModel isVisible={isVisible && selectedData} profileData={selectedData} onClose={() => setVisible(false)} />
            <div className='header'>
                <h6 className='heading pb-2'>Profile's</h6>
                {/* {shortlistedProfiles.length > 0 && <div className='alert-box' style={{ marginTop: '-10px' }}>
                    <Alert type='info' message={getTags()} />
                </div>} */}
            </div>
            <Row gutter={[16, 16]}>
                {shortlistedProfiles.length == 0 ? <Col span={6}>
                    <ResourcesFilter />
                </Col> :
                    <Col span={6}>
                        <SelectedProfiles profiles={shortlistedProfiles} onCloseTag={(e) => onCloseTag(e)} />
                    </Col>}
                <Col span={18}>
                    <div className='profile-listings' style={{ maxHeight: (windowHeight - 145), overflowY: 'auto', }} ref={containerRef}>
                        <Row gutter={[16, 16]}>
                            {profiles.map(item => <Col span={8}>
                                <div className='view-box bg-white job-dscription profile' style={{}}>
                                    <div className='d-f justify-between'>
                                        <div>
                                            <div className='job-title-view'>
                                                <div className='img-container-developer'>
                                                    <Image src={item?.photo ? routes.fileServer + item?.photo : require('./../../../assets/images/logo/logo-w.png')}
                                                        onError={(e) => { e.target.src = require('./../../../assets/images/logo/logo-w.png') }}
                                                    />
                                                </div>
                                                <div className='heading-view-for-rol padding-left'>
                                                    <div className='job-title-box'>
                                                        <div className='main-text'> {[item?.firstName, item?.lastName].join(' ')}</div>
                                                        <div className='sub-text'> {item?.Experiance}+ Year</div>
                                                    </div>
                                                    <div className='price-view'>
                                                        {item?.JobTitle}
                                                    </div>
                                                    <div className='price-view'>
                                                        <Tag color='magenta' bordered={false}>{[...item?.locations, ...item?.onsiteOptions].join(' | ')}</Tag>
                                                        {/* | <Tag color='cyan' bordered={false}>{item?.currency} {item?.minimum}LPM - {item?.maximum}LPM</Tag> */}
                                                    </div>
                                                    <div className=''>
                                                        {item?.skills?.slice(0, 2).map(ele => <span className='tag'> #{ele.skillName}</span>)}
                                                        <span className='tag'> {totalSize(item?.skills)}+</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='footer-box'>
                                        <Space>
                                            {checkSelected(item._id) ? <Button type='link' danger onClick={() => onRemove(item._id)}>Remove</Button> :
                                                <Button type='link' onClick={() => onSelectToHire(item)}>Select to hire</Button>}
                                            <Button onClick={() => onSelectData(item)} type='link'>View profile</Button>
                                        </Space>
                                    </div>
                                </div>
                            </Col>)}
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Resources;
