// AdminRoutes.js
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import UploadProfile from '../admin/uplod-profile';
import UploadRequirement from '../admin/Jobs/my-requirments/upload-requirement';
import QuikUploadRequirment from '../admin/Jobs/my-requirments/quik-upload';
import QuikResumeUploadRequirment from '../admin/uplod-profile/quik-upload';
import axios from "axios";
import CalculatorView from '../pages/calculator';

const PublicRoutes = ({ pinned }) => {
  const queryParams = new URLSearchParams(location.search);
  const publicValue = queryParams.get('public');

  useEffect(() => {
    axios.defaults.headers.common.defaultValue = publicValue;
  }, [queryParams, publicValue])

  return (
    <>
      <Routes>
        <Route path="/profiles/upload-profile" element={<UploadProfile pinned={true} isPublic={true} />} />
        <Route path="/profiles/quik/upload-profile" element={<QuikResumeUploadRequirment pinned={true} isPublic={true} />} />
        <Route path="/requirements/add" element={<UploadRequirement />} />
        <Route path="/requirements/quik-upload" element={<QuikUploadRequirment />} />
        <Route path="/calulator" element={<CalculatorView />} />
      </Routes>
    </>
  );
};

export default PublicRoutes;
