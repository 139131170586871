import { useNavigate } from 'react-router-dom';
import './index.css';
import { Form, Input, Button, Checkbox, Row, Col, Segmented, message, notification } from 'antd';
import { UserOutlined, InsertRowLeftOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { validateCINGSTN, validateEmail, validatePhoneNumber, validateWebsiteUrl } from '../admin/common/validate';
import { postData } from '../actions/middleware';
import { useEffect } from 'react';
import { getUserData } from '../actions/auth';
import { setLocalStorage } from '../actions/localStorage';
import constant from '../actions/constant';
const { useForm } = Form

function Register() {
  const [form] = useForm()
  const navigate = useNavigate()
  const [registrationType, setRegistrationType] = useState('Organisation');

  const onFinish = async (values) => {
    try {
      const resData = await postData('/vendor/register', { ...values, loginType: registrationType })
      if (resData && resData.code == 200) {
        setLocalStorage(constant.LOGIN_KEY, values.workEmail);
        notification.success({
          message: 'Registered Successfully',
          description: 'You have successfully registered. Please check your email and change your password.'
        })
        navigate('/auth/verify-otp')
      } else {
        notification.error({
          message: 'Error',
          description: resData.message
        })
      }
    } catch (error) {
      notification.success({
        message: 'Error',
        description: error.message
      })
    }
  };

  const changeType = (type) => {
    setRegistrationType(type)
  }

  useEffect(() => {
    getUserDetails();
  }, [])


  const getUserDetails = () => {
    const data = getUserData();
    if (data) {
      navigate('/admin')
    }
  }

  const handleInputChange = (e) => {
    const uppercasedValue = e.target.value.toUpperCase();
    form.setFieldsValue({ cin_gstn: uppercasedValue });
  };


  return (
    <div id="main-wrapper" className='login-register formView'>
      <div className="site-wrapper-reveal">
        <div className="row">
          <div className="col-lg-7 col-md-7 center pt-10">
            <div className="projects-wrap style-04 wow move-up">
              <div className="projects-image-box">
                <div className="content">
                  <div className='text-center p-10'>
                    <h5 className='heading'>Create your Accelgrowth Account</h5>
                    <div>Embark on a journey of unparalleled professional advancement with Accelgrowth, an esteemed network dedicated to connecting exceptional individuals with leading-edge opportunities. Elevate your career trajectory as we open doors to premier companies and invaluable resources, propelling you towards accelerated growth and unparalleled success.</div>
                  </div>
                  <div className='switch-view'>
                    <Segmented
                      size='large'
                      onChange={changeType}
                      default={registrationType}
                      options={[
                        {
                          label: 'Organisation',
                          value: 'Organisation',
                          icon: <InsertRowLeftOutlined />,
                        },
                        {
                          label: 'Individual',
                          value: 'Individual',
                          icon: <UserOutlined />,
                        },
                      ]}
                    />
                  </div>
                  <Form
                    name="basic"
                    form={form}
                    initialValues={{
                      remember: true,
                    }}
                    layout='vertical'
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="First Name"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Last Name"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {registrationType == 'Organisation' && <Col span={12}>
                        <Form.Item
                          label="Job Title"
                          name="jobTitle"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>}
                      {registrationType == 'Organisation' && <Col span={12}>
                        <Form.Item
                          label="Company Name"
                          name="companyName"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>}
                      {registrationType == 'Organisation' && <Col span={12}>
                        <Form.Item
                          label="Website"
                          name="website"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                            { validator: validateWebsiteUrl },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>}
                      <Col span={12}>
                        <Form.Item
                          label="Work Email"
                          name="workEmail"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                            {
                              validator: validateEmail
                            }
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Phone Number"
                          name="phoneNumber"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                            {
                              validator: validatePhoneNumber
                            }
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="City"
                          name="city"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {registrationType == 'Organisation' && <Col span={12}>
                        <Form.Item
                          label="CIN/GSTN"
                          name="cin_gstn"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required',
                            },
                            {
                              validator: validateCINGSTN
                            }
                          ]}
                        >
                          <Input onChange={handleInputChange} />
                        </Form.Item>
                      </Col>}
                    </Row>
                    <Form.Item
                    >
                      <div className='text-center'>
                        <Button type="primary" htmlType='submit' size={'large'}>
                          Register
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </div>
              <div className='text-center'>
                <div>I have an account ? <Button className='no-padding' type='link' onClick={() => navigate('/auth/login')} >Sign In</Button> </div>
                <div> Please <Button type='link' className='no-padding'>Contact Us</Button> if you require any assistance  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register;
