import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Upload, Button, Tabs, Empty, Row, Col } from 'antd';
import './../../index.css';
import { getData } from '../../../../actions/middleware';
import { routes } from '../../../../actions/api-routes';
import { decryptData, encryptData } from '../../../../actions/crypto';
import BulkListItem from './bulkListItem';

const BulkNewProfile = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [Profile, setRequirement] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [listFor, setListFor] = useState('review');
    const [page, setPage] = useState(1);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (hasMore) {
            getProfile(listFor);
        }

        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [page, hasMore])

    const reloadPage = () => {
        setRequirement([])
        setHasMore(true)
        setPage(1)
        getProfile(listFor);
    }

    const getProfile = async (listFor = 'review') => {
        const resData = await getData(routes.developer.bulkUploadList, { current: page, listFor: encryptData(listFor) });
        if (resData.code == 200) {
            const data = decryptData(resData.data)
            console.log("datadatadata", data)
            if (page == resData.pagination.current) {
                if (data.length < 14) {
                    setHasMore(false);
                    setRequirement(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                } else {
                    setRequirement(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                }
            }
        }
    }

    const handleScroll = () => {
        const container = containerRef.current;
        if (
            container.scrollTop + container.clientHeight === container.scrollHeight
        ) {
            setPage(prevPage => prevPage + 1);
        }
    };

    return (
        <div className='page-view' style={{ maxHeight: windowHeight - 160, overflowY: 'auto' }} ref={containerRef}>
            <Row>
                {Profile.length > 0 && Profile.map(ele => <Col lg={8} md={12}> <BulkListItem reloadPage={reloadPage} item={ele} style={{ margin: "10px 10px", marginTop: '1px', marginLeft: '3px', paddingBottom: '10px' }} /></Col>)}
            </Row>
            {Profile.length == 0 && <Empty />}
        </div>
    );
}

export default BulkNewProfile;
