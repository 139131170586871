import React from 'react';
import { Form, Input, DatePicker, Button, Col, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import DynamicSelect from '../../common/Select';
import { useEffect } from 'react';
import { putData } from '../../../actions/middleware';
import { formatDates, validateMinAndMax } from '../../common/validate';
import { encryptData } from '../../../actions/crypto';

const { Options } = Select;
const { TextArea } = Input;
const { useForm } = Form;

const EmploymentForm = ({ data, onSubmitFromHeader, onClear, nextCall }) => {
  const initialValue = (data && data.employment.length > 0) ? { employment: data ? formatDates(data.employment) : [{}] } : {};

  const [form] = useForm()
  useEffect(() => {
    if (!data) {
      nextCall(0)
    }
  }, [data])

  useEffect(() => {
    if (onSubmitFromHeader == 'EMPLOYMENT') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])

  const onFinish = async (values) => {
    console.log('Received values:', values);
    try {
      const resData = await putData(`/vendor/developers/${encryptData(data._id)}`, { data: encryptData({ ...values }) });
      if (resData && resData.code == 200) {
        nextCall(5)
      } else {
        error('Fix Error', resData.message)
      }
    } catch (_error) {
      error('Fix Error', _error.message)
    }
  };

  return (
    <Form
      name="EmploymentForm"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      initialValues={initialValue}
    >
      <div className='form-view'>
        <Row span={16} justify={'space-around'}>
          <Col span={22}>
            <Form.List name="employment" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                    <div key={key} style={{ border: '1px dashed lightgray', padding: '10px', marginBottom: '10px' }}>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'companyName']}
                            label="Company name"
                            fieldKey={[fieldKey, 'companyName']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <DynamicSelect
                            {...restField}
                            name={[name, 'jobTitle']}
                            label="Job Title"
                            fieldKey={[fieldKey, 'jobTitle']}
                            keyName={'jobTitle'}
                            rules={[{ required: true, message: 'Please select job title' }]}
                          />
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'location']}
                            label="Location"
                            fieldKey={[fieldKey, 'location']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <DynamicSelect
                            {...restField}
                            name={[name, 'employmentType']}
                            label="Employment type"
                            keyName={'employmentType'}
                            fieldKey={[fieldKey, 'employmentType']}
                            rules={[{ required: true, message: 'Please select Employment Type' }]}
                          />
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'startDate']}
                            label="Start Date"
                            fieldKey={[fieldKey, 'startDate']}
                            rules={[{ required: true, message: 'Please select Start Date' }]}
                          >
                            <DatePicker.MonthPicker format="MM/YYYY" placeholder="Select Month and Year" />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'endDate']}
                            label="End Date"
                            fieldKey={[fieldKey, 'endDate']}
                            rules={[{ required: true, message: 'Please select End Date' }]}
                          >
                            <DatePicker.MonthPicker format="MM/YYYY" placeholder="Select Month and Year" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'responsibilities']}
                            label="Your Responsibilities"
                            fieldKey={[fieldKey, 'responsibilities']}
                            rules={[{ required: true, message: 'Please enter Your Responsibilities' }, ...validateMinAndMax(100, 1000)]}
                          >
                            <TextArea placeholder="Your Responsibilities" count={{ show: true, max: 1000 }} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item>
                            <Button
                              type="link"
                              onClick={() => remove(name)}
                              icon={<MinusCircleOutlined />}
                              disabled={fields.length === 1}
                              style={{ padding: '0px 0px' }}
                            >
                              Remove
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      style={{ float: 'right' }}
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}>
                      Add More Records
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </div>
      {/* <Row gutter={16} className='botton-footer'>
        <Col span={2}>
          <Form.Item>
            <Button type="dashed" size='middle' onClick={() => nextCall(3)}>
              Previous
            </Button>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item>
            <Button type="primary"
              className='float-right'
              htmlType="submit" size='middle'>
              Save & Next
            </Button>
          </Form.Item>
        </Col>
      </Row> */}
    </Form>
  );
};

export default EmploymentForm;
