import React from 'react';
import { useState } from 'react';
import { Alert, Avatar, Button, Checkbox, Col, Divider, Image, Modal, Radio, Row, Space, Table, Tag, message } from 'antd';
import { UserAddOutlined, GlobalOutlined, HomeOutlined } from '@ant-design/icons';
import './index.css';
import { routes } from '../../../../../actions/api-routes';
import { encryptData } from '../../../../../actions/crypto';
import { putData } from '../../../../../actions/middleware';
import moment from 'moment';
import PDFViewer from '../../../../../actions/table-configs/files/pdfViewer';
import UpdateBulkRequierment from '../modal';

let tags = {
    "PENDING": { name: 'PENDING', color: 'warning' },
    "WAITING_APPROVAL": { name: 'WAITING FOR APPROVAL', color: 'magenta' },
    "APPROVED": { name: 'APPROVED', color: 'green' },
    "REJECTED": { name: 'REJECTED', color: 'error' },
    "IN_PROGRES": { name: 'IN PROGRES', color: 'info' }
}


const BulkListItem = ({ style, item, reloadPage }) => {
    const [showFullText, setShowFullText] = useState(false);
    const [maxChars, setmaxChars] = useState(200);
    const [isVisible, setVisible] = useState(false);
    const [itemData, setItem] = useState(null);
    const [viewFile, setViewFile] = useState(false);

    const handleSeeMore = () => {
        setShowFullText(true);
    };

    const handleSeeLess = () => {
        setShowFullText(false);
    };

    const approveReject = async (_id, status) => {
        const data = encryptData({ approvalStatus: status })
        const resData = await putData(routes.jobs.list + encryptData(_id), { data })
        if (resData && resData.code == 200) {
            message.success('Successfully updated')
            reloadPage();
        } else {
            message.error('Something went wrong, please try again later')
        }
    }

    const prompt = (_id, status) => {
        Modal.confirm({
            title: 'Confirm',
            content: 'Are you sure you want to reject this item?',
            onOk() {
                approveReject(_id, status)
            },
            onCancel() { },
        });
    }

    const openFile = (_file) => {
        const file = routes.fileServer + _file.file
        if (file.endsWith('.pdf')) {
            setViewFile(true)
        } else {
            var link = document.createElement('a');
            link.href = file;
            link.download = file.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <div className='view-box bg-white job-dscription' style={style}>
            <Modal
                open={viewFile}
                onCancel={() => setViewFile(false)}
                width={'80%'}
            >
                <PDFViewer fileUrl={routes.fileServer + item.file} />
            </Modal>
            <UpdateBulkRequierment isVisible={isVisible} onClose={() => {
                setVisible(false)
                setItem(null)
            }} reloadPage={reloadPage} data={itemData} />
            <div className='d-f justify-between'>
                <div>
                    <div className='job-title-view'>
                        <div className='icon-view'>
                            {item?.jobLocationType == 'Remote' && <Avatar icon={<HomeOutlined title='Remote' />} />}
                            {item?.jobLocationType != 'Remote' && <Avatar icon={<GlobalOutlined title='Office' />} />}
                        </div>
                        <div className='heading-view-for-rol'>
                            <div className='job-title-box'>
                                <div className='main-text'> {moment(item?.createdAt).format('lll')}</div>
                            </div>
                            <div className='price-view'>
                                <Tag color='magenta' bordered={false}>{item?.fileType}</Tag>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-box'>
                <div className={`text-container ${showFullText ? 'show-full' : ''}`}>
                    <div className={`text ${showFullText ? 'show-full-text' : ''}`}>
                        {item?.fileType == 'text' && <div dangerouslySetInnerHTML={{ __html: showFullText ? item?.jobDiscription : item?.jobDiscription.slice(0, maxChars) }}>
                        </div>}
                        {item?.fileType == "images" && <div className='img-container'>
                            <Image src={routes.fileServer + item?.file} />
                        </div>}
                        {item?.fileType == "pdf_doc_excel" && <div className='img-container'>
                            <Button type='default' onClick={() => openFile(item)}>{item?.file.endsWith('.pdf') ? 'View PDF' : 'Download'}</Button>
                        </div>}
                        {item?.fileType == "Text" && !showFullText && item?.jobDiscription.length > maxChars && (
                            <Button type='link' className='button-seemore' style={{ padding: 0 }} onClick={handleSeeMore}>
                                ...see more
                            </Button>
                        )}
                        {item?.fileType == "Text" && showFullText && (
                            <Button type='link' className='button-seemore' style={{ padding: 0 }} onClick={handleSeeLess}>
                                see less
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className='tag-box d-f justify-between'>
                <Space>
                    <div className='bold-text'><Space><UserAddOutlined />Shubham</Space></div>
                    <div><Space><GlobalOutlined />Accelgrowth Technology</Space></div>
                </Space>
                <Space>
                    <Tag color={tags[item.approvalStatus].color} bordered={false}>{tags[item.approvalStatus].name}</Tag>
                </Space>
            </div>
        </div>
    );
};

export default BulkListItem;
