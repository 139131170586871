import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Upload, Button, Tabs, Row, Col, Empty } from 'antd';
import './../../index.css';
import ListItem from '../listItem';
import { getData } from '../../../../actions/middleware';
import { routes } from '../../../../actions/api-routes';
import { decryptData, encryptData } from '../../../../actions/crypto';

const NewProfiles = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [profileData, setProfiles] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [listFor, setListFor] = useState('review');
    const [page, setPage] = useState(1);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (hasMore) {
            getProfiles(listFor);
        }

        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [page, hasMore])

    const reloadPage = () => {
        setProfiles([])
        setHasMore(true)
        setPage(1)
        getProfiles(listFor);
    }

    const getProfiles = async (listFor = 'review') => {
        const resData = await getData(routes.profiles.adminList, { current: page, listFor: encryptData(listFor) });
        if (resData.code == 200) {
            const data = decryptData(resData.data)
            console.log("datadatadata", data)
            if (page == resData.pagination.current) {
                if (data.length < 14) {
                    setHasMore(false);
                    setProfiles(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                } else {
                    setProfiles(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                }
            }
        }
    }

    const handleScroll = () => {
        const container = containerRef.current;
        if (
            container.scrollTop + container.clientHeight === container.scrollHeight
        ) {
            setPage(prevPage => prevPage + 1);
        }
    };

    return (
        <div className='page-view' style={{ maxHeight: windowHeight - 160, overflowY: 'auto' }} ref={containerRef}>
            <Row>
                {profileData.length > 0 && profileData.map(ele => <Col lg={8} md={12} sm={24}><ListItem reloadPage={reloadPage} item={ele} style={{ margin: "10px 10px", marginTop: '1px', marginLeft: '3px', paddingBottom: '10px' }} /></Col>)}
                {profileData.length == 0 && <Empty />}
            </Row>
        </div>
    );
}

export default NewProfiles;
