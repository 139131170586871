const TAB_KEY = {
    APPLICATIONS: '1',
    SYSTEM_GENRATED: '2',
    SHORTLISTED: '3',
    REJECTED: '4'
}

const PROFILES_FOR_JOB = [{
    key: TAB_KEY.APPLICATIONS,
    label: 'Applications',
},
{
    key: TAB_KEY.SYSTEM_GENRATED,
    label: 'System Generated',
},
{
    key: TAB_KEY.SHORTLISTED,
    label: 'Shortlisted',
},
{
    key: TAB_KEY.REJECTED,
    label: 'Rejected',
},
]


export { PROFILES_FOR_JOB, TAB_KEY }