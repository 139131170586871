import moment from "moment";
import { Link } from "react-router-dom";
import { Switch, Tag } from "antd";

import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    EditOutlined
} from '@ant-design/icons';
import { putData } from "../../../actions/middleware";
import Actions from "../../../actions/table-configs/actions";
import { encryptData } from "../../../actions/crypto";

const testAllotConfig = (actions, config, refreshData, openModal) => [
    {
        title: 'Actions',
        dataIndex: '',
        key: '',
        ellipsis: true,
        render: (text) => {
            let array = []
            if (text && !text.pdfFile) {
                array.push('View Pdf')
            }
            if (text && text.approvalStatus == 'APPROVED') {
                array.push('delete')
            }
            return <Actions onChangeMenu={(e) => Actions(e, text)} config={config.filter(e => !array.includes(e))} />
        }
    },
    {
        title: 'Test name',
        dataIndex: '',
        key: '',
        ellipsis: true,
        render: (text) => {
            const link = `AGPF${text.profile}-${text.profileID}`
            return <Link to={`/profile-details?developer=${link}`} className="link_text" target="_blank">{`AGPF${text.profile}`}</Link>
        }
    },
    {
        title: 'Time required',
        dataIndex: '',
        key: '',
        ellipsis: true,
        render: (text) => {
            return <div style={{ textTransform: 'capitalize' }}>{[text.firstName, text.lastName].join(" ")}</div>
        }
    },
    {
        title: 'Expire in',
        dataIndex: 'JobTitle',
        key: 'JobTitle',
        ellipsis: true,
        render: (text) => {
            return text
        }
    },
    {
        title: 'Skills',
        dataIndex: '',
        key: '',
        ellipsis: true,
        render: (text) => {
            return <div>{text.Experiance} Years <EditOutlined className="cursor-pointer" onClick={() => openModal(text, 'Experiance')} /></div>
        }
    },
    {
        title: 'Status',
        dataIndex: '',
        key: '',
        ellipsis: true,
        render: (text) => {
            return <div style={{ textTransform: 'capitalize' }}>{text.locations.join('/')} <EditOutlined className="cursor-pointer" onClick={() => openModal(text, 'locations')} /></div>
        }
    },
    {
        title: 'Created at',
        dataIndex: '',
        key: '',
        ellipsis: true,
        render: (text) => {
            return <div>{text.currency} - {text.minimum ? text.minimum : 0} to {text.maximum ? text.maximum : 0} LPM <EditOutlined className="cursor-pointer" onClick={() => openModal(text, 'Price')} /></div>
        }
    }
];



export { testAllotConfig }