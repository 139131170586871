import React, { useState, useEffect } from 'react';
import {
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Flex, Image, Layout, Menu, theme, Typography } from 'antd';
import { getAapplicant, getApplicantData, logoutApplicant } from "../actions/auth";
import './home.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { encryptData } from '../actions/crypto';

const { Title, Text } = Typography;

const { Header, Sider, Content } = Layout;
const ApplicantHome = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [userData, setUserData] = useState(null);

    const navigate = useNavigate()
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        getUserDetails();
    }, [])

    const getUserDetails = async () => {
        const data = getApplicantData();
        getAapplicant();
        setUserData(data)
    }

    const handleMenuClick = (e) => {
        logoutApplicant();
    };
    const items = [
        {
            label: 'LogOut',
            key: '1',
            icon: <LogoutOutlined />,
        },
    ];
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const onchange = (e) => {
        console.log("onchangeonchangeonchange", e)
        const data = getApplicantData();
        if (e.key == '/status/') {
            navigate('/interview/applicant' + e.key + encryptData(data._id))
        } else {
            navigate('/interview/applicant' + e.key)
        }
    }

    const getinitial = (initials) => {
        if (initials) {
            const words = [initials.firstName, initials.lastName]
            const _initials = words.map(word => word[0].toUpperCase()).join('');
            return _initials;
        } else {
            return ''
        }
    }

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider trigger={null} theme='light' collapsible collapsed={collapsed}>
                <div
                    style={{
                        height: 64,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                    }}
                >
                    <Image preview={false} src={require('./../../src/assets/images/logo/logov1.png')} />
                </div>
                <Menu
                    theme="light"
                    mode="inline"
                    onClick={(e) => onchange(e)}
                    defaultSelectedKeys={['1']}
                    items={[
                        {
                            key: '/status/',
                            icon: <UserOutlined />,
                            label: 'Status',
                        },
                        {
                            key: '/offer',
                            icon: <UserOutlined />,
                            label: 'Offer Letter',
                        }
                    ]}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                    }}
                >
                    <Flex justify='space-between'>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                        <Dropdown menu={menuProps}>
                            <div className='card_view'>
                                {userData && <Avatar size={'large'}>{getinitial(userData)}</Avatar>}
                                <div className='card_name_designation'>
                                    <Title level={5} style={{ margin: 0 }}>{userData?.firstName} {userData?.lastName}</Title>
                                    <Text type='secondary'>User</Text>
                                </div>
                            </div>
                        </Dropdown>
                    </Flex>
                </Header>
                <Content
                    className='applicant'
                    style={{
                        margin: '16px 16px',
                        height: '100vh'
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};
export default ApplicantHome;