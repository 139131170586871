import React, { useState } from 'react';
import { Slider, InputNumber, Row, Col } from 'antd';

const ExperienceSlider = () => {
  const [experienceRange, setExperienceRange] = useState({ min: 0, max: 70 });

  const onChange = value => {
    setExperienceRange({ ...experienceRange, min: value[0], max: value[1] });
  };

  const onMinInputChange = value => {
    setExperienceRange({ ...experienceRange, min: isNaN(value) ? 0 : value });
  };

  const onMaxInputChange = value => {
    setExperienceRange({ ...experienceRange, max: isNaN(value) ? 0 : value });
  };

  return (
    <Row>
      <Col span={24}>
        <Slider range min={0} max={70} onChange={onChange} value={[experienceRange.min, experienceRange.max]} />
      </Col>
      <Col span={12}>
        <InputNumber
          min={0}
          max={70}
          style={{width: '70px'}}
          value={experienceRange.min}
          onChange={onMinInputChange}
        />
      </Col>
      <Col span={12}>
        <InputNumber
          min={0}
          max={70}
          style={{ float: 'right', width: '70px' }}
          value={experienceRange.max}
          onChange={onMaxInputChange}
        />
      </Col>
    </Row>
  );
};

export default ExperienceSlider;
