const routing = {
    about_test: '/test/about-test',
    instructions: '/test/read-instructions',
    online_exam: '/test/online-exam'
}

const COMMON_STRING = {
    FULL_SCREEN_MODE: 'FULL_SCREEN_MODE',
    IS_FULL_SCREEN_MODE_ON: 'ON',
    IS_FULL_SCREEN_MODE_OFF: 'OFF',
}


export default { routing, COMMON_STRING };