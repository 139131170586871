import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { routes } from '../../../actions/api-routes';
import { getData } from '../../../actions/middleware';
import { decryptData } from '../../../actions/crypto';

const AutoSearchComponent = () => {
  const [options, setOptions] = useState([]);

  const handleSearch = async (value) => {
    const resData = await getData([routes.option.list, 'skillName'].join('/'), { search: value, keys: 'name' });
    if (resData && resData.code == 200) {
      const data = decryptData(resData.data)
      if (data.length) {
        setOptions(data)
      } else {
        setOptions([{ name: value }])
      }
    }
  };

  const onSelect = (value) => {
    console.log('Selected:', value);
  };

  return (
    <AutoComplete
      style={{ width: '100%' }}
      options={options.map((option) => ({ value: option.name }))}
      onSelect={onSelect}
      onSearch={handleSearch}
      placeholder={"Search....."}
    />
  );
};

export default AutoSearchComponent;
