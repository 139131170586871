import React, { useState } from 'react';
import { Form, Input, Select, Checkbox, Upload, Button, InputNumber, Tooltip, Row, Col, Space } from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const ShareProfiles = () => {
    const [form] = Form.useForm();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [budgetRange, setBudgetRange] = useState({ min: 50000, max: 200000 });

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };

    const uploadProps = {
        beforeUpload: (file) => {
            // Prevent upload
            return false;
        },
        accept: '.pdf',
        multiple: true
    };

    const validateBudget = (_, value) => {
        const { min, max } = budgetRange;
        if (value >= min && value <= max) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(`Budget must be between ${min} and ${max}!`));
    };

    const slots = [
        '9:00 AM - 11:00 AM',
        '11:00 AM - 1:00 PM',
        '1:00 PM - 3:00 PM',
        '3:00 PM - 5:00 PM',
        '5:00 PM - 7:00 PM',
        '7:00 PM - 9:00 PM'
    ];

    return (
        <div className='formView'>
            <Form form={form} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item label={<Space>Select from available list <Tooltip color='orange' title="Check this if you want to select from an available list of developers"><InfoCircleOutlined /></Tooltip></Space>}>
                            <Checkbox onChange={handleCheckboxChange} className='font-14'>Select from available list</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {isCheckboxChecked ? (
                            <Form.Item label={<Space>Select developer name <Tooltip color='orange' title="Select the developer's name from the list"><InfoCircleOutlined /></Tooltip></Space>} name="developerName" rules={[{ required: true, message: 'Please select a developer name!' }]}>
                                <Select placeholder="Select developer name">
                                    <Option value="developer1">Developer 1</Option>
                                    <Option value="developer2">Developer 2</Option>
                                    <Option value="developer3">Developer 3</Option>
                                </Select>
                            </Form.Item>
                        ) : (
                            <Form.Item label={<Space>Upload developer resume <Tooltip color='orange' title="Upload the developer's resume in PDF format"><InfoCircleOutlined /></Tooltip></Space>} name="developerResume" rules={[{ required: true, message: 'Please upload a developer resume!' }]}>
                                <Upload {...uploadProps}>
                                    <Button icon={<UploadOutlined />}>Upload developer resume</Button>
                                </Upload>
                            </Form.Item>
                        )}
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<Space>Budget ({budgetRange.min} to {budgetRange.max}) <Tooltip color='orange' title={`Enter the budget amount (must be between ${budgetRange.min} and ${budgetRange.max})`}><InfoCircleOutlined /></Tooltip></Space>} name="budget" rules={[{ required: true, message: 'Please enter the budget!' }, { validator: validateBudget }]}>
                            <InputNumber min={budgetRange.min} max={budgetRange.max} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<Space>Select screening time <Tooltip color='orange' title="Select a screening time slot within the available range"><InfoCircleOutlined /></Tooltip></Space>} name="screeningTime" rules={[{ required: true, message: 'Please select a screening time slot!' }]}>
                            <Select placeholder="Select a time slot">
                                {slots.map((slot, index) => (
                                    <Option key={index} value={slot}>{slot}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<Space>Developer email ID <Tooltip color='orange' title="Enter the developer's email address"><InfoCircleOutlined /></Tooltip></Space>} name="developerEmail" rules={[{ required: true, message: 'Please enter the developer email ID!' }, { type: 'email', message: 'Please enter a valid email!' }]}>
                            <Input placeholder="Enter developer email ID" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<Space>Upload Developer education proof <Tooltip color='orange' title="Upload the developer's education proof in PDF format"><InfoCircleOutlined /></Tooltip></Space>} name="educationProof" rules={[{ required: true, message: 'Please upload a PDF of the education proof!' }]}>
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />}>Upload PDF</Button>
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={<Space>Upload developer identity document <Tooltip color='orange' title="Upload the developer's identity document in PDF format"><InfoCircleOutlined /></Tooltip></Space>}>
                            <Form.Item name="identityDocumentType" label={<Space>Select document <Tooltip color='orange' title="Select the type of identity document"><InfoCircleOutlined /></Tooltip></Space>} rules={[{ required: true, message: 'Please select a document type!' }]}>
                                <Select placeholder="Select document">
                                    <Option value="adhar">Adhar</Option>
                                    <Option value="pan">Pan</Option>
                                    <Option value="drivingLicense">Driving license</Option>
                                    <Option value="votingId">Voting ID</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name="identityDocument" rules={[{ required: true, message: 'Please upload a PDF of the document!' }]}>
                                <Upload {...uploadProps}>
                                    <Button icon={<UploadOutlined />}>Upload PDF</Button>
                                </Upload>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ShareProfiles;
