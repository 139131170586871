import moment from 'moment';
import './index.css';

function Educations({ item }) {
  return (<>
    <div className='d-f space-between'>
      <div className='text capitalise'>{item.university}</div>
      <div className='set-date text'>{moment(item.startDate).format('YYYY')} - {moment(item.endDate).format('YYYY')}</div>
    </div>
  </>)
}


export default Educations;