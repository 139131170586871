import axios from "axios";
import { notification } from "antd";
import { routes } from "./api-routes";
const API_URL = routes.fileServer + '/api'

const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

const postData = (url, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${API_URL + url}`, data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  })
};

const putData = async (url, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(`${API_URL + url}`, data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  })
};

const getData = async (url, query = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${API_URL + url}`, {
        params: query,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  })
};

const deleteRow = async (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(`${API_URL + url}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  })
};

const getDataBy = (url) =>
  async () => {
    const response = await axios.get(`${API_URL + url}`);
    return response.data;
  };



const uploadFile = async (path, params) => {
  try {
    const response = await axios.post(`${API_URL + path}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response.status >= 200 && response.status < 300) {
      return await response.data;
    } else {
      throw new Error(`${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export { postData, putData, getData, getDataBy, deleteRow, uploadFile }
