import React from 'react';
import { Tag } from 'antd';

const Skills = ({ ele, noWrap }) => {
  const maxSkillsToShow = noWrap ? ele.skils.length : 1;
  const remainingSkillsCount = ele.skils.length - maxSkillsToShow;

  return (
    <div>
      {ele.skils.slice(0, maxSkillsToShow).map((e, index) => (
        <Tag key={index} color="blue" className='text-c'>
          {e}
        </Tag>
      ))}
      {remainingSkillsCount > 0 ? (
        <>
          <Tag color="blue" >...</Tag>
          <Tag color="blue" >{remainingSkillsCount}+</Tag>
        </>
      ) : null}
    </div>
  );
};

export default Skills;
