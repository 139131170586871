import React, { useState, useEffect } from 'react';
import { Form, Button, message, Upload, Row, Col, Typography, Divider, Modal, Tag } from 'antd';
import { CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf';
import { getData, uploadFile } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import './doccument.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { getApplicantData } from '../../../actions/auth';

const { useForm } = Form;

const Doccuments = ({ next, previous }) => {
  const [fileList, setFileList] = useState({});
  const [form] = useForm();
  const [previewFile, setPreviewFile] = useState({});
  const [currentDocument, setCurrentDocument] = useState(null);
  const [zoomedIn, setZoomedIn] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages: loadedPages }) => {
    setNumPages(loadedPages);
  };

  // Define fields
  const fileFields = [
    { name: 'passportPhoto', label: 'Passport Photo', required: true },
    { name: 'aadharCard', label: 'Aadhar Card', required: true },
    { name: 'panCard', label: 'Pan Card', required: true },
    { name: 'marksheet10', label: '10th Marksheet', required: true },
    { name: 'diplomaMarksheet', label: 'Diploma Marksheet', required: true },
    { name: 'degreeProvisional', label: 'Degree Provisional', required: true },
    { name: 'postGraduate', label: 'Post Graduate Certificate', required: false },
  ];

  // Get existing documents
  const getAllDoccuments = async () => {
    const resData = await getData(routes.applicant.documentList);
    if (resData.code === 200) {
      const groupFilesByField = (files) => {
        return files.reduce((acc, file) => {
          if (!acc[file.fieldname]) {
            acc[file.fieldname] = [];
          }
          acc[file.fieldname].push({
            uid: file._id,
            name: file.originalname,
            status: file.approvalStatus === 'Rejected' ? 'error' : 'done',
            url: routes.fileServer + '/applicant/' + file.parentFolder + '/' + file.filename,
            size: file.size,
            approvalStatus: file.approvalStatus,
            message: file.message,
          });
          setPreviewFile(prev => ({
            ...prev,
            [file.fieldname]: routes.fileServer + '/applicant/' + file.parentFolder + '/' + file.filename
          }))
          return acc;
        }, {});
      };

      const groupedFiles = groupFilesByField(resData.data);
      form.setFieldsValue(groupedFiles);
      setFileList(groupedFiles);
    }
  };

  // Handle file upload change and set preview
  const handleFileChange = (field, info) => {
    const file = info.fileList[0]; // Only one file is allowed
    setFileList((prev) => ({
      ...prev,
      [field]: info.fileList,
    }));

    if (file && file.originFileObj && file.originFileObj.type === 'application/pdf') {
      setPreviewFile((prev) => ({
        ...prev,
        [field]: file.originFileObj,
      }));
    }
  };

  // Render PDF preview
  const renderPreview = (field) => {
    const file = previewFile[field];
    if (file) {
      return (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Document file={file} onClick={() => {
            setCurrentDocument(file);
            setZoomedIn(true);
          }}>
            <Page pageNumber={1} width={60} />
          </Document>
        </div>
      );
    }
    return <div style={{ textAlign: 'center', color: '#888' }}>No Preview Available</div>;
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    const data = getApplicantData();
    formData.append('folderName', data.email);

    Object.entries(values).forEach(([fieldname, fileList]) => {
      fileList?.forEach((file) => {
        if (file.approvalStatus == 'Rejected') {
          return
        }
        if (file.originFileObj) {
          formData.append(fieldname, file.originFileObj);
        }
      });
    });

    uploadFile(routes.applicant.uploadFile, formData).then((resData) => {
      if (resData && resData.code === 200) {
        message.success('Successfully updated your response');
        next();
      } else {
        message.error('Something went wrong, please try again');
      }
    });
  };

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    getAllDoccuments();
  }, []);

  const getValidation = () => {
    let status = false;
    fileFields.forEach(ele => {
      if (fileList[ele.name]) {
        fileList[ele.name].forEach(_ele => {
          if (_ele.approvalStatus == 'Rejected') {
            status = true
          }
        })
      }
    })
    return status;
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-8 scroll-view-box justify-bet">
          <div>
            <div className="heading-padding">
              <h6>Documents</h6>
              <div>
                <Button className="button-view margin-lr" onClick={previous}>
                  Previous
                </Button>
                <Button className="button-view d-dextop" disabled={getValidation()} onClick={onSubmit} type="primary">
                  Save & Next
                </Button>
              </div>
            </div>
            <Divider className="divider" />
            <div className="scroll-view">
              <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={[16, 16]} style={{ marginRight: '25px' }}>
                  {fileFields.map((field) => (
                    <Col sm={24} key={field.name}>
                      <div className="card_view_for_form">
                        <div>
                          <Typography.Title level={5}>
                            {field.label}{' '}
                            {field.required && <Typography.Text type="danger">*</Typography.Text>}
                          </Typography.Title>
                          <div>{renderPreview(field.name)}</div>
                        </div>
                        {fileList[field.name] && fileList[field.name].length > 0 && fileList[field.name][0]?.message && <div>
                          <Typography.Text type='danger'>{fileList[field.name][0]?.message}</Typography.Text>
                        </div>}
                        <div>
                          {(fileList[field.name] && fileList[field.name].length > 0 && fileList[field.name][0].approvalStatus !== 'Approved') || !fileList[field.name] ?
                            <Form.Item
                              name={field.name}
                              valuePropName="fileList"
                              getValueFromEvent={(e) => e && e.fileList}
                              rules={[{ required: field.required, message: `Please upload your ${field.label}!` }]}
                            >
                              <Upload
                                accept=".pdf"
                                maxCount={1}
                                beforeUpload={() => false}
                                fileList={fileList[field.name] || []}
                                onChange={(info) => handleFileChange(field.name, info)}
                                showUploadList={false}
                              >
                                <Button icon={<UploadOutlined />} >Choose File</Button>
                              </Upload>
                            </Form.Item> :
                            <Tag icon={<CheckOutlined />} color='success'>Approved</Tag>}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Form>
            </div>
            <Modal
              open={zoomedIn}
              footer={null}
              onCancel={() => setZoomedIn(false)}
              width="700px"
              centered
            >
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", overflow: "auto" }}>
                <Document file={currentDocument}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={600}
                    />
                  ))}
                </Document>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Doccuments;
