import { Avatar, Button, Space, Tabs } from 'antd'
import './index.css'

const DeveloperHired = () => {

    return <>
        <div className='formView vendor-details'>
            No Data Found
        </div>
    </>
}

export default DeveloperHired;