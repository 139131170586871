import React, { useEffect, useState } from 'react';
import { Upload, Button, message, Modal, Row, Col, Form, Input, Space, Select } from 'antd';
import { PlusCircleOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { routes } from '../../../../actions/api-routes';
import { postData, uploadFile } from '../../../../actions/middleware';
import './index.css';
import { validateMaximum, validateMinimum } from '../../../common/validate';
import DynamicSelect from '../../../common/Select';
import { encryptData } from '../../../../actions/crypto';
import { useNavigate } from 'react-router-dom';
const { useForm } = Form;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const FileUpload = ({ submitForm, onClear }) => {
    const [form] = useForm();
    const navigate = useNavigate();
    const [fileList, setFileList] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [showSublocations, setHandleLocationChange] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const publicValue = queryParams.get('public');

    const customRequest = async (fileObject) => {
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();
            formData.append('file', fileObject.resume.file);
            try {
                const resData = await uploadFile(routes.fileUpload, formData);
                if (resData && resData.status === 'SUCCESS') {
                    const response = await postData(routes.developer.bulkUpload, { data: encryptData({ ...fileObject, resume: resData.files[0] }) })
                    if (response && response.status === 'SUCCESS') {
                        resolve(response);
                    } else {
                        reject()
                    }
                } else {
                    message.error(`${file.name} Failed to upload`);
                    reject()
                }
            } catch (error) {
                message.error(`${file.name} Failed to upload`);
                reject()
            }
        })
    };

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const beforeUpload = (file) => {
        const isDocOrPdf = ['.pdf'].includes(file.name.slice(-4).toLowerCase());
        if (!isDocOrPdf) {
            message.error('You can only upload PDF.');
            return false;
        }
        return false;
    };

    useEffect(() => {
        if (submitForm == 'Submit') {
            form.submit();
        }
    }, [submitForm])

    const onFinish = async (values) => {
        console.log('Received values of form:', values);
        if (values && values.developers.length > 0) {
            for (let i = 0; i < values.developers.length; i++) {
                await customRequest(values.developers[i]);
                if (i == values.developers.length - 1) {
                    navigate('/auth')
                }
            }

        }
        onClear();
    };

    return (
        <div className='pdc_doc_excel'>
            <Form form={form}
                name="dynamic_form_nest_item"
                layout='vertical'
                onFinish={onFinish}
                autoComplete="off">
                <Form.List
                    initialValue={[{ developers: '' }]}
                    name={'developers'}
                >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                <div className='form-border'>
                                    {!publicValue && <Row gutter={16} justify={'space-around'}>
                                        <Col span={14}>
                                            <Row gutter={16} >
                                                <Col span={6}>
                                                    <Form.Item
                                                        {...restField}
                                                        label={"Currency"}
                                                        name={[name, 'currency']}
                                                        fieldKey={[fieldKey, 'currency']}
                                                        rules={[{ required: true, message: 'Currency is required' }]}
                                                    >
                                                        <Select>
                                                            <Select.Option value={"INR"}>{'INR'}</Select.Option>
                                                            <Select.Option value={"USD"}>{'USD'}</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={9}>
                                                    <Form.Item
                                                        {...restField}
                                                        label={"Minimum"}
                                                        name={[name, 'minimum']}
                                                        fieldKey={[fieldKey, 'minimum']}
                                                        rules={[{
                                                            validator: (_, value) => {
                                                                return validateMinimum(_, value, form.getFieldValue('maximum'))
                                                            }
                                                        }]}
                                                    >
                                                        <Input type='number' onChange={() => form.validateFields(['maximum'])} suffix={'LPM'} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={9}>
                                                    <Form.Item
                                                        {...restField}
                                                        label={"Maximum"}
                                                        name={[name, 'maximum']}
                                                        fieldKey={[fieldKey, 'maximum']}
                                                        rules={[{
                                                            validator: (_, value) => {
                                                                return validateMaximum(_, value, form.getFieldValue('minimum'))
                                                            }
                                                        }]}
                                                    >
                                                        <Input type='number' onChange={() => form.validateFields(['minimum'])} suffix={'LPM'} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4}></Col>
                                    </Row>}
                                    <Row gutter={16} justify={'space-around'}>
                                        <Col span={14}>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...restField}
                                                        label={"Location"}
                                                        name={[name, 'locations']}
                                                        fieldKey={[fieldKey, 'locations']}
                                                        rules={[{ required: true, message: 'This field is required' }]}
                                                    >
                                                        <Select onChange={(e) => setHandleLocationChange({ [index]: e })} mode='tags'>
                                                            <Select.Option value="remote">Remote</Select.Option>
                                                            <Select.Option value="onsite">Onsite</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                {(showSublocations[index] && showSublocations[index].includes('onsite')) ? <Col span={12}>
                                                    <DynamicSelect
                                                        {...restField}
                                                        label={"Onsite Locations"}
                                                        name={[name, 'onsiteOptions']}
                                                        keyName={'onsiteOptions'}
                                                        fieldKey={[fieldKey, 'onsiteOptions']}
                                                        rules={[{ required: true, message: 'This field is required' }]}
                                                    />
                                                </Col> : <Col span={12}></Col>}
                                            </Row>
                                        </Col>
                                        <Col span={4}></Col>
                                    </Row>
                                    <Row gutter={16} justify={'space-around'}>
                                        <Col span={8}>
                                            <Form.Item
                                                {...restField}
                                                label={"Resume"}
                                                name={[name, 'resume']}
                                                fieldKey={[fieldKey, 'resume']}
                                                rules={[{ required: true, message: 'Resume is required' }]}
                                            >
                                                <Upload
                                                    listType="text"
                                                    accept=".pdf"
                                                    beforeUpload={beforeUpload}
                                                    onPreview={handlePreview}
                                                    maxCount={1}
                                                >
                                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}></Col>
                                    </Row>
                                    <div className='button-footer'>
                                        {fields.length == 1 && <div></div>}
                                        {fields.length != 1 && <Button type="text" danger onClick={() => remove(name)} icon={<DeleteOutlined />}>
                                            Remove
                                        </Button>}
                                        {fields.length == index + 1 && <Button type="primary" ghost onClick={() => add()} icon={<PlusCircleOutlined />}>
                                            Add
                                        </Button>}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </Form.List >
            </Form>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <iframe
                    title="File Preview"
                    width="100%"
                    height="500px"
                    src={previewImage}
                />
            </Modal>
        </div >
    );
};

export default FileUpload;
