import './index.css';
function Testimonials() {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div className="testimonial-slider-area section-space--ptb_120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_40">
                  <h6 className="section-sub-title mb-20">Testimonials</h6>
                  <h3 className="heading">Clients' Success Stories  <span className="text-color-primary">Hear <br /> from Satisfied Customers</span></h3>
                </div>
                <div className="testimonial-slider av">
                  <div className="swiper-container testimonial-slider__container">
                    <div className="swiper-wrapper testimonial-slider__wrapper">
                      <div className="swiper-slide">
                        <div className="testimonial-slider__one wow move-up">
                          <div className="testimonial-slider--info">
                            <div className="testimonial-slider__media">
                              <img src={require("../../../assets/images/testimonial/user.png")} className="img-fluid" alt="" />
                            </div>
                            <div className="testimonial-slider__author">
                              <div className="testimonial-rating">
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                              </div>
                              <div className="author-info">
                                <h6 className="name">Emily Johnson</h6>
                                <span className="designation">CTO</span>
                              </div>
                            </div>
                          </div>
                          <div className="testimonial-slider__text">Working with Accelgrowth Technology was a game-changer for our business. They provided innovative solutions that transformed our operations</div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testimonial-slider__one wow move-up">
                          <div className="testimonial-slider--info">
                            <div className="testimonial-slider__media">
                              <img src={require("../../../assets/images/testimonial/user.png")} className="img-fluid" alt="" />
                            </div>
                            <div className="testimonial-slider__author">
                              <div className="testimonial-rating">
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                              </div>
                              <div className="author-info">
                                <h6 className="name">David Thompson</h6>
                                <span className="designation">Founder</span>
                              </div>
                            </div>
                          </div>

                          <div className="testimonial-slider__text">The team at Accelgrowth Technology demonstrated exceptional expertise and professionalism throughout our project. We are extremely satisfied.</div>

                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testimonial-slider__one wow move-up">

                          <div className="testimonial-slider--info">
                            <div className="testimonial-slider__media">
                              <img src={require("../../../assets/images/testimonial/user.png")} className="img-fluid" alt="" />
                            </div>

                            <div className="testimonial-slider__author">
                              <div className="testimonial-rating">
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                              </div>
                              <div className="author-info">
                                <h6 className="name">Anna Martinez</h6>
                                <span className="designation">Founder</span>
                              </div>
                            </div>
                          </div>

                          <div className="testimonial-slider__text">Accelgrowth Technology proved to be a reliable and trusted partner, delivering exceptional solutions that drove our business forward</div>

                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testimonial-slider__one wow move-up">

                          <div className="testimonial-slider--info">
                            <div className="testimonial-slider__media">
                              <img src={require("../../../assets/images/testimonial/user.png")} className="img-fluid" alt="" />
                            </div>

                            <div className="testimonial-slider__author">
                              <div className="testimonial-rating">
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                              </div>
                              <div className="author-info">
                                <h6 className="name">Pooja Sharma</h6>
                                <span className="designation">Manager</span>
                              </div>
                            </div>
                          </div>

                          <div className="testimonial-slider__text">I am extremely pleased with the exceptional services provided by Accelgrowth Technology. Their team's professionalism and technical expertise have exceeded..</div>

                        </div>
                      </div>
                    </div>
                    <div className="swiper-pagination swiper-pagination-t01 section-space--mt_30"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials;
