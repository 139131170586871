import { useEffect, useState } from 'react';
import DrawerView from '../../common/drawer/drawer';
import './index.css'
import ConfigForm from './configForm';
import { Input, Space, Switch, Button, Table, Tag } from 'antd';
import Actions from '../../../actions/table-configs/actions';
import { TestTableConfig } from '../config/testConfig';
import { SearchOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { getData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import { decryptData } from '../../../actions/crypto';

const TestConfig = () => {
    const [isVisible, setVisible] = useState(false)
    const [isSave, setSave] = useState(false)
    const [testConfigRes, setTestConfigRes] = useState([])
    const [pagination, setpagignations] = useState()


    useEffect(() => {
        getTestConfigs()
    }, [])


    const getTestConfigs = async () => {
        const configResponse = await getData(routes.onlineTest.testConfig)
        if (configResponse && configResponse.code == 200) {
            setTestConfigRes(decryptData(configResponse.data))
            setpagignations(configResponse.pagination)
        }
    }

    const refreshData = () => { }

    const onSave = () => {
        setSave(true)
    }

    const handleExpand = (expanded, record) => {
        if (expanded) {
            // Call your API here using fetch or any other method
            console.log('API called for expanded row:', record);
        }
    };



    return <>
        <div className='test-config'>
            <div className='filter-quetionPaper'>
                <div>
                    <Space>
                        <Input
                            addonAfter={<SearchOutlined />}
                            onInput={(e) => onSearch(e)}
                            onKeyDown={(e) => onEnterCall(e)}
                        />
                    </Space>
                </div>
                <div>
                    <Space>
                        <div>
                            <Tag>Total Configs: {pagination?.total}</Tag>
                        </div>
                        <div>
                            <Button icon={<PlusSquareOutlined />} type='primary' onClick={() => setVisible(true)}> Question</Button>
                        </div>
                    </Space>
                </div>
            </div>
            <DrawerView
                onClose={() => setVisible(false)}
                isVisible={isVisible}
                onSave={onSave}
            >
                <ConfigForm onClose={() => setVisible(false)} isSave={isSave} onSave={() => setSave(false)} />
            </DrawerView>
            <div className='table-config'>
                <Table
                    className="custom-table"
                    columns={TestTableConfig(Actions, ['view', 'edit', 'delete'], () => refreshData(), () => { })}
                    pagination={false}
                    rowKey={'_id'}
                    scroll={{ y: 1000 }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <p
                                style={{
                                    margin: 0,
                                }}
                            >
                                {record?.testConfig.map(ele => <Tag bordered={false} color='cyan'>{ele.skillName} - ({ele.numberOfQuestions})</Tag>)}
                            </p>
                        ),
                        onExpand: handleExpand,
                    }}
                    dataSource={testConfigRes}
                />
            </div>
        </div>
    </>
}

export default TestConfig;