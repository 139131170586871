import { decryptData, encryptData } from "./crypto";

const getLocalStorage = (key) => {
    const data = localStorage.getItem(key)
    return data ? decryptData(data) : null;
}

const setLocalStorage = (key, value) => {
    localStorage.setItem(key, encryptData(value))
}

const removeLocalStorage = (key) => {
    localStorage.removeItem(key)
}

export { getLocalStorage, setLocalStorage, removeLocalStorage };