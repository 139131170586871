import React from 'react';
import { useState } from 'react';
import { Alert, Avatar, Button, Checkbox, Col, Divider, Image, Modal, Radio, Row, Space, Table, Tag, message } from 'antd';
import { UserAddOutlined, GlobalOutlined, HomeOutlined } from '@ant-design/icons';
import './index.css';
import { routes } from '../../../../../actions/api-routes';
import UpdateRequierment from '../../../requierments/modal';
import { encryptData } from '../../../../../actions/crypto';
import { putData } from '../../../../../actions/middleware';
import moment from 'moment';
import PDFViewer from '../../../../../actions/table-configs/files/pdfViewer';

const BulkListItem = ({ style, item, reloadPage }) => {
    const [showFullText, setShowFullText] = useState(false);
    const [maxChars, setmaxChars] = useState(200);
    const [isVisible, setVisible] = useState(false);
    const [itemId, setItemId] = useState(null);
    const [viewFile, setViewFile] = useState(false);

    const handleSeeMore = () => {
        setShowFullText(true);
    };

    const handleSeeLess = () => {
        setShowFullText(false);
    };

    const approveReject = async (_id, status) => {
        const data = encryptData({ approvalStatus: status })
        const resData = await putData(routes.jobs.list + encryptData(_id), { data })
        if (resData && resData.code == 200) {
            message.success('Successfully updated')
            reloadPage();
        } else {
            message.error('Something went wrong, please try again later')
        }
    }

    const prompt = (_id, status) => {
        Modal.confirm({
            title: 'Confirm',
            content: 'Are you sure you want to reject this item?',
            onOk() {
                approveReject(_id, status)
            },
            onCancel() { },
        });
    }

    const openFile = (_file) => {
        setViewFile(true)
    }

    return (
        <div className='view-box bg-white job-dscription' style={style}>
            <Modal
                open={viewFile}
                onCancel={() => setViewFile(false)}
                width={'80%'}
            >
                <PDFViewer fileUrl={routes.fileServer + item.resume} />
            </Modal>
            <UpdateRequierment isVisible={isVisible} onClose={() => {
                setVisible(false)
                setItemId(null)
            }} reloadPage={reloadPage} id={itemId} />
            <div className='d-f justify-between'>
                <div>
                    <div className='job-title-view'>
                        <div className='icon-view'>
                            {item?.jobLocationType == 'Remote' && <Avatar icon={<HomeOutlined title='Remote' />} />}
                            {item?.jobLocationType != 'Remote' && <Avatar icon={<GlobalOutlined title='Office' />} />}
                        </div>
                        <div className='heading-view-for-rol'>
                            <div className='job-title-box'>
                                <div className='main-text'> {moment(item?.createdAt).format('lll')}</div>
                            </div>
                            <div className='price-view'>
                                <Tag color='magenta' bordered={false}>{item?.locations.join(' | ')}</Tag> | <Tag color='cyan' bordered={false}>{item?.currency} {item?.minimum}LPM - {item?.maximum}LPM</Tag>
                            </div>
                            {item?.onsiteOptions.length > 0 && <div>
                                <Tag color='magenta' bordered={false}>{item?.onsiteOptions.join(',')}</Tag>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-box'>
                <div className={`text-container ${showFullText ? 'show-full' : ''}`}>
                    <div className={`text ${showFullText ? 'show-full-text' : ''}`}>
                        <div className='img-container'>
                            <Button type='default' onClick={() => openFile(item)}>{item?.resume.endsWith('.pdf') ? 'View PDF' : 'Download'}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tag-box d-f justify-between'>
                <Space>
                    <div><Space><GlobalOutlined />Accelgrowth Technology</Space></div>
                </Space>
                <Space>
                    {item.approvalStatus !== 'REJECTED' && <Button type='link' danger onClick={() => prompt(item._id, 'REJECTED')}>Rejecte</Button>}
                    {item.approvalStatus !== 'APPROVED' && <Button type='link' onClick={() => approveReject(item._id, 'APPROVED')}>Appove</Button>}
                </Space>
            </div>
        </div>
    );
};

export default BulkListItem;
