import { routes } from "../../../actions/api-routes";
import { decryptData } from "../../../actions/crypto";
import { getData } from "../../../actions/middleware";


const sleep = (ms) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(undefined);
        }, ms);
    });

export const loadOptions = async (
    search,
    prevOptions,
    { page }
) => {
    await sleep(1000);
    const resData = await getData(routes.option.list + '/skillName', { current: page, search: search, pageSize: 10 });
    if (resData.code == 200) {
        const data = decryptData(resData.data)
        if (page == resData.pagination.current) {
            if (data.length < 10) {
                return {
                    options: data.map(ele => ({ value: ele.name, label: ele.name })),
                    hasMore: false,
                    additional: {
                        page: page + 1
                    }
                };
            } else {
                return {
                    options: data.map(ele => ({ value: ele.name, label: ele.name })),
                    hasMore: true,
                    additional: {
                        page: page + 1
                    }
                };
            }
        }
    }
};
