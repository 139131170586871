import React, { useEffect } from 'react';
import { Form, Input, Button, Checkbox, Radio, Row, Col, Select } from 'antd';
import { useState } from 'react';
import DynamicSelect from '../../common/Select';
import { putData } from '../../../actions/middleware';
import { validateWebsiteUrl } from '../../common/validate';
import { NodeExpandOutlined } from '@ant-design/icons';
import { error } from '../../common/Notify';
import { encryptData } from '../../../actions/crypto';
const { useForm } = Form;

const Experience = ({ nextCall, data, onSubmitFromHeader, onClear }) => {
  const [form] = useForm();
  const [showSublocations, setShowSublocations] = useState(data && data.locations.includes("onsite") ? true : false); 

  useEffect(() => {
    if (!data) {
      nextCall(0)
    } else {
      form.setFieldsValue(data)
    }
  }, [data])

  useEffect(() => {
    if (onSubmitFromHeader == 'EXPERIANCE') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])


  const onFinish = async (values) => {
    console.log('Received values:', values);
    try {

      const resData = await putData(`/vendor/developers/${encryptData(data._id)}`, { data: encryptData({ ...values }) });
      if (resData && resData.code == 200) {
        nextCall(2)
      } else {
        error('Fix Error', resData.message)
      }
    } catch (_error) {
      error('Fix Error', _error.message)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleLocationChange = (checkedValues) => {
    setShowSublocations(checkedValues.includes('onsite'));
  };

  return (
    <Form
      name="basic"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      scrollToFirstError={true}
    >
      <div className='form-view'>
        <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <Form.Item
              label="Total Experiance"
              name="Experiance"
              style={{ width: '100%' }}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>
          <Col span={10}>
            <DynamicSelect
              label="Primary job title"
              name="JobTitle"
              keyName={"JobTitle"}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </Col>
        </Row>
        <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <Form.Item
              label="LinkedIn"
              name="linkedIn"
              style={{ width: '100%' }}
              rules={[{ validator: validateWebsiteUrl }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Github"
              name="github"
              style={{ width: '100%' }}
              rules={[{ validator: validateWebsiteUrl }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <Form.Item
              label="Location"
              name="locations"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select onChange={handleLocationChange} mode='tags'>
                <Select.Option value="remote">Remote</Select.Option>
                <Select.Option value="onsite">Onsite</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {!showSublocations && <Col span={10}></Col>}
          {showSublocations && <Col span={10}>
            <DynamicSelect
              label={"Onsite Locations"}
              name={'onsiteOptions'}
              keyName={'onsiteOptions'}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </Col>}
        </Row>
        <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <Form.Item
              name="comittmentType"
              label="Which type of comittment do you prefer? *"
              rules={[
                {
                  required: true,
                  message: 'Please select your proficiency level',
                },
              ]}
            >
              <Select mode='tags' maxTagCount={1}>
                <Select.Option value="full-time">Full-time (40 hours/week) (Recommended)</Select.Option>
                <Select.Option value="Part-time">Part-time (20 hours/week)</Select.Option>
                <Select.Option value="hourly">Hourly (upto 10 hours/week)</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}></Col>
        </Row>
      </div>
    </Form >
  );
};

export default Experience;
