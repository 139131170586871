import './index.css';
function Technologies() {
  return (
    <div className="feature-images-wrapper bg-gradient section-space--ptb_40 image-box-boder-hv">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-wrap text-center section-space--mb_60">
              <h6 className="section-sub-title mb-20">Our Expertise</h6>
              <h4 className="heading">Advanced technology expertise tailored to  <br /> meet your  <span className="text-color-primary"> specific requirements.
              </span></h4>
            </div>
          </div>
        </div>
        <div className="image-l-r-box">
          <div className="row">
            <div className="col image-box-area">
              <div className="row image-box-boder-box">
                <div className="col-lg-6 col-md-6 image-box-boder ">
                  <div className="ht-box-images style-09">
                    <div className="image-box-wrap">
                      <h6 className="heading section-space--mb_20">Frontend Technology</h6>
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/angular.jpg")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Angular JS
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/reactjs.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  React JS
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/js.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  JavaScript
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/ts.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  TypeScript
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/html.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  HTML/Html5
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/vuejs.jpg")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  VueJS
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/meanStack.jpg")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  MEAN Stack
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/mern.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  MERN Stack
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/c#.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  MERN Stack
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 image-box-boder">
                  <div className="ht-box-images style-09">
                    <div className="image-box-wrap">
                      <h6 className="heading section-space--mb_20">Backend Technology</h6>
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/nodejs.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  NodeJS
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/java.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  JAVA
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/dotnet.jpg")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Dot Net
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/python.jpg")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Python
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/php.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  PHP
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/laravel.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Laravel
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/djangp.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  DJango
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/ror.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Ruby on Rails
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/spring.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Spring Boot
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 image-box-boder">
                  <div className="ht-box-images style-09">
                    <div className="image-box-wrap">
                      <h6 className="heading section-space--mb_20">App Development</h6>
                      <div className="row">
                        <div className="col-lg-2 col-md-4">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/android.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Android
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/kotlin.jpg")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Kotlin
                                </li>

                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/reactjs.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  React Native
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/flutter.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Flutter
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/swift.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Swift
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/ionic.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Ionic
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/phonegap.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Phone Gap
                                </li>
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/cordova.jpg")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Cordova
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="list-group-wrap section-space--mb_60">
                            <div className="separator-list-wrap">
                              <ul className="icon-list">
                                <li className="list-item">
                                  <span className="list__icon">
                                    <img src={require("../../../assets/images/technology/renative.png")} className="img-fluid img-fluid-wh" width="50px" alt="" />
                                  </span>
                                  Renative
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Technologies;
