import { combineReducers } from 'redux';
import Common from '../../onlineTest/routing'

const { COMMON_STRING } = Common;

const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN_ACTION':
      return { ...state, data: action.payload };
    case 'USER_DETAILS':
      return { ...state, data: action.payload };
    case 'EDIT_DATA':
      return { ...state, data: action.payload };
    case 'NETWORK_DETAILS':
      return { ...state, NETWORK_DETAILS: action.payload };
    case COMMON_STRING.FULL_SCREEN_MODE:
      return { ...state, [COMMON_STRING.FULL_SCREEN_MODE]: action.payload };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  app: reducer,
});

export default rootReducer;
