import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Button, Avatar, Space, Row, Col, Empty, Tag, Tabs } from 'antd';
import { CheckCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getData } from '../../../../actions/middleware';
import { routes } from '../../../../actions/api-routes';
import { decryptData, encryptData } from '../../../../actions/crypto';
import Developerprofiles from './../listItem';
import './../index.css';
import './index.css';
import DeveloperDetails from './details';


const ListAndDetails = ({ items, activeTab }) => {
    const containerRef = useRef(null)
    const [profiles, setProfiles] = useState([]);
    const [prevId, setPrevId] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [developerProfile, setDeveloperProfile] = useState(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const divRef = useRef(null);


    useEffect(() => {
        if (items && items._id && prevId != items._id) {
            setProfiles([])
            setHasMore(true)
            setPage(1)
            setPrevId(items._id)
        }
    }, [items])

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (hasMore) {
            getProfileList();
        }
        const container = containerRef.current;
        container.addEventListener('scroll', handelScroll);
        return () => {
            container.removeEventListener('scroll', handelScroll);
        };
    }, [items, page, hasMore])

    const getProfileList = async () => {
        if (items) {
            const experiance = items?.experiance;
            const minimum = items?.minimum;
            const maximum = items?.maximum;
            const jobLocationType = items?.jobLocationType;

            // experiance,
            // minimum,
            // maximum,
            // jobLocationType,
            // requirment: items._id

            const resData = await getData(routes.applied.list_developer, {
                current: page,
                job: encryptData(items._id)
            });
            if (resData.code == 200) {
                const data = decryptData(resData.data)
                if (page == resData.pagination.current) {
                    setTotal(resData.pagination)
                    if (data.length < 14) {
                        setHasMore(false);
                        setProfiles(prevData => {
                            const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                            return [...prevData, ...newData];
                        });
                    } else {
                        setProfiles(prevData => {
                            const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                            return [...prevData, ...newData];
                        });
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (profiles.length > 0 && !developerProfile) {
            onSelectDeveloper(profiles[0])
        }
    }, [profiles])

    const handelScroll = () => {
        const containerShortlites = containerRef.current;
        if (
            containerShortlites.scrollTop + containerShortlites.clientHeight === containerShortlites.scrollHeight
        ) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const onSelectDeveloper = (item) => {
        scrollToTop()
        setDeveloperProfile(item)
    }

    const scrollToTop = () => {
        if (divRef.current) {
            if (divRef.current) {
                const scrollOptions = {
                    top: 0,
                    behavior: 'smooth'
                };
                divRef.current.scrollTo(scrollOptions);
            }
        }
    };

    const getStatus = (key) => {
        let status = '';
        if (key == 'Shortlisted') {
            status = "SHORTLITED";
        }
        if (key == 'Rejected') {
            status = "REJECTED";
        }

        return status;
    }

    const updatedStatus = (e) => {
        setProfiles(prevData => prevData.map(ele => ele._id == e.id ? { ...ele, approvalStatus: getStatus(e.key) } : ele))
    }

    return (
        <div className='profile-listing'>
            <Row>
                <Col span={6}>
                    <div className='heading-2 padding-5'>
                        Profiles recived: {total.total}
                    </div>
                    <div ref={containerRef} style={{ height: windowHeight - 290, overflowY: 'auto' }} >
                        {profiles.map(ele => <Col span={24} className='border-bottom'>
                            <Developerprofiles onSelectDeveloper={onSelectDeveloper} selected={developerProfile} item={ele} />
                        </Col>)}
                    </div>
                </Col>
                <Col span={18}>
                    <div className='detail-box' ref={divRef} style={{ height: windowHeight - 240, overflowY: 'auto' }}>
                        {developerProfile && <DeveloperDetails details={developerProfile} statusUpdated={updatedStatus} />}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ListAndDetails;
