import { useState } from 'react'
import Actions from '../../../actions/table-configs/actions'
import './index.css'
import { Table } from 'antd'
import { testAllotConfig } from '../config/testAllot'

const Results = () => {
    const [isVisible, setVisible] = useState(true)

    const refreshData = () => { }

    const profileRes = []


    return <>
        <div className='test-allot'>
            <Table
                className="custom-table"
                dataSource={profileRes}
                columns={testAllotConfig(Actions, ['view', 'edit', 'delete'], () => refreshData(), () => { })}
                pagination={false}
                scroll={{ y: 1000 }}
            />
        </div>
    </>
}

export default Results;