import React, { useState } from 'react';
import { Avatar, Button, Col, Drawer, Form, Progress, Row, Tooltip } from 'antd';
import { CloseOutlined, LogoutOutlined } from '@ant-design/icons';
import './index.css';

const SettingDrawer = ({ isVisible, width, onClose, onSave }) => {

  const drawerHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className='my-info'>
        <div className='avatar-view'>
          <Avatar size={'large'} shape='square' />
        </div>
        <div className='personal-details'>
          <div className='my-name'>Shubham Kanfade</div>
          <div className='sub-title'>Managin Director</div>
          <div className='sub-title'>shubham.kanfade@accelgrowthtech.com</div>
          <div className='sub-title'>+91 7057250694</div>
        </div>
      </div>
      <Button type="text" className='closeButton' onClick={onClose}>
        <CloseOutlined />
      </Button>
    </div>
  );

  return (
    <>
      <Drawer
        title={drawerHeader}
        placement="right"
        onClose={onClose}
        open={isVisible}
        // width={'100%'}
        closable={false}
        maskClosable={false}
        footer={<div>
          <LogoutOutlined /> Log out
        </div>}
      >
        <div>
          <div className='profile-drawer-card'>
            <div className='title'>My Profiles</div>
            <div className='status-profiles'>
              <div>
                <Tooltip title="3 approved / 3 in progress / 4 to do">
                  <Progress
                    percent={100}
                    success={{
                      percent: 30,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='profile-drawer-card'>
            <div className='title'>Test Status</div>
            <div className='status-profiles'>
              <div>
                <Tooltip title="3 approved / 3 in progress / 4 to do">
                  <Progress
                    percent={100}
                    success={{
                      percent: 30,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='profile-drawer-card'>
            <div className='title'>Developer Ownboarded</div>
            <div className='status-profiles'>
              <div>
                <Tooltip title="3 approved / 3 in progress / 4 to do">
                  <Progress
                    percent={100}
                    success={{
                      percent: 30,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='profile-drawer-card'>
            <div className='title'>Current Opening based on your profiles</div>
            <div className='status-profiles'>
              <div>
                <Tooltip title="3 approved / 3 in progress / 4 to do">
                  <Progress
                    percent={100}
                    success={{
                      percent: 30,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </Drawer >
    </>
  );
};
export default SettingDrawer;