import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spin } from 'antd'; // Assuming you're using Ant Design for loading spinner
import './loading.css';

const Loading = () => {
    const [loading, setLoading] = useState(false);
    const [activeRequests, setActiveRequests] = useState(0);

    useEffect(() => {
        const axiosInterceptorRequest = axios.interceptors.request.use(config => {
            setActiveRequests(prev => prev + 1);
            return config;
        });

        const axiosInterceptorResponse = axios.interceptors.response.use(response => {
            setActiveRequests(prev => prev - 1);
            return response;
        }, error => {
            setActiveRequests(prev => prev - 1);
            return Promise.reject(error);
        });

        return () => {
            axios.interceptors.request.eject(axiosInterceptorRequest);
            axios.interceptors.response.eject(axiosInterceptorResponse);
        };
    }, []);

    useEffect(() => {
        setLoading(activeRequests > 0);
    }, [activeRequests]);

    return (
        <div className="loading-App">
            {loading && <div className="overlay"><Spin size="large" /></div>}
        </div>
    );
};

export default Loading;
