import { Checkbox, Col, Space, Form, Input, Row, notification } from "antd";
import DynamicSelect from "../../common/Select";
import ReactQuill from "react-quill";
import RichTextEditor from "./editor";
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import './index.css';
import { postData } from "../../../actions/middleware";
import { routes } from "../../../actions/api-routes";
import { encryptData } from "../../../actions/crypto";
const { useForm } = Form;

const QuestionForm = ({ onSubmit, onClickOnSave, onClose }) => {
    const [form] = useForm();
    const [options, setOptions] = useState([{ option: '', answer: false }]);

    const onFinishFailed = () => { }

    useEffect(() => {
        if (onSubmit) {
            form.submit()
            onClickOnSave()
        }
    }, [onSubmit])


    const handleRemove = (index) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        setOptions(newOptions);
    };

    const closeForm = () => {
        form.resetFields()
        onClose()
    }
    const onFinish = async (value) => {
        const result = await postData(routes.onlineTest.questions, { data: encryptData(value) });
        if (result && result.code == 200) {
            notification.success({ message: 'Success', description: 'Question has been created successfully' })
            closeForm();
        } else {
            notification.error({ message: 'Failed', description: 'Something went wrong, please try again later' })
        }
    }

    return <>
        <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="add-question"
            form={form}
        >
            <Row gutter={16}>
                <Col span={8}>
                    <DynamicSelect
                        label="Select Skill"
                        name="skills"
                        keyName={"skillName"}
                        required={true}
                        rules={[{ required: true, message: 'This field is required' }]}
                    />
                </Col>
                <Col span={8}>
                    <DynamicSelect
                        label="Question Type"
                        name="questionType"
                        keyName={"QuestionType"}
                        required={true}
                        rules={[{ required: true, message: 'This field is required' }]}
                    />
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Marks"
                        name="marks"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <RichTextEditor
                        label={'Quetions'}
                        name={'question'}
                        validation={[{ required: true, message: 'This field is required' }]}
                        form={form}
                    />
                </Col>
                {form.getFieldValue('questionType') != 'Code' && <Col span={24}>
                    <Form.List
                        label="Options"
                        name="options"
                        initialValue={options}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, field, fieldKey, ...restField }, index) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 0 }} align="baseline">
                                        <RichTextEditor
                                            restField={restField}
                                            label={'Option'}
                                            name={[name, 'option']}
                                            fieldKey={[fieldKey, 'option']}
                                            parentKey="options"
                                            validation={[{ required: true, message: 'This field is required' }]}
                                            className="options-block"
                                            form={form}
                                        />
                                        <Form.Item
                                            {...restField}
                                            label={"Answer"}
                                            name={[name, 'answer']}
                                            valuePropName="checked"
                                            fieldKey={[fieldKey, 'answer']}
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                        <div className='form-buttons'>
                                            {fields.length > 1 ? (
                                                <MinusCircleOutlined onClick={() => { remove(name); handleRemove(fields.findIndex((field) => field.name === name)); }} />
                                            ) : null}
                                            {' '}
                                            {fields.length - 1 == index ? <PlusCircleOutlined onClick={() => add()} /> : <></>}
                                        </div>
                                    </Space>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Col>}
            </Row>
        </Form >
    </>
}

export default QuestionForm;