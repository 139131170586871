import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Input, Modal, Row, Space, Steps } from 'antd';
import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { getData, postData } from '../../../../actions/middleware';
import './index.css';
import ButtonSection from '../../../common/layout/ButtonSection';
import ContentSection from '../../../common/layout/ContentSection';
import { CopyOutlined } from '@ant-design/icons';
import RequirementForm from './form/requirementForm';
import RequirementFormV2 from './form/requirementFormV2';
import RequirementFormV3 from './form/requirementFormV3';
import { routes } from '../../../../actions/api-routes';
import { decryptData } from '../../../../actions/crypto';

const UploadRequirement = ({ pinned }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [submitForm, setSubmitForm] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [emailToSendLink, setEmailToSendLink] = useState('');
  const [emailLinkError, setEmailSendLinkError] = useState('');
  const job = queryParams.get('job');
  const currentTab = queryParams.get('tab');
  const [current, setCurrent] = useState(currentTab || 0);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getPublicLink()
  }, [])

  const getPublicLink = () => {

  }

  useEffect(() => {
    if (job) {
      navigate(`/admin/requirements/add?job=${job}&tab=${current}`)
      getProfileData(job)
    } else {
      navigate(`/admin/requirements/add?tab=${current}`)
    }
  }, [job, current])

  const getProfileData = async () => {
    const resData = await postData(routes.jobs.getby, { job });
    if (resData && resData.code == 200) {
      setUserData(decryptData(resData.data))
    }
  }

  const onChange = (value) => {
    setCurrent(value);
  };


  const onSubmit = () => {
    switch (parseInt(current)) {
      case 0:
        setSubmitForm('JOBDISCRIPTION')
        break;
      case 1:
        setSubmitForm('SKILLS')
        break;
      case 2:
        setSubmitForm('COMMERTIALS')
        break;
    }
  }

  const askDeveloperTofill = () => {
    setVisible(true)
  }

  const validateEmail = (e) => {
    setEmailToSendLink(e.target.value)
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(e.target.value)) {
      setEmailSendLinkError(null)
    } else {
      setEmailSendLinkError("Enter valid email")
    }
  }

  return (
    <div className='admin-view bg-fff full-bg'>
      <Modal
        onCancel={() => {
          setEmailSendLinkError(null)
          setEmailToSendLink(null)
          setVisible(false)
        }}
        open={isVisible}
        title={'Create a public link'}
        footer={<>
          {emailToSendLink &&
            <div>
              <Button disabled={emailLinkError} type='primary'>Send Link</Button>
            </div>
          }
        </>}
      >
        <div>
          <div>
            Give access to email
          </div>
          <Input type='email' value={emailToSendLink} onChange={(e) => validateEmail(e)} />
          {emailLinkError && <div style={{ color: 'red' }}>{emailLinkError}</div>}
        </div>
        <div>
          <Divider type='horizontal'>Or</Divider>
        </div>
        <div>
          Copy public Link <CopyOutlined />
        </div>
      </Modal>
      <ButtonSection pinned={pinned}>
        <div className='flex-view'>
          <div>
            <h6>Requirement</h6>
          </div>
          <div>
            <Space>
              <Button type="default" size='middle' onClick={() => navigate('/admin/requirements/quik-upload')}>
                Quik Post
              </Button>

              <Button type="default" size='middle' onClick={() => askDeveloperTofill()}>
                Genrate Link
              </Button>

              <Button type="default" size='middle' onClick={() => onChange(current ? current - 1 : 0)} disabled={!current} >
                Previous
              </Button>

              <Button type="default" size='middle' disabled>
                Preview Resume
              </Button>

              <Button type="default" size='middle' onClick={() => navigate('/admin/requirements/openings')}>
                Cancel
              </Button>

              <Button type="primary" size='middle' onClick={() => onSubmit()}>
                Save
              </Button>
            </Space>
          </div>
        </div>
      </ButtonSection>
      <ContentSection style={{ height: windowHeight - (150 - 15) }}>
        <Row gutter={16} style={{ margin: 0 }}>
          <Col span={3}>
            <div className='steps-box' style={{ position: 'fixed', height: windowHeight - (150 - 15) }}>
              <Steps
                current={current}
                size='default'
                labelPlacement='horizontal'
                direction="vertical"
                type='default'
                items={[
                  {
                    title: 'Job Discription',
                  },
                  {
                    title: 'Experience',
                  },
                  {
                    title: 'Commercials',
                  }
                ]} />
            </div>
          </Col>
          <Col className='formView' span={21} >
            <div className='p-5-10 formView' style={{ height: windowHeight - 140 }}>
              {current == 0 && <RequirementForm
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}
              {current == 1 && <RequirementFormV2
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}
              {current == 2 && <RequirementFormV3
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}

            </div>
          </Col>
        </Row>
      </ContentSection>
    </div>
  );
};

export default UploadRequirement;
