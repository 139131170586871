import { Avatar, Button, Space, Tabs } from 'antd'
import './index.css'

const LabelCard = ({ lable, text }) => {

    return <>
        <div className='card_view'>
            <div className='lable-heading'>{lable}</div>
            <div className='lable-text'>{text}</div>
        </div>
    </>
}

export default LabelCard;