import { useEffect, useState } from 'react';

function PaymentComponent() {

    const [queryParams, setQueryParams] = useState({});

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const queryParamsObject = {};
        for (const [key, value] of params) {
            queryParamsObject[key] = value;
        }
        setQueryParams(queryParamsObject);
    }, []);

    useEffect(() => {
        if (queryParams && queryParams.payment_url) {
            window.open(queryParams.payment_url, '_self')
        }
    }, [queryParams]);

    return <></>
}

export default PaymentComponent;