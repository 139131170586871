import { Col, Row } from 'antd';
import './index.css'
import LineChart from '../../dashboard/charts/LineCharts';

const TestDashboard = () => {
    return <>
        <div className='dashboard-box'>
            <div className='dashboard-title'>Test status Overview</div>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <div className='ongoing-test content'>
                        <div className='dashboard-image'>
                            <img src={require('../../../assets/images/onlineTest/ongong.png')} />
                        </div>
                        <div className='test-text-box'>
                            <div>Ongoing Test</div>
                            <div>05</div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='total-test content'>
                        <div className='dashboard-image'>
                            <img src={require('../../../assets/images/onlineTest/total.png')} />
                        </div>
                        <div className='test-text-box'>
                            <div>Total Test</div>
                            <div>05</div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='passed-test content'>
                        <div className='dashboard-image'>
                            <img src={require('../../../assets/images/onlineTest/passed.png')} />
                        </div>
                        <div className='test-text-box '>
                            <div>Passed Test</div>
                            <div>05</div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='failed-test content'>
                        <div className='dashboard-image'>
                            <img src={require('../../../assets/images/onlineTest/failed.png')} />
                        </div>
                        <div className='test-text-box'>
                            <div>Failed Test</div>
                            <div>05</div>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div className='chart-view'>
                        <LineChart />
                    </div>
                </Col>
            </Row>
        </div>
    </>
}

export default TestDashboard;