import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { routes } from '../../../../../actions/api-routes';
import { Button, Divider, Dropdown, Image, Select, Space, Steps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.css';

import { postData } from '../../../../../actions/middleware';
import { encryptData } from '../../../../../actions/crypto';
import ProfileView from '../../../../common/profile-view';

function DeveloperDetails({ details, statusUpdated }) {
  const [listData, setListData] = useState(details?.developerProfiles);
  const [profileStatus, setProfileStatus] = useState('Rate As');

  useEffect(() => {
    setListData(details?.developerProfiles)
    let status = '';
    if (details?.approvalStatus == 'SHORTLITED') {
      status = "Shortlisted";
    } else if (details?.approvalStatus == 'REJECTED') {
      status = "Rejected";
    } else {
      status = "Rate As";
    }
    setProfileStatus(status);
  }, [details])

  const handleMenuClick = (e) => {
    setProfileStatus(e.key);
    updateStatus(e.key);
    statusUpdated({ key: e.key, id: details._id })
  };


  const updateStatus = async (key) => {
    let status = '';
    if (key == 'Shortlisted') {
      status = "SHORTLITED";
    }
    if (key == 'Rejected') {
      status = "REJECTED";
    }
    await postData(routes.applied.read, { data: encryptData({ profile: details._id, data: { approvalStatus: status } }) });
  }

  const items = [
    {
      label: 'Shortlisted',
      key: 'Shortlisted',
    },
    {
      label: 'Rejected',
      key: 'Rejected',
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <div id="main-wrapper ">
        <div className='d-f justify-between'>
          <div className='heading-2'>{listData.firstName} {listData.lastName} </div>
          <div className='button-box'>
            <Dropdown menu={menuProps}>
              <Button style={profileStatus != 'Rate As' ? {
                fontWeight: 600, fontSize: 14,
                color: profileStatus == 'Shortlisted' ? 'green' : 'red'
              } : {}} type={profileStatus == 'Rate As' ? 'primary' : 'link'}>
                <Space>
                  {profileStatus}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
        {listData && <ProfileView listData={listData} />}
      </div>
    </>
  )
}

export default DeveloperDetails;
