import React, { useEffect, useState } from 'react';
import { Modal, Button, Space } from 'antd';
import TextAreaForm from './textarea';
import { FileAddOutlined, FilePdfOutlined } from '@ant-design/icons';

const PostModal = ({ isVisible, onClose }) => {
    const [onSubmitFromHeader, setFormHeader] = useState(null)

    const handleCancel = () => {
        onClose(false);
    };

    const onSubmit = () => {
        setFormHeader('text_submit')
    }

    const clearText = () => {
        setFormHeader(null)
        onClose();
    }

    return (
        <div>
            <Modal
                title="Post Requirement"
                open={isVisible}
                onCancel={handleCancel}
                footer={<>
                        <Space>
                        <Button type='primary' onClick={() => onSubmit()}>Post</Button>
                    </Space>
                </>}
            >
                <div style={{ overflowX: 'hidden' }} className='quik-post'>
                    <TextAreaForm onSubmitFromHeader={onSubmitFromHeader} onClear={() => clearText()} />
                </div>
            </Modal>
        </div>
    );
};

export default PostModal;
