// import React, { useState } from 'react';
// import { Form, InputNumber, Button, Typography, Card } from 'antd';

// const { Title } = Typography;

// function CalculatorView() {
//     const [result, setResult] = useState(null);

//     const onFinish = (values) => {
//         const { value, percentage } = values;
//         if (percentage !== 0) {
//             const totalValue = value / (1 - percentage / 100);
//             setResult(totalValue.toFixed(2));
//         } else {
//             setResult('Percentage cannot be zero.');
//         }
//     };

//     return (
//         <div className='formView' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
//             <Card style={{ width: 400 }}>
//                 <Title level={2} style={{ textAlign: 'center' }}>Percentage Calculator</Title>
//                 <Form
//                     layout="vertical"
//                     onFinish={onFinish}
//                 >
//                     <Form.Item
//                         label="Enter Value"
//                         name="value"
//                         rules={[{ required: true, message: 'Please input the value!' }]}
//                     >
//                         <InputNumber style={{ width: '100%' }} />
//                     </Form.Item>
//                     <Form.Item
//                         label="Enter Percentage"
//                         name="percentage"
//                         rules={[{ required: true, message: 'Please input the percentage!' }]}
//                     >
//                         <InputNumber style={{ width: '100%' }} />
//                     </Form.Item>
//                     <Form.Item>
//                         <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
//                             Calculate
//                         </Button>
//                     </Form.Item>
//                 </Form>
//                 {result !== null && (
//                     <Title level={4} style={{ textAlign: 'center', marginTop: 20 }}>{result}</Title>
//                 )}
//             </Card>
//         </div>
//     );
// }

// export default CalculatorView;

import React, { useState } from 'react';
import { Form, InputNumber, Button, Typography, Card } from 'antd';

const { Title } = Typography;

function CalculatorView() {
    const [result, setResult] = useState(null);

    const onFinish = (values) => {
        const { value, percentage, volume } = values;
        const effectiveVolume = volume || 1000;
        if (percentage !== 0) {
            const baseTotalValue = value / (1 - percentage / 100);
            const adjustedValue = (baseTotalValue / 1000) * effectiveVolume;
            setResult(adjustedValue.toFixed(2));
        } else {
            setResult('Percentage cannot be zero.');
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
            <Card style={{ width: 400 }}>
                <Title level={2} style={{ textAlign: 'center' }}>Percentage Calculator</Title>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Enter Value"
                        name="value"
                        rules={[{ required: true, message: 'Please input the value!' }]}
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="Enter Percentage"
                        name="percentage"
                        rules={[{ required: true, message: 'Please input the percentage!' }]}
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="Enter Volume (optional, default is 1000)"
                        name="volume"
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <div style={{fontSize: '25px'}}>
                        {result !== null && (
                            <Title level={4} style={{ textAlign: 'center', marginTop: 20 }}>{result}</Title>
                        )}
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Calculate
                        </Button>
                    </Form.Item>
                </Form>

            </Card>
        </div>
    );
}

export default CalculatorView;
