import React, { useEffect, useState } from 'react';
import { Avatar, Button, Col, Drawer, Modal, Progress, Row, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { logout } from '../actions/auth';

const LogOutComponent = ({ isVisible, width, onClose, onSave }) => {
    const [modal, contextHolder] = Modal.useModal();

    const config = {
        title: 'Confirm',
        content: 'Are you sure want to Logout?'
    };

    const onLogot = async (row) => {
        const confirmed = await modal.confirm(config);
        if (confirmed) {
            logout();
        }
    }

    return <>
        {contextHolder}
        <div onClick={() => onLogot()}>
            <LogoutOutlined /> Log out
        </div>
    </>
};

export default LogOutComponent;
