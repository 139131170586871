
import { Button, notification, Space } from 'antd';


const openNotificationWithIcon = (type, message, disc) => {
  notification[type]({
    message: message,
    description: disc,
  });
};



const success = (mesg, dis) => {
  openNotificationWithIcon('success', mesg, dis)
}

const info = (mesg, dis) => {
  openNotificationWithIcon('info', mesg, dis)
}


const warning = (mesg, dis) => {
  openNotificationWithIcon('warning', mesg, dis)
}


const error = (mesg, dis) => {
  openNotificationWithIcon('error', mesg, dis)
}


export { error, warning, info, success }