import React, { useEffect, useRef } from 'react';
import { Alert, Button, Col, FloatButton, Radio, Row, Select, Slider } from 'antd';
const { Option } = Select;
import './index.css';

const ResourcesFilter = () => {

    const marks = {
        1: '1 Year',
        30: '30 Year',
    };


    const budgetMarks = {
        0: '0 LPM',
        40: '40 LPM',
    };

    const onChange = (value) => {
        console.log('onChange: ', value);
    };
    const onChangeComplete = (value) => {
        console.log('onChangeComplete: ', value);
    };

    return (
        <div className='filter-wrapper'>
            <div className='heading-2'>Filter</div>
            <div className='filter-options'>
                <div>
                    <div className='heading-3'>Skills</div>
                    <div>
                        <Select
                            allowClear={true}
                            mode="tags"
                            style={{ width: '100%' }}
                            maxTagCount={5}
                            maxTagTextLength={5}
                        >
                            <Option value={'Java'}>Java</Option>
                            <Option value={'PHP'}>PHP</Option>
                            <Option value={'DotNet'}>Dot Net</Option>
                        </Select>
                    </div>
                </div>
                <div>
                    <div className='heading-3'>Location</div>
                    <div>
                        <Select
                            allowClear={true}
                            mode="tags"
                            style={{ width: '100%' }}
                            maxTagCount={5}
                            maxTagTextLength={5}
                        >
                            <Option value={'Remote'}>Remote</Option>
                            <Option value={'Onsite'}>Onsite</Option>
                        </Select>
                    </div>
                </div>
                <div>
                    <div className='heading-3'>Location</div>
                    <div>
                        <Select
                            allowClear={true}
                            mode="tags"
                            style={{ width: '100%' }}
                            maxTagCount={5}
                            maxTagTextLength={5}
                        >
                            <Option value={'Remote'}>Remote</Option>
                            <Option value={'Onsite'}>Onsite</Option>
                        </Select>
                    </div>
                </div>
                <div>
                    <div className='heading-3'>Experiance</div>
                    <div>
                        <Slider
                            marks={marks}
                            range={true}
                            min={1}
                            max={30}
                            onChange={onChange}
                            onChangeComplete={onChangeComplete}
                            defaultValue={[1, 10]}
                        />
                    </div>
                </div>
                {/* <div>
                    <div className='heading-3'>Budget Monthly</div>
                    <div>
                        <Slider
                            marks={budgetMarks}
                            range={true}
                            min={0}
                            max={40}
                            onChange={onChange}
                            onChangeComplete={onChangeComplete}
                            defaultValue={[2, 10]}
                            tooltip={'heelo'}
                        />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default ResourcesFilter;
