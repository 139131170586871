import { Button, Descriptions } from "antd";
import React, { useState } from "react";
import PdfViewer from "../../../components/PdfViewer";
import { LinkOutlined } from '@ant-design/icons';
import { routes } from "../../../actions/api-routes";

const ProviewComponent = ({ data }) => {
    const [isVisible, setVisible] = useState(false);

    return <>
        <Descriptions title={'My Profile'}>
            <Descriptions.Item span={24} label="Name">{data?.firstName} {data?.middleName} {data?.lastName}</Descriptions.Item>
            <Descriptions.Item span={24} label="Phone">{data?.phone}</Descriptions.Item>
            <Descriptions.Item span={24} label="Gender">{data?.gender}</Descriptions.Item>
            <Descriptions.Item span={24} label="Experiance Level">{data?.candidateType}</Descriptions.Item>
            <Descriptions.Item span={24} label="Internship Experiance">{data?.internshipExperiance}</Descriptions.Item>
            {data?.internshipExperiance == 'Experience' && <Descriptions.Item span={24} label="Experiance">{data?.experiance}</Descriptions.Item>}
            {data?.currentCTC && <Descriptions.Item span={24} label="Current CTC">{data?.currentCTC}</Descriptions.Item>}
            {data?.expectedCTC && <Descriptions.Item span={24} label="Expected CTC">{data?.expectedCTC}</Descriptions.Item>}
            <Descriptions.Item span={24} label="Notice Period">{data?.noticePeriod}</Descriptions.Item>
            <Descriptions.Item span={24} label="Resume">
                <Button
                    onClick={() => setVisible(true)}
                    type='link'
                    icon={<LinkOutlined />}
                    className='no-padding'>
                    View Resume
                </Button></Descriptions.Item>
            <Descriptions.Item span={24} label="Address">{data?.address}, {data?.city}, {data?.state}, {data?.country} ({data?.zipCode})</Descriptions.Item>
        </Descriptions>
        <div className='profile-box'>
            <PdfViewer
                className='react-model'
                title={'Resume'}
                isOpen={isVisible}
                onClose={() => setVisible(false)}
                pdfUrl={`${routes.fileServer}${data?.resume}`}
            >
            </PdfViewer>
        </div>
    </>
}

export default ProviewComponent;