
export const HireDevelopers = {
  mainTitle: 'Hire Developers',
  menu: [
    {
      list: [
        {
          title: 'Hire Frontend Developers',
          list: [
            { name: 'Hire UI/UX Designer', link: '/hire-developers?search=' },
            { name: 'Hire HTML/CSS Developers', link: '/hire-developers?search=' },
            { name: 'Hire AngularJS Developers', link: '/hire-developers?search=' },
            { name: 'Hire ReactJS Developers', link: '/hire-developers?search=' },
            { name: 'Hire Angular 2+ Developers', link: '/hire-developers?search=' },
            { name: 'Hire VueJS Developers', link: '/hire-developers?search=' },
            { name: 'Hire NextJS Developers', link: '/hire-developers?search=' },
            { name: 'Hire JavaScript Developers', link: '/hire-developers?search=' },
          ],
        },
        {
          title: 'Hire DevOps Developers',
          list: [
            { name: 'Hire DevOps Developers', link: '/hire-developers?search=' },
            { name: 'Hire CI/CD Developers', link: '/hire-developers?search=' },
            { name: 'Hire Azure Developers', link: '/hire-developers?search=' },
          ],
        },
      ],
    },
    {
      list: [
        {
          title: 'Hire Backend Developers',
          list: [
            { name: 'Hire NodeJS Developers', link: '/hire-developers?search=' },
            { name: 'Hire NestJS Developers', link: '/hire-developers?search=' },
            { name: 'Hire Java Developers', link: '/hire-developers?search=' },
            { name: 'Hire PHP Developers', link: '/hire-developers?search=' },
            { name: 'Hire Laravel Developers', link: '/hire-developers?search=' },
            { name: 'Hire ASP.Net Developers', link: '/hire-developers?search=' },
            { name: 'Hire Python Developers', link: '/hire-developers?search=' },
            { name: 'Hire Flask Developers', link: '/hire-developers?search=' },
            { name: 'Hire ROR Developers', link: '/hire-developers?search=' },
            { name: 'Hire Golang Developers', link: '/hire-developers?search=' },
            { name: 'Hire Codeigniter Developers', link: '/hire-developers?search=' },
            { name: 'Hire Django Developers', link: '/hire-developers?search=' },
          ],
        },
      ],
    },
    {
      list: [
        {
          title: 'Hire Frontend & Backend Developers',
          list: [
            { name: 'Hire Java with Reactjs Developers', link: '/hire-developers?search=' },
            { name: 'Hire Java with Angular Developers', link: '/hire-developers?search=' },
            { name: 'Hire MEAN Stack Developers', link: '/hire-developers?search=' },
            { name: 'Hire MERN Stack Developers', link: '/hire-developers?search=' },
            { name: 'Hire Full Stack Developers', link: '/hire-developers?search=' },
            { name: 'Hire Nodejs with VueJS Developers', link: '/hire-developers?search=' },
            { name: 'Hire Nodejs with Jade Developers', link: '/hire-developers?search=' },
            { name: 'Hire Nodejs with EJS Developers', link: '/hire-developers?search=' },
          ],
        },
      ],
    },
    {
      list: [
        {
          title: 'Hire Mobile Developers',
          list: [
            { name: 'Hire Android Developers', link: '/hire-developers?search=' },
            { name: 'Hire IOS Developers', link: '/hire-developers?search=' },
            { name: 'Hire React Native Developers', link: '/hire-developers?search=' },
            { name: 'Hire Kotlin Developers', link: '/hire-developers?search=' },
            { name: 'Hire Swift Developers', link: '/hire-developers?search=' },
            { name: 'Hire AR/VR Developers', link: '/hire-developers?search=' },
            { name: 'Hire Flutter Developers', link: '/hire-developers?search=' },
            { name: 'Hire Ionic Developers', link: '/hire-developers?search=' },
            { name: 'Hire Xamarin Developers', link: '/hire-developers?search=' },
          ],
        },
        {
          title: 'Hire Artificial Developers',
          list: [
            { name: 'Hire AI Developers', link: '/hire-developers?search=' },
            { name: 'Hire Machine Learning', link: '/hire-developers?search=' },
            { name: 'Hire Data Analytics', link: '/hire-developers?search=' },
          ],
        },
      ],
    },
    {
      list: [
        {
          title: 'Other Services',
          list: [
            { name: 'Hire Software Tester', link: '/hire-developers?search=' },
            { name: 'Hire Automation Tester', link: '/hire-developers?search=' },
            { name: 'Hire VAPT Tester', link: '/hire-developers?search=' },
            { name: 'Hire Meteor Developers', link: '/hire-developers?search=' },
            { name: 'Hire AWS Cloud Management', link: '/hire-developers?search=' },
            { name: 'Hire Symfony Developers', link: '/hire-developers?search=' },
            { name: 'Hire GraphQL Developers', link: '/hire-developers?search=' },
            { name: 'Hire Salesforce Developers', link: '/hire-developers?search=' },
            { name: 'Hire Microsoft Power BI Developers', link: '/hire-developers?search=' },
          ],
        },
      ],
    }
  ]
}





