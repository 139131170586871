import { useLocation, useNavigate } from 'react-router-dom';
import './index.css';


import { Form, Input, Button, Checkbox, notification } from 'antd';
import { validateConfirmPassword, validatePassword } from '../admin/common/validate';
import { useState, useEffect } from 'react';
import { postData } from '../actions/middleware';

function ChangePassword() {
  const location = useLocation();
  const [emailCode, setEmailCode] = useState('');

  const navigate = useNavigate()

  const onFinish = async (values) => {
    try {
      const resData = await postData('/vendor/change-password', { ...values, email: emailCode })
      if (resData && resData.code == 200) {
        notification.success({
          message: 'Password Changed Successfully',
          description: 'Your password has been changed successfully. Please log in to access our platform.'
        })
        navigate('/auth/login')
      } else {
        notification.success({
          message: 'Error',
          description: resData.message
        })
      }
    } catch (error) {
      notification.success({
        message: 'Error',
        description: error.message
      })
    }

    // Add your login logic here
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('code');
    setEmailCode(paramValue)
  }, [location.search]);


  return (
    <div id="main-wrapper" className='login-register'>
      <div className="site-wrapper-reveal">
        <div className="row">
          <div className="col-lg-4 col-md-4 center pt-10">
            <div className="projects-wrap style-04 wow move-up">
              <div className="projects-image-box">
                <div className="content">
                  <div className='text-center p-10'>
                    <h5 className='heading'>Change Password</h5>
                  </div>
                  <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    layout='vertical'
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password!',
                        },
                        {
                          validator: validatePassword,
                        }
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      label="Confirm Password"
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter confirm password!',
                        },
                        validateConfirmPassword,
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType='submit' size={'large'} style={{ width: '100%' }}>
                        Change Password
                      </Button>
                    </Form.Item>
                  </Form>
                  <Button type='link' className='no-padding' onClick={() => navigate('/auth/login')} >Have an account? Login </Button>
                </div>
              </div>
              <div className='text-center'>
                <div>Don't have an account ? <Button className='no-padding' type='link' onClick={() => navigate('/auth/register')} >Sign up</Button> </div>
                <div> Please <Button type='link' className='no-padding'>Contact Us</Button> if you require any assistance  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword;
