import moment from "moment";
import { Table, Tag } from "antd";
import Actions from "../../../actions/table-configs/actions";

const TestTableConfig = (actions, config, refreshData, openModal) => [
    {
        title: 'Actions',
        dataIndex: '',
        key: '',
        ellipsis: true,
        render: (text) => {
            let array = []
            if (text && !text.pdfFile) {
                array.push('View Pdf')
            }
            if (text && text.approvalStatus == 'APPROVED') {
                array.push('delete')
            }
            return <Actions onChangeMenu={(e) => Actions(e, text)} config={config.filter(e => !array.includes(e))} />
        }
    },
    {
        title: 'Test name',
        dataIndex: 'testName',
        key: 'testName',
        ellipsis: true,
        render: (text) => {
            return text
        }
    },
    {
        title: 'Time required',
        dataIndex: 'timeRequired',
        key: 'timeRequired',
        ellipsis: true,
        render: (text) => {
            return <Tag color="blue" bordered={false}>{text}</Tag>
        }
    },
    {
        title: 'Expire in',
        dataIndex: 'expiredTime',
        key: 'expiredTime',
        ellipsis: true,
        render: (text) => {
            return <Tag color="blue" bordered={false}>{text} Hours</Tag>
        }
    },
    Table.EXPAND_COLUMN,
    {
        title: 'Skills',
        dataIndex: 'testConfig',
        key: 'testConfig',
        ellipsis: true,
        render: (text) => {
            return text.map(ele => <Tag bordered={false}>{ele.skillName}</Tag>)
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        ellipsis: true,
        render: (text) => {
            return <Tag color={text == 'active' ? 'green' : 'red'}>{text}</Tag>
        }
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        ellipsis: true,
        render: (text) => {
            return moment(text).format('lll')
        }
    }
];



export { TestTableConfig }