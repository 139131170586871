import React, { useState } from 'react';
import { Layout, Menu } from 'antd';

const { Sider, Content } = Layout;

const DocumentViewer = () => {
    const [selectedDocument, setSelectedDocument] = useState(null);
    const handleDocumentSelect = (documentName) => {
        setSelectedDocument('https://clickdimensions.com/links/TestPDFfile.pdf');
    };

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider width={200} style={{ background: '#fff' }}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['0']}
                    style={{ height: '100%', borderRight: 0 }}
                >
                    <Menu.Item key="0" onClick={() => handleDocumentSelect(null)}>Select a document</Menu.Item>
                    <Menu.Item key="1" onClick={() => handleDocumentSelect('dummy1.pdf')}>Document 1</Menu.Item>
                    <Menu.Item key="2" onClick={() => handleDocumentSelect('dummy2.pdf')}>Document 2</Menu.Item>
                    <Menu.Item key="3" onClick={() => handleDocumentSelect('dummy3.pdf')}>Document 3</Menu.Item>
                </Menu>
            </Sider>
            <Content style={{ padding: '24px', background: '#fff', overflowY: 'auto' }}>
                <div style={{ width: '100%', height: '100%' }}>
                    {selectedDocument && (
                        <iframe
                            title="PDF Viewer"
                            src={selectedDocument}
                            style={{ width: '100%', height: '100%', border: 'none' }}
                        />
                    )}
                </div>
            </Content>
        </Layout>
    );
};

export default DocumentViewer;
