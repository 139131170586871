import axios from "axios";
import Cookies from "js-cookie";
import { merge } from "lodash";
import * as jwt_decode from 'jwt-decode';
import { getData } from "./middleware";
import { useEffect } from "react";
const APP_COOKIE = 'vendor-login'
const APPLICANT_COOKIS = 'applicant_cookies'

const setAuthHeader = (jwt) => {
  axios.defaults.headers.common.authorization = `Bearer ${jwt}`;
  axios.defaults.headers.common.token = jwt;
};

const setJWT = (jwt) => {
  let payload = { jwt };
  const localData = Cookies.get(APP_COOKIE);
  if (localData) {
    payload = merge(JSON.parse(localData), payload);
  }
  Cookies.set(APP_COOKIE, JSON.stringify(payload));
};

const SetCookies = (data) => {
  setJWT(data.accessToken);
  setAuthHeader(data.accessToken)
}


const getUserData = () => {
  const localData = Cookies.get(APP_COOKIE)
  if (localData) {
    const token = JSON.parse(localData).jwt;
    const data = jwt_decode.jwtDecode(token);
    setAuthHeader(token)
    checkAuthentication();
    return { ...data, token: token };
  }
  return undefined;
};

const checkAuthentication = async () => {
  getData('/vendor').then(resData => {
    if (resData.code !== 200) {
      clearJWT();
    }
  }).catch(error => {
    clearJWT();
  })
}


const getRole = () => {
  const localData = Cookies.getJSON(APP_COOKIE);
  if (localData) {
    return localData.role;
  }
  return undefined;
};


const clearJWT = async () => {
  Cookies.remove(APP_COOKIE);
  location.href = '/auth'
};


const logout = async () => {
  clearJWT();
  localStorage.clear();
};



//Applicant 
const setApplicantJWT = (jwt) => {
  let payload = { jwt };
  const localData = Cookies.get(APPLICANT_COOKIS);
  if (localData) {
    payload = merge(JSON.parse(localData), payload);
  }
  Cookies.set(APPLICANT_COOKIS, JSON.stringify(payload));
};

const SetApplicantCookies = (data) => {
  setApplicantJWT(data.accessToken);
  setAuthHeader(data.accessToken)
}

const getApplicantData = () => {
  const localData = Cookies.get(APPLICANT_COOKIS)
  if (localData) {
    const token = JSON.parse(localData).jwt;
    const data = jwt_decode.jwtDecode(token);
    setAuthHeader(token)
    return { ...data, token: token };
  }
  return undefined;
};

const clearApplicantJWT = async () => {
  Cookies.remove(APPLICANT_COOKIS);
  location.href = '/interview'
};

const getAapplicant = async () => {
  getData('/applicant').then(resData => {
    if (resData.code !== 200) {
      clearApplicantJWT();
    }
  }).catch(error => {
    clearApplicantJWT();
  })
}


const logoutApplicant = async () => {
  clearApplicantJWT();
  localStorage.clear();
};

export { setAuthHeader, logout, clearJWT, getRole, getUserData, setJWT, SetCookies, getApplicantData, SetApplicantCookies, setApplicantJWT, logoutApplicant, getAapplicant }