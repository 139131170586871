import React, { useRef, useState, useEffect } from 'react';
import { Form, Input, Button, Space, Select, Row, Col, message, Switch, Upload } from 'antd';
const { Option } = Select;
import { MinusCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import DynamicSelect from '../../../../common/Select';
import { postData, uploadFile } from '../../../../../actions/middleware';
import { routes } from '../../../../../actions/api-routes';
import { error, success } from '../../../../common/Notify';
import './../../index.css'
import { useNavigate } from 'react-router-dom';
import { encryptData } from '../../../../../actions/crypto';
import ReactQuill from 'react-quill';

const { useForm } = Form;

const required = { required: true, message: 'This field is required' }

const RequirementForm = ({ onSubmitFromHeader, onClear, nextCall, data }) => {
  const [form] = useForm()
  const navigate = useNavigate();
  const [changeLocation, setChangeLocation] = useState('');
  const [typeOfRequirment, setTypeOfRequirment] = useState('Text');

  const [fileList, setFileList] = useState([]);
  const [fileResponse, setFileResponse] = useState();


  const queryParams = new URLSearchParams(location.search);
  const job = queryParams.get('job');

  useEffect(() => {
    if (onSubmitFromHeader == 'JOBDISCRIPTION') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])

  const onFinish = async (values) => {
    if (typeOfRequirment == 'Image') {
      values['requirmentFile'] = fileResponse
    }
    const encryptedData = encryptData({ ...values, typeOfRequirment: typeOfRequirment, jobId: job });
    const resData = await postData(routes.jobs.list + 'JOBDISCRIPTION', { data: encryptedData });
    if (resData && resData.code == 200) {
      navigate(`/admin/requirements/add?job=${resData.data}`)
      success("Success", "Details Added Successfully")
      nextCall(1)
    } else {
      error("Failed", "Something went wrong please try again later")
    }

  };

  const onDelete = () => {
    setFileResponse('')
    form.setFieldValue('photo', '');
  }

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    return isImage;
  };

  const customRequest = async ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append('file', file);
    const resData = await uploadFile(routes.fileUpload, formData)
    if (resData && resData.status == 'SUCCESS') {
      form.setFieldValue('photo', resData.files[0]);
      form.setFields([{ name: 'photo', errors: [] }]);
      setFileResponse(resData.files[0])
      message.success(`${file.name} uploaded successfully`);
      onSuccess();
    } else {
      message.error(`${file.name} Failed to upload`);
    }
  };


  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setChangeLocation(data.jobLocationType)
      setTypeOfRequirment(data.typeOfRequirment)
      if (data.typeOfRequirment == 'Image') {
        setFileResponse(data.requirmentFile)
      }
    }
  }, [data])

  return (
    <>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        layout='vertical'
        className='requirment-form'
        scrollToFirstError={true}
      >
        <Row gutter={16} justify={'space-around'} >
          <Col span={10}>
            <DynamicSelect
              label="Job Title"
              name="jobTitle"
              keyName={"jobTitle"}
              rules={[required]}
            />
          </Col>
          <Col span={10}>
            <Form.Item
              label="Job Location Type"
              name="jobLocationType"
              rules={[required]}
            >
              <Select onChange={setChangeLocation}>
                {['Remote', 'Onsite', 'Hybrid'].map(ele => <Option key={ele} value={ele}>{ele}</Option>)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {changeLocation && changeLocation !== 'Remote' && <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <DynamicSelect
              label="Work Location"
              name="workLocation"
              keyName={"workLocation"}
              required={true}
            />
          </Col>
          <Col span={10}></Col>
        </Row>}

        <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <Form.Item
              label={
                <div className='lable-class-view'>
                  <div>Job Description</div>
                  <div className='switch-button'>
                    <Switch
                      checkedChildren="Text"
                      unCheckedChildren="Image"
                      defaultChecked={typeOfRequirment}
                      onChange={(e) => setTypeOfRequirment(e ? 'Text' : 'Image')}
                    />
                  </div>
                </div>
              }
              name="jobDescription"
              rules={[required]}
            >
              {typeOfRequirment == 'Text' && <ReactQuill
                theme='snow'
                placeholder='Enter jd...'
              />}
              {typeOfRequirment == 'Image' && <div className='avatar-container-requiremnt'>
                <img className="avatar-image" src={fileResponse ? routes.fileServer + fileResponse : require('./../../../../../assets/images/logo/logov1.png')}
                  onError={(e) => { e.target.src = require('./../../../../../assets/images/logo/logov1.png') }} alt="Profile Avatar" />
                {!fileResponse ? <Upload
                  beforeUpload={beforeUpload}
                  customRequest={customRequest}
                  fileList={fileList}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />} className="upload-button-requirment" />
                </Upload> :
                  <Button icon={<DeleteOutlined />} onClick={onDelete} className="upload-button-requirment" />
                }
              </div>} 
            </Form.Item>
          </Col>
          <Col span={10}></Col>
        </Row>
      </Form >
    </>
  );
};

export default RequirementForm;
