// AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import OnlineTest from '../onlineTest';
import HomeView from '../onlineTest/Home';
import ExamWithHeaderRoutes from './ExamWithHeaderRoutes';
import AboutTest from '../onlineTest/about-test';
import OnlineExam from '../onlineTest/OnlineExam';
import ExamInstructionsPDF from '../onlineTest/Instructions';

const OnlineExamRoutes = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<OnlineTest />} /> */}
        <Route path="/" element={<HomeView />} />
        <Route path='/about-test' element={<AboutTest />} />
        {/* <Route path="/read-instructions" element={<ExamInstructionsPDF />} />
        <Route path="/online-exam" element={<OnlineTest />} /> */}
        <Route path="/*" element={<ExamWithHeaderRoutes />} />
      </Routes>
    </>
  );
};

export default OnlineExamRoutes;
