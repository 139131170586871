import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core'; // Import Viewer component
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // Import default layout plugin
import '@react-pdf-viewer/core/lib/styles/index.css'; // Import styles for Viewer
import '@react-pdf-viewer/default-layout/lib/styles/index.css'; // Import styles for default layout
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'
pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker

const PDFViewer = ({ fileUrl }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div>
            <Viewer
                fileUrl={fileUrl}
                plugins={[defaultLayoutPluginInstance]}
            />
        </div>
    );
};

export default PDFViewer;
