// AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ExamInstructionsPDF from '../onlineTest/Instructions';
import OnlineExam from '../onlineTest/OnlineExam';
import ProtectedComponent from '../onlineTest/protected';

const ExamWithHeaderRoutes = () => {

  return (
    <>
      <ProtectedComponent>
        <Routes>
          <Route path="/read-instructions" element={<ExamInstructionsPDF />} />
          <Route path="/online-exam" element={<OnlineExam />} />
        </Routes>
      </ProtectedComponent>
    </>
  );
};

export default ExamWithHeaderRoutes;
