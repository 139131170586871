import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Input, Modal, Row, Space, Radio, Alert } from 'antd';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './index.css';
import ButtonSection from '../../common/layout/ButtonSection';
import ContentSection from '../../common/layout/ContentSection';
import { CopyOutlined, RightCircleOutlined } from '@ant-design/icons';
import FileUpload from './fileUpload';

const QuikResumeUploadRequirment = ({ pinned, isPublic = false }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [submitForm, setSubmitForm] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [emailToSendLink, setEmailToSendLink] = useState('');
  const [emailLinkError, setEmailSendLinkError] = useState('');
  const [formSubmit, setFormSubmit] = useState('');
  const [current, setCurrent] = useState('text');

  const publicValue = queryParams.get('public');

  const divRef = useRef(null);
  const [divWidth, setDivWidth] = useState(null);

  useEffect(() => {
    if (divRef.current) {
      const width = divRef.current.offsetWidth;
      setDivWidth(width);
    }
  }, [divRef]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const onChange = (value) => {
    setCurrent(value.target.value);
  };

  const askDeveloperTofill = () => {
    setVisible(true)
  }

  const validateEmail = (e) => {
    setEmailToSendLink(e.target.value)
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(e.target.value)) {
      setEmailSendLinkError(null)
    } else {
      setEmailSendLinkError("Enter valid email")
    }
  }

  const onSubmit = () => {
    setFormSubmit('Submit')
  }

  return (
    <div className='admin-view bg-fff full-bg'>
      <Modal
        onCancel={() => {
          setEmailSendLinkError(null)
          setEmailToSendLink(null)
          setVisible(false)
        }}
        open={isVisible}
        title={'Create a public link'}
        footer={<>
          {emailToSendLink &&
            <div>
              <Button disabled={emailLinkError} type='primary'>Send Link</Button>
            </div>
          }
        </>}
      >
        <div>
          <div>
            Give access to email
          </div>
          <Input type='email' value={emailToSendLink} onChange={(e) => validateEmail(e)} />
          {emailLinkError && <div style={{ color: 'red' }}>{emailLinkError}</div>}
        </div>
        <div>
          <Divider type='horizontal'>Or</Divider>
        </div>
        <div>
          Copy public Link <CopyOutlined />
        </div>
      </Modal>
      <ButtonSection pinned={pinned} isPublic={isPublic}>
        <div className='flex-view'>
          <div>
            <h6>Quik Post</h6>
          </div>
          <div>
            <Space>
              <Button type="default" size='middle' onClick={() => askDeveloperTofill()}>
                Quik Approval
              </Button>

              {!publicValue && <Button type="default" size='middle' onClick={() => askDeveloperTofill()}>
                Genrate public Link
              </Button>}

              <Button type="default" size='middle'>
                Cancel
              </Button>

              <Button type="primary" size='middle' onClick={() => onSubmit()}>
                Save
              </Button>
            </Space>
          </div>
        </div>
      </ButtonSection>
      <ContentSection style={{ height: windowHeight - (150 - 15) }}>
        <Row gutter={16} style={{ margin: 0 }}>
          <Col span={4} ref={divRef}>
            <div className='steps-box note' style={{ position: 'fixed', height: isPublic ? '100%' : windowHeight - (150 - 15), width: divWidth }}>
              <div className='points'>
                <div className='note-title'>Please ensure that your CV includes.</div>
                <div className='ponit'><RightCircleOutlined /> Tatal Experiance </div>
                <div className='ponit'><RightCircleOutlined /> Technical Skills</div>
                <div className='ponit'><RightCircleOutlined /> Projects</div>
              </div>
            </div>
          </Col>
          <Col className='formView' span={20} >
            <div className='p-5-10 formView' style={{ height: windowHeight - 140 }}>
              <Alert message="Upload in bulk. Once you have uploaded the profiles, the system will update all of them with the new resume format we are currently using. This process will take approximately 30 to 60 minutes."></Alert>
              <div style={{ paddingTop: '10px' }}>
                <FileUpload submitForm={formSubmit} onClear={() => setFormSubmit(null)} />
              </div>
            </div>
          </Col>
        </Row>
      </ContentSection>
    </div>
  );
};

export default QuikResumeUploadRequirment;
