import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './../index.css';
import MyRequirmentList from './myRequierment';

const ProfileReceived = ({ subTab, mainTab }) => {
    const [openClose, setOpenClose] = useState(subTab);
    const [aprroveReject, setApproveReject] = useState(mainTab);

    useEffect(() => {
        if (subTab) {
            setOpenClose(subTab)
        }

        if (mainTab) {
            setApproveReject(mainTab)
        }
    }, [subTab, mainTab])

    return <MyRequirmentList subTab={openClose} tab={aprroveReject} />
};

export default ProfileReceived;
