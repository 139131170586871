import { useState } from 'react';
import { useEffect } from 'react';
import { getData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import { Link, useLocation } from 'react-router-dom';
import Skills from '../../company/careers/showSkils';
import { Button, Divider, Space, Steps } from 'antd';
import ProjectDetails from './project-details';
import Responsibilites from './responsibilities';
import Educations from './educations';
import Langauges from './languages';
import { ArrowLeftOutlined, DoubleLeftOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import './index.css';

import {
  ClockCircleOutlined,
  HourglassOutlined,
  SafetyOutlined,
  SettingOutlined,
  ShoppingOutlined,
  AccountBookOutlined,
  InsertRowLeftOutlined,
  GoldOutlined
} from '@ant-design/icons';

function HireDeveloperDetails() {
  const [listData, setListData] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('developer');

  useEffect(() => {
    getProfileData();
  }, [])

  const getProfileData = async () => {
    const resData = await getData(routes.public.getProfiles + paramValue.split('-')[1]);
    if (resData && resData.code == 200) {
      setListData(resData.data)
    }
  }

  return (
    <>
      <div id="main-wrapper padding-top-20">
        <div className="feature-images-wrapper job-details">
          <div className="container">
            <div className='row'>
              <div className='col-lg-10'>
                <ArrowLeftOutlined /> <a href='/home' className='link'>Home</a> <DoubleLeftOutlined /> <Link className='link' to='/hire-developers'> Developers</Link> <DoubleLeftOutlined /> AGPF{listData.profile}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-10 m-a cv-box'>
                <div className="projects-wrap  style-04 wow move-up">
                  <div className="projects-image-box padding-30 developer-profile">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className='avatar-container-hire'>
                          <img className="avatar-image" src={listData.photo ? routes.fileServer + listData.photo : require('./../../../assets/images/logo/logov1.png')}
                            onError={(e) => { e.target.src = require('./../../../assets/images/logo/logov1.png') }}
                          alt="Profile Avatar" />
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className='d-f'>
                          <div className='heading-text'>{listData.firstName} {listData.lastName} </div>
                          <div className='profileId'>AGPF{listData.profile} </div>
                          <div className='active-text'>Active</div>
                        </div>
                        <div className='heading-text-1'>
                          {listData.JobTitle} in {listData.city} ({listData.citizenship})
                        </div>
                        <div className='discriptions'>
                          {listData.shortBio}
                        </div>
                      </div>
                      <div className='col-12 pad-top-15'>
                        {listData && listData.skills && <Skills ele={{ skils: listData.skills.map(e => e.skillName) }} noWrap={true} />}
                      </div>
                      <div className='col-8 industries text'>
                        <span className='text bold uppercase'> Industries : </span> {listData && listData.projects && listData.projects.map(ele => ele.industry).join(', ')}
                      </div>
                      <div className='col-4'>
                        <div className='d-f'>
                          <Button type='link' icon={<CheckCircleOutlined />} size='small'>
                            Hire Dev
                          </Button>
                          <Button type='link' size='small' icon={<DownloadOutlined />}>Download Profile</Button>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <Divider className='divider-resume' />
                        <div className='row'>
                          <div className='col-lg-4 text bold uppercase'>
                            <Space>
                              <ClockCircleOutlined /> Availability
                            </Space>
                          </div>
                          <div className='col-lg-8 text capitalise'>
                            {listData && listData.locations && listData.locations.map(ele => ele)}
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <Divider className='divider-resume' />
                        <div className='row'>
                          <div className='col-lg-4 text bold uppercase'>
                            <Space>
                              <HourglassOutlined /> Total experience
                            </Space>
                          </div>
                          <div className='col-lg-8 text capitalise'>
                            {listData && listData.Experiance}+ Years
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <Divider className='divider-resume' />
                        <div className='row'>
                          <div className='col-lg-4 text bold uppercase'>
                            <Space>
                              <SafetyOutlined /> Technical skills
                            </Space>
                          </div>
                          <div className='col-lg-8 text capitalise'>
                            {listData.skills && listData.skills.map(ele => (<div className='row'>
                              <div className='col-4'>{ele.skillName}</div>
                              <div className='col-6'>{ele.years} Years and {ele.months} Months</div>
                            </div>))}
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <Divider className='divider-resume' />
                        <div className='row'>
                          <div className='col-lg-4 text bold uppercase'>
                            <Space>
                              <SettingOutlined /> Projects
                            </Space>
                          </div>
                          <div className='col-lg-8 text'>
                            <Steps
                              progressDot
                              current={listData.projects && listData.projects.length}
                              direction="vertical"
                              items={listData && listData.projects &&
                                listData.projects.map(ele => ({
                                  title: <div className='text bold uppercase'>{ele.projectTitle}</div>,
                                  description: <ProjectDetails item={ele} />,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <Divider className='divider-resume' />
                        <div className='row'>
                          <div className='col-lg-4 text bold uppercase'>
                            <Space>
                              <GoldOutlined /> Soft skills
                            </Space>
                          </div>
                          <div className='col-lg-8 text'>
                            {listData && listData.softSkills && <Skills noWrap={true} ele={{ skils: listData.softSkills }} />}
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <Divider className='divider-resume' />
                        <div className='row'>
                          <div className='col-lg-4 text bold uppercase'>
                            <Space>
                              <ShoppingOutlined /> Work history
                            </Space>
                          </div>
                          <div className='col-lg-8 '>
                            <Steps
                              progressDot
                              current={listData.employment && listData.employment.length}
                              direction="vertical"
                              items={listData && listData.employment &&
                                listData.employment.map(ele => ({
                                  title: <div className='text bold uppercase'>{ele.jobTitle}</div>,
                                  description: <Responsibilites item={ele} />,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <Divider className='divider-resume' />
                        <div className='row'>
                          <div className='col-lg-4 text bold uppercase'>
                            <Space>
                              <InsertRowLeftOutlined />
                              Education
                            </Space>
                          </div>
                          <div className='col-lg-8'>
                            <Steps
                              progressDot
                              current={listData.educations && listData.educations.length}
                              direction="vertical"
                              items={listData && listData.educations &&
                                listData.educations.map(ele => ({
                                  title: <div className='text bold uppercase'>{ele.degree} in {ele.major}</div>,
                                  description: <Educations item={ele} />,
                                }))
                              }
                            />
                            {/* {listData && listData.educations && listData.educations.map(ele => <Educations item={ele} />)} */}
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <Divider className='divider-resume' />
                        <div className='row'>
                          <div className='col-lg-4 text bold uppercase'>
                            <Space>
                              <AccountBookOutlined /> Language
                            </Space>
                          </div>
                          <div className='col-lg-8'>
                            <Langauges item={listData} />
                          </div>
                        </div>
                        <Divider className='divider-resume' />
                      </div>
                      <div className='col-8'> </div>
                      <div className='col-4'>
                        <div className='d-f'>
                          <Button type='link' icon={<CheckCircleOutlined />} size='small'>
                            Hire Dev
                          </Button>
                          <Button type='link' size='small' icon={<DownloadOutlined />}>Download Profile</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HireDeveloperDetails;
