import { useEffect, useState } from 'react';
import './index.css';
import Dailog from '../../components/modal';
import PramoteDailog from './pramote';
import Technologies from './technologies';
import WhyWe from './whyWe';
import ChooseUs from './choose-us';
import Testimonials from './testimonial';
import CountersView from './counters';
import ClientsView from './clients';

function Home() {
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);


  useEffect(() => {
    // if (!sessionStorage.getItem("modelOpend")) {
    //   setTimeout(() => {
    //     setOpen(true)
    //   }, 5000)
    // }
  }, [])

  const onClose = () => {
    sessionStorage.setItem("modelOpend", "yes")
    setOpen(false)
  }

  return (
    <div>
      <Dailog isOpen={open} onClose={onClose}>
        <PramoteDailog />
      </Dailog>
      <div id="main-wrapper" className='main-wrapper'>
        <div className="site-wrapper-reveal">
          <div className="modern-it-company-top-area">
            <div className="modern-it-company-hero">
              <div className="container">
                <div className="row align-items-center">
                  <div className="modern-it-company-hero-image">
                    <img src={require("../../assets/images/hero/home-reputable-success.webp")} className="img-fluid" alt="" />
                  </div>
                  <div className="col-lg-10 col-md-10">
                    <div className="modern-it-company-hero-text  wow move-up section-space--pt_150">
                      <h3 className="heading">Transforming Businesses with Cutting-Edge Technology Solutions</h3>
                      <h6 className="sort-dec sub-heading0 sub-title0">Stay one step ahead with our future-proof technology services. We leverage the latest innovations and industry best practices to ensure your business remains agile, competitive, and prepared for the challenges of tomorrow. </h6>
                      <div className="book-box">
                        <div className="image">
                          <img src={require("../../assets/images/icons/green-curve-arrow.webp")} className="img-fluid" alt="" />
                        </div>
                        <h5 className="book-text font-weight--bold">Unlock your business potential with a free consultation. </h5>
                        <h6 className="sort-dec book-text sub-heading0">Our experts will assess your needs, provide tailored recommendations, and guide you towards digital success. </h6>

                      </div>

                      <div className="modern-it-company-form-wrap">
                        <form id="contact-form" action="" method="post">
                          <div className="modern-it-company-contact-form-hero">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="contact-inner">
                                  <input name="con_email" type="email" placeholder="Email *" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="contact-select">
                                  <div className="form-item contact-inner">
                                    <span className="inquiry">
                                      <select id="Visiting" name="Visiting" defaultValue={""}>
                                        <option disabled value="">Want to know more about</option>
                                        <option value="Hire Developer on contract">Hire Developer on Contract</option>
                                        <option value="Project discussion">Project Discussion</option>
                                        <option value="Partner Relations">Partner Relations</option>
                                        <option value="Careers">Careers</option>
                                      </select>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="button-wrap">
                                  <a href="#" className="ht-btn ht-btn-md ht-btn-full" type="submit">Get a free consultation</a>
                                  <p className="form-messege"></p>
                                </div>
                              </div>
                            </div>

                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ClientsView />


            <div className="modern-it-company-clients-review-area modern-it-company-testimonial-bg section-space--pb_120">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="image text-center">
                      <img src={require("../../assets/images/testimonial/home-reputable-success-about-image.webp")} className="img-fluid" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="modern-it-company-testimonial text-left">
                      <h5 className="heading">ABOUT <span className="text-color-primary">ACCELGROWTH </span> </h5>
                      <p className="rm text ">
                        Welcome to Accelgrowth Technology, a leading Software Development and Outsourcing
                        company headquartered in Nagpur, India. We take immense pride in our ability
                        to provide exceptional software development teams on demand, specializing in
                        ANY technology, and that too within a day.
                        <p></p>
                        Our core strength lies in our highly experienced sales team,
                        who understand the urgency and criticality of your project requirements.
                        We have a proven track record of delivering top-notch software development teams
                        consisting of multiple profile developers promptly, ensuring a seamless and efficient
                        hiring process.
                        <p></p>
                        At Accelgrowth Technology, we recognize that every project
                        is unique, and technology requirements may vary. That's why
                        we have cultivated a diverse talent pool of skilled professionals
                        who possess expertise in a wide range of technologies.
                        Whether you need...
                        <a href="#" className="btn-text font-weight--bold small-mt__20 see-more">see more <i className="ml-1 button-icon fas fa-arrow-right see-more-icon"></i></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CountersView />

          {/* OK Content -================ */}
          <WhyWe />
          <div className="feature-images-wrapper bg-gradient section-space--ptb_60">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-wrap text-center section-space--mb_60">
                    <h6 className="section-sub-title mb-20">Our services</h6>
                    <h4 className="heading">Unlock exponential business growth with our
                      specialized <br /> services designed to <span className="text-color-primary"> propel your success forward.
                      </span></h4>
                  </div>
                </div>
              </div>
              <div className="image-l-r-box">
                <div className="row">
                  <div className="col image-box-area">
                    <div className="row image-box-boder-box">
                      <div className="col-lg-4 col-md-6 image-box-boder">
                        <div className="ht-box-images style-09">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img src={require("../../assets/images/icons/mitech-processing-service-image-01-100x104.webp")} className="img-fluid" alt="" />
                            </div>
                            <div className="content">
                              <h6 className="heading">Web & Mobile App Development</h6>
                              <div className="text">Cutting-edge web and mobile app development solutions to amplify your online presence and engage your audience.
                              </div>
                              <div className="more-arrow-link">
                                <a href="#">
                                  Learn more <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 image-box-boder">
                        <div className="ht-box-images style-09">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img src={require("../../assets/images/icons/mitech-processing-service-image-02-100x104.webp")} className="img-fluid" alt="" />
                            </div>
                            <div className="content">
                              <h6 className="heading">Automation & AI</h6>
                              <div className="text">Harness the power of automation and AI technologies to revolutionize your business processes and drive efficiency.
                              </div>
                              <div className="more-arrow-link">
                                <a href="#">
                                  Learn more <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 image-box-boder">
                        <div className="ht-box-images style-09">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img src={require("../../assets/images/icons/mitech-processing-service-image-03-100x104.webp")} className="img-fluid" alt="" />
                            </div>
                            <div className="content">
                              <h6 className="heading">Could Services</h6>
                              <div className="text">Embrace the cloud and unlock the potential of scalable and secure cloud services for your business.
                              </div>
                              <div className="more-arrow-link">
                                <a href="#">
                                  Learn more <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 image-box-boder">
                        <div className="ht-box-images style-09">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img src={require("../../assets/images/icons/mitech-processing-service-image-04-100x104.webp")} className="img-fluid" alt="" />
                            </div>
                            <div className="content">
                              <h6 className="heading">IT Staff Augmentation</h6>
                              <div className="text">Expand your team's capabilities with our IT staff augmentation services for enhanced productivity and efficiency.
                              </div>
                              <div className="more-arrow-link">
                                <a href="#">
                                  Learn more <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 image-box-boder">
                        <div className="ht-box-images style-09">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img src={require("../../assets/images/icons/mitech-processing-service-image-05-100x104.webp")} className="img-fluid" alt="" />
                            </div>
                            <div className="content">
                              <h6 className="heading">Tech Partnership</h6>
                              <div className="text">Forge a powerful technology partnership with us to fuel your business growth and achieve digital excellence.
                              </div>
                              <div className="more-arrow-link">
                                <a href="#">
                                  Learn more <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 image-box-boder">
                        <div className="ht-box-images style-09">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img src={require("../../assets/images/icons/mitech-processing-service-image-06-100x104.webp")} className="img-fluid" alt="" />
                            </div>
                            <div className="content">
                              <h6 className="heading">Analytics & Insights</h6>
                              <div className="text">Leverage data analytics to gain valuable insights for informed decision-making and business optimization.
                              </div>
                              <div className="more-arrow-link">
                                <a href="#">
                                  Learn more <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="talk-message-box-wrapper section-space--mt_80 small-mt__60">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="talk-message-box ">
                      <div className="message-icon">
                        <i className="far fa-comment-alt"></i>
                      </div>
                      <div className="talk-message-box-content ">
                        <h6 className="heading">Cheers to the work that comes from trusted service providers in time.</h6>
                        <a href="#" className="ht-btn ht-btn-sm">Let's talk</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <Technologies />

          <ChooseUs />

          <Testimonials />

          <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_two">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-8 col-lg-7">
                  <div className="cta-content md-text-center">
                    <h3 className="heading">We run all kinds of IT services that vow your <span className="text-color-primary"> success</span></h3>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5">
                  <div className="cta-button-group--two text-center">
                    <a href="#" className="btn btn--white btn-one"><span className="btn-icon me-2"><i className="far fa-comment-alt"></i></span> Let's talk</a>
                    <a href="#" className="btn btn--secondary btn-two "><span className="btn-icon me-2"><i className="fas fa-info-circle"></i></span> Get info</a>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
      <div className="demo-option-container">
        {/* <div className="aeroland__toolbar">
          <div className="inner">
            <a className="quick-option hint--bounce hint--left hint--black primary-color-hover-important" href="#" aria-label="Quick Options">
              <i className="fas fa-project-diagram"></i>
            </a>
            <a className="hint--bounce hint--left hint--black primary-color-hover-important" target="_blank" href="https://hasthemes.com/contact-us/" aria-label="Support Center">
              <i className="far fa-life-ring"></i>
            </a>
          </div>
        </div> */}
        <div className="demo-option-wrapper">
          <div className="demo-panel-header">
            <div className="title">
              <h6 className="heading mt-30">IT Solutions Mitech - Technology, IT Solutions & Services Html5 Template</h6>
            </div>

            <div className="panel-btn mt-20">
              <a className="ht-btn ht-btn-md" href="https://1.envato.market/c/417168/275988/4415?subId1=hastheme&amp;subId2=mitech-preview&amp;subId3=https%3A%2F%2Fthemeforest.net%2Fcart%2Fconfigure_before_adding%2F24906742%3Flicense%3Dregular%26size%3Dsource&amp;u=https%3A%2F%2Fthemeforest.net%2Fcart%2Fconfigure_before_adding%2F24906742%3Flicense%3Dregular%26size%3Dsource"><i className="fa fa-shopping-cart me-2"></i> Buy Now </a>
            </div>
          </div>
          <div className="demo-quick-option-list">
            <a className="link hint--bounce hint--black hint--top hint--dark" href="index-appointment.html" aria-label="Appointment">
              <img className="img-fluid" src={require("../../assets/images/demo-images/home-01.webp")} alt="Images" />
            </a>
            <a className="link hint--bounce hint--black hint--top hint--dark" href="index-infotechno.html" aria-label="Infotechno">
              <img className="img-fluid" src={require("../../assets/images/demo-images/home-02.webp")} alt="Images" />
            </a>
            <a className="link hint--bounce hint--black hint--top hint--dark" href="index-processing.html" aria-label="Processing">
              <img className="img-fluid" src={require("../../assets/images/demo-images/home-03.webp")} alt="Images" />
            </a>
            <a className="link hint--bounce hint--black hint--top hint--dark" href="index-services.html" aria-label="Services">
              <img className="img-fluid" src={require("../../assets/images/demo-images/home-04.webp")} alt="Images" />
            </a>
            <a className="link hint--bounce hint--black hint--top hint--dark" href="index-resolutions.html" aria-label="Resolutions">
              <img className="img-fluid" src={require("../../assets/images/demo-images/home-05.webp")} alt="Images" />
            </a>
            <a className="link hint--bounce hint--black hint--top hint--dark" href="index-cybersecurity.html" aria-label="Cybersecurity">
              <img className="img-fluid" src={require("../../assets/images/demo-images/home-06.webp")} alt="Images" />
            </a>
            <a className="link hint--bounce hint--black hint--top hint--dark" href="index-modern-it-company.html" aria-label="Modern IT Company">
              <img className="img-fluid" src={require("../../assets/images/demo-images/modern-it-company.webp")} alt="Images" />
            </a>
            <a className="link hint--bounce hint--black hint--top hint--dark" href="index-machine-learning.html" aria-label="Machine Learning">
              <img className="img-fluid" src={require("../../assets/images/demo-images/machine-learning.webp")} alt="Images" />
            </a>
            <a className="link hint--bounce hint--black hint--top hint--dark" href="index-software-innovation.html" aria-label="Software Innovation">
              <img className="img-fluid" src={require("../../assets/images/demo-images/software-innovation.webp")} alt="Images" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
