import React, { useState } from 'react';
import { Avatar, Button, Card, Checkbox, Col, Typography, Flex, List, Radio, Row, Space } from 'antd';
import { DoubleRightOutlined, DoubleLeftOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import './index.css'
const { Title } = Typography;
const letters = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F'
}

const Question = () => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const getLatter = (index) => {
    return <div className='latter'>{letters[index]}</div>
  }

  return <>
    <div className='question-box'>
      <div className='question flex justify-between'>
        <div className='flex'>
          <Button type="text" icon={<LeftOutlined />} />
          <div className='question-title'>
            <Title level={4} className='quetions-arrow'> Question 01/12</Title>
          </div>
          <Button type="text" icon={<RightOutlined />} />
        </div>
      </div>
      <Card className='card-shadow'>
        <div className='question-view-disc'>
          <Title level={4} className='margin-top-0'>Question</Title>
          <Title level={4} className='que-list'>1. What About react js?</Title>
        </div>
        <div className='answers'>
          <div className='info-note'>Please choose one of the following answers</div>
          <Row>
            <Col span={24} className='container-of-buttons-view'>
              <Checkbox.Group
                style={{ width: '100%' }}
              >
                <Space direction="vertical">
                  <Flex className='space-between-option'>
                    {getLatter(0)} <Title level={5}><Checkbox value="A">A</Checkbox></Title>
                  </Flex>
                  <Flex className='space-between-option'>
                    {getLatter(1)} <Title level={5}> <Checkbox value="A">A</Checkbox></Title>
                  </Flex>
                  <Flex className='space-between-option'>
                    {getLatter(1)} <Title level={5}> <Checkbox value="A">A</Checkbox></Title>
                  </Flex>
                  <Flex className='space-between-option'>
                    {getLatter(1)} <Title level={5}> <Checkbox value="A">A</Checkbox></Title>
                  </Flex>
                </Space>
              </Checkbox.Group>
              <Space align='end' className='bottom-right-content'>
                <Button
                  type="default"
                  icon={<DoubleLeftOutlined />}
                  danger
                >
                  Previous
                </Button>
                <Button
                  type="primary"
                  className='button-with-icon'
                  ghost
                >Next <DoubleRightOutlined /></Button>
              </Space>
            </Col>
            {/* <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={1}>Option A</Radio>
              <Radio value={2}>Option B</Radio>
              <Radio value={3}>Option C</Radio>
            </Space>
          </Radio.Group> */}
            {/* <Col span={4} className='container-of-buttons-view'>
              <Space align='end' className='bottom-right-content'>
                <Button
                  type="default"
                  icon={<DoubleLeftOutlined />}
                  danger
                >
                  Previous
                </Button>
                <Button
                  type="primary"
                  className='button-with-icon'
                  ghost
                >Next <DoubleRightOutlined /></Button>
              </Space>
            </Col> */}
          </Row>
        </div>

      </Card>
    </div>

  </>
};
export default Question;