import { useState } from 'react';
import './index.css';
function BlogsList() {
  return (
    <>
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title">Blogs</h2>
                <ul className="breadcrumb-list">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item active">Blog Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div className="blog-pages-wrapper section-space--ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  <div className="single-blog-item blog-grid">
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img className="img-fluid" src={require("../../../../assets/images/blog/blog-01-370x230.webp")} alt="Blog Images" />
                      </a>
                    </div>
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          January 14, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">Ideas for High Returns on Investment</a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>Using the profit accumulator is one such way, and you should understand why it increases your chances of the profits. …</p>
                      </div>
                      <div className="btn-text">
                        <a href="#">Read more <i className="ml-1 button-icon fas fa-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  <div className="single-blog-item blog-grid">
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img className="img-fluid" src={require("../../../../assets/images/blog/blog-08-370x230.webp")} alt="Blog Images" />
                      </a>
                    </div>
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">How Technology Made Businesses More Efficient</a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>They play a role in making operations more seamless, bridging the gap between authorities, consumers and businesses. …</p>
                      </div>
                      <div className="btn-text">
                        <a href="#">Read more <i className="ml-1 button-icon fas fa-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  <div className="single-blog-item blog-grid">
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img className="img-fluid" src={require("../../../../assets/images/blog/blog-07-370x230.webp")} alt="Blog Images" />
                      </a>
                    </div>
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">Data Secure on Transitioning to a New Office</a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>It is far wiser to do your own installations and ensure that all data flowing through the organization has end-to-end encryption. …</p>
                      </div>
                      <div className="btn-text">
                        <a href="#">Read more <i className="ml-1 button-icon fas fa-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  <div className="single-blog-item blog-grid">
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img className="img-fluid" src={require("../../../../assets/images/blog/blog-07-370x230.webp")} alt="Blog Images" />
                      </a>
                    </div>
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">The Keys to Persuading Customers Nowadays</a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>It is far wiser to do your own installations and ensure that all data flowing through the organization has end-to-end encryption. …</p>
                      </div>
                      <div className="btn-text">
                        <a href="#">Read more <i className="ml-1 button-icon fas fa-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  <div className="single-blog-item blog-grid">
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img className="img-fluid" src={require("../../../../assets/images/blog/blog-05-370x230.webp")} alt="Blog Images" />
                      </a>
                    </div>
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">Blockchain Technology Effects on Logistics Sector?</a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>Changing or adding information to the “chain” requires the authentication of everyone who is in the network. …</p>
                      </div>
                      <div className="btn-text">
                        <a href="#">Read more <i className="ml-1 button-icon fas fa-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  <div className="single-blog-item blog-grid">
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img className="img-fluid" src={require("../../../../assets/images/blog/blog-02-370x230.webp")} alt="Blog Images" />
                      </a>
                    </div>
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">How Wireless Technology is Changing Business</a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>If there’s one way that wireless technology has changed the way we work, it’s that everyone is now connected. …</p>
                      </div>
                      <div className="btn-text">
                        <a href="#">Read more <i className="ml-1 button-icon fas fa-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-lg-12 wow move-up">
                  <div className="ht-pagination mt-30 pagination justify-content-center">
                    <div className="pagination-wrapper">

                      <ul className="page-pagination">
                        <li><a className="prev page-numbers" href="#">Prev</a></li>
                        <li><a className="page-numbers current" href="#">1</a></li>
                        <li><a className="page-numbers" href="#">2</a></li>
                        <li><a className="next page-numbers" href="#">Next</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogsList;
