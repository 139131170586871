import './index.css';
function CountersView() {
  return (
    <div className="fun-fact-wrapper bg-gray section-space--pb_30 section-space--pt_60">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="fun-fact--four text-center">
              <div className="icon">
                <i className="far fa-smile-beam"></i>
              </div>
              <div className="fun-fact-text">
                <div className="fun-fact__count">150+</div>
                <h6 className="fun-fact__text">Global clients</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="fun-fact--four text-center">
              <div className="icon">
                <i className="far fa-folder"></i>
              </div>
              <div className="fun-fact-text">
                <div className="fun-fact__count">750+</div>
                <h6 className="fun-fact__text">Projects Completed</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="fun-fact--four text-center">
              <div className="icon">
                <i className="far fa-user"></i>
              </div>
              <div className="fun-fact-text">
                <div className="fun-fact__count">200+</div>
                <h6 className="fun-fact__text">Experienced experts</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CountersView;
