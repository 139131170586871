const emailEncryption = (email) => {
    if(!email){
        return;
    }
    const [localPart, domainPart] = email.split('@');
    const start = localPart.slice(0, 2);
    const end = localPart.slice(-2);
    const obfuscatedLocal = `${start}***${end}`;
    return `${obfuscatedLocal}@${domainPart}`;
}


export { emailEncryption } 