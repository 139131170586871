import { useState } from 'react';
import './index.css';
import { Divider } from 'antd';

function Footer() {
  const [date, setDate] = useState(new Date())


  return (
    <div>
      <div className="contact-us-area appointment-contact-bg section-space--ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-title section-space--mb_50">
                <h3 className="mb-20">Need a hand?</h3>
                <p className="sub-title">Reach out to the world’s most reliable IT services.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-form-wrap">

                <form className="contact-form" id="contact-form-2" action="" method="post">
                  <div className="contact-form__two">
                    <div className="contact-input">
                      <div className="contact-inner">
                        <input name="con_name" type="text" placeholder="Name *" />
                      </div>
                      <div className="contact-inner">
                        <input name="con_email" type="email" placeholder="Email *" />
                      </div>
                    </div>
                    <div className="contact-select">
                      <div className="form-item contact-inner">
                        <span className="inquiry">
                          <select id="visiting-two" name="Visiting" defaultValue={"Your inquiry about"}>
                            <option disabled >Select Department to email</option>
                            <option value="Your inquiry about">Your inquiry about</option>
                            <option value="General Information Request">General Information Request</option>
                            <option value="Partner Relations">Partner Relations</option>
                            <option value="Careers">Careers</option>
                            <option value="Software Licencing">Software Licencing</option>
                          </select>

                        </span>
                      </div>
                    </div>
                    <div className="contact-inner contact-message">
                      <textarea name="con_message" placeholder="Please describe what you need."></textarea>
                    </div>
                    <div className="comment-submit-btn">
                      <button className="ht-btn ht-btn-md" type="submit">Get a free consultation</button>
                      <p className="form-messege-2"></p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5 ms-auto">
              <div className="contact-info-three text-left">
                <h6 className="heading font-weight--reguler">Reach out now!</h6>
                <h3 className="call-us"><a href="tel:9309569094">(+91) 9309569094</a></h3>
                <div className="text">Start the collaboration with us while figuring out the best solution based on your needs.</div>
                <div className="location-button mt-20">
                  <a href="https://goo.gl/maps/ybrArpV2BVsUDFgAA" className="location-text-button"><span className="button-icon"></span> <span className="button-text">View on Google map</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-area-wrapper bg-gray">

        <div className="footer-area section-space--ptb_80">
          <div className="container">
            <div className="row footer-widget-wrapper">
              {/* <div className="col-lg-4 col-md-6 col-sm-6 footer-widget">
              <div className="footer-widget__logo mb-30">
                <img src={require("../../assets/images/logo/dark-logo-160x48.webp")} width="160" height="48" className="img-fluid" alt="" />
              </div>
              <ul className="footer-widget__list">
                <li>58 Howard Street #2 San Francisco, CA 941</li>
                <li><a href="mailto:contact@aeroland.com" className="hover-style-link">contact@aeroland.com</a></li>
                <li><a href="tel:123344556" className="hover-style-link text-black font-weight--bold">(+68)1221 09876</a></li>
                <li><a href="https://hasthemes.com/" className="hover-style-link text-color-primary">www.mitech.xperts.com</a></li>
              </ul>
            </div> */}
              <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                <h6 className="footer-widget__title mb-20">Hire Frontend Developers</h6>
                <ul className="footer-widget__list">
                  <li><a href="element-accordion.html"><span>Hire UI/UX Designer</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire HTML/CSS Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire AngularJS Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire ReactJS Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire Angular 2+ Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire VueJS Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire NextJS Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire JavaScript Developers</span></a></li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                <h6 className="footer-widget__title mb-20">Hire Backend Developers</h6>
                <ul className="footer-widget__list">
                  <li><a href="#" className="hover-style-link"><span>Hire NodeJS Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire NestJS Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire Java Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire PHP Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire Laravel Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire ASP.Net Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire Python Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire Flask Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire ROR Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire Golang Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire Codeigniter Developers</span></a></li>
                  <li><a href="#" className="hover-style-link"><span>Hire Django Developers</span></a></li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                <h6 className="footer-widget__title mb-20">Hire Mobile Developers</h6>
                <ul className="footer-widget__list">
                  <li><a className="hover-style-link"><span>Hire Android Developers</span></a></li>
                  <li><a className="hover-style-link"><span>Hire IOS Developers</span></a></li>
                  <li><a className="hover-style-link"><span>Hire React Native Developers</span></a></li>
                  <li><a className="hover-style-link"><span>Hire Kotlin Developers</span></a></li>
                  <li><a className="hover-style-link"><span>Hire Swift Developers</span></a></li>
                  <li><a className="hover-style-link"><span>Hire AR/VR Developers</span></a></li>
                  <li><a className="hover-style-link"><span>Hire Flutter Developers</span></a></li>
                  <li><a className="hover-style-link"><span>Hire Ionic Developers</span></a></li>
                  <li><a className="hover-style-link"><span>Hire Xamarin Developers</span></a></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                <h6 className="footer-widget__title mb-20">We Involved In</h6>
                <ul className="footer-widget__list">
                  <li><a className="hover-style-link"><span>Healthcare</span></a></li>
                  <li><a className="hover-style-link"><span>Banking, Financial Services and Insurance</span></a></li>
                  <li><a className="hover-style-link"><span>Manufacturing</span></a></li>
                  <li><a className="hover-style-link"><span>Professional Services</span></a></li>
                  <li><a className="hover-style-link"><span>Retail</span></a></li>
                  <li><a className="hover-style-link"><span>Transportation and Logistics</span></a></li>
                  <li><a className="hover-style-link"><span>Telecommunications</span></a></li>
                  <li><a className="hover-style-link"><span>Oil and Gas</span></a></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                <h6 className="footer-widget__title mb-20">Services</h6>
                <ul className="footer-widget__list">
                  <li><a className="hover-style-link"><span>Custom Software Development</span></a></li>
                  <li><a className="hover-style-link"><span>Software Development Costs</span></a></li>
                  <li><a className="hover-style-link"><span>Software Development Automation</span></a></li>
                  <li><a className="hover-style-link"><span>Secure Software Development</span></a></li>
                  <li><a className="hover-style-link"><span>Agile Consulting Services</span></a></li>
                  <li><a className="hover-style-link"><span>Custom API Development</span></a></li>
                  <li><a className="hover-style-link"><span>GDPR Compliance in Software Development</span></a></li>
                  <li><a className="hover-style-link"><span>Embedded Software Development</span></a></li>
                  <li><Divider></Divider></li>
                  <li><a href='/privacy' className="hover-style-link"><span></span>Privacy Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright-area section-space--pb_30">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 text-center text-md-start">
                <span className="copyright-text">&copy; {date.getFullYear()} Accelgrowth. <a href="https://accelgrowth.in/">All Rights Reserved.</a></span>
              </div>
              <div className="col-md-6 text-center text-md-end">
                <ul className="list ht-social-networks solid-rounded-icon">

                  <li className="item">
                    <a href="https://twitter.com/" target="_blank" aria-label="Twitter" className="social-link hint--bounce hint--top hint--primary">
                      <i className="fab fa-twitter link-icon"></i>
                    </a>
                  </li>
                  <li className="item">
                    <a href="https://facebook.com/" target="_blank" aria-label="Facebook" className="social-link hint--bounce hint--top hint--primary">
                      <i className="fab fa-facebook-f link-icon"></i>
                    </a>
                  </li>
                  <li className="item">
                    <a href="https://instagram.com/" target="_blank" aria-label="Instagram" className="social-link hint--bounce hint--top hint--primary">
                      <i className="fab fa-instagram link-icon"></i>
                    </a>
                  </li>
                  <li className="item">
                    <a href="https://linkedin.com/" target="_blank" aria-label="Linkedin" className="social-link hint--bounce hint--top hint--primary">
                      <i className="fab fa-linkedin link-icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

