import { useEffect, useState } from 'react';
import './../index.css';
import { Tag, Progress, Pagination, Input } from 'antd';
const { Search } = Input;
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { JitsiMeeting, JaaSMeeting } from '@jitsi/react-sdk';

function BookCalender() {

  return (
    <div>
      <JaaSMeeting
        roomName={'YOUR_ROOM_NAME_9897989'}
        appId='vpaas-magic-cookie-8457a4293e314a15b356849a20822bf9'
        configOverwrite={{
          startWithAudioMuted: true,
          disableModeratorIndicator: false,
          startScreenSharing: true,
          enableEmailInStats: false,
          screenShareSettings: {
            desktopPreferCurrentTab: false,
            desktopSystemAudio: 'include',
            desktopSurfaceSwitching: 'include',
            desktopDisplaySurface: undefined,
            desktopSelfBrowserSurface: 'exclude'
          },
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
        }}
        getIFrameRef={iframeRef => { iframeRef.style.height = '100vh'; }}
      />
    </div>
  )
}

export default BookCalender;
