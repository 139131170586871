import React from 'react';
import { useState } from 'react';
import { Alert, Avatar, Button, Checkbox, Col, Divider, Image, Modal, Radio, Row, Space, Table, Tag, message } from 'antd';
import { EyeOutlined, CloudDownloadOutlined, GlobalOutlined, HomeOutlined } from '@ant-design/icons';
import './index.css';
import { routes } from '../../../../../actions/api-routes';
import { encryptData } from '../../../../../actions/crypto';
import { putData } from '../../../../../actions/middleware';
import moment from 'moment';
import PDFViewer from '../../../../../actions/table-configs/files/pdfViewer';

let tags = {
    "PENDING": { name: 'PENDING', color: 'warning' },
    "WAITING_APPROVAL": { name: 'WAITING FOR APPROVAL', color: 'magenta' },
    "APPROVED": { name: 'APPROVED', color: 'green' },
    "REJECTED": { name: 'REJECTED', color: 'error' },
    "IN_PROGRES": { name: 'IN PROGRES', color: 'info' }
}

const BulkListItem = ({ style, item, reloadPage }) => {
    const [showFullText, setShowFullText] = useState(false);
    const [viewFile, setViewFile] = useState(false);

    const openFile = (_file) => {
        setViewFile(true)
    }

    return (
        <div className='view-box bg-white job-dscription' style={style}>
            <Modal
                open={viewFile}
                onCancel={() => setViewFile(false)}
                width={'80%'}
            >
                <PDFViewer fileUrl={routes.fileServer + item.resume} />
            </Modal>
            <div className='d-f justify-between'>
                <div>
                    <div className='job-title-view'>
                        <div className='icon-view'>
                            {item?.jobLocationType == 'Remote' && <Avatar icon={<HomeOutlined title='Remote' />} />}
                            {item?.jobLocationType != 'Remote' && <Avatar icon={<GlobalOutlined title='Office' />} />}
                        </div>
                        <div className='heading-view-for-rol'>
                            <div className='job-title-box'>
                                <div className='main-text'> {moment(item?.createdAt).format('lll')}</div>
                            </div>
                            <div className='price-view'>
                                <Tag color='magenta' bordered={false}>{item?.locations.join(' | ')}</Tag> | <Tag color='cyan' bordered={false}>{item?.currency} {item?.minimum}LPM - {item?.maximum}LPM</Tag>
                            </div>
                            {item?.onsiteOptions.length > 0 && <div>
                                <Tag color='magenta' bordered={false}>{item?.onsiteOptions.join(',')}</Tag>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-box'>
                <div className={`text-container ${showFullText ? 'show-full' : ''}`}>
                    <div className={`text ${showFullText ? 'show-full-text' : ''}`}>
                        <div className='img-container'>
                            <Button type='link' icon={item?.resume.endsWith('.pdf') ? <EyeOutlined /> : <CloudDownloadOutlined />} onClick={() => openFile(item)}>{item?.resume.endsWith('.pdf') ? 'View PDF' : 'Download'}</Button>
                        </div>
                    </div>
                </div>
            </div >
            <div className='tag-box d-f justify-between'>
                <Space>
                    <div><Space><GlobalOutlined />Accelgrowth Technology</Space></div>
                </Space>
                <Space>
                    <Tag color={tags[item.approvalStatus].color} bordered={false}>{tags[item.approvalStatus].name}</Tag>
                </Space>
            </div>
        </div >
    );
};

export default BulkListItem;
