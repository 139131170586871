import { Modal } from "antd"
import ProfileView from "../../../common/profile-view"
import { useEffect, useState } from "react"


const ProfileViewModel = ({ profileData, onClose, isVisible }) => {
    const [useData, setData] = useState(profileData)

    useEffect(() => {
        setData(profileData)
    }, [profileData])

    return <Modal
        title={`${useData?.firstName} ${useData?.lastName}`}
        open={isVisible}
        onCancel={onClose}
        width={'80%'}
    >
        {useData && <ProfileView listData={useData} />}
    </Modal>
}

export default ProfileViewModel