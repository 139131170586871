import React, { useState } from 'react';
import { Form, Input, Button, Space, Select, Row, Col, DatePicker } from 'antd';
const { Option } = Select;
import { MinusCircleOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import DynamicSelect from '../../common/Select';
import { useEffect } from 'react';
import { putData } from '../../../actions/middleware';
import { formatDates } from '../../common/validate';
import { encryptData } from '../../../actions/crypto';

const required = { required: true, message: 'This field is required' }

const EducationForm = ({ data, onSubmitFromHeader, onClear, nextCall }) => {
  const [form] = Form.useForm();
  const [educations, setEducatiion] = useState([{ degree: '', major: '', university: '', startDate: '', endDate: '' }, { degree: '', major: '', university: '', startDate: '', endDate: '' }]);
  const [certificates, setCertificates] = useState([{ name: '', issuer: '', year: '' }]);

  useEffect(() => {
    if (!data) {
      nextCall(0)
    }
  }, [data])

  useEffect(() => {
    if (onSubmitFromHeader == 'EDUCATION') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])

  const onFinish = async (values) => {
    try {
      const resData = await putData(`/vendor/developers/${encryptData(data._id)}`, { data: encryptData({ ...values }) });
      if (resData && resData.code == 200) {
        nextCall(6)
      } else {
        error('Fix Error', resData.message)
      }
    } catch (_error) {
      error('Fix Error', _error.message)
    }
  };

  const handleRemove = (index) => {
    const newEducations = [...educations];
    newEducations.splice(index, 1);
    setEducatiion(newEducations);
  };


  return (
    <>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        layout='vertical'
      >
        <div className='form-view'>
          <Row span={16} justify={'space-around'}>
            <Col span={22}>
              <div>
                <h6 className='heading'>Add your programming languages and frameworks</h6>
                <p className='sub-heading'>These will help us match you with relevant work, more skills are better</p>
              </div>
              <Form.List
                name="educations"
                initialValue={data && data.educations.length > 0 ? formatDates(data.educations) : educations}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 0 }} align="baseline">
                        <DynamicSelect
                          {...restField}
                          label={"Degree"}
                          name={[name, 'degree']}
                          fieldKey={[fieldKey, 'degree']}
                          rules={[required]}
                          keyName={'degree'}
                          style={{ width: '200px' }}
                        />
                        <DynamicSelect
                          {...restField}
                          label={"Major"}
                          name={[name, 'major']}
                          fieldKey={[fieldKey, 'major']}
                          rules={[required]}
                          keyName={'major'}
                          style={{ width: '200px' }}
                        />
                        <DynamicSelect
                          {...restField}
                          label={"University"}
                          name={[name, 'university']}
                          fieldKey={[fieldKey, 'university']}
                          rules={[required]}
                          keyName={'university'}
                          style={{ width: '200px' }}
                        />

                        <Form.Item
                          {...restField}
                          name={[name, 'startDate']}
                          label="Start Date"
                          fieldKey={[fieldKey, 'startDate']}
                          rules={[{ required: true, message: 'Please select Start Date' }]}
                        >
                          <DatePicker.YearPicker format="YYYY" placeholder="Select start Year" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'endDate']}
                          label="End Date"
                          fieldKey={[fieldKey, 'endDate']}
                          rules={[{ required: true, message: 'Please select End Date' }]}
                        >
                          <DatePicker.YearPicker format="YYYY" placeholder="Select end Year" />
                        </Form.Item>


                        <div className='form-buttons'>
                          {index >= 2 ? (
                            <MinusCircleOutlined onClick={() => { remove(name); handleRemove(fields.findIndex((field) => field.name === name)); }} />
                          ) : null}
                          {' '}
                          {fields.length - 1 == index ? <PlusCircleOutlined onClick={() => add()} /> : <></>}
                        </div>
                      </Space>
                    ))}
                  </>
                )}

              </Form.List>
              <div>
                <h6 className='heading'>Add your certificates</h6>
                <p className='sub-heading'>These will help us match you with relevant work, more skills are better</p>
              </div>
              <Form.List
                name="certificates"
                initialValue={data && data.certificates.length > 0 ? formatDates(data.certificates, ['year']) : certificates}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 0 }} align="baseline">
                        <DynamicSelect
                          {...restField}
                          label={"Name"}
                          name={[name, 'name']}
                          fieldKey={[fieldKey, 'name']}
                          keyName={'certificateName'}
                          style={{ width: '200px' }}
                        />
                        <DynamicSelect
                          {...restField}
                          label={"Issuer"}
                          name={[name, 'issuer']}
                          fieldKey={[fieldKey, 'issuer']}
                          keyName={'issuer'}
                          style={{ width: '200px' }}
                        />
                        <Form.Item
                          {...restField}
                          name={[name, 'year']}
                          label="Year"
                          fieldKey={[fieldKey, 'year']}
                        >
                          <DatePicker.YearPicker format="YYYY" placeholder="Select Year" />
                        </Form.Item>

                        <div className='form-buttons'>
                          {index >= 1 ? (
                            <MinusCircleOutlined onClick={() => { remove(name); handleRemove(fields.findIndex((field) => field.name === name)); }} />
                          ) : null}
                          {' '}
                          {fields.length - 1 == index ? <PlusCircleOutlined onClick={() => add()} /> : <></>}
                        </div>
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </div>
        {/* <Row gutter={16} className='botton-footer'>
          <Col span={2}>
            <Form.Item>
              <Button type="dashed" size='middle' onClick={() => nextCall(4)}>
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className='float-right'
                size='middle'>
                Save & Next
              </Button>
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </>
  );
};

export default EducationForm;
