// ProfilePage.js
import React from 'react';
import { Row, Col, Card, Divider, Button, Space } from 'antd';
import ProfileForm from './ProfileForm';
import './index.css'
import ProfileView from './ProfileView';
import ChangePasswordForm from './ChangePasswordForm';

const ProfilePage = () => {

  const profileData = {
    "accountStatus": "ACTIVE",
    "status": "active",
    "_id": "655105ade2119714dc166284",
    "firstName": "shubham",
    "lastName": "kanfade",
    "jobTitle": "test",
    "companyName": "accelgrowth",
    "website": "https://test.com",
    "workEmail": "skanfade89@gmail.com",
    "phoneNumber": "6767676767",
    "city": "nagour",
    "cin_gstn": "ytytytytytytyyyyttttt",
    "loginType": "Organisation",
    "passCode": null,
    "createdAt": "2023-11-12T17:04:45.142Z",
    "updatedAt": "2023-11-12T17:25:36.466Z",
  }

  return (
    <Row className='profile-view'>
      <Col span={24}>
        <div className='d-f space-between'>
          <div className='padding-10'>
            <h6 className='heading-text'>
              My Profile
            </h6>
          </div>
          <div>
            <Button type='primary'>Edit</Button>
          </div>
        </div>
        <Divider style={{ marginTop: 0, marginBottom: 10 }} />
      </Col>
      <Col span={24}>
        <Space>
          <div>
            <ProfileView profileData={profileData} />
          </div>
        </Space>
        <div>
          <Card title="Change Password" >
            <ChangePasswordForm />
          </Card>
        </div>
      </Col>
      {/* <Row justify="center">
          <Col xs={24} sm={20} md={16} lg={12} xl={10}>
            <Card title="Profile">
              <ProfileForm profileData={profileData} />
            </Card>
          </Col>
        </Row> */}
    </Row>
  );
};

export default ProfilePage;
