import React from 'react';
import { useState } from 'react';
import { Alert, Avatar, Button, Checkbox, Col, Divider, Image, Modal, Radio, Row, Space, Table, Tag, message } from 'antd';
import { UserAddOutlined, GlobalOutlined, HomeOutlined } from '@ant-design/icons';
import './index.css';
import { routes } from '../../../../actions/api-routes';
import UpdateRequierment from '../modal';
import { encryptData } from '../../../../actions/crypto';
import { putData } from '../../../../actions/middleware';

const ListItem = ({ style, item, reloadPage }) => {
    const [showFullText, setShowFullText] = useState(false);
    const [maxChars, setmaxChars] = useState(200);
    const [isVisible, setVisible] = useState(false);
    const [itemId, setItemId] = useState(null);

    const handleSeeMore = () => {
        setShowFullText(true);
    };

    const handleSeeLess = () => {
        setShowFullText(false);
    };

    const approveReject = async (_id, status) => {
        const data = encryptData({ approvalStatus: status })
        const resData = await putData(routes.jobs.list + encryptData(_id), { data })
        if (resData && resData.code == 200) {
            message.success('Successfully updated')
            reloadPage();
        } else {
            message.error('Something went wrong, please try again later')
        }
    }

    const prompt = (_id, status) => {
        Modal.confirm({
            title: 'Confirm',
            content: 'Are you sure you want to reject this item?',
            onOk() {
                approveReject(_id, status)
            },
            onCancel() { },
        });
    }

    return (
        <div className='view-box bg-white job-dscription' style={style}>
            <UpdateRequierment isVisible={isVisible} onClose={() => {
                setVisible(false)
                setItemId(null)
            }} reloadPage={reloadPage} id={itemId} />
            <div className='d-f justify-between'>
                <div>
                    <div className='job-title-view'>
                        <div className='icon-view'>
                            {item?.jobLocationType == 'Remote' && <Avatar icon={<HomeOutlined title='Remote' />} />}
                            {item?.jobLocationType != 'Remote' && <Avatar icon={<GlobalOutlined title='Office' />} />}
                        </div>
                        <div className='heading-view-for-rol'>
                            <div className='job-title-box'>
                                <div className='main-text'> {item?.jobTitle}</div>
                                <div className='sub-text'> {item?.experiance}+ Year</div>
                            </div>
                            <div className='price-view'>
                                <Tag color='magenta' bordered={false}>{item?.jobLocationType}</Tag> | <Tag color='cyan' bordered={false}>{item?.currency} {item?.minimum}LPM - {item?.maximum}LPM</Tag>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Button type='link' onClick={() => {
                        setVisible(true)
                        setItemId(item._id)
                    }}>Edit</Button>
                </div>
            </div>
            <div className='text-box'>
                <div className={`text-container ${showFullText ? 'show-full' : ''}`}>
                    <div className={`text ${showFullText ? 'show-full-text' : ''}`}>
                        {item?.typeOfRequirment == 'Text' && <div dangerouslySetInnerHTML={{ __html: showFullText ? item?.jobDescription : item?.jobDescription.slice(0, maxChars) }}>
                        </div>}
                        {item?.typeOfRequirment == "Image" && <div className='img-container'>
                            <Image src={routes.fileServer + item?.requirmentFile} />
                        </div>}
                        {item?.typeOfRequirment == "Text" && !showFullText && item?.jobDescription.length > maxChars && (
                            <Button type='link' className='button-seemore' style={{ padding: 0 }} onClick={handleSeeMore}>
                                ...see more
                            </Button>
                        )}
                        {item?.typeOfRequirment == "Text" && showFullText && (
                            <Button type='link' className='button-seemore' style={{ padding: 0 }} onClick={handleSeeLess}>
                                see less
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className='tag-box'>
                {item?.skils.map(ele => <span className='tag'> #{ele}</span>)}
            </div>
            <div className='tag-box d-f justify-between'>
                <Space>
                    <div className='bold-text'><Space><UserAddOutlined />Shubham</Space></div>
                    <div><Space><GlobalOutlined />Accelgrowth Technology</Space></div>
                </Space>
                <Space>
                    {item.approvalStatus !== 'REJECTED' && <Button type='primary' danger onClick={() => prompt(item._id, 'REJECTED')}>Rejecte</Button>}
                    {item.approvalStatus !== 'APPROVED' && <Button type='primary' onClick={() => approveReject(item._id, 'APPROVED')}>Appove</Button>}
                </Space>
            </div>
        </div>
    );
};

export default ListItem;
