import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Col, FloatButton, Radio, Row, Select, Image, Space, Tag, Avatar } from 'antd';
import { DeleteOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import './index.css';
import { routes } from '../../../../actions/api-routes';
const { confirm } = Modal

const SelectedProfiles = ({ profiles, onCloseTag }) => {
    const [useProfiles, setProfiles] = useState([]);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);


    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setProfiles(profiles)
    }, [profiles])

    const totalSize = (yourArray) => {
        if (yourArray && yourArray.length > 2) {
            return yourArray.length - 2;
        } else {
            return 0
        }
    }

    const onRemove = (id) => {
        confirm({
            title: 'Are you sure want to remove profile?',
            onOk: () => onCloseTag(id)
        })
    }

    const onRemoveAll = () => {
        confirm({
            title: 'Are you sure want to remove all?',
            onOk: () => onCloseTag()
        })
    }

    return (
        <div className='filter-wrapper'>
            <div className='heading-2'>Selected Profiles ({useProfiles.length})</div>
            <div className='profile-listings' style={{ maxHeight: (windowHeight - 220), overflowY: 'auto', }}>
                <Row gutter={[16, 16]}>
                    {useProfiles.map(item => <Col span={24}>
                        <div className='view-box bg-white job-dscription profile' style={{}}>
                            <div className='d-f justify-between'>
                                <div>
                                    <div className='job-title-view'>
                                        <Avatar size={'large'} shape='square' src={item?.photo ? routes.fileServer + item?.photo : require('./../../../../assets/images/logo/logo-w.png')} />
                                        <div className='heading-view-for-rol padding-left'>
                                            <div className='job-title-box'>
                                                <div className='main-text'> {[item?.firstName, item?.lastName].join(' ')}</div>
                                                <div className='sub-text'> {item?.Experiance}+ Year</div>
                                            </div>
                                            <div className='price-view'>
                                                {item?.JobTitle}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='footer-box'>
                                <Space>
                                    <Button type='link' icon={<DeleteOutlined />} danger onClick={() => onRemove(item._id)}></Button>
                                    <Button icon={<EyeInvisibleOutlined />} onClick={() => onSelectData(item)} type='link'></Button>
                                </Space>
                            </div>
                        </div>
                    </Col>)}
                </Row>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <Space align='end' >
                    <Button type='primary' danger onClick={onRemoveAll}>Remove All</Button>
                    <Button type='primary'>Get Quote</Button>
                </Space>
            </div>
        </div>
    );
};

export default SelectedProfiles;
