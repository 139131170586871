// ChangePasswordForm.js
import React from 'react';
import { Form, Input, Button } from 'antd';

const ChangePasswordForm = () => {
  const [form] = Form.useForm();

  const handleChangePassword = () => {
    form.validateFields().then((values) => {
      // Handle change password logic here
      console.log('Change password data:', values);
      form.resetFields();
    });
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label="Old Password"
        name="oldPassword"
        rules={[{ required: true, message: 'Please enter your old password' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[{ required: true, message: 'Please enter your new password' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm New Password"
        name="confirmNewPassword"
        dependencies={['newPassword']}
        rules={[
          { required: true, message: 'Please confirm your new password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords do not match');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleChangePassword}>
          Change Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordForm;
