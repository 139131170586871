import moment from 'moment';
import './index.css';

function Langauges({ item }) {
  return (<>
    <div>
      <div className='text'>English - {item.englishProficiency}</div>
    </div>
  </>)
}


export default Langauges;