import { Alert, Button, Modal, notification, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import './index.css';
import { FieldTimeOutlined } from '@ant-design/icons';
const { Title } = Typography;
const CountdownTimer = ({ initialHours }) => {
  const initialTime = initialHours * 60 * 60; // 2 hours in seconds
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
        }
        return Math.max(prevTime - 1, 0);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return <div className='time-show-box'>
      <div className='div'>
        <div className='time-box-shadow'>
          {hours}
        </div>
        <div className='title-for-time'>
          Hours
        </div>
      </div>
      <div className='div'>
        <div className='time-box-shadow'>
          {String(minutes).padStart(2, '0')}
        </div>
        <div className='title-for-time'>
          Minutes
        </div>
      </div>
      <div className='div'>
        <div className='time-box-shadow'>
          {String(remainingSeconds).padStart(2, '0')}
        </div>
        <div className='title-for-time'>
          Seconds
        </div>
      </div>
    </div>
  };

  return (
    <div className='time-background'>
      <Title level={5} className='title-for-time-head'> <FieldTimeOutlined /> Time remaining</Title>
      {formatTime(time)}
    </div>
  );
};

export default CountdownTimer;
