import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Radio, Row, Col, Select } from 'antd';
import DynamicSelect from '../../common/Select';
import { postData, putData } from '../../../actions/middleware';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useEffect } from 'react';
import { error } from '../../common/Notify';
import { decryptData, encryptData } from '../../../actions/crypto';
const { useForm } = Form;

const ProfileForm = ({ onSubmitFromHeader, onClear, nextCall, data }) => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const publicValue = queryParams.get('public');

  const [form] = useForm();

  const getRandomDigits = (numberOfDigits) => {
    const min = Math.pow(10, numberOfDigits - 1);
    const max = Math.pow(10, numberOfDigits) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (onSubmitFromHeader == 'PROFILE') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data])


  const onFinish = async (values) => {
    try {
      if (!data) {
        const profileID = [getRandomDigits(8), values.firstName, values.lastName].join('_')
        const resData = await postData('/vendor/developers', { data: encryptData({ ...values, profileID: profileID }) });
        if (resData && resData.code == 200) {
          if (publicValue) {
            navigate(`/public/profiles/upload-profile?public=${publicValue}&developer=${resData.data}&tab=${1}`)
          } else {
            navigate(`/admin/profiles/upload-profile?developer=${resData.data}&tab=1`)
          }
          nextCall(1)
        } else {
          error('Fix Error', resData.message)
        }
      } else {
        const resData = await putData('/vendor/developers/' + encryptData(data._id), { data: encryptData({ ...values }) });
        if (resData && resData.code == 200) {
          nextCall(1)
        } else {
          error('Fix Error', resData.message)
        }
      }
    } catch (_error) {
      error('Fix Error', _error.message)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      scrollToFirstError={true}
    // labelCol={{ span: 8 }}  // Set the width of the label column
    // wrapperCol={{ span: 16 }} // Set the width of the input field column
    >
      <div className='form-view'>
        <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <Form.Item
              label="First Name"
              name="firstName"
              style={{ width: '100%' }}
              rules={[{ required: true, message: 'Please Enter First Name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} >
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Please Enter Last Name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row><Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <DynamicSelect
              keyName="country"
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please select country!' }]}
            />
          </Col>
          <Col span={10}>
            <DynamicSelect
              keyName="city"
              label="City"
              name="city"
              rules={[{ required: true, message: 'Please select City!' }]}
            />
          </Col>
        </Row><Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <DynamicSelect
              keyName="citizenship"
              label="Citizenship"
              name="citizenship"
              rules={[{ required: true, message: 'Please select citizenship!' }]}
            />
          </Col>
          <Col span={10}>
            <DynamicSelect
              keyName="englishProficiency"
              label="English Proficiency"
              name="englishProficiency"
              rules={[{ required: true, message: 'Please select your proficiency level' }]}
            />
            {/* <Radio.Group>
                <Radio value="beginner">Beginner</Radio>
                <Radio value="intermediate">Intermediate</Radio>
                <Radio value="advanced">Advanced</Radio>
              </Radio.Group> */}
          </Col>
        </Row>
      </div>
      {/* <Row gutter={16} className='botton-footer'>
        <Col span={2}>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" className='float-right' htmlType="submit" size='middle'>
              Save & Next
            </Button>
          </Form.Item>
        </Col>
      </Row> */}
    </Form >
  );
};

export default ProfileForm;
