import moment from "moment";
import { Button, Space, Table, Tag } from "antd";
import Actions from "../../../actions/table-configs/actions";
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

const VendorConfig = (actions, config, refreshData, openModal) => [
    {
        title: 'Actions',
        dataIndex: '',
        key: '',
        ellipsis: true,
        render: (text) => {
            return <Space>
                <Button icon={<EyeOutlined />} type="link" onClick={(e) => actions('view', text)}></Button>
                <Button icon={<EditOutlined />} type="link" onClick={(e) => actions('edit', text)}></Button>
                <Button icon={<DeleteOutlined />} type="link" danger onClick={(e) => actions('delete', text)}></Button>
            </Space>
        }
    },
    {
        title: 'Name',
        dataIndex: '',
        key: '',
        ellipsis: true,
        render: (text) => {
            return [text.firstName, text.lastName].join(' ')
        }
    },
    {
        title: 'Company Name',
        dataIndex: 'companyName',
        key: 'companyName',
        ellipsis: true,
        render: (text) => {
            return text ?? 'Individual'
        }
    },
    {
        title: 'Email',
        dataIndex: 'workEmail',
        key: 'workEmail',
        ellipsis: true,
        render: (text) => {
            return <Tag color="blue" bordered={false}>{text}</Tag>
        }
    },
    {
        title: 'Phone',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        ellipsis: true,
        render: (text) => {
            return text
        }
    },
    Table.EXPAND_COLUMN,
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        ellipsis: true,
        render: (text) => {
            return <Tag color={text == 'active' ? 'green' : 'red'}>{text}</Tag>
        }
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        ellipsis: true,
        render: (text) => {
            return moment(text).format('lll')
        }
    }
];

export { VendorConfig }