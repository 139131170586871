// InterviewConfirmation.js

import React from 'react';

const InterviewConfirmation = ({ candidateName, interviewDate, interviewTime }) => {
  return (
    <div className='pageView'>
      <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px', margin: '16px' }}>
        <h6>Interview Details</h6>
        <p style={{ marginBottom: '0px' }}><strong>Date:</strong> {interviewDate}</p>
        <p style={{ marginBottom: '0px' }}><strong>Time:</strong> {interviewTime}</p>
      </div>

      <p>Dear {candidateName},</p>

      <p>We hope this message finds you well. This is to inform you that your slot for the upcoming interview has been successfully booked with us. We appreciate your interest in our organization and are excited about the opportunity to learn more about you.</p>

      <p>To stay abreast of the latest developments regarding your interview, we recommend keeping a close eye on your email inbox. We will be utilizing this channel as the primary mode of communication to provide you with timely updates on the status of your interview.</p>

      <p>The interview process is a crucial step in our selection procedure, and we want to ensure that you are well-informed and prepared for each stage. Our goal is to make the experience as seamless and transparent as possible for you.</p>

      <p>As we progress through the interview process, you can expect to receive notifications regarding the following:</p>

      <p><strong>Confirmation of Booking:</strong><br />
        Once you've successfully booked a slot through our system, you will receive an initial confirmation email. This email will serve as proof of your reserved interview time and will include essential details such as the date, time, and platform (if virtual).</p>

      {/* Add the rest of the content here */}

      <p>Thank you once again for considering a career with us. We look forward to getting to know you better during the interview process. If you have any questions or concerns, please feel free to reach out to our HR department at <a href="mailto:hr@accelgrowthtech.com">hr@accelgrowthtech.com</a>.</p>
    </div>
  );
}

export default InterviewConfirmation;
