import React, { useRef, useState, useEffect } from 'react';
import { Form, Input, Button, Space, Select, Row, Col, DatePicker } from 'antd';
const { Option } = Select;
import { MinusCircleOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import DynamicSelect from '../../../../common/Select';
import { postData } from '../../../../../actions/middleware';
import { routes } from '../../../../../actions/api-routes';
import { error, success } from '../../../../common/Notify';
import { formatDates, validateMaximum, validateMinimum } from '../../../../common/validate';
import { useSelector } from 'react-redux';
import extractInfoFromJD from '../../../NLP/extracter';
import { encryptData } from '../../../../../actions/crypto';

const { useForm } = Form;

const required = { required: true, message: 'This field is required' }

const RequirementFormV2 = ({ onSubmitFromHeader, onClear, nextCall, data }) => {
  const [form] = useForm()
  const queryParams = new URLSearchParams(location.search);
  const job = queryParams.get('job');

  useEffect(() => {
    if (onSubmitFromHeader == 'SKILLS') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data])


  const onFinish = async (values) => {
    const resData = await postData(routes.jobs.list + 'EXPERIENCE', { data: encryptData({ ...values, jobId: job }) });
    if (resData && resData.code == 200) {
      success("Success", "Details Added Successfully")
      nextCall(2)
    } else {
      error("Failed", "Something went wrong please try again later")
    }
  };

  return (
    <>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        layout='vertical'
        scrollToFirstError={true}
      >
        <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <DynamicSelect
              label="Employment Type"
              name="employmentType"
              keyName={"employmentType"}
              rules={[required]}
            />
          </Col>
          <Col span={10}>
            <DynamicSelect
              label="Mandatory Technical Skills"
              name="skils"
              multiple={true}
              keyName={"skillName"}
              rules={[required]}
            />
          </Col>
        </Row>
        <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <Form.Item
              label="Years of Experience"
              name="experiance"
              rules={[required]}
            >
              <Input type='number' suffix={'YEAR'} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Interview Rounds"
              name="interviewRounds"
            >
              <Input type='number' />
            </Form.Item>
          </Col>
        </Row>
      </Form >
    </>
  );
};

export default RequirementFormV2;
