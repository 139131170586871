import React, { useEffect, useState } from 'react';
import { Upload, Button, message, Modal, notification } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { routes } from '../../../../../actions/api-routes';
import { postData, uploadFile } from '../../../../../actions/middleware';
import { encryptData } from '../../../../../actions/crypto';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ImageUpload = ({ onSubmitFromHeader, onClear, fileType }) => {
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileResponse, setFileResponse] = useState([]);


    useEffect(() => {
        if (onSubmitFromHeader == 'images_submit') {
            onFinish();
        }
    }, [onSubmitFromHeader])

    const onFinish = async () => {
        try {
            if (fileList.length > 0) {
                customRequest().then(async (fileResData) => {
                    const payload = {
                        fileType: fileType,
                        files: fileResData
                    }
                    const resData = await postData(routes.jobs.bulk_upload, { data: encryptData(payload) });
                    if (resData && resData.code == 200) {
                        notification.success({ message: 'Success', description: 'Job added successfully. It will be posted within 30 to 60 minutes.' })
                    }
                }).catch(error => {
                    notification.success({ message: 'Error', description: 'An error occurred. Please try again later.' })
                }).finally(() => {
                    onClear();
                })

            } else {
                notification.error({ message: 'Validation Failed', description: 'Please upload at least 1 image.' })
            }
        } catch (error) {
            notification.error({ message: 'Error', description: 'An error occurred. Please try again later.' })
        } finally {
            onClear();
        }
    };

    const customRequest = async () => {
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();
            fileList.forEach(ele => {
                formData.append('file', ele.originFileObj);
            })
            try {
                const resData = await uploadFile(routes.fileUpload, formData);
                if (resData && resData.status === 'SUCCESS') {
                    setFileResponse(resData.files);
                    resolve(resData.files)
                } else {
                    reject()
                    message.error(`${file.name} Failed to upload`);
                }
            } catch (error) {
                reject()
                message.error(`${file.name} Failed to upload`);
            }
        })
    };

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList.filter(file => file.type.startsWith('image/')))
    };

    const beforeUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('You can only upload images');
            return false;
        }
        return false;
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    return (
        <div>
            <Upload
                listType="picture-card"
                fileList={fileList}
                accept="image/*"
                multiple
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
            >
                {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>
    );
};

export default ImageUpload;
