import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { uploadFile } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
const BASE_URL = 'http://localhost:3000';

const ScreenshotComponent = ({ children }) => {
    const [imgUrl, setImageUrl] = useState('');

    useEffect(() => {
        const takeScreenshot = () => {
            const rootElement = document.getElementById('root');
            html2canvas(rootElement).then((canvas) => {
                const imageData = canvas.toDataURL('image/png');
                setImageUrl(imageData);
                sendImageToBackend(imageData);
            });
        };

        const getRandomTime = () =>
            Math.random() * 5 * 60 *
            2000;

        const scheduleScreenshot = () => {
            const randomTime = getRandomTime();
            setTimeout(() => {
                takeScreenshot();
                scheduleScreenshot();
            }, randomTime);
        };

        scheduleScreenshot();
        return () => clearTimeout();
    }, []);

    // const dataURLtoBlob = (dataURL) => {
    //     const parts = dataURL.split(';base64,');
    //     const contentType = parts[0].split(':')[1];
    //     const byteString = atob(parts[1]);
    //     const arrayBuffer = new ArrayBuffer(byteString.length);
    //     const uint8Array = new Uint8Array(arrayBuffer);

    //     for (let i = 0; i < byteString.length; i++) {
    //         uint8Array[i] = byteString.charCodeAt(i);
    //     }

    //     return new Blob([arrayBuffer], { type: contentType });
    // };

    const uploadFiles = async (file) => {
        const formData = new FormData();
        file.forEach(element => {
            formData.append('file', element, 'file.png');
        });
        const axiosData = await axios.post(routes.fileServer + '/api' + routes.fileUpload, formData, { headers: { "Content-Type": 'multipart/form-data' } })
        return { success: true, fileUrl: axiosData.data.files[0] }
    }

    function dataURLtoBlob(dataURL) {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }


    const sendImageToBackend = async (imageUrl) => {
        // try {
        //     const file = [dataURLtoBlob(imageUrl)]
        //     const uploadResponse = await uploadFiles(file)
        //     console.log(uploadResponse);
        // } catch (error) {
        //     console.error('Error uploading image URL:', error);
        // }
    };


    return (
        <div>
            {children}
        </div>
    );
};

export default ScreenshotComponent;
