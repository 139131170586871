import React from 'react';
import { Avatar, Button, List, Steps } from 'antd';
import './index.css'
import Question from '../QuestionList';
import CodeEditor from '../../Common/CodeEditor';
import CountdownTimer from '../../Common/CountDown';

const QuestionList = () => {
  return <div className='questionList'>
    <div className='main-navbar-online-test'>
      <CountdownTimer initialHours={1} />
    </div>
    <Steps
      type="navigation"
      size='small'
      current={0}
      items={[
        {
          status: 'process',
          title: 'React JS',
        },
        {
          status: 'wait',
          title: 'Nodejs',
        },
        {
          status: 'wait',
          title: 'Mongo DB',
        },
        {
          status: 'wait',
          title: 'Coding Round',
        },
      ]}
    />
    <div className="projects-wrap style-04 wow move-up">
      <div className="projects-image-box project-box ">
        <div>
          {/* <CodeEditor /> */}
          <Question />
        </div>
      </div>
    </div>
  </div>
};
export default QuestionList;