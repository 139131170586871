import React from 'react';
import { Form, Input, DatePicker, Button, Col, Row } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import DynamicSelect from '../../common/Select';
import { putData } from '../../../actions/middleware';
import { useEffect } from 'react';
import { formatDates, validateMinAndMax, validateWebsiteUrl } from '../../common/validate';
import enUS from 'antd/lib/locale/en_US';
import { encryptData } from '../../../actions/crypto';
const { useForm } = Form;
const { TextArea } = Input;

const ProjectForm = ({ data, nextCall, onSubmitFromHeader, onClear }) => {
  const [form] = useForm();
  const initialValue = (data && data.projects.length > 0) ? { projects: data ? formatDates(data.projects) : {} } : {};

  useEffect(() => {
    if (!data) {
      nextCall(0)
    } else {
      form.setFieldsValue(initialValue)
    }
  }, [data])


  useEffect(() => {
    if (onSubmitFromHeader == 'PROJECTS') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])


  const onFinish = async (values) => {
    try {
      const resData = await putData(`/vendor/developers/${encryptData(data._id)}`, { data: encryptData({ ...values }) });
      if (resData && resData.code == 200) {
        nextCall(4)
      } else {
        error('Fix Error', resData.message)
      }
    } catch (_error) {
      error('Fix Error', _error.message)
    }
  };

  return (
    <Form
      name="projectForm"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <div className='form-view'>
        <Row span={16} justify={'space-around'}>
          <Col span={22}>
            <Form.List name="projects" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                    <div key={key} style={{ border: '1px dashed lightgray', padding: '10px', marginBottom: '10px' }}>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'projectTitle']}
                            label="Project Title"
                            fieldKey={[fieldKey, 'projectTitle']}
                            rules={[
                              { required: true, message: 'Please enter Project Title' },
                            ]}
                          >
                            <Input placeholder="Project Title" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <DynamicSelect
                            {...restField}
                            multiple={true}
                            name={[name, 'technologiesUsed']}
                            label="Technologies Used"
                            keyName={'technologiesUsed'}
                            fieldKey={[fieldKey, 'technologiesUsed']}
                            rules={[{ required: true, message: 'Please enter Technologies Used' }]}
                          />
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'startDate']}
                            label="Start Date"
                            fieldKey={[fieldKey, 'startDate']}
                            rules={[{ required: true, message: 'Please select Start Date' }]}
                          >
                            <DatePicker.MonthPicker locale={enUS} format="MM/YYYY" placeholder="Select Month and Year" />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'endDate']}
                            label="End Date"

                            fieldKey={[fieldKey, 'endDate']}
                            rules={[{ required: true, message: 'Please select End Date' }]}
                          >
                            <DatePicker.MonthPicker format="MM/YYYY" locale={enUS} placeholder="Select Month and Year" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'projectURL']}
                            label="Project URL"
                            fieldKey={[fieldKey, 'projectURL']}
                            rules={[{ validator: validateWebsiteUrl }]}
                          >
                            <Input placeholder="Project URL" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <DynamicSelect
                            {...restField}
                            name={[name, 'industry']}
                            label="Industry"
                            keyName={"industry"}
                            fieldKey={[fieldKey, 'industry']}
                            rules={[{ required: true, message: 'Please enter Industry' }]}
                          />
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'projectDescription']}
                            label="Project Description"
                            fieldKey={[fieldKey, 'projectDescription']}
                            rules={[{ required: true, message: 'Please enter Project Description' },
                            ...validateMinAndMax(100, 1000)
                            ]}
                          >
                            <TextArea placeholder="Project Description" count={{ show: true, max: 1000 }} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'responsibilities']}
                            label="Your Responsibilities"
                            fieldKey={[fieldKey, 'responsibilities']}
                            rules={[{ required: true, message: 'Please enter Your Responsibilities' },
                            ...validateMinAndMax(100, 1000)
                            ]}
                          >
                            <TextArea placeholder="Your Responsibilities" count={{ show: true, max: 1000 }} />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item>
                            <Button
                              type="link"
                              onClick={() => remove(name)}
                              icon={<MinusCircleOutlined />}
                              disabled={fields.length === 1}
                              style={{ padding: '0px 0px' }}
                            >
                              Remove
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      style={{ float: 'right' }}
                      type="primary"
                      size='middle'
                      onClick={() => add()}
                      icon={<PlusOutlined />}>
                      Add Project
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </div>
      {/* <Row gutter={16} className='botton-footer'>
        <Col span={2}>
          <Form.Item>
            <Button type="dashed" size='middle' onClick={() => nextCall(2)}>
              Previous
            </Button>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item>
            <Button type="primary"
              className='float-right'
              htmlType="submit" size='middle'>
              Save & Next
            </Button>
          </Form.Item>
        </Col>
      </Row> */}
    </Form>
  );
};

export default ProjectForm;
