import { Avatar, Button, Space, Tabs } from 'antd'
import './index.css'
import VendorsContacts from './vendorDetails/contacts';
import DeveloperHired from './vendorDetails/developerHired';
import VendorJobpost from './vendorDetails/jobpost';
import VendorsCompany from './vendorDetails/company';
import VendorsDevelopers from './vendorDetails/developers';
import RateCards from './vendorDetails/rate-cards';

const VendorsDetails = () => {

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: '1',
            label: 'Contact',
            children: <VendorsContacts />,
        },
        {
            key: '5',
            label: 'Rate Card',
            children: <RateCards />,
        },
        {
            key: '6',
            label: 'My Developers',
            children: <VendorsDevelopers />,
        },
        {
            key: '2',
            label: 'Hired Developer',
            children: <DeveloperHired />,
        },
        {
            key: '3',
            label: 'Job Posts',
            children: <VendorJobpost />,
        },
        {
            key: '4',
            label: 'Company',
            children: <VendorsCompany />,
        },

    ];

    const drawerHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className='my-info'>
                <div className='avatar-view'>
                    <Avatar size={'large'} shape='square' />
                </div>
                <div className='personal-details'>
                    <div className='my-name'>Shubham Kanfade</div>
                    <div className='sub-title'>Managin Director | Accelgroth Technolohy</div>
                </div>
            </div>
        </div>
    );

    return <>
        <div className='formView vendor-details'>
            {drawerHeader}
            <Tabs size='large' defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
    </>
}

export default VendorsDetails;