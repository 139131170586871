import React, { useEffect, useRef, useState } from "react";
import RecordRTC from "recordrtc";
import './index.css'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import Common from "../../routing";
const { routing, COMMON_STRING } = Common;

const ScreenRecorderWithCamera = ({ children, }) => {
  const cameraVideoRef = useRef(null);
  const screenRecorderRef = useRef(null);
  const cameraStreamRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const IS_FULL_SCREEN = useSelector(({ app }) => app[COMMON_STRING.FULL_SCREEN_MODE]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', fullscreenChangeHandler);
    setIsFullscreen(!!document.fullscreenElement);
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
    };
  }, [isFullscreen]);

  useEffect(() => {
    if (!isFullscreen) {
      dispatch({
        type: COMMON_STRING.FULL_SCREEN_MODE,
        payload: COMMON_STRING.IS_FULL_SCREEN_MODE_OFF
      })
      // navigate(routing.instructions)
    }
  }, [isFullscreen])


  const requestFullScreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      if (document.fullscreenEnabled) {
        element.requestFullscreen()
          .then(() => setIsFullscreen(true))
          .catch((error) => console.error('Failed to enter fullscreen:', error));
      } else {
        console.error('Fullscreen is not allowed in this context.');
      }
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
      setIsFullscreen(true);
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      setIsFullscreen(true);
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
      setIsFullscreen(true);
    }
  };

  const exitFullscreen = () => {
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      document.exitFullscreen()
        .then(() => {
          setIsFullscreen(false)
        })
        .catch((error) => console.error('Failed to exit fullscreen:', error))
        .finally(() => {
          setIsFullscreen(false)
        })
    }
  };

  const showNotificationAlert = () => {
    if ("Notification" in window) {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          document.addEventListener("visibilitychange", handleVisibilityChange);
        } else {
          alert('You need to allow notification permission to give this test')
          showNotificationAlert();
        }
      });
    } else {
      alert("Your browser doesn't support Notification alerts. please join with another browser")
      navigate('/')
    }
  };

  const handleFullscreenChange = () => {
    if (!document.fullscreenElement) {
      Modal.confirm({
        title: 'Warning',
        content: 'Exiting fullscreen mode will interrupt your test. Are you sure you want to exit fullscreen mode?',
        onOk: () => {
          exitFullscreen();
        },
        onCancel: () => {
          requestFullScreen()
        }
      })
    }
  };

  useEffect(() => {
    if (IS_FULL_SCREEN && IS_FULL_SCREEN == COMMON_STRING.IS_FULL_SCREEN_MODE_ON && !isFullscreen) {
      onClick()
    }
  }, [IS_FULL_SCREEN, isFullscreen])

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);


  const onClick = () => {
    requestFullScreen()
  }

  const handleVisibilityChange = () => {
    // if (document.visibilityState === "hidden") {
    //   alert("You can't give this test any more.");
    // }
  };

  useEffect(() => {
    showNotificationAlert();
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const showNotification = () => {
    if (notificationsEnabled) {
      new Notification("Test Notification", {
        body: "You have only one chance left",
      });
    }
  };

  const startRecording = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: { mediaSource: "screen" },
      });

      const videoSettings = screenStream.getVideoTracks()[0]?.getSettings();
      const displaySurface = videoSettings?.displaySurface;

      if (displaySurface === "monitor") {
        const cameraStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        const screenRecorder = RecordRTC([screenStream, cameraStream], {
          type: "video",
        });
        screenRecorderRef.current = screenRecorder;

        if (screenRecorder.stream && screenRecorder.stream.ondataavailable) {
          screenRecorder.stream.ondataavailable = function (event) {
            if (event.data && event.data.size > 0) {
              uploadChunk(event.data);
            }
          };
        } else {
          console.error("Cannot set ondataavailable property.");
        }
        screenRecorder.startRecording();
        cameraStreamRef.current = cameraStream;
        cameraVideoRef.current.srcObject = cameraStream;
        setRecording(true);
        // showNotification();
        // requestFullScreen();
      } else {
        alert("Please share the entire screen to start recording.");
        startRecording();
        screenStream.getTracks().forEach((track) => track.stop());
      }
    } catch (error) {
      startRecording();
      console.error("Error during recording:", error);
    }
  };

  useEffect(() => {
    // startRecording();
    const handleResize = () => {
      console.log("Window resized!");
    };
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.documentElement);

    return () => {
      stopRecording();
      resizeObserver.disconnect();
    };

  }, []);

  const stopRecording = () => {
    const { current: screenRecorder } = screenRecorderRef;

    if (screenRecorder) {
      screenRecorder.stopRecording(() => {
        const screenBlob = screenRecorder.getBlob();
        const screenVideoURL = URL.createObjectURL(screenBlob);

        const cameraStream = cameraStreamRef.current;
        if (cameraStream) {
          cameraStream.getTracks().forEach((track) => track.stop());
        }

        uploadChunk(screenBlob, true);
        setRecording(false);
      });
    }
  };

  const uploadChunk = (chunk, isFinalChunk = false) => {
    const formData = new FormData();
    formData.append('videoChunk', chunk);
    formData.append('isFinalChunk', isFinalChunk);

    console.log("chunkchunkchunkchunk", chunk)

    // fetch('http://your-nodejs-server/upload', {
    //   method: 'POST',
    //   body: formData,
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       console.error('Failed to upload video chunk:', response.statusText);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('Error uploading video chunk:', error);
    //   });
  };
  // stopRecording();


  // useEffect(() => {
  //   requestFullScreen();
  // }, [])

  return (
    <div className="no-select">
      <video className="video-play" ref={cameraVideoRef} controls={true} autoPlay muted />
      {children}
    </div>
  );
};

export default ScreenRecorderWithCamera;
