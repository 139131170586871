import React, { useRef } from 'react';
import { Route, useNavigate, useNavigation } from 'react-router-dom';
import { Button, Col, Divider, Input, Modal, Row, Space, Radio, Alert } from 'antd';
import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { getData } from '../../../../actions/middleware';
import './index.css';
import ButtonSection from '../../../common/layout/ButtonSection';
import ContentSection from '../../../common/layout/ContentSection';
import { CopyOutlined, RightCircleOutlined } from '@ant-design/icons';
import TextAreaForm from './textarea';
import ImageUpload from './ImageUpload';
import FileUpload from './fileUpload';

const QuikUploadRequirment = ({ pinned }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [submitForm, setSubmitForm] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [emailToSendLink, setEmailToSendLink] = useState('');
  const [emailLinkError, setEmailSendLinkError] = useState('');
  const [current, setCurrent] = useState('text');

  const divRef = useRef(null);
  const [divWidth, setDivWidth] = useState(null);

  useEffect(() => {
    if (divRef.current) {
      const width = divRef.current.offsetWidth;
      setDivWidth(width);
    }
  }, [divRef]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onSubmit = () => {
    switch (current) {
      case 'text':
        setSubmitForm('text_submit')
        break;
      case 'images':
        setSubmitForm('images_submit')
        break;
      case 'pdf_doc_excel':
        setSubmitForm('pdf_doc_excel_submit')
        break;
    }
  }

  const onChange = (value) => {
    setCurrent(value.target.value);
  };


  const askDeveloperTofill = () => {
    setVisible(true)
  }

  const validateEmail = (e) => {
    setEmailToSendLink(e.target.value)
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(e.target.value)) {
      setEmailSendLinkError(null)
    } else {
      setEmailSendLinkError("Enter valid email")
    }
  }

  return (
    <div className='admin-view bg-fff full-bg'>
      <Modal
        onCancel={() => {
          setEmailSendLinkError(null)
          setEmailToSendLink(null)
          setVisible(false)
        }}
        open={isVisible}
        title={'Create a public link'}
        footer={<>
          {emailToSendLink &&
            <div>
              <Button disabled={emailLinkError} type='primary'>Send Link</Button>
            </div>
          }
        </>}
      >
        <div>
          <div>
            Give access to email
          </div>
          <Input type='email' value={emailToSendLink} onChange={(e) => validateEmail(e)} />
          {emailLinkError && <div style={{ color: 'red' }}>{emailLinkError}</div>}
        </div>
        <div>
          <Divider type='horizontal'>Or</Divider>
        </div>
        <div>
          Copy public Link <CopyOutlined />
        </div>
      </Modal>
      <ButtonSection pinned={pinned}>
        <div className='flex-view'>
          <div>
            <h6>Quik Post</h6>
          </div>
          <div>
            <Space>
              <Button type="default" size='middle' onClick={() => askDeveloperTofill()}>
                Quik Post
              </Button>

              <Button type="default" size='middle' onClick={() => askDeveloperTofill()}>
                Genrate Link
              </Button>

              <Button type="default" size='middle' onClick={() => navigate('/admin/requirements/openings')}>
                Cancel
              </Button>

              <Button type="primary" size='middle' onClick={() => onSubmit()}>
                Save
              </Button>
            </Space>
          </div>
        </div>
      </ButtonSection>
      <ContentSection style={{ height: windowHeight - (150 - 15) }}>
        <Row gutter={16} style={{ margin: 0 }}>
          <Col span={4} ref={divRef}>
            <div className='steps-box note' style={{ position: 'fixed', height: windowHeight - (150 - 15), width: divWidth }}>
              <div className='points'>
                <div className='note-title'>Please ensure that your requirement includes.</div>
                <div className='ponit'><RightCircleOutlined /> Budget </div>
                <div className='ponit'><RightCircleOutlined /> Work Location</div>
                <div className='ponit'><RightCircleOutlined /> Required Experiance </div>
              </div>
            </div>
          </Col>
          <Col className='formView' span={20} >
            <div className='p-5-10 formView' style={{ height: windowHeight - 140 }}>
              <Alert message="Upload in bulk. Once your upload is complete, the system will process your requirements and post them on our portal to match with more candidates. You will receive an email along with candidate resumes, rates, and available screening times."></Alert>
              <Radio.Group onChange={(e) => onChange(e)} style={{ marginTop: 16 }}>
                <Radio.Button value="text">Text</Radio.Button>
                <Radio.Button value="images">Images</Radio.Button>
                <Radio.Button value="pdf_doc_excel">PDF/Doc/Excel</Radio.Button>
              </Radio.Group>
              <div style={{ paddingTop: '10px' }}>
                {current == 'text' && <TextAreaForm
                  onSubmitFromHeader={submitForm}
                  onClear={() => setSubmitForm(null)}
                  fileType={current}
                />}
                {current == 'images' && <ImageUpload
                  onSubmitFromHeader={submitForm}
                  onClear={() => setSubmitForm(null)}
                  fileType={current}
                />}
                {current == 'pdf_doc_excel' && <FileUpload
                  onSubmitFromHeader={submitForm}
                  onClear={() => setSubmitForm(null)}
                  fileType={current}
                />}
              </div>
            </div>
          </Col>
        </Row>
      </ContentSection>
    </div>
  );
};

export default QuikUploadRequirment;
