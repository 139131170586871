import moment from 'moment';
import './index.css';

function Responsibilites({ item }) {
  return (<>
    <div>
      <div className='d-f space-between'>
        <div className='text capitalise'>{item.companyName} - {item.location} - {item.employmentType}</div>
        <div className='set-date text'>{moment(item.startDate).format('MMMM YYYY')} to {moment(item.endDate).format('MMMM YYYY')}</div>
      </div>
      <div className='text bold'>Description</div>
      <div className='text'>{item.responsibilities}</div>
    </div>
  </>)
}


export default Responsibilites;