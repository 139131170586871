import React from 'react';
import { useState } from 'react';
import { Divider, Tabs } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, WhatsAppOutlined, BellOutlined, SettingOutlined, UserOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './index.css';

const Shortlisted = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const items = [
        {
            label: `Upcoming Interviews`,
            icon: <UserOutlined />,
            key: 0,
        },
        {
            label: `Shortlisted Profiles`,
            icon: <UserOutlined />,
            key: 1,
        },
        {
            icon: <UserOutlined />,
            label: `Rejected Profiles`,
            key: 2,
        },
    ]

    const onChange = (key) => {
        console.log(key);
    };

    return (
        <div className='padding-view-box'>
            <div className='bg-fff padding-horigontal'>
                <Tabs
                    onChange={onChange}
                    type="line"
                    items={items}
                />
                <div>
                    
                </div>
            </div>
        </div>
    );
};

export default Shortlisted;
