import React, { useRef, useState, useEffect } from 'react';
import { Form, Input, Button, Space, Select, Row, Col, DatePicker, ConfigProvider } from 'antd';
const { Option } = Select;
import { MinusCircleOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { postData } from '../../../../../actions/middleware';
import { routes } from '../../../../../actions/api-routes';
import { error, success } from '../../../../common/Notify';
import { formatDates, validateMaximum, validateMinimum } from '../../../../common/validate';
import { encryptData } from '../../../../../actions/crypto';
import DynamicSelect from '../../../../common/Select';
const { useForm } = Form;

const required = { required: true, message: 'This field is required' }

const RequirementFormV3 = ({ onSubmitFromHeader, onClear, nextCall, data }) => {
  const [form] = useForm()
  const [interval, setInterval] = useState('')
  const queryParams = new URLSearchParams(location.search);
  const job = queryParams.get('job');

  useEffect(() => {
    if (onSubmitFromHeader == 'COMMERTIALS') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])

  const onFinish = async (values) => {
    const resData = await postData(routes.jobs.list + 'COMMERTIALS', { data: encryptData({ ...values, jobId: job }) });
    if (resData && resData.code == 200) {
      success("Success", "Details Added Successfully")
    } else {
      error("Failed", "Something went wrong please try again later")
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
    }
  }, [data])

  const getSuffix = (text) => {
    switch (text) {
      case 'Monthly':
        return 'LAKH'
      case 'Yearly':
        return 'LAKH'
      case 'Hourly':
        return 'LAKH'
      case 'Weekly':
        return 'LAKH'
      case 'Fixed':
        return 'LAKH'
      default:
        return 'LAKH'
    }
  }

  const handleInput = (e, type) => {
    const value = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (!regex.test(value)) {
      e.target.value = value.slice(0, -1);
    }
  };


  return (
    <>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        layout='vertical'
        scrollToFirstError={true}
      >
        {/* <Row gutter={16} justify={'space-around'}>
          <Col span={10}>
            <Form.Item
              label="Valid Till"
              name="validTill"
              style={{ width: '100%' }}
            >
              <DatePicker
                locale={en_US}
                picker='date'
                format={'DD-MMM-YYYY'} />
            </Form.Item>
          </Col>
          <Col span={10}>
          </Col>
        </Row> */}

        <Row gutter={16} justify={'space-around'}>
          <Col span={14}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  label="Currency"
                  name="currency"
                  rules={[required]}
                >
                  <Select>
                    {['INR', 'USD'].map(ele => <Option key={ele} value={ele}>{ele}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Interval"
                  name="interval"
                  rules={[required]}
                >
                  <Select onChange={setInterval}>
                    {['Monthly', 'Yearly', 'Hourly', 'Weekly', 'Fixed'].map(ele => <Option key={ele} value={ele}>{ele}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Minimum"
                  name="minimum"
                  rules={[required, { validator: (_, value) => validateMinimum(_, value, form.getFieldValue('maximum')) }]}
                >
                  <Input type='number'
                    onChange={(e) => handleInput(e, 'minimum')}
                    suffix={getSuffix(interval)} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Maximum"
                  name="maximum"
                  rules={[required, { validator: (_, value) => validateMaximum(_, value, form.getFieldValue('minimum')) }]}
                >
                  <Input
                    type='number'
                    onChange={(e) => handleInput(e, 'maximum')}
                    suffix={getSuffix(interval)} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6}></Col>
        </Row>
        <Row gutter={16} justify={'space-around'}>
          <Col span={7}>
            <DynamicSelect
              label="Client"
              name="jobTitle"
              keyName={"jobTitle"}
              rules={[required]}
            />
          </Col>
          <Col span={13}></Col>
        </Row>
      </Form >
    </>
  );
};

export default RequirementFormV3;
