import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Avatar, Button, Col, Pagination, Radio, Row, Table, Tabs, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import './index.css';
import { ShoppingFilled } from '@ant-design/icons';
import { getData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import { decryptData } from '../../../actions/crypto';
import { timeAgo } from '../../common/functions';
import ProfileRecived from './profilesRecived';
import AutoFetch from './systemShared';
import ListAndDetails from './list_details';
import { PROFILES_FOR_JOB, TAB_KEY } from '../../common/TabList';

const Profilelisting = () => {
    const [tab, setTab] = useState(TAB_KEY.APPLICATIONS);
    const [item, setItems] = useState(null);

    let { id } = useParams();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        if (id) {
            getJobDetails(id)
        }
    }, [id])

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getJobDetails = (id) => {
        getData(routes.jobs.get_job_details, { id })
            .then(resData => {
                setItems(decryptData(resData.data))
            }).catch(error => {

            })
    }

    const onChange = (key) => {
        setTab(key)
    };

    return (
        <div className=''>
            <div className='tabs-bg padding-title'>
                <div className='d-f justify-between'>
                    <div>
                        <div className='job-title-view'>
                            <div className='icon-view'>
                                <Avatar shape={'square'} icon={<ShoppingFilled />} />
                            </div>
                            <div className='heading-view-for-rol'>
                                <div className='job-title-box'>
                                    <div className='main-text'> {item?.jobTitle}</div>
                                    <div className='sub-text'> {item?.experiance}+ Year</div>
                                </div>
                                <div className='price-view'>
                                    <Tag color='magenta' bordered={false}>{item?.jobLocationType}</Tag> | <Tag color='cyan' bordered={false}>{item?.currency} {item?.minimum}LPM - {item?.maximum}LPM</Tag>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button type='primary' shape='round' onClick={() => { }}>Manage Job</Button>
                    </div>
                </div>
                <div>
                    <b>{item?.jobActive} . </b>
                    {timeAgo(item?.createdAt)}
                </div>
            </div>
            <div className='tabs-bg border-top'>
                <Tabs defaultActiveKey={tab} activeKey={tab} items={PROFILES_FOR_JOB} onChange={onChange} />
            </div>
            <div>
                <ListAndDetails items={item} activeTab={tab} />
            </div>
        </div>
    );
};

export default Profilelisting;
