import React from 'react';
import { Avatar, List } from 'antd';
const data = [
  {
    title: 'React JS Questiosn',
  },
  {
    title: 'MongoDB Questions',
  },
  {
    title: 'Coding Round',
  },
  {
    title: 'Coding Round 2',
  },
];
const Secotions = () => (
  <List
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item, index) => (
      <List.Item>
        <List.Item.Meta
          title={item.title}
          description="10 answered 5 Un-answered"
        />
      </List.Item>
    )}
  />
);
export default Secotions;