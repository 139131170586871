import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from './options';
import './index.css';

const LoadMoreCheckboxSelect = ({ onSelect, isMulti }) => {
    const [value, onChange] = useState([]);

    useEffect(() => {
        if (value && Array.isArray(value)) {
            onSelect(value.map(ele => ele.value))
        } else {
            onSelect(value)
        }
    }, [value])

    return (
        <AsyncPaginate
            value={value}
            loadOptions={loadOptions}
            additional={{ page: 1 }}
            isMulti={!isMulti}
            closeMenuOnSelect={false}
            onChange={onChange}
        />
    );
};

export default LoadMoreCheckboxSelect;
