import './index.css';
function FAQ() {
  return (
    <div>
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title">FAQs </h2>
                <ul className="breadcrumb-list">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item active">FAQs </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div className="accordion-wrapper section-space--ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-wrap text-center section-space--mb_20">
                    <h6 className="section-sub-title mb-20">Style 01</h6>
                    <h3 className="heading">Frequently asked questions</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="faq-wrapper  section-space--mt_40">
                    <div id="accordion">
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h5 className="mb-0">
                            <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              How can we help your business?<span> <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-chevron-up"></i> </span>
                            </button>
                          </h5>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                          <div className="card-body">
                            <p>Mitech takes into consideration every little detail to make sure the system run smoothly and responsively. Mitech employs a new technique called Minified Technology for securing customers' database & building up highly confidential firewalls. </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h5 className="mb-0">
                            <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              What are the advantages of Mitech? <span> <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-chevron-up"></i> </span>
                            </button>
                          </h5>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                          <div className="card-body">
                            <p>We reduce redundant complex calculations and lengthy erroneous code texts with simpler ones to ensure Mitech would run seamlessly and the design is reserved in its best form when viewed from a wide range of mobile devices & browsers. </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h5 className="mb-0">
                            <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              How working process is simplified?<span> <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-chevron-up"></i> </span>
                            </button>
                          </h5>
                        </div>
                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                          <div className="card-body">
                            <p>Our service offerings to enhance customer experience throughout the product lifecycle includes – test and repair, service management, and end-to-end warranty management. </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="toggles-wrapper section-space--mt_40">
                    <div className="faq-wrapper">
                      <div id="faq-toggles">
                        <div className="card">
                          <div className="card-header" id="faq_2">
                            <h5 className="mb-0">
                              <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#faq_two" aria-expanded="false" aria-controls="faq_two">
                                What are the advantages of Mitech?<span> <i className="fas fa-chevron-down"></i>
                                  <i className="fas fa-chevron-up"></i> </span>
                              </button>
                            </h5>
                          </div>
                          <div id="faq_two" className="collapse" aria-labelledby="faq_2">
                            <div className="card-body">
                              <p>Mitech takes into consideration every little detail to make sure the system run smoothly and responsively. Mitech employs a new technique called Minified Technology for securing customers' database & building up highly confidential firewalls. </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="faq_3">
                            <h5 className="mb-0">
                              <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#faq_three" aria-expanded="false" aria-controls="faq_three">
                                How working process is simplified? <span> <i className="fas fa-chevron-down"></i>
                                  <i className="fas fa-chevron-up"></i> </span>
                              </button>
                            </h5>
                          </div>
                          <div id="faq_three" className="collapse" aria-labelledby="faq_3">
                            <div className="card-body">
                              <p>We reduce redundant complex calculations and lengthy erroneous code texts with simpler ones to ensure Mitech would run seamlessly and the design is reserved in its best form when viewed from a wide range of mobile devices & browsers. </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="faq_4">
                            <h5 className="mb-0">
                              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq_four" aria-expanded="false" aria-controls="faq_four">
                                Product Engineering & Services <span> <i className="fas fa-chevron-down"></i>
                                  <i className="fas fa-chevron-up"></i> </span>
                              </button>
                            </h5>
                          </div>
                          <div id="faq_four" className="collapse" aria-labelledby="faq_4" data-bs-parent="#faq-toggles">
                            <div className="card-body">
                              <p>We are available to freelance hiring with on-demand extra services, including WordPress site design/ redesign, WordPress installation, all-in-one customization, video production, video editing and stop motion video producing.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="accordion-wrapper section-space--ptb_100 border-top">
            <div className="container">

              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-wrap text-center section-space--mb_20">
                    <h6 className="section-sub-title mb-20">Style 02</h6>
                    <h3 className="heading">Frequently asked questions</h3>
                  </div>
                </div>
              </div>


              <div className="row ">
                <div className="col-lg-6 pe-5">
                  <div className="faq-two-wrapper section-space--mt_40">
                    <div id="accordion_two">
                      <div className="card">
                        <div className="card-header" id="heading__10">
                          <h5 className="mb-0 font-weight--bold">
                            <button className="btn-link" data-bs-toggle="collapse" data-bs-target="#tab__10" aria-expanded="true" aria-controls="tab__10">
                              How can we help your business? <span>
                                <i className="fas fa-chevron-circle-down"></i>
                                <i className="fas fa-chevron-circle-right"></i> </span>
                            </button>
                          </h5>
                        </div>

                        <div id="tab__10" className="show" aria-labelledby="heading__10" data-bs-parent="#accordion_two">
                          <div className="card-body">
                            <p>Through the collaboration with customers in discussing needs and demand, we're able to attain mutual understanding, gain customer trust to offer appropriate advice, and bring about suggestions on suitable technology to transform your business. </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="heading__11">
                          <h5 className="mb-0">
                            <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse__11" aria-expanded="false" aria-controls="collapse__11">
                              What are the advantages of Mitech?<span>
                                <i className="fas fa-chevron-circle-down"></i>
                                <i className="fas fa-chevron-circle-right"></i>  </span>
                            </button>
                          </h5>
                        </div>
                        <div id="collapse__11" className="collapse" aria-labelledby="heading__11" data-bs-parent="#accordion_two">
                          <div className="card-body">
                            <p>Mitech takes into consideration every little detail to make sure the system run smoothly and responsively. Mitech employs a new technique called Minified Technology for securing customers' database & building up highly confidential firewalls. </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="heading__12">
                          <h5 className="mb-0">
                            <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse__12" aria-expanded="false" aria-controls="collapse__12">
                              How working process is simplified? <span><i className="fas fa-chevron-circle-down"></i>
                                <i className="fas fa-chevron-circle-right"></i> </span>
                            </button>
                          </h5>
                        </div>
                        <div id="collapse__12" className="collapse" aria-labelledby="heading__12" data-bs-parent="#accordion_two">
                          <div className="card-body">
                            <p>We reduce redundant complex calculations and lengthy erroneous code texts with simpler ones to ensure Mitech would run seamlessly and the design is reserved in its best form when viewed from a wide range of mobile devices & browsers. </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="heading__13">
                          <h5 className="mb-0">
                            <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapse__13" aria-expanded="false" aria-controls="collapse__13">
                              Product Engineering & Services<span><i className="fas fa-chevron-circle-down"></i>
                                <i className="fas fa-chevron-circle-right"></i>  </span>
                            </button>
                          </h5>
                        </div>
                        <div id="collapse__13" className="collapse" aria-labelledby="heading__13" data-bs-parent="#accordion_two">
                          <div className="card-body">
                            <p>Our service offerings to enhance customer experience throughout the product lifecycle includes – test and repair, service management, and end-to-end warranty management. </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="video-interview section-space--mt_40 video-popup">
                    <a href="https://www.youtube.com/watch?v=9No-FiEInLA" className="video-link mt-30">
                      <div className="single-popup-wrap">
                        <img className="img-fluid border-radus-5" src={require("./../../../assets/images/bg/faqs-video-poster.webp")} alt="" />
                        <div className="ht-popup-video video-button">
                          <div className="video-mark">
                            <div className="wave-pulse wave-pulse-1"></div>
                            <div className="wave-pulse wave-pulse-2"></div>
                          </div>
                          <div className="video-button__two">
                            <div className="video-play">
                              <span className="video-play-icon"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default FAQ;
