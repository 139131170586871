import { Button } from 'antd';
import React, { useState, useEffect } from 'react';

const ResendOTPButton = ({ onClick, disabled }) => {
    const [countdown, setCountdown] = useState(60);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [countdown]);

    const handleResendClick = () => {
        onClick();
        setCountdown(90);
    };

    return (
        <div>
            <Button type='link' onClick={handleResendClick} disabled={disabled || countdown > 0}>
                {countdown > 0 ? `Resend OTP in ${countdown}s` : 'Resend OTP'}
            </Button>
        </div>
    );
};

export default ResendOTPButton;
