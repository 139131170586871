import React, { useEffect, useState } from 'react';
import { Upload, Button, message, Modal, notification, Space, Image } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { routes } from '../../../../actions/api-routes';
import { postData, uploadFile } from '../../../../actions/middleware';
import { encryptData } from '../../../../actions/crypto';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ImageUpload = ({ children, onClose }) => {
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileResponse, setFileResponse] = useState([]);

    const onFinish = async () => {
        try {
            if (fileList.length > 0) {
                customRequest().then(async (fileResData) => {
                    const payload = {
                        fileType: 'images',
                        files: fileResData
                    }
                    const resData = await postData(routes.jobs.bulk_upload, { data: encryptData(payload) });
                    if (resData && resData.code == 200) {
                        notification.success({ message: 'Success', description: 'Job added successfully. It will be posted within 30 to 60 minutes.' })
                    }
                }).catch(error => {
                    notification.success({ message: 'Error', description: 'An error occurred. Please try again later.' })
                }).finally(() => {
                    setFileList([])
                    onClose()
                })
            } else {
                notification.error({ message: 'Validation Failed', description: 'Please upload at least 1 image.' })
                setFileList([])
                onClose()
            }
        } catch (error) {
            notification.error({ message: 'Error', description: 'An error occurred. Please try again later.' })
            setFileList([])
            onClose()
        }
    };

    const customRequest = async () => {
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();
            fileList.forEach(ele => {
                formData.append('file', ele.originFileObj);
            })
            try {
                const resData = await uploadFile(routes.fileUpload, formData);
                if (resData && resData.status === 'SUCCESS') {
                    setFileResponse(resData.files);
                    resolve(resData.files)
                } else {
                    reject()
                    message.error(`${file.name} Failed to upload`);
                }
            } catch (error) {
                reject()
                message.error(`${file.name} Failed to upload`);
            }
        })
    };

    const handleCancel = () => {
        setFileList([])
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => {
        const fileResponse = newFileList.filter(file => file.type.startsWith('image/'));
        setFileList(fileResponse)
        if (fileResponse.length > 0) {
            handlePreview(fileResponse[0])
        }
    };

    const beforeUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('You can only upload images');
            return false;
        }
        return false;
    };

    return (
        <div>
            <Upload
                listType="text"
                fileList={fileList}
                accept="image/*"
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
                showUploadList={false}
                maxCount={1}
            >
                {children}
            </Upload>
            <Modal
                open={fileList.length}
                title={'Post File'}
                footer={<>
                    <Space>
                        <Button type='default' onClick={handleCancel}>Cancel</Button>
                        <Button type='primary' onClick={onFinish}>Post</Button>
                    </Space>
                </>}
                onCancel={handleCancel}>
                <Image
                    alt="example"
                    style={{
                        width: '100%',
                        objectFit: 'contain',
                        margin: 'auto'
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>
    );
};

export default ImageUpload;
