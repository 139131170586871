import './index.css';
function ChooseUs() {
  return (
    <div className="gradation-process-area section-space--ptb_40">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="gradation-title-wrapper section-space--mb_60">
              <div className="gradation-title-wrap ">
                <h6 className="section-sub-title text-black mb-20">How we deal</h6>
                <h4 className="heading">Top 10 reasons <span className="text-color-primary"> to <br /> choose us</span></h4>
              </div>
              <div className="gradation-sub-heading">
                <h3 className="heading"><mark>10</mark> Reasons</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ht-gradation style-01">
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.1s">
                <div className="line"></div>
                <div className="line-left"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">H</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">One Day Hiring Policy</h6>
                  <div className="text">Rapid hiring process, secure your talent today.</div>
                </div>
              </div>
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.15s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">I</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">34+ Technology Expertise</h6>
                  <div className="text">Diverse technology prowess across 34+ domains.</div>
                </div>
              </div>
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.20s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">R</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Zero Developer Backout</h6>
                  <div className="text">Assured commitment, zero developer turnover guarantee.</div>
                </div>
              </div>
              <div className="item item-1 animate wow fadeInRight" data-wow-delay="0.25s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">E</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Risk-Free Trial</h6>
                  <div className="text">Try our services with a risk-free trial.</div>
                </div>
              </div>
              <div className="item item-1 animate wow fadeInRight" data-wow-delay="0.25s">
                <div className="line"></div>

                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">H</h6>
                </div>
                <div className="line-right"></div>
                <div className="content-wrap">
                  <h6 className="heading">Dedicated Project Manager</h6>
                  <div className="text">Your project, our dedicated management expertise.</div>
                </div>
              </div>
            </div>

            <div className="ht-gradation style-01">
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.1s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">I</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Quik Replacement</h6>
                  <div className="text">Swift replacement for uninterrupted project continuity.</div>
                </div>
              </div>
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.15s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">H</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Regular Client Updates</h6>
                  <div className="text">Frequent updates to keep clients well-informed.</div>
                </div>
              </div>
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.20s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">E</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Flexible Contracts</h6>
                  <div className="text">Adaptable contracts for your changing business needs.</div>
                </div>
              </div>
              <div className="item item-1 animate wow fadeInRight" data-wow-delay="0.25s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">R</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Extremely Competitive Costs</h6>
                  <div className="text">High-value services at exceptionally competitive prices.</div>
                </div>
              </div>
              <div className="item item-1 animate wow fadeInRight" data-wow-delay="0.25s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">I</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Hassle-Free Development and Designing</h6>
                  <div className="text">Seamless development and design, hassle-free experience.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseUs;
