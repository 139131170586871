import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Radio, Row, Modal, Col, Select } from 'antd';
import DynamicSelect from '../../common/Select';
import { putData } from '../../../actions/middleware';
import { validateMaximum, validateMinimum, validateWebsiteUrl } from '../../common/validate';
import { error } from '../../common/Notify';
import { encryptData } from '../../../actions/crypto';
const { useForm } = Form;

const EditModal = ({ visible, onClose, data, type }) => {
    const [form] = useForm();
    const [showSublocations, setShowSublocations] = useState(false);
    const [title, setTitle] = useState();

    useEffect(() => {
        if (data) {
            form.setFieldsValue(data)
            setTitle('Edit for ' + data.firstName)
            if (data.locations.includes("onsite")) {
                setShowSublocations(true)
            } else {
                setShowSublocations(false)
            }
        }
    }, [data])

    const onFinish = async (values) => {
        console.log('Received values:', values);
        try {
            const resData = await putData(`/vendor/developers/${encryptData(data._id)}`, { data: encryptData(values) });
            if (resData && resData.code == 200) {
                onClose();
            } else {
                error('Fix Error', resData.message)
            }
        } catch (_error) {
            error('Fix Error', _error.message)
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleLocationChange = (checkedValues) => {
        setShowSublocations(checkedValues.includes('onsite'));
    };

    const handleOk = () => {
        form.submit();
    }

    return (
        <div>
            <Modal
                title={title}
                open={visible}
                onOk={handleOk}
                onCancel={onClose}
            >
                <Form
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    scrollToFirstError={true}
                >
                    <div className='form-view'>
                        {type == 'Experiance' && <Row gutter={16} >
                            <Col span={10}>
                                <Form.Item
                                    label="Total Experiance"
                                    name="Experiance"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'This field is required' }]}
                                >
                                    <Input type='number' />
                                </Form.Item>
                            </Col>
                        </Row>}
                        {type == 'locations' && <Row gutter={16} >
                            <Col span={10}>
                                <Form.Item
                                    label="Location"
                                    name="locations"
                                    rules={[{ required: true, message: 'This field is required' }]}
                                >
                                    <Select onChange={handleLocationChange} mode='tags'>
                                        <Select.Option value="remote">Remote</Select.Option>
                                        <Select.Option value="onsite">Onsite</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {showSublocations && <Col span={10}>
                                <DynamicSelect
                                    label={"Onsite Locations"}
                                    name={'onsiteOptions'}
                                    keyName={'onsiteOptions'}
                                    rules={[{ required: true, message: 'This field is required' }]}
                                />
                            </Col>}
                        </Row>}
                        {type == 'Price' && <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    label="Currency"
                                    name="currency"
                                    style={{ width: '100%' }}
                                >
                                    <Select defaultValue={'INR'}>
                                        <Select.Option value={"INR"}>{'INR'}</Select.Option>
                                        <Select.Option value={"USD"}>{'USD'}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Minimum"
                                    name="minimum"
                                    style={{ width: '100%' }}
                                    rules={[{
                                        validator: (_, value) => {
                                            return validateMinimum(_, value, form.getFieldValue('maximum'))
                                        }
                                    }]}
                                >
                                    <Input type='number' onChange={() => form.validateFields(['maximum'])} suffix={'LPM'} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Maximum"
                                    name="maximum"
                                    style={{ width: '100%' }}
                                    rules={[{
                                        validator: (_, value) => {
                                            return validateMaximum(_, value, form.getFieldValue('minimum'))
                                        }
                                    }]}
                                >
                                    <Input type='number' onChange={() => form.validateFields(['minimum'])} suffix={'LPM'} />
                                </Form.Item>
                            </Col>
                        </Row>}
                    </div>
                </Form >
            </Modal>
        </div>
    );
};

export default EditModal;
