import { Checkbox, Col, Space, Form, Input, Row, Select, TimePicker } from "antd";
import DynamicSelect from "../../common/Select";
import ReactQuill from "react-quill";
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import './index.css';
import { postData } from "../../../actions/middleware";
import { routes } from "../../../actions/api-routes";
import { encryptData } from "../../../actions/crypto";
const { Option } = Select;
const { useForm } = Form;

const ConfigForm = ({ isSave, onSave, onClose }) => {
    const [form] = useForm()
    const [options, setOptions] = useState([{ skillName: '', numberOfQuestions: '' }]);

    const onFinish = async (value) => {
        const values = { ...value, timeRequired: value.timeRequired.format('HH:mm') }
        const respoNseData = await postData(routes.onlineTest.testConfig, { data: encryptData(values) });

        if (respoNseData.code == 200) {
            onClose()
        }
    }

    const onFinishFailed = () => { }

    useEffect(() => {
        if (isSave) {
            form.submit()
            onSave()
        }
    }, [isSave])


    const handleRemove = (index) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        setOptions(newOptions);
    };

    return <>
        <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="add-question"
        >
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        label="Test Name"
                        name="testName"
                        rules={[{ required: true, message: 'Please input name' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Time required"
                        name="timeRequired"
                        rules={[{ required: true, message: 'Please select time' }]}
                    >
                        <TimePicker format="HH:mm" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Expires in"
                        name="expiredTime"
                        rules={[{ required: true, message: 'Please select expire time' }]}
                    >
                        <Select name={'expiredTime'}>
                            {[4, 6, 8, 12, 24, 36].map(ele => <Option name={ele} value={ele}>{ele}</Option>)}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.List
                        name="testConfig"
                        initialValue={options}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                    <Space
                                        key={key}
                                        style={{ display: 'flex', marginBottom: 0 }}
                                        align="baseline">
                                        <DynamicSelect
                                            {...restField}
                                            label={"Select Skill"}
                                            name={[name, 'skillName']}
                                            keyName={"skillName"}
                                            fieldKey={[fieldKey, 'option']}
                                            required={true}
                                            style={{ width: '240px' }}
                                        />
                                        <Form.Item
                                            {...restField}
                                            label={"No of Ques"}
                                            name={[name, 'numberOfQuestions']}
                                            fieldKey={[fieldKey, 'numberOfQuestions']}
                                        >
                                            <Input type="number" />
                                        </Form.Item>
                                        <div className='form-buttons'>
                                            {fields.length > 1 ? (
                                                <MinusCircleOutlined onClick={() => { remove(name); handleRemove(fields.findIndex((field) => field.name === name)); }} />
                                            ) : null}
                                            {' '}
                                            {fields.length - 1 == index ? <PlusCircleOutlined onClick={() => add()} /> : <></>}
                                        </div>
                                    </Space>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
        </Form >
    </>
}

export default ConfigForm;