import React from 'react';
import { Button, Empty, Input, Pagination, Tag } from 'antd';
import Skills from '../../company/careers/showSkils';
import CheckboxGroups from '../common/group-checkbox';
import ExperienceSlider from '../common/slider';
import DynamicSelect from '../../../admin/common/Select';
import { useEffect, useState } from 'react';
import { getData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import { truncateText } from '../../../admin/common/validate';
import { Link, useLocation } from 'react-router-dom';
import AutoSearchComponent from '../common/autocomplete';
import { ArrowRightOutlined } from '@ant-design/icons';
import './index.css';
import HireDeveloperModal from '../Hire-modal';
import { decryptData } from '../../../actions/crypto';

function HireDeveloperList() {
  const [listData, setListData] = useState([]);
  const [pagignation, setPagignation] = useState({});
  const [selectedTags, setTags] = useState([]);
  const [insdustries, setIndustries] = useState([]);
  const [current, setCurrent] = useState(1);
  const [isVisible, setVisible] = useState(false);
  const [developerData, setDeveloperData] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('search');

  useEffect(() => {
    getProfileData(1);
    getIndustires();
  }, [])

  const getTechnology = (input) => {
    const match = input.match(/Hire_(.*?)(?:_with_(.*?))?_Developers/i);
    if (match) {
      return match
        .slice(1)
        .map((part) => (part ? part.replace(/\//g, ' ').replace(/\band\b/gi, '') : ''))
        .filter(Boolean);
    }
    return null;
  }

  useEffect(() => {
    if (paramValue) {
      const dataArray = getTechnology(paramValue)
      setTags(dataArray)
    }
  }, [paramValue])

  const getIndustires = async () => {
    const resData = await getData(routes.public.vendorOptions + '/industry', {});
    if (resData && resData.code == 200) {
      setIndustries(decryptData(resData.data))
    }
  }


  const getProfileData = async (_current, pageSize = 10) => {
    let conditions = { current: _current }
    if (pageSize) {
      conditions['pageSize'] = pageSize
    }

    if (selectedTags && selectedTags.length > 0) {
      conditions['skills'] = selectedTags
    }
    const resData = await getData(routes.public.getProfiles, conditions);
    if (resData && resData.code == 200) {
      setListData(resData.data)
      setPagignation(resData.pagination)
    }
  }

  const handleClose = (removedTag) => {
    const updatedTags = selectedTags.filter((tag) => tag !== removedTag);
    setTags(updatedTags);
  };

  const onChangeOption = (value) => {
    console.log("valuevaluevaluevalue", value)
  }


  const onCangePagignation = (value) => {
    setCurrent(value)
    getProfileData(value)
  }


  const onSizeChange = (current, pageSize) => {
    setCurrent(current)
    getProfileData(current, pageSize)
  }

  const onDownload = (item) => {
    const downloadPdf = async () => {
      try {
        const pdfUrl = item.pdfFile;
        const response = await fetch(pdfUrl);

        if (!response.ok) {
          throw new Error(`Failed to download PDF. Status: ${response.status}`);
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = pdfUrl.substring(pdfUrl.lastIndexOf('/') + 1);;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading PDF:', error.message);
      }
    }
    downloadPdf();
  }

  const onClose = () => {
    setVisible(false)
  }

  const openHiringModal = (developer) => {
    setDeveloperData(developer);
    setVisible(true);
  }

  return (
    <>
      <div id="main-wrapper">
        <HireDeveloperModal
          data={developerData}
          isVisible={isVisible}
          onClose={onClose}
        />
        <div className="feature-images-wrapper padding-top-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="projects-wrap style-04 wow move-up">
                  <div className="projects-image-box developer-profile">
                    <div className='filter-view'>
                      {listData.length > 0 && <><div>
                        <div>
                          <h6 className='heading text-align-left'>Industries</h6>
                        </div>
                        <div className='padding-bottom'>
                          <CheckboxGroups onChange={onChangeOption} plainOptions={insdustries.map(ele => ele.name)} />
                        </div>
                      </div>
                        <div>
                          <div >
                            <h6 className='heading text-align-left'>Remote/Onsite</h6>
                          </div>
                          <div className='padding-bottom'>
                            <CheckboxGroups plainOptions={['Remote', 'Onsite']} />
                          </div>
                        </div>
                        <div>
                          <div >
                            <h6 className='heading text-align-left'>Locations</h6>
                          </div>
                          <div className='padding-bottom'>
                            <DynamicSelect
                              name={'locations'}
                              multiple={true}
                              keyName={"city"}
                              addNew={false}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <h6 className='heading text-align-left'> Years of experience</h6>
                          </div>
                          <div className='padding-bottom'>
                            <ExperienceSlider />
                          </div>
                        </div>
                      </>}
                      <div>
                        <div>
                          <h6 className='heading text-align-left'>Hiring resources</h6>
                        </div>
                        <div className='padding-bottom'>
                          <div className='sub-heading click'>
                            Guide to Hiring devs
                            ( <ArrowRightOutlined /> )
                          </div>
                          <div className='sub-heading click'>
                            Interview Questions
                            ( <ArrowRightOutlined /> )
                          </div>
                          <div className='sub-heading click'>
                            Common Mistakes
                            ( <ArrowRightOutlined /> )
                          </div>
                        </div>
                      </div>
                      <div>
                        <h6 className='heading text-align-left'> Need help?</h6>
                        <div className='padding-bottom'>
                          <div className='sub-heading click'>
                            Book a meeting
                            ( <ArrowRightOutlined /> )
                          </div>
                          <div className='sub-heading click'>
                            Chat with an expert
                            ( <ArrowRightOutlined /> )
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="projects-wrap style-04 wow move-up">
                  <div className="projects-image-box developer-profile">
                    <div className='search-box-hiredeveloper'>
                      <AutoSearchComponent />
                      {(selectedTags.length > 0) && < div className='tag-class'>
                        {selectedTags.map(e => <Tag onClose={handleClose} closable color='blue' >{e}</Tag>)}
                      </div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  {listData.map((ele, index) => <div key={index} className='col-md-6'>
                    <div className="projects-wrap style-04 wow move-up">
                      <div className="projects-image-box developer-profile">
                        <Link to={`/profile-details?developer=AGPF${ele.profile}-${ele.profileID}`} >
                          <div className='d-f'>
                            <div className='avatar-container-1'>
                              <img src={ele.photo ? routes.fileServer + ele.photo : require('./../../../assets/images/logo/logov1.png')}
                                onError={(e) => { e.target.src = require('./../../../assets/images/logo/logov1.png') }}
                                className='avatar-image-1' />
                            </div>
                            <div className='name-sections'>
                              <div className='name'>
                                {ele.firstName} {ele.lastName}
                                <span className='profile-id'> AGPF{ele.profile}</span>
                              </div>
                              <div className='text jobtitle'>
                                {ele.JobTitle}, {ele.Experiance}+ Years
                              </div>
                              <div>
                                <Skills ele={{ skils: ele.skills.map(e => e.skillName) }} />
                              </div>
                            </div>
                          </div>
                          <div className='text disc'>
                            {truncateText(ele.shortBio, 180)}
                          </div>
                          <div className='text'>
                            <span className='bold'>Industries:</span> {truncateText(ele.projects.map(e => e.industry).join(', '), 100)}
                          </div>
                        </Link>
                        <div className='d-f hirButtons'>
                          <Button className='text' type='link' onClick={() => openHiringModal(ele)} size='small'>Hire Dev</Button>
                          <Button className='text' type='link' onClick={() => onDownload(ele)} size='small'>Download Profile</Button>
                        </div>
                      </div>
                    </div>
                  </div>)}
                  <div className='col-12'>
                    {listData.length == 0 && <div>
                      <Empty size='large' />
                    </div>}
                  </div>

                  {pagignation && pagignation.total > 10 && <div className='col-12'>
                    <div className='d-f space-between'>
                      <div></div>
                      <div>
                        <Pagination
                          onChange={onCangePagignation}
                          className='center'
                          current={pagignation.current}
                          total={pagignation.total}
                          showSizeChanger
                          onShowSizeChange={(current, pageSize) => onSizeChange(current, pageSize)}
                        />
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default HireDeveloperList;
