// AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../admin/dashboard';
import UploadProfile from '../admin/uplod-profile';
import ProfileList from '../admin/profile-list';
import Jobs from '../admin/Jobs';
import ProfilePage from '../admin/myProfile/ProfilePage';
import Shortlisted from '../admin/shortlisted';
import UploadRequirement from '../admin/Jobs/my-requirments/upload-requirement';
import QuikUploadRequirment from '../admin/Jobs/my-requirments/quik-upload';
import QuikResumeUploadRequirment from '../admin/uplod-profile/quik-upload';
import ManageRequirments from '../admin/manage/requierments';
import ManageProfiles from '../admin/manage/profiles';
import PublicProfiles from '../admin/profile-list/profiles';
import PublicBulkProfiles from '../admin/profile-list/profiles/bulkRequest';
import MyRequirment from '../admin/Jobs/my-requirments';
import BulkRequirments from '../admin/Jobs/my-requirments/bulk';
import ProfileReceived from '../admin/profileRecived';
import RequirmentDetails from '../admin/profileRecived/JobDetails';
import Profilelisting from '../admin/profileRecived/ShortlistedProfiles';
import Resources from '../admin/profiles/Resources';
import TestConfig from '../admin/onlineExam/testConfig';
import TestDashboard from '../admin/onlineExam/dashboard';
import Alloated from '../admin/onlineExam/alloted';
import QuestionBank from '../admin/onlineExam/questionBank';
import Results from '../admin/onlineExam/results';
import Vendors from '../admin/vendors';

const AdminRoutes = ({ pinned }) => {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<Dashboard />} /> */}
        {/* <Route path="/profiles/home" element={<Dashboard />} /> */}
        <Route path="/profiles/developers" element={<PublicProfiles />} />
        <Route path="/profiles/bulk/developers" element={<PublicBulkProfiles />} />
        <Route path="/profiles/shortlisted" element={<Shortlisted />} />
        <Route path="/profiles/upload-profile" element={<UploadProfile pinned={pinned} />} />
        <Route path="/profiles/quik/upload-profile" element={<QuikResumeUploadRequirment />} />
        <Route path="/requirements/openings" element={<Jobs />} />
        <Route path="/requirements/my/openings" element={<MyRequirment />} />
        <Route path="/requirements/bulk/openings" element={<BulkRequirments />} />
        <Route path="/requirements/profile/recived" element={<ProfileReceived />} />
        <Route path="/requirements/details/:id" element={<RequirmentDetails />} />
        <Route path="/requirements/details/:id/profiles" element={<Profilelisting />} />
        <Route path="/profiles/available/resources" element={<Resources />} />

        <Route path="/requirements/add" element={<UploadRequirement />} />
        <Route path="/requirements/quik-upload" element={<QuikUploadRequirment />} />
        <Route path="/my-jobs" element={<Jobs />} />
        <Route path="/my-profile" element={<ProfilePage />} />

        {/* manage */}
        <Route path="/manage/requirements" element={<ManageRequirments />} />
        <Route path="/manage/profiles" element={<ManageProfiles />} />


        {/* manage exam */}
        <Route path="/manage/exam/dashboard" element={<TestDashboard />} />
        <Route path="/manage/exam/testconfig" element={<TestConfig />} />
        <Route path="/manage/exam/alloted" element={<Alloated />} />
        <Route path="/manage/exam/question/bank" element={<QuestionBank />} />
        <Route path="/manage/exam/results" element={<Results />} />


        <Route path='/manage/vendors' element={<Vendors />} />

      </Routes>
    </>
  );
};

export default AdminRoutes;
