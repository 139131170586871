import React, { useEffect } from 'react';
import { Form, Select, Input, Divider, Button, Upload, Modal, message, Row, Col, Space, Rate, Collapse, Card, Tag, Typography, Result, Descriptions } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import MobileFooter from './mobile-footer/mobile.footer';
import { routes } from '../../../../actions/api-routes';
import './profile.css'
import { getData, postData, putData } from '../../../../actions/middleware';
import { useParams } from 'react-router-dom';
import { decryptData, encryptData } from '../../../../actions/crypto';

const { useForm } = Form;

const ApplicantProfile = ({ }) => {
  const { id } = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [experiance, setExperiance] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [resJob, setResData] = useState();

  const [form] = useForm()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup the event listener
    };
  }, []);

  useEffect(() => {
    getResponse(id)
  }, [id])


  const getResponse = async (_id) => {
    const resData = await getData(routes.public.currentJob + _id)
    if (resData) {
      setResData(decryptData(resData.data))
    }
  }

  const onFinish = async (values) => {
    if (!pdfUrl) {
      message.error('Please upload a PDF file before submitting.');
      return;
    } else {
      const skills = resJob?.skils.map(ele => ({ [ele]: values[ele] }));
      const payload = encryptData({ ...values, skills, profileFor: resJob.jobTitle, resume: pdfUrl })
      const resData = await postData(routes.public.currentJob + id, { data: payload })
      if (resData && resData.code == 200) {
        message.success(`Successfully updated`);
        setSuccess(true)
      } else if (resData.code == '301') {
        message.error(resData.message);
      } else {
        message.error(`Something went wrong please try again later`);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showViewModal = () => {
    setViewModalVisible(true);
  };

  const hideViewModal = () => {
    setViewModalVisible(false);
  };

  const beforeUpload = (file) => {
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      message.error('You can only upload PDF files!');
    }
    return isPdf;
  };

  const handleUploadChange = (info) => {
    if (info.file && info.file.response && info.file.response.status === 'SUCCESS') {
      setPdfUrl(info.file.response.files[0]);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      setPdfUrl(null);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const phoneNumberValidator = (_, value) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    if (!value || phoneNumberRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter a valid 10-digit phone number!');
  };


  const handleFileNameClick = () => {
    if (pdfUrl) {
      showViewModal();
    }
  };

  const onSubmitForm = () => {
    form.submit();
  }

  return (
    <>
      <Row gutter={[16, 16]} style={{ margin: 10 }}>
        <Col lg={12} md={12} sm={22} style={{ margin: '0 auto', width: '100%' }}>
          <Collapse
            size="small"
            defaultActiveKey={isMobile ? '' : 'active'}
            items={[{
              key: 'active', label: resJob?.jobTitle?.name + '(JD)', children: <>


                <p dangerouslySetInnerHTML={{ __html: resJob?.jobDescription }} />
                <Space direction='horizontal'>
                  <Typography.Title level={5} type='secondary'>Location : </Typography.Title>
                  <Typography.Title level={5}> {resJob?.workLocation}({resJob?.jobLocationType})</Typography.Title>
                </Space>

                <Typography.Title level={5}>Skills</Typography.Title>
                <Row gutter={[8, 8]}>
                  {resJob?.skils.map(ele => <Col lg={4} md={6} sm={6}><Tag color='blue'>{ele}</Tag></Col>)}
                </Row>

              </>
            }]}
            expandIconPosition='right'
          />
        </Col>
        <Col lg={12} md={12} sm={22} style={{ margin: '0 auto', marginBottom: 100 }}>
          {!success ? <>
            <Card>
              <div>
                <div className='heading-padding'>
                  <h6>Profile</h6>
                  <Button className='button-view d-dextop' onClick={onSubmitForm} type='primary'>Apply</Button>
                </div>
                <Divider className='divider' />
                <div className='row interview-form'>
                  <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout='vertical'
                    scrollToFirstError={true}
                  >
                    <Row gutter={[16, 16]}>
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="First Name"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your first name',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Middle Name"
                          name="middleName"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your middle name',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Last Name"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your last name',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your email!',
                            },
                            {
                              type: "email",
                              message: "Please enter a valid email address!",
                            },
                          ]}
                        >
                          <Input type='email' />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Phone"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your contact number!',
                            },
                            {
                              validator: phoneNumberValidator,
                            },
                          ]}
                        >
                          <Input prefix={'+91'} />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Gender"
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: 'Please select your gender!',
                            },
                          ]}
                        >
                          <Select>
                            <Select.Option value="Male">Male</Select.Option>
                            <Select.Option value="Female">Female</Select.Option>
                            <Select.Option value="Other">Other</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Experience level"
                          name="candidateType"
                          rules={[
                            {
                              required: true,
                              message: 'Please select experience level!',
                            },
                          ]}
                        >
                          <Select onChange={setExperiance}>
                            <Select.Option value="Intern">Intern</Select.Option>
                            <Select.Option value="Fresher">Fresher</Select.Option>
                            <Select.Option value="Experience">Experience</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Internship Experiance"
                          name="internshipExperiance"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your internship experiance!',
                            },
                          ]}
                        >
                          <Input type='number' suffix={'Years'} />
                        </Form.Item>
                      </Col>
                      {experiance == 'Experience' && <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Total Experiance"
                          name="experiance"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter total experiance!',
                            },
                          ]}
                        >
                          <Input type='number' suffix={'Years'} />
                        </Form.Item>
                      </Col>}
                      {experiance == 'Experience' && <>
                        <Col lg={8} md={12} sm={24}>
                          <Form.Item
                            label="Current CTC"
                            name="currentCTC"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter CTC!',
                              },
                            ]}
                          >
                            <Input type='number' prefix={'INR'} suffix={'LPA'} />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} sm={24}>
                          <Form.Item
                            label="Expected CTC"
                            name="expectedCTC"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter expected CTC!',
                              },
                            ]}
                          >
                            <Input type='number' prefix={'INR'} suffix={'LPA'} />
                          </Form.Item>
                        </Col>
                      </>}
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Notice Periods"
                          name="noticePeriod"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter notice period!',
                            },
                          ]}
                        >
                          <Input type='number' suffix={'Days'} />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label="Upload Resume"
                          name="pdf"
                          rules={[
                            {
                              required: true,
                              message: 'Please upload a PDF file!',
                            },
                          ]}
                        >
                          <Upload
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            onChange={handleUploadChange}
                            action={`${routes.fileServer}/api/upload/add`}
                            showUploadList={false}
                          >
                            <Button icon={<UploadOutlined />}>
                              Upload PDF
                            </Button>
                          </Upload>
                        </Form.Item>

                        {pdfUrl && (
                          <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={handleFileNameClick}>
                            {pdfUrl.split('/').pop()}
                          </span>
                        )}
                      </Col>
                      <Col lg={8} md={12} sm={24}></Col>
                      <Col span={24}>
                        <Divider orientation='left'>Skills</Divider>
                      </Col>
                      {resJob?.skils.map(ele => <Col lg={8} md={12} sm={24}>
                        <Form.Item
                          label={ele}
                          name={ele}
                          rules={[
                            {
                              required: true,
                              message: 'Please rate yourself out of 10',
                            },
                          ]}
                        >
                          <Rate
                            count={10}
                          ></Rate>
                        </Form.Item>
                      </Col>)}
                    </Row>
                    <Modal
                      title="View PDF"
                      open={viewModalVisible}
                      onCancel={hideViewModal}
                      footer={null}
                      destroyOnClose
                    >
                      <iframe title="PDF Viewer" src={routes.fileServer + pdfUrl} style={{ width: '100%', height: '500px' }} />
                    </Modal>
                  </Form>
                </div>
              </div>
            </Card>
            <MobileFooter nextButtonText={'Apply'} next={onSubmitForm} />
          </>
            : <Result
              status="success"
              title="Application Submitted Successfully"
              subTitle="Our HR team will reach out to you shortly!"
            />}
        </Col>
      </Row>
    </>
  );
};

export default ApplicantProfile;
