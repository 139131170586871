import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Row } from 'antd';
const DrawerView = ({ isVisible, width, onClose, children, title, onSave, isView }) => {

  return (
    <>
      <Drawer
        title={title}
        placement="right"
        onClose={() => onClose(false)}
        open={isVisible}
        width={width ? width : 800}
        maskClosable={false}
        footer={
          !isView ? <div className='footer-buttons' style={{ justifyContent: 'flex-end' }}>
            <Button type="dashed" onClick={() => onClose(false)} size='middle'>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onSave}
              size='middle'>
              Save
            </Button>
          </div>
            : false
        }
      >
        {children}
      </Drawer >
    </>
  );
};
export default DrawerView;