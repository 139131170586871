import React, { useState, useEffect, useRef } from "react";

import Editor from "@monaco-editor/react";
import './index.css';
import { Col, Row, Select } from "antd";
import Secotions from "./sections";
import QuestionList from "./Questions/questions";

function OnlineExam() {
  return <>
    <QuestionList />
  </>
}

export default OnlineExam;
