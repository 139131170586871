import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Avatar, Button, Col, Pagination, Radio, Row, Table, Tabs, Tag, Image } from 'antd';
import { useDispatch } from 'react-redux';
import './index.css';
import { ShoppingFilled } from '@ant-design/icons';
import { routes } from '../../../../actions/api-routes';

const Details = ({ job }) => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='box-setting'>
            <Row gutter={[16, 16]}>
                <Col span={16}>
                    <div className='job-details' style={{ maxHeight: windowHeight - 240, overflowY: 'auto' }}>
                        <Row>
                            <Col span={16}>
                                <div className='jd-box'>
                                    {job?.typeOfRequirment == 'Text' && <div dangerouslySetInnerHTML={{ __html: job?.jobDescription }}>
                                    </div>}
                                    {job?.typeOfRequirment == "Image" && <div>
                                        <Image style={{ width: '100%', height: '100%' }} src={routes.fileServer + job?.requirmentFile} />
                                    </div>}
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className=''>
                                    <div className='heading-2'>Employment Type</div>
                                    <div>{job?.employmentType}</div>
                                </div>
                                <div className=''>
                                    <div className='heading-2'>Skills</div>
                                    <div>
                                        {job?.skils.map(ele => <Tag color='green' >{ele}</Tag>)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='job-details'>
                        <div className='text-center heading-1'>Job Performance</div>
                        <div className='profiles-recived-view'>
                            <div>
                                <div className='heading-1'>20</div>
                                <div>Profile Recived</div>
                            </div>
                            <div>
                                <div className='heading-1'>30</div>
                                <div>System Shared</div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Details;
