import React, { useState, useEffect, useRef } from "react";
import './index.css';
import { Button, Col, Input, Row, Select, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import ResendOTPButton from "./ResendOTP";
import routing from "../routing";
const { Title } = Typography;

function HomeView() {
  const navigate = useNavigate();

  const onVerify = () => {
    navigate(routing.about_test)
  }

  const onChange = (text) => {
    console.log('onChange:', text);
  };

  const handleResendClick = () => {
    console.log('Resend OTP logic here');
  }

  return <>
    <div className="verify-email">
      <Row>
        <Col span={12}>
          <div className="left-side">
            <img className="left-top" src={require('./../../assets/images/onlineTest/left-top.png')} />
            <img className="left-bottom" src={require('./../../assets/images/onlineTest/left-bottom.png')} />
          </div>
          <div className="otp-box">
            <Space direction="vertical" align="center">
              <Title level={3}>Verify Email</Title>
              <Title level={5}>A 6 digit code send via email on ska...@gmail.com</Title>
              <Input.OTP length={6} onChange={onChange} />
              <div className="resend-time">
                <ResendOTPButton onClick={handleResendClick} disabled={false} />
              </div>
              <Button className="verify-button" type="primary" onClick={onVerify}>Verify</Button>
            </Space>
          </div>
        </Col>
        <Col span={12}>
          <div className="right-view">
            <div className="image-box">
              <img src={require('./../../assets/images/onlineTest/home-page.png')} />
              <div className="text-display">
                Before starting the test, you need to read all the instructions carefully and give sufficient time for each question. There is no negative marking, but do not miss any single question; it will help your result to clear this test.
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </>
}

export default HomeView;
