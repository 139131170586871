import { useEffect } from 'react';
import './index.css';
import SlotBookingForm from './SlotBookingForm';
function TrainingPlacement() {

  return (
    <div>
      <div className="breadcrumb-area breadcrumb-area-extra">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title">Book your Seat </h2>
                <ul className="breadcrumb-list">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item active">training-placement </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div className="bg-white">
            <div className="feature-images-wrapper bg-gray section-space--ptb_100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SlotBookingForm/>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-delivering-wrapper section-space--ptb_100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-wrap text-center section-space--mb_20">
                      <h3 className="heading">Course cover</h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="delivering-optimal-wrap">
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">Basic Concept </h5>
                          <div className="desc">Clarify your C, C++, HTML, CSS, JS basics. Build a strong foundation for programming and web development skills from the start.</div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">Data Security </h5>
                          <div className="desc">Back up your database, store in a safe and secure place while still maintaining its accessibility. </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">Infrastructure Plan </h5>
                          <div className="desc">Mitech takes into account all conditions and budgets needed for building infrastructure plan. </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="delivering-optimal-wrap">
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">Start working on Live Project </h5>
                          <div className="desc">Once your basic concepts are clear, you'll receive a project. Working on tasks will build confidence on your journey. </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">Business Reform</h5>
                          <div className="desc">We propose feasible & practical plans for successfully transform businesses based on their needs. </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">Firewall Advancement </h5>
                          <div className="desc">Enhancing the strength and security of firewalls to protect online data from malicious sources. </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-resources-wrapper">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-lg-6 order-2 order-lg-1">
                    <div className="resources-left-box">
                      <div className="resources-inner">
                        <h6 className="sub-title mb-20">Resources</h6>
                        <h3 className="heading">Get a copy of brochure on <span className="text-color-primary"> Brand New IT Tech.</span></h3>
                        <div className="button mt-30">
                          <a href="#" className="ht-btn ht-btn-md">Download now (3MB)</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="resources-right-box">
                      <div className="resources-right-inner text-center">
                        <div className="resources-images">
                          <img className="img-fluid" src={require("../../assets/images/banners/managed-it-services-book-cover.webp")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrainingPlacement;
