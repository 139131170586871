import { Link, useNavigate } from 'react-router-dom';
import './index.css';
import { useEffect, useState } from 'react';
import { getUserData } from '../../actions/auth';
import { HireDevelopers } from './technologies';

function Header() {
  const [userName, setUserName] = useState();
  const navigate = useNavigate()

  useEffect(() => {
    getUserDetails();
  }, [])

  function getInitiative(name) {
    const words = name.split(' ');

    if (words.length === 1) {
      if (name.length <= 12) {
        return name;
      } else {
        const firstChar = name[0];
        const lastChar = name[name.length - 1];
        const middleChars = '...';
        return `${firstChar}${middleChars}${lastChar}`;
      }
    } else {
      return words.map(word => word[0]).join('');
    }
  }

  const getUserDetails = () => {
    const data = getUserData();
    if (data) {
      if (data.loginType == "Organisation") {
        setUserName(getInitiative(data.companyName))
      } else {
        setUserName(getInitiative([data.firstName, data.lastName].join(' ')))
      }
    }
  }

  return (
    <>
      <div className="preloader-activate preloader-active open_tm_preloader">
        <div className="preloader-area-wrap">
          <div className="spinner d-flex justify-content-center align-items-center h-100">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
      <div className="header-area">
        <div className="header-bottom-wrap header-sticky bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="header position-relative">
                  <div className="header__logo">
                    <a href="/" >
                      <img src={require("../../assets/images/logo/logov1.png")} aria-label="Logo" width="160" height="48" className="img-fluid" alt="" />
                    </a>
                  </div>

                  <div className="search-overlay" id="search-overlay">
                    <div className="search-overlay__header">
                      <div className="container-fluid">
                        <div className="row align-items-center">
                          <div className="col-md-6 ms-auto col-4">
                            <div className="search-content text-end">
                              <span className="mobile-navigation-close-icon" id="search-close-trigger"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="search-overlay__inner">
                      <div className="search-overlay__body">
                        <div className="search-overlay__form">
                          <form action="#">
                            <input type="text" placeholder="Search" />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="header-right">
                    <div className="header__navigation menu-style-three d-none d-xl-block">
                      <nav className="navigation-menu">
                        <ul>
                          <li className="">
                            <a href="/" ><span>Home</span></a>
                          </li>
                          <li className="has-children">
                            <a href="#"><span>Services</span></a>
                            <ul className="megamenu megamenu--mega">
                              <li>
                                <h2 className="page-list-title">Software Development</h2>
                                <ul>
                                  <li><a href={undefined} onClick={() => navigate('/services')}><span>Web & Mobile App Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>Custom Software Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>Software Development Costs</span></a></li>
                                  <li><a href="element-accordion.html"><span>Software Development Automation</span></a></li>
                                  <li><a href="element-accordion.html"><span>Secure Software Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>Agile Consulting Services</span></a></li>
                                  <li><a href="element-accordion.html"><span>Custom API Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>GDPR Compliance in Software Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>Embedded Software Development</span></a></li>
                                </ul>
                              </li>
                              <li>
                                <h2 className="page-list-title">Cloud Services</h2>
                                <ul>
                                  <li><a href="element-accordion.html"><span>Amazon Web Services (AWS)</span></a></li>
                                  <li><a href="element-accordion.html"><span>Google Cloud Platform</span></a></li>
                                  <li><a href="element-accordion.html"><span>Microsoft Azure</span></a></li>
                                  <li><a href="element-accordion.html"><span>Alibab Cloud</span></a></li>
                                  <li><a href="element-accordion.html"><span>Dropbox</span></a></li>
                                  <li><a href="element-accordion.html"><span>Salesforce</span></a></li>
                                  <li><a href="element-accordion.html"><span>DigitalOcean</span></a></li>
                                  <li><a href="element-accordion.html"><span>SAP</span></a></li>
                                  <li><a href="element-accordion.html"><span>Microsoft</span></a></li>
                                  <li><a href="element-accordion.html"><span>Oracle Cloud</span></a></li>
                                </ul>
                              </li>
                              <li>
                                <h2 className="page-list-title">Offshore Development</h2>
                                <ul>
                                  <li><a href="element-accordion.html"><span>Software Product Engineering</span></a></li>
                                  <li><a href="element-accordion.html"><span>Saas Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>Web Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>Frontend Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>UI/UX Design</span></a></li>
                                  <li><a href="element-accordion.html"><span>Mobile App Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>Automation Services (RPA)</span></a></li>
                                  <li><a href="element-accordion.html"><span>DevOps Services</span></a></li>
                                </ul>
                              </li>
                              <li>
                                <h2 className="page-list-title">Other Services</h2>
                                <ul>
                                  <li><a href="element-accordion.html"><span>Analytics & Insights</span></a></li>
                                  <li><a href="element-accordion.html"><span>Automation & AI</span></a></li>
                                  <li><a href="element-accordion.html"><span></span>Blockchain</a></li>
                                  <li><a href="element-accordion.html"><span>Cloud Services</span></a></li>
                                  <li><a href="element-accordion.html"><span>Cognitive Business Oprations</span></a></li>
                                  <li><a href="element-accordion.html"><span>Consulting</span></a></li>
                                  <li><a href="element-accordion.html"><span>Cyber Security</span></a></li>
                                  <li><a href="element-accordion.html"><span>Enterprise Applications</span></a></li>
                                  <li><a href="element-accordion.html"><span>IOT & Digital Engineering</span></a></li>
                                  <li><a href="element-accordion.html"><span>Quality Engineering</span></a></li>
                                </ul>
                              </li>
                              <li>
                                <h2 className="page-list-title"></h2>
                                <ul>
                                  <li><a href="element-accordion.html"><span>Accelgrowth Intractive</span></a></li>
                                  <li><a href="element-accordion.html"><span>Power BI Services</span></a></li>
                                  <li><a href="element-accordion.html"><span>Web Services</span></a></li>
                                  <li><a href="element-accordion.html"><span>Offshore Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>Product Engineering</span></a></li>
                                  <li><a href="element-accordion.html"><span>Strategic IT Services</span></a></li>
                                  <li><a href="element-accordion.html"><span>Research and Development</span></a></li>
                                  <li><a href="element-accordion.html"><span>IT Staff Augmentation</span></a></li>
                                  <li><a href="element-accordion.html"><span>Large-Scale Software Development</span></a></li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li className="has-children">
                            <a href="#"><span>Solutions</span></a>
                            <ul className="megamenu megamenu--mega">
                              <li>
                                <h2 className="">Solutions</h2>
                                <ul>
                                  <li>
                                    We build on the IT domain expertise and industry knowledge to design sustainable technology solutions.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h2 className="page-list-title">Solutions</h2>
                                <ul>
                                  <li><a href="element-accordion.html"><span>CRM</span></a></li>
                                  <li><a href="element-accordion.html"><span>Marketing & Advertising</span></a></li>
                                  <li><a href="element-accordion.html"><span>Human Resources</span></a></li>
                                  <li><a href="element-accordion.html"><span>ELearning</span></a></li>
                                  <li><a href="element-accordion.html"><span>Doccument Management</span></a></li>
                                  <li><a href="element-accordion.html"><span>Supply Chain Management</span></a></li>
                                  <li><a href="element-accordion.html"><span>Fleet Management</span></a></li>
                                  <li><a href="element-accordion.html"><span>Kiosk Software</span></a></li>
                                </ul>
                              </li>
                              <li>
                                <h2 className="page-list-title"></h2>
                                <ul>
                                  <li><a href="element-accordion.html"><span>ERP</span></a></li>
                                  <li><a href="element-accordion.html"><span>Opration Management</span></a></li>
                                  <li><a href="element-accordion.html"><span>Financial Management</span></a></li>
                                  <li><a href="element-accordion.html"><span>Asset Management</span></a></li>
                                  <li><a href="element-accordion.html"><span>Project Management</span></a></li>
                                  <li><a href="element-accordion.html"><span>Data Analytics</span></a></li>
                                  <li><a href="element-accordion.html"><span>Ecommerce</span></a></li>
                                  <li><a href="element-accordion.html"><span>Web Portals</span></a></li>
                                  <li><a href="element-accordion.html"><span>CMS Development</span></a></li>
                                </ul>
                              </li>
                              <li>
                                <h2 className="page-list-title">We involved In</h2>
                                <ul>
                                  <li><a href="element-accordion.html"><span>Healthcare</span></a></li>
                                  <li><a href="element-accordion.html"><span>Banking, Financial Services and Insurance</span></a></li>
                                  <li><a href="element-accordion.html"><span>Manufacturing</span></a></li>
                                  <li><a href="element-accordion.html"><span>Professional Services</span></a></li>
                                  <li><a href="element-accordion.html"><span>Retail</span></a></li>
                                  <li><a href="element-accordion.html"><span>Transportation and Logistics</span></a></li>
                                  <li><a href="element-accordion.html"><span>Telecommunications</span></a></li>
                                  <li><a href="element-accordion.html"><span>Oil and Gas</span></a></li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li className="has-children">
                            <a href="#"><span>Hire Developers</span></a>
                            <ul className="megamenu megamenu--mega">
                              {HireDevelopers.menu.map((ele, ind) => <li key={ind}>
                                {ele.list.map((subTitle, _index) => <>
                                  <h2 className="page-list-title" key={_index + ind + _index}>{subTitle?.title}</h2>
                                  {subTitle.list.map((item, index) => <ul key={index + ind + _index}>
                                    <li><a href={`${item.link}${item.name.split(' ').join('_')}`}><span>{item.name}</span></a></li>
                                  </ul>)}
                                </>)}
                              </li>)}
                            </ul>
                          </li>
                          <li className="has-children has-children--multilevel-submenu">
                            <a href="#"><span>Company</span></a>
                            <ul className="submenu">
                              <li >
                                <a href="/about-us"><span>About us</span></a>
                              </li>
                              <li>
                                <a href="/career"><span>Career</span></a>
                              </li>
                              <li><a href="/contact-us"><span>Contact us</span></a></li>
                              <li><a href="leadership.html"><span>Leadership</span></a></li>
                              <li><a href="why-choose-us.html"><span>Why choose us</span></a></li>
                              <li><a href="our-history.html"><span>Our history</span></a></li>
                              <li><a href="faqs.html"><span>FAQs</span></a></li>
                            </ul>
                          </li>
                          {!userName && <li className="">
                            <Link to="/auth/login"><span>Login as Vendor</span></Link>
                          </li>}

                          {userName && <li className="has-children has-children--multilevel-submenu">
                            <a href="#"><span style={{ textTransform: 'capitalize' }}>{userName}</span></a>
                            <ul className="submenu">
                              <li >
                                <Link to="/admin"><span>Dashboard</span></Link>
                              </li>
                              <li>
                                <Link to="/profile"><span>Profile</span></Link>
                              </li>
                              <li><a href="#"><span>Logout</span></a></li>
                            </ul>
                          </li>}
                        </ul>
                      </nav>
                    </div>
                    <div className="mobile-navigation-icon d-block d-xl-none" id="mobile-menu-trigger">
                      <i></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href="#" className="scroll-top" id="scroll-top">
        <i className="arrow-top fas fa-chevron-up"></i>
        <i className="arrow-bottom fas fa-chevron-up"></i>
      </a>
      <a id="whatsapp-icon" className="whatsapp-icon show" href="https://wa.me/+919309569094?text=Hi Accelgrowth" target="_blank" title="Chat with us on WhatsApp">
        <i className="arrow-top fab fa-whatsapp"></i>
        <i className="arrow-bottom fab fa-whatsapp"></i>
      </a>
      <div className="mobile-menu-overlay" id="mobile-menu-overlay">
        <div className="mobile-menu-overlay__inner">
          <div className="mobile-menu-overlay__header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-6 col-8">
                  <div className="logo">
                    <a href="index.html">
                      <img src={require("../../assets/images/logo/logov1.png")} className="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-4">
                  <div className="mobile-menu-content text-end">
                    <span className="mobile-navigation-close-icon" id="mobile-menu-close-trigger"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu-overlay__body">
            <nav className="offcanvas-navigation">
              <ul>
                <li >
                  <a href="index.html">Home</a>
                </li>
                <li className="has-children">
                  <a href="#">Company</a>
                  <ul className="sub-menu">
                    <li className="has-children">
                      <a href="about-us-01.html"><span>About us</span></a>
                      <ul className="sub-menu">
                        <li><a href="about-us-01.html"><span>About us 01</span></a></li>
                        <li><a href="about-us-02.html"><span>About us 02</span></a></li>
                        <li className="has-children">
                          <a href="#"><span>Submenu Level Two</span></a>
                          <ul className="sub-menu">
                            <li><a href="#"><span>Submenu Level Three</span></a></li>
                            <li><a href="#"><span>Submenu Level Three</span></a></li>
                            <li><a href="#"><span>Submenu Level Three</span></a></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li><a href="contact-us.html"><span>Contact us</span></a></li>
                    <li><a href="leadership.html"><span>Leadership</span></a></li>
                    <li><a href="why-choose-us.html"><span>Why choose us</span></a></li>
                    <li><a href="our-history.html"><span>Our history</span></a></li>
                    <li><a href="faqs.html"><span>FAQs</span></a></li>
                    <li><a href="careers.html"><span>Careers</span></a></li>
                    <li><a href="pricing-plans.html"><span>Pricing plans</span></a></li>
                  </ul>
                </li>
                <li className="has-children">
                  <a href="#">IT solutions</a>
                  <ul className="sub-menu">
                    <li><a href="it-services.html"><span>IT Services</span></a></li>
                    <li><a href="managed-it-service.html"><span>Managed IT Services</span></a></li>
                    <li><a href="industries.html"><span>Industries</span></a></li>
                    <li><a href="business-solution.html"><span>Business solution</span></a></li>
                    <li><a href="it-services-details.html"><span>IT Services Details</span></a></li>
                  </ul>
                </li>
                <li className="has-children">
                  <a href="#">Elements</a>
                  <ul className="sub-menu">
                    <li className="has-children">
                      <a href="#">Element Group 01</a>
                      <ul className="sub-menu">
                        <li><a href="element-accordion.html"><span>Accordions & Toggles</span></a></li>
                        <li><a href="element-box-icon.html"><span>Box Icon</span></a></li>
                        <li><a href="element-box-image.html"><span>Box Image</span></a></li>
                        <li><a href="element-box-large-image.html"><span>Box Large Image</span></a></li>
                        <li><a href="element-buttons.html"><span>Buttons</span></a></li>
                        <li><a href="element-cta.html"><span>Call to action</span></a></li>
                        <li><a href="element-client-logo.html"><span>Client Logo</span></a></li>
                        <li><a href="element-countdown.html"><span>Countdown</span></a></li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="#">Element Group 02</a>
                      <ul className="sub-menu">
                        <li><a href="element-counters.html"><span>Counters</span></a></li>
                        <li><a href="element-dividers.html"><span>Dividers</span></a></li>
                        <li><a href="element-flexible-image-slider.html"><span>Flexible image slider</span></a></li>
                        <li><a href="element-google-map.html"><span>Google Map</span></a></li>
                        <li><a href="element-gradation.html"><span>Gradation</span></a></li>
                        <li><a href="element-instagram.html"><span>Instagram</span></a></li>
                        <li><a href="element-lists.html"><span>Lists</span></a></li>
                        <li><a href="element-message-box.html"><span>Message box</span></a></li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="#">Element Group 03</a>
                      <ul className="sub-menu">
                        <li><a href="element-popup-video.html"><span>Popup Video</span></a></li>
                        <li><a href="element-pricing-box.html"><span>Pricing Box</span></a></li>
                        <li><a href="element-progress-bar.html"><span>Progress Bar</span></a></li>
                        <li><a href="element-progress-circle.html"><span>Progress Circle</span></a></li>
                        <li><a href="element-rows-columns.html"><span>Rows & Columns</span></a></li>
                        <li><a href="element-social-networks.html"><span>Social Networks</span></a></li>
                        <li><a href="element-tabs.html"><span>Tabs</span></a></li>
                        <li><a href="element-team-member.html"><span>Team member</span></a></li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="#">Element Group 04</a>
                      <ul className="sub-menu">
                        <li><a href="element-testimonials.html"><span>Testimonials</span></a></li>
                        <li><a href="element-timeline.html"><span>Timeline</span></a></li>
                        <li><a href="element-carousel-sliders.html"><span>Carousel Sliders</span></a></li>
                        <li><a href="element-typed-text.html"><span>Typed Text</span></a></li>
                      </ul>
                    </li>

                  </ul>
                </li>
                <li className="has-children">
                  <a href={undefined}>Case Studies</a>
                  <ul className="sub-menu">
                    <li><a href="case-studies.html"><span>Case Studies 01</span></a></li>
                    <li><a href="case-studies-02.html"><span>Case Studies 02</span></a></li>
                    <li><a href="single-smart-vision.html"><span>Single Layout</span></a></li>
                  </ul>
                </li>
                <li className="has-children">
                  <a href={undefined}>Blogs</a>
                  <ul className="sub-menu">
                    <li><a href="blog-list-large-image.html"><span>List Large Image</span></a></li>
                    <li><a href="blog-list-left-large-image.html"><span>Left Large Image</span></a></li>
                    <li><a href="blog-grid-classic.html"><span>Grid Classic</span></a></li>
                    <li><a href="blog-grid-masonry.html"><span>Grid Masonry</span></a></li>
                    <li className="has-children">
                      <a href="blog-post-layout-one.html"><span>Single Layouts</span></a>
                      <ul className="sub-menu">
                        <li><a href="blog-post-layout-one.html"><span>Left Sidebar</span></a></li>
                        <li><a href="blog-post-right-sidebar.html"><span>Right Sidebar</span></a></li>
                        <li><a href="blog-post-no-sidebar.html"><span>No Sidebar</span></a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="search-overlay" id="search-overlay">
        <div className="search-overlay__header">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6 ms-auto col-4">
                <div className="search-content text-end">
                  <span className="mobile-navigation-close-icon" id="search-close-trigger"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-overlay__inner">
          <div className="search-overlay__body">
            <div className="search-overlay__form">
              <form action="#">
                <input type="text" placeholder="Search" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
