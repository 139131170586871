import React from 'react';
import { Image, Tag } from 'antd';
import './index.css';
import { routes } from '../../../../actions/api-routes';
import { postData } from '../../../../actions/middleware';
import { timeAgo } from '../../../common/functions';
import { encryptData } from '../../../../actions/crypto';

const Developerprofiles = ({ style, item, onSelectDeveloper, selected }) => {

    const onSelect = (item) => {
        onSelectDeveloper(item)
        if (!item.isRead) {
            onRead()
        }
    }

    console.log("itemitemitem", item)

    const onRead = async () => {
        await postData(routes.applied.read, { data: encryptData({ profile: item._id, data: { isRead: true } }) });
    }

    const getClass = () => {
        let classObject = ['profile-box']
        if (selected && item._id == selected._id) {
            classObject.push['profile-active']
        }
        if (!item?.isRead) {
            classObject.push['unread']
        }

        return classObject.join(' ');
    }

    const getStatus = () => {
        let status = '';
        if (item?.approvalStatus == 'SHORTLITED') {
            status = "Shortlisted";
        } else if (item?.approvalStatus == 'REJECTED') {
            status = "Rejected";
        }
        return status;
    }

    return (
        <div className={getClass()} style={style} onClick={() => onSelect(item)}>
            <div className='d-f justify-between'>
                <div>
                    <div className='job-title-view'>
                        <div className='img-avatar-developer'>
                            <Image src={item?.developerProfiles?.photo ? routes.fileServer + item?.developerProfiles?.photo : require('./../../../../assets/images/logo/logo-w.png')}
                                onError={(e) => { e.target.src = require('./../../../../assets/images/logo/logo-w.png') }}
                            />
                        </div>
                        <div className='heading-view-for-rol padding-left'>
                            <div className='job-title-box'>
                                <div className='main-text'>{[item?.developerProfiles?.firstName, item?.developerProfiles?.lastName].join(' ')}</div>
                                <div className='sub-text'>{item?.developerProfiles?.Experiance}+ Year</div>
                            </div>
                            <div className='price-view'>{item?.developerProfiles?.JobTitle}</div>
                            <div className='price-view'>
                                <Tag color='magenta' bordered={false}>{item?.developerProfiles?.locations?.join(' | ')}</Tag>
                            </div>
                            {getStatus() && <div className='price-view d-f'>
                                <div className={item?.approvalStatus == 'SHORTLITED' ? 'shortlisted' : 'rejected'}>{getStatus()}</div> . {timeAgo(item?.statusChangeAt)}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Developerprofiles;
