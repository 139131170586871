import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RequestForm = () => {
  const [text, setText] = useState('');

  const handleTextChange = (value) => {
    setText(value);
  };

  const onFinish = (values) => {
    console.log('Received values:', values);
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            label="Enter Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter your name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        {/* Email */}
        <Col span={6}>
          <Form.Item
            label="Enter Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email!',
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        {/* Phone number */}
        <Col span={6}>
          <Form.Item
            label="Enter Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please enter your phone number!',
                pattern: /^[0-9\b]+$/,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        {/* <Col span={18}>
          <Form.Item label="Job Discriptions (Optional)" name={"jobDiscriptions"}>
            <ReactQuill
              value={text}
              onChange={handleTextChange}
              placeholder="Enter the job description here so we can help you onboard developers faster."
            />
          </Form.Item>
        </Col> */}
        <Col span={6}>
          <div className='center-box'>
            <div className='center-text'>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Book Screening
                </Button>
              </Form.Item>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default RequestForm;
