import { useEffect, useState } from 'react';
import './index.css';
import { Tag, Progress, Pagination, Input } from 'antd';
const { Search } = Input;
import { Link, useNavigate } from 'react-router-dom';
import { getData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import moment from 'moment';
import Skills from './showSkils';
import { getUserData } from '../../../actions/auth';

function Careers() {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState({});
  const [listData, setListData] = useState();
  const [pagination, setPagination] = useState();
  const [userData, setUserData] = useState(null);


  const navigate = useNavigate();

  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const getFilter = (text) => {
    return filter[text] ? 'blue' : '';
  }

  const getID = (jobData) => {
    return `AGJ${jobData.jobId}-${jobData.jobTitle.replace(/\s+/g, '_')}`;
  }

  useEffect(() => {
    getJobs();
    const userData = getUserData();
    setUserData(userData)
  }, [])

  const getJobs = async (pageNumber = 1) => {
    const resData = await getData(routes.public.jobList, { current: pageNumber })
    if (resData && resData.code == 200) {
      setListData(resData.data)
      setPagination(resData.pagination)
    }
  }

  return (
    <div>
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title">Career</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div className="careers-experts-wrapper pt-10">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 m-auto">
                  <div className="section-title-wrap text-center section-space--mb_30">
                    <h5 className="heading">Become a part of our big family to inspire and get inspired by <span className="text-color-primary"> professional experts.</span></h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="projects-wrap">
                    <div className='row section-space--mb_30'>
                      <div className="col-9">
                        {/* <div className='filter'>
                          <div>
                            Filter :
                          </div>
                          <div className='tags'>
                            <Tag color={getFilter('Full_Time')} onClick={() => setFilter({ ...filter, Full_Time: !filter['Full_Time'] })} className='click'>Full Time</Tag>
                            <Tag color={getFilter('Contract')} onClick={() => setFilter({ ...filter, Contract: !filter['Contract'] })} className='click'>Contract</Tag>
                            <Tag color={getFilter('Remote')} onClick={() => setFilter({ ...filter, Remote: !filter['Remote'] })} className='click'>Remote</Tag>
                            <Tag color={getFilter('Onsite')} onClick={() => setFilter({ ...filter, Onsite: !filter['Onsite'] })} className='click'>Onsite</Tag>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-3">
                        <Search placeholder="Search Jobs" className='searchClass' onSearch={onSearch} />
                      </div>
                    </div>
                    <div className="row">
                      {listData && listData.map((ele, key) => <div key={key} className="col-lg-4 col-md-6">
                        <Link to={`/job-details?job=${getID(ele)}`} className="projects-wrap style-04 wow move-up">
                          <div className="projects-image-box">
                            <div className="content">
                              <div className='dates'>
                                <div>
                                  <Tag color="#108ee9" className='text-c'>{ele.employmentType}</Tag>
                                </div>
                                <div>
                                  Apply before - {moment(ele.validTill).format('DD MMM YYYY')}
                                </div>
                              </div>
                              <h6 className="heading text-c ellipsis">{ele?.jobTitle}, {ele.experiance}+ years</h6>
                              <div>
                                <Skills ele={ele} />
                              </div>
                              <div>
                                <div className='text-c'>{[ele.jobLocationType, ele.workLocation].join(', ')}</div>
                              </div>
                              {
                                userData && userData._id == ele.addedByVendor && <div>
                                  <br/>
                                  <p className='lable'>This job added by you or your organization</p>
                                </div>
                              }
                              {userData && userData._id !== ele.addedByVendor && <div>
                                <Progress percent={0} size="small" />
                                <p className='lable'>Match</p>
                              </div>}
                            </div>
                          </div>
                        </Link>
                      </div>)}
                    </div>
                    <div className='text-center'>
                      {pagination && pagination.total > 14 && <Pagination total={pagination.total} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default Careers;
