import React, { useState } from 'react';
import { Form, Input, Button, Space, Select, Row, Col } from 'antd';
const { Option } = Select;
import { MinusCircleOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import DynamicSelect from '../../common/Select';
import { useEffect } from 'react';
import { putData } from '../../../actions/middleware';
import { encryptData } from '../../../actions/crypto';

const SkillForm = ({ data, onSubmitFromHeader, onClear, nextCall }) => {
  const [form] = Form.useForm();
  const [skills, setSkills] = useState([{ skillName: '', years: '', months: '' }, { skillName: '', years: '', months: '' }, { skillName: '', years: '', months: '' }]);

  useEffect(() => {
    if (!data) {
      nextCall(0)
    } else {
      form.setFieldsValue(data && data.skills.length >= 3 ? data : {})
    }
  }, [data])

  useEffect(() => {
    if (onSubmitFromHeader == 'SKILLS') {
      form.submit();
      onClear();
    }
  }, [onSubmitFromHeader])


  const onFinish = async (values) => {
    console.log('Received values:', values);
    try {
      const resData = await putData(`/vendor/developers/${encryptData(data._id)}`, { data: encryptData({ ...values }) });
      if (resData && resData.code == 200) {
        nextCall(3)
      } else {
        error('Fix Error', resData.message)
      }
    } catch (_error) { 
      error('Fix Error', _error.message)
    }
  };

  const handleRemove = (index) => {
    const newSkills = [...skills];
    newSkills.splice(index, 1);
    setSkills(newSkills);
  };

  return (
    <>

      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        layout='vertical'
      >
        <Row span={16} justify={'space-around'}>
          <Col span={22}>
            <div className='form-view'>
              <div>
                <h6 className='heading'>Add your programming languages and frameworks</h6>
                <p className='sub-heading'>These will help us match you with relevant work, more skills are better</p>
              </div>
              <Form.List
                name="skills"
                initialValue={skills}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 0 }} align="baseline">

                        <DynamicSelect
                          {...restField}
                          label={"Skill name"}
                          name={[name, 'skillName']}
                          fieldKey={[fieldKey, 'skillName']}
                          keyName={"skillName"}
                          rules={[{ required: true, message: 'Please enter the skill name' }]}
                          style={{ width: '200px' }}
                        />
                        <Form.Item
                          {...restField}
                          label={"Years"}
                          name={[name, 'years']}
                          fieldKey={[fieldKey, 'years']}
                          rules={[{ required: true, message: 'Please enter the years' }]}
                        >
                          <Input placeholder="Years" type="number" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={"Months"}
                          name={[name, 'months']}
                          fieldKey={[fieldKey, 'months']}
                          rules={[{ required: true, message: 'Please enter the months' }]}
                        >
                          <Input placeholder="Months" type="number" />
                        </Form.Item>
                        <div className='form-buttons'>
                          {index >= 3 ? (
                            <MinusCircleOutlined onClick={() => { remove(name); handleRemove(fields.findIndex((field) => field.name === name)); }} />
                          ) : null}
                          {' '}
                          {fields.length - 1 == index ? <PlusCircleOutlined onClick={() => add()} /> : <></>}
                        </div>
                      </Space>
                    ))}
                  </>
                )}

              </Form.List>
              <Row span={16}>
                <Col span={9}>
                  <DynamicSelect
                    multiple={true}
                    label={"Add your soft skills"}
                    name={'softSkills'}
                    keyName={"softSkills"}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* <Row gutter={16} className='botton-footer'>
          <Col span={2}>
            <Form.Item>
              <Button type="dashed" size='middle' onClick={() => nextCall(1)}>
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button type="primary" className='float-right' htmlType="submit" size='middle'>
                Save & Next
              </Button>
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </>
  );
};

export default SkillForm;
