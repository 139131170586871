import React, { useEffect, useState } from 'react';
import { Upload, Button, message, Modal, Row, Col, notification } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { routes } from '../../../../../actions/api-routes';
import { postData, uploadFile } from '../../../../../actions/middleware';
import './index.css';
import { encryptData } from '../../../../../actions/crypto';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const FileUpload = ({ onSubmitFromHeader, onClear, fileType }) => {
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    useEffect(() => {
        if (onSubmitFromHeader == 'pdf_doc_excel_submit') {
            onFinish();
        }
    }, [onSubmitFromHeader])

    const onFinish = async () => {
        try {
            if (fileList.length > 0) {
                customRequest().then(async (fileResData) => {
                    const payload = {
                        fileType: fileType,
                        files: fileResData
                    }
                    const resData = await postData(routes.jobs.bulk_upload, { data: encryptData(payload) });
                    if (resData && resData.code == 200) {
                        notification.success({ message: 'Success', description: 'Job added successfully. It will be posted within 30 to 60 minutes.' })
                    }
                }).catch(error => {
                    notification.success({ message: 'Error', description: 'An error occurred. Please try again later.' })
                }).finally(() => {
                    onClear();
                })

            } else {
                notification.error({ message: 'Validation Failed', description: 'Please upload at least 1 image.' })
            }
        } catch (error) {
            notification.error({ message: 'Error', description: 'An error occurred. Please try again later.' })
        } finally {
            onClear();
        }
    };

    const customRequest = async () => {
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();
            fileList.forEach(ele => {
                formData.append('file', ele.originFileObj);
            })
            try {
                const resData = await uploadFile(routes.fileUpload, formData);
                if (resData && resData.status === 'SUCCESS') {
                    resolve(resData.files)
                } else {
                    reject()
                    message.error(`${file.name} Failed to upload`);
                }
            } catch (error) {
                reject()
                message.error(`${file.name} Failed to upload`);
            }
        })
    };
    const beforeUpload = (file) => {
        console.log("filefilefile", file, file.name.slice(-4).toLowerCase())
        const isDocOrPdf = ['.pdf', '.doc', '.docx', 'pdf', 'doc', 'docx'].includes(file.name.slice(-4).toLowerCase());
        const isExcel = ['.xls', '.xlsx', '.csv', 'xls', 'xlsx', 'csv'].includes(file.name.slice(-4).toLowerCase()); // Adjusted to -4

        if (!isDocOrPdf && !isExcel) {
            message.error('You can only upload PDF, DOC, DOCX, XLS, or XLSX files!');
            return false;
        }
        return false;
    };

    const validateFile = (file) => {
        console.log("filefilefile", file, file.name.slice(-4).toLowerCase())
        const isDocOrPdf = ['.pdf', '.doc', '.docx', 'pdf', 'doc', 'docx'].includes(file.name.slice(-4).toLowerCase());
        const isExcel = ['.xls', '.xlsx', '.csv', 'xls', 'xlsx', 'csv'].includes(file.name.slice(-4).toLowerCase()); // Adjusted to -4

        if (!isDocOrPdf && !isExcel) {
            message.error('You can only upload PDF, DOC, DOCX, XLS, or XLSX files!');
            return false;
        }
        return true;
    };

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => { 
        setFileList(newFileList.filter(file => validateFile(file)))
    }

    return (
        <div className='pdc_doc_excel'>
            <Row gutter={16}>
                <Col span={8}>
                    <Upload
                        listType="text"
                        fileList={fileList}
                        accept=".pdf,.doc,.docx,.xls,.xlsx,.csv"
                        multiple
                        beforeUpload={beforeUpload}
                        onPreview={handlePreview}
                        onChange={handleChange}
                    >
                        {fileList.length >= 8 ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
                    </Upload>
                </Col>
            </Row>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <iframe
                    title="File Preview"
                    width="100%"
                    height="500px"
                    src={previewImage}
                />
            </Modal>
        </div >
    );
};

export default FileUpload;
