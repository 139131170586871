import * as moment from 'moment';

const validatePhoneNumber = (_, value) => {
  // Regular expression to validate a phone number (basic example)
  const phoneRegex = /^[0-9]{10}$/;

  if (!value || phoneRegex.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject('Please enter a valid phone number');
};

const validateWebsiteUrl = (_, value) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  if (!value || urlRegex.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject('Please enter a valid website URL');
};

const validateCINGSTN = (_, value) => {
  const cinGstnRegex = /^[A-Z0-9]{21}$/;
  const gst = /^[A-Z0-9]{15}$/;
  if (!value || cinGstnRegex.test(value)) {
    return Promise.resolve();
  } else if (!value || gst.test(value)) {
    return Promise.resolve();
  } else {
    return Promise.reject('Please enter a valid CIN/GSTN in capital letters');
  }
};

const validateEmail = (_, value) => {
  // Regular expression to validate an email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!value || emailRegex.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject('Please enter a valid email address');
};

const validatePassword = (_, value, callback) => {
  if (value && value.length < 8) {
    callback('Password must be at least 8 characters long');
  } else {
    callback();
  }
};

const validateConfirmPassword = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject('The two passwords do not match');
  },
});

const formatDates = (data, keys = ['startDate', 'endDate']) => {
  return data.map((project) => {
    const keysData = {};
    keys.forEach(ele => keysData[ele] = moment(project[ele]))
    return {
      ...project,
      ...keysData
    };
  });
};

const validateMinimum = async (_, value, maximumFieldValue) => {
  if (parseFloat(value) > parseFloat(maximumFieldValue)) {
    return Promise.reject('Minimum value should not be greater than Maximum value');
  }
  return Promise.resolve();
};

const validateMaximum = async (_, value, minimumFieldValue) => {
  if (parseFloat(value) < parseFloat(minimumFieldValue)) {
    return Promise.reject('Maximum value should not be less than Minimum value');
  }
  return Promise.resolve();
};

const validateMinAndMax = (min, max) => {
  const _min = { min: min, message: `Minimum ${min} characters required` }
  const _max = { max: max, message: `Maximum ${max} characters required` }
  const result = [];
  if (min) {
    result.push(_min)
  }

  if (max) {
    result.push(_max)
  }
  return result
}

const truncateText = (text, maxLength) => {
  if (text && text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const getCurrency = (interval) => {
  switch (interval) {
    case 'Monthly':
      return 'LPM'
    case 'Yearly':
      return 'LPY'
    case 'Hourly':
      return 'LPH'
    case 'Weekly':
      return 'LPW'
    case 'Fixed':
      return 'FIXED'
    default:
      return 'LPM'
  }
}

const getDescountBudget = (budget) => {
  var _budget = parseFloat(budget).toFixed(2);
  return _budget - (_budget * 0.15);
}

export {
  validatePhoneNumber,
  validateWebsiteUrl,
  validateMinimum,
  validateMaximum,
  formatDates,
  validateCINGSTN,
  validateEmail,
  validatePassword,
  validateConfirmPassword,
  validateMinAndMax,
  truncateText,
  getCurrency,
  getDescountBudget
}