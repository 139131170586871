import React, { useState, useEffect, useRef } from "react";
import './index.css';
import { Button, Col, Divider, Row, Table, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import common from "../routing";
const { routing, COMMON_STRING } = common;

const columns = [
  {
    title: 'Questions Type',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Marks On IT',
    dataIndex: 'marks',
    key: 'marks',
  },
]

const dataSource = [
  {
    name: '12 Questions for Java',
    marks: '1 mark on each',
  },
  {
    name: '12 Questions for React js',
    marks: '1 Mark on each',
  },
  {
    name: '2 Coding quetions',
    marks: '7 Mark on each',
  }
];

const listOfInstructions = [
  {
    heading: 'Before the Exam',
    content: [
      {
        heading: 'Technical Requirements',
        content: [
          'Ensure you have a stable internet connection.',
          'Use a compatible web browser (e.g., Chrome, Firefox).',
          "Make sure your device's camera and microphone are working."
        ]
      },
      {
        heading: 'Prepare Your Workspace',
        content: [
          'Choose a quiet and well-lit room.',
          'Clear your desk of any materials not allowed during the exam.',
          "Check that your computer setup is comfortable."
        ]
      },
      {
        heading: 'Log In Early',
        content: [
          'Log in to the examination platform a few minutes before the scheduled start time.',
          'Ensure you can access all necessary materials.'
        ]
      }
    ]
  },
  {
    heading: 'During the Exam',
    content: [
      {
        heading: 'Read Instructions Carefully',
        content: [
          'Read all instructions and questions carefully.',
          "Understand the format of the questions and the expected answers."
        ]
      },
      {
        heading: 'Time Management',
        content: [
          "Keep track of the time and allocate it wisely among questions.",
          "Avoid spending too much time on a single question.",
        ]
      },
      {
        heading: 'No Unauthorized Aids',
        content: [
          "Do not use any unauthorized aids, materials, or external devices.",
          "Keep your workspace clear of notes, books, or other resources.",
        ]
      },
      {
        heading: 'No Communication',
        content: [
          "Do not communicate with anyone during the exam, either verbally or through electronic means.",
        ]
      },
      {
        heading: 'Monitoring',
        content: [
          "Be aware that your camera and microphone may be monitored during the exam.",
          "Follow any proctoring guidelines provided.",
        ]
      },
      {
        heading: 'Saving Work',
        content: [
          "If the platform allows, save your work regularly to avoid losing progress.",
        ]
      },
      {
        heading: 'Technical Issues',
        content: [
          "If you encounter technical issues, notify the invigilator or support immediately.",
          "Have a backup device available if possible."
        ]
      },
    ]
  },
  {
    heading: 'After the Exam',
    content: [
      {
        heading: 'Submission',
        content: [
          "Submit your answers within the specified timeframe.",
          "Confirm that your submission was successful."
        ]
      },
      {
        heading: 'Follow-Up',
        content: [
          "If required, follow up with any post-exam procedures or assessments.",
          "Address any technical issues or concerns with the relevant support channels."
        ]
      },
      {
        heading: 'Feedback',
        content: [
          "Provide feedback on the exam process if requested.",
          "Review your performance for self-improvement."
        ]
      },
    ]
  }
]

const ExamInstructionsPDF = () => {
  const selector = useSelector(auth => auth.app);
  const navigate = useNavigate();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [warning, setWarning] = useState("Don't have permission");
  const dispatch = useDispatch()
  const IS_FULL_SCREEN = useSelector(store => store.app[COMMON_STRING.FULL_SCREEN_MODE]);
  const [isChange, setFullScreenChanged] = useState(IS_FULL_SCREEN);

  const renderInstructions = (instructions) => (
    <div>
      {instructions.map((section, index) => (
        <div key={index} className="section">
          <Divider className="no-marging" />
          <h6>{section.heading}</h6>
          <Divider className="no-marging" />
          {section.content.map((item, i) => (
            <div key={i} className="item">
              <p className="bold-text">{item.heading}</p>
              <ul className="ul">
                {item.content.map((point, j) => (
                  <li type="a" key={j}>{point}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    setFullScreenChanged(IS_FULL_SCREEN)
  }, [IS_FULL_SCREEN])

  const dispatchData = () => {
    dispatch({
      type: COMMON_STRING.FULL_SCREEN_MODE,
      payload: COMMON_STRING.IS_FULL_SCREEN_MODE_ON
    })
  }

  const onClick = () => {
    navigate(routing.online_exam)
  }

  return (
    <>
      <Row className="">
        <Col span={12} className="ml-15">
          <div className="box-view">
            <h6 className="mt-15 mb-15">How your test will be</h6>
            <div>
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              />
            </div>
            {isChange && isChange == COMMON_STRING.IS_FULL_SCREEN_MODE_ON ?
              <Button className="start-test" type="primary" onClick={() => onClick()} >
                Start Test
              </Button>
              : <Button className="start-test" type="primary" onClick={() => dispatchData()} >
                Request Fullscreen
              </Button>
            }
          </div>
        </Col>
        <Col span={12}>
          <div className="pdf-view-box">
            <div className="pdf-container">
              <h1 className="pdf-title">Exam Instructions</h1>
              <div className="pdf-content">
                {renderInstructions(listOfInstructions)}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ExamInstructionsPDF;