import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Upload, Button, Tabs, Image } from 'antd';
import './../index.css';
import NewProfiles from './tabs/newProfiles';
import RejectedProfiles from './tabs/RejectedProfiles';
import ApprovedProfiles from './tabs/ApprovedProfiles';
import WaitingForApproval from './tabs/WaitingForApproval';

const PublicProfiles = () => {
    const [listFor, setListFor] = useState('approved');

    const items = [
        {
            key: 'approved',
            label: 'Approved',
        },
        {
            key: 'rejected',
            label: 'Rejected',
        },
        {
            key: 'pending',
            label: 'Pending',
        },
        {
            key: 'Waiting_for_approval',
            label: 'Waiting for approval',
        },

    ];

    const onChange = (key) => {
        setListFor(key)
    };

    return (
        <div>
            <div className='tabs-bg'>
                <Tabs size='large' defaultActiveKey="review" items={items} onChange={onChange} />
            </div>
            {listFor == 'pending' && <NewProfiles />}
            {listFor == 'rejected' && <RejectedProfiles />}
            {listFor == 'approved' && <ApprovedProfiles />}
            {listFor == 'Waiting_for_approval' && <WaitingForApproval />}
        </div>
    );
}

export default PublicProfiles;
