

import { useNavigate } from 'react-router-dom';
import './index.css';
import { Input, Button, Space, Typography } from 'antd';
import { postData } from '../actions/middleware';
import { error, success } from '../admin/common/Notify';
import { SetCookies, getUserData } from '../actions/auth';
import { useEffect, useState } from 'react';
import ResendOTPButton from '../onlineTest/Home/ResendOTP';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../actions/localStorage';
import constant from '../actions/constant';
import { routes } from '../actions/api-routes';
import { emailEncryption } from '../actions/commonFunc';
const { Title } = Typography;

function OTPLogin() {
    const navigate = useNavigate()
    const [otp, setOTP] = useState();
    const [workEmail, setWorkEmail] = useState(null);

    useEffect(() => {
        const _workEmail = getLocalStorage(constant.LOGIN_KEY);
        setWorkEmail(_workEmail);
    }, [])

    useEffect(() => {
        getUserDetails();
    }, [])

    const getUserDetails = () => {
        const data = getUserData();
        if (data) {
            navigate('/admin/requirements/openings')
        }
    }

    const onChange = (text) => {
        console.log('onChange:', text);
        setOTP(text);
    };

    const handleResendClick = async () => {
        const workEmail = getLocalStorage(constant.LOGIN_KEY);
        const resData = await postData('/vendor/login', { workEmail })
        if (resData && resData.code == 200) {
            setWorkEmail(workEmail)
            success("OTP Sent", `OTP sent successfully over ${emailEncryption(workEmail)}`)
        } else {
            error("Validation Faild", 'Please enter correct credentials.')
        }
    }

    const onVerify = async () => {
        const workEmail = getLocalStorage(constant.LOGIN_KEY);
        console.log('Resend OTP logic here', workEmail, otp);
        if (otp && workEmail) {
            const resData = await postData(routes.authRoutes.verifyOTP, { workEmail, otp })
            if (resData && resData.code == 200) {
                removeLocalStorage(constant.LOGIN_KEY);
                success("Login Success", "You are login Success")
                SetCookies(resData.data)
                getUserDetails();
                navigate('/admin/requirements/openings')
            } else {
                error("Validation Faild", 'Please enter valid OTP.')
            }
        }
    }

    return (
        <div className="row">
            <div className="col-8 center hide-mobile">
                <img src={require('./../assets/images/background/78.jpg')} className='bg_image_fit' />
            </div>
            <div className="col-lg-4 col-md-12 center p-10">
                <div id="main-wrapper" className='login-register formView'>
                    <div className="site-wrapper-reveal">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 center pt-10">
                                <div className="projects-wrap style-04 wow move-up">
                                    <div className="otp-box">
                                        <Space direction="vertical" align="center">
                                            <Title level={3}>Verify Email</Title>
                                            <Title level={5}>A 4 digit code send via email on {emailEncryption(workEmail)}</Title>
                                            <Input.OTP length={4} onChange={onChange} />
                                            <div className="resend-time">
                                                <ResendOTPButton onClick={handleResendClick} disabled={false} />
                                            </div>
                                            <Button className="verify-button" type="primary" onClick={onVerify}>Verify</Button>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OTPLogin;
