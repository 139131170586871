import React from 'react';
import { Form, Select, Input, Checkbox, DatePicker, Radio, Divider, Button } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import './index.css'

const MobileFooter = ({ next, nextButtonText, previous, isPrevious }) => {

  return (
    <>
      <div className='mobile-footer d-mobile'>
        <Divider className='divider' />
        <div className='footer-buttons'>
          {isPrevious ?
            <Button onClick={previous}>Previous</Button>
            : <div></div>}
          <Button onClick={next} type='primary'>{nextButtonText ? nextButtonText : 'Next'}</Button>
        </div>
      </div>
    </>
  );
};

export default MobileFooter;
