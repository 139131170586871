// ProfileForm.js
import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Card } from 'antd';
import moment from 'moment'

const ProfileView = ({ profileData }) => {

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Card
            title="Personal Details"
          >
            <Row gutter={16}>
              <Col span={12}>
                <label className='heading'>First Name</label>
                <div className='field'>{profileData?.firstName}</div>
              </Col>
              <Col span={12}>
                <label className='heading'>Last Name</label>
                <div className='field'>{profileData?.lastName}</div>
              </Col>
              <Col span={12}>
                <label className='heading'>Job Title</label>
                <div className='field'>{profileData?.jobTitle}</div>
              </Col>
              <Col span={12}>
                <label className='heading'>Work Email</label>
                <div className='field'>{profileData?.workEmail}</div>
              </Col>
              <Col span={12}>
                <label className='heading'>Work Phone</label>
                <div className='field'>{profileData?.phoneNumber}</div>
              </Col>
              <Col span={12}>
                <label className='heading'>City</label>
                <div className='field'>{profileData?.city}</div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title="Company Details"
          >
            <Row gutter={16}>
              <Col span={12}>
                <label className='heading'>Company Name</label>
                <div className='field'>{profileData?.companyName}</div>
              </Col>
              <Col span={12}>
                <label className='heading'>CIN/GSTN</label>
                <div className='field'>{profileData?.cin_gstn}</div>
              </Col>
              <Col span={12}>
                <label className='heading'>Account Status</label>
                <div className='field'>{profileData?.accountStatus}</div>
              </Col>
              <Col span={12}>
                <label className='heading'>Account Created At</label>
                <div className='field'>{moment(profileData?.createdAt).format('llll')}</div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProfileView;
