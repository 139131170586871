import React, { useEffect } from 'react';
import { useState } from 'react';
import { Avatar, Button, Tag } from 'antd';
import { ShoppingFilled } from '@ant-design/icons';
import './index.css';
import UpdateRequierment from '../../manage/requierments/modal';
import { getDays, timeAgo } from '../../common/functions';
import { useNavigate } from 'react-router-dom';
import { encryptData } from '../../../actions/crypto';

const ListMyRequirmentItem = ({ style, item, reloadPage, activeId, setActiveItem }) => {
    const [isVisible, setVisible] = useState(false);
    const [itemId, setItemId] = useState(null);
    const [active, setActive] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        setActive(activeId)
    }, [activeId])

    const onActive = (itemId) => {
        if (itemId.approvalStatus == 'APPROVED') {
            setActiveItem(itemId)
            navigate(`/admin/requirements/details/${encryptData(itemId._id)}`)
        }
    }

    const getCount = () => {
        if (item && item.profileCount) {
            const { pendingCount, rejectedCount, selectedCount } = item.profileCount;
            return [pendingCount, rejectedCount, selectedCount].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        } else {
            return 0
        }
    }

    return (
        <div className={(active == item._id ? 'active-box' : '') + ' view-box bg-white-fff job-dscription'} style={style} onClick={() => onActive(item)}>
            <UpdateRequierment isVisible={isVisible} onClose={() => {
                setVisible(false)
                setItemId(null)
            }} reloadPage={reloadPage} id={itemId} />
            <div className='d-f justify-between'>
                <div>
                    <div className='job-title-view'>
                        <div className='icon-view'>
                            <Avatar shape={'square'} icon={<ShoppingFilled />} />
                        </div>
                        <div className='heading-view-for-rol'>
                            <div className='job-title-box'>
                                <div className='main-text'> {item?.jobTitle}</div>
                                <div className='sub-text'> {item?.experiance}+ Year</div>
                                {getDays(item?.createdAt) <= 2 && <div className='new-label'>New</div>}
                            </div>
                            <div className='price-view'>
                                <Tag color='magenta' bordered={false}>{item?.jobLocationType}</Tag> | <Tag color='cyan' bordered={false}>{item?.currency} {item?.minimum}LPM - {item?.maximum}LPM</Tag>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Button type='link' onClick={() => {
                        setVisible(true)
                        setItemId(item._id)
                    }} disabled={item.approvalStatus == 'APPROVED'}>Edit</Button>
                </div>
            </div>
            <div className=''>
                {item?.skils.map(ele => <span className='tag'> #{ele}</span>)}
            </div>
            <div>
                <b>{item.jobActive} . </b>
                {timeAgo(item.createdAt)}
            </div>
            <div>Profiles recived: {getCount() > 0 ? getCount() + '+' : getCount()}  </div>
        </div>
    );
};

export default ListMyRequirmentItem;
