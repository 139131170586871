import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Col, FloatButton, Input, Radio, Row, Select, Slider } from 'antd';
const { Option } = Select;
import './index.css';
import LoadMoreCheckboxSelect from '../../common/checkbox';
import { UpOutlined } from '@ant-design/icons';

const initialValue = {
    skills: [],
    location: { onsite: false, remote: false },
    experiance: [],
    budgetMonthly: [],
    customBudget: {},
    customExperiance: {}
}



const FilterSkills = ({ onFilter }) => {
    const [selectedCheckbox, setChekbox] = useState({
        skills: [],
        location: { onsite: false, remote: false },
        experiance: [],
        budgetMonthly: [],
        customBudget: {},
        customExperiance: {},
    })
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [minExperience, setMinExperience] = useState();
    const [maxExperience, setMaxExperience] = useState();

    const [experiance, setExperiance] = useState([]);
    const [budget, setBudget] = useState([]);
    const [skills, setSkills] = useState([]);

    const [minBudget, setMinBudget] = useState();
    const [maxBudget, setMaxBudget] = useState();

    const areObjectsEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];
            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!areObjectsEqual(val1, val2)) {
                    return false;
                }
            } else if (val1 !== val2) {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        if (!areObjectsEqual(initialValue, selectedCheckbox)) {
            onFilter(selectedCheckbox)
        }
    }, [selectedCheckbox])


    useEffect(() => {
        let cust_budget = { min: minBudget, max: maxBudget }
        setChekbox(prevData => ({ ...prevData, budgetMonthly: budget, customBudget: cust_budget }))
    }, [budget])

    useEffect(() => {
        setChekbox(prevData => ({ ...prevData, skills: skills }))
    }, [skills])

    useEffect(() => {
        let cust_experiance = { min: minExperience, max: maxExperience }
        setChekbox(prevData => ({ ...prevData, experiance: experiance, customExperiance: cust_experiance }))
    }, [experiance])


    const onSkills = (e) => {
        setSkills(e)
    }

    const onSelectedLocation = (name, { target: { checked } }) => {
        let checkBox = {};
        checkBox[name] = checked;
        const data = { location: { ...selectedCheckbox['location'], ...checkBox } }
        setChekbox(preValue => ({ ...preValue, ...data }))
    }

    const onMinBudget = (e) => {
        setMinBudget(e.target.value);
    };

    const onMaxBudget = (e) => {
        setMaxBudget(e.target.value);
    };

    const onMinExperienceChange = (e) => {
        setMinExperience(e.target.value);
    };

    const onMaxExperienceChange = (e) => {
        setMaxExperience(e.target.value);
    };


    const getHeading = (name) => {
        return <div className='heading-div'>
            <div className='heading-3'>{name}</div>
        </div>
    }

    const onChange = (checkedValues) => {
        console.log('checked = ', checkedValues);
        setExperiance(checkedValues)
    };

    const onChangeBudget = (checkedValues) => {
        console.log('checked = ', checkedValues);
        setBudget(checkedValues)
    };

    const options = [
        { label: 'Less than 3 year', value: 'lessThan3' },
        { label: '3 year to 6 year', value: '3to6' },
        { label: '7 year to 12 year', value: '7to12' },
        {
            label: (
                <div className='check-box-view'>
                    <div>
                        <Input className='price-input' suffix={'Year'} value={minExperience} onChange={onMinExperienceChange} />
                    </div>
                    <div className='to-class'>to</div>
                    <div>
                        <Input className='price-input' suffix={'Year'} value={maxExperience} onChange={onMaxExperienceChange} />
                    </div>
                </div>
            ),
            value: 'customRange'
        },
    ];

    const optionsBudget = [
        { label: 'Less than 1LPM', value: 'lessThan3' },
        { label: '1LPM to 3LPM', value: '1to3' },
        { label: '3LPM to 9LPM', value: '3to9' },
        {
            label: (
                <div className='check-box-view'>
                    <div>
                        <Input className='price-input' suffix={'LPM'} value={minBudget} onChange={onMinBudget} />
                    </div>
                    <div className='to-class'>to</div>
                    <div>
                        <Input className='price-input' suffix={'LPM'} value={maxBudget} onChange={onMaxBudget} />
                    </div>
                </div>
            ),
            value: 'customRange'
        },
    ];

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='filter-wrapper'>
            <div className='filter-options' style={{ maxHeight: (windowHeight - 145), overflowY: 'auto', }}>
                <div className='margin-bottom'>
                    {getHeading('Skills')}
                    <div className='padding-10px'>
                        <LoadMoreCheckboxSelect onSelect={(e) => onSkills(e)} />
                    </div>
                </div>
                <div className='margin-bottom'>
                    {getHeading('Location')}
                    <div className='padding-10px'>
                        <Checkbox checked={selectedCheckbox.location.onsite} name='onsite' onChange={e => onSelectedLocation('onsite', e)}> Onsite</Checkbox> <br />
                        <Checkbox checked={selectedCheckbox.location.remote} name='remote' onChange={e => onSelectedLocation('remote', e)}> Remote</Checkbox>
                    </div>
                </div>
                <div className='margin-bottom'>
                    {getHeading('Experiance')}
                    <div className='padding-10px'>
                        <Checkbox.Group onChange={onChange}>
                            {options.map(option => (
                                <div key={option.value} className='checkbox-div'>
                                    <Checkbox value={option.value}>{option.label}</Checkbox>
                                </div>
                            ))}
                        </Checkbox.Group>
                    </div>
                </div>
                {/* <div className='margin-bottom'>
                    {getHeading('Budget Monthly')}
                    <div className='padding-10px'>
                        <Checkbox.Group onChange={onChangeBudget}>
                            {optionsBudget.map(option => (
                                <div key={option.value} className='checkbox-div'>
                                    <Checkbox value={option.value}>{option.label}</Checkbox>
                                </div>
                            ))}
                        </Checkbox.Group>
                    </div>
                </div> */}
            </div >
        </div >
    );
};

export default FilterSkills;
