// MainSiteRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import Privacy from '../pages/privacy';
import About from '../pages/company/about';
import Contact from '../pages/company/contact';
import FAQ from '../pages/company/FAQs';
import Careers from '../pages/company/careers';
import Blogs from '../pages/company/blogs/blog-details';
import BlogsList from '../pages/company/blogs/list';
import ServicesList from '../pages/services/list';
import SolutionsList from '../pages/solutions/list';
import HireDeveloperDetails from '../pages/hire-developer/details';
import Footer from '../pages/footer';
import CareerDetails from '../pages/company/careers/details';
import HireDeveloperList from '../pages/hire-developer/HireDeveloperList/index';
import BookCalender from '../pages/company/careers/book-Interview';
import TrainingPlacement from '../pages/training-placement';
import UploadProfile from '../admin/uplod-profile';


const MainSiteRoutes = () => {
  return (
    <>
      {/* <Headers /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/career" element={<Careers />} />
        <Route path="/blog-details" element={<Blogs />} />
        <Route path="/blogs" element={<BlogsList />} />
        <Route path="/services" element={<ServicesList />} />
        <Route path="/solutions" element={<SolutionsList />} />
        {/* <Route path="/hire-developers" element={<HireDeveloperList />} /> */}
        <Route path="/profile-details" element={<HireDeveloperDetails />} />
        <Route path="/book-calender" element={<BookCalender />} />
        <Route path="/job-details" element={<CareerDetails />} />
        <Route path="/hire-developers" element={<HireDeveloperList />} />
        <Route path="/training-placement" element={<TrainingPlacement />} />
        <Route path="/upload-profile-public" element={<UploadProfile />} />

      </Routes>
      {/* <Footer /> */}
    </>
  );
};

export default MainSiteRoutes;
