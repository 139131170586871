import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Alert, Button, Col, FloatButton, Radio, Row } from 'antd';
import './index.css';
import MyRequirment from './my-requirments';
import CurrentOpenings from './current-openings';
import FilterSkills from './filter';
import { getData } from '../../actions/middleware';
import { routes } from '../../actions/api-routes';
import { decryptData, encryptData } from '../../actions/crypto';
import PostRequirment from './post-requirment';
import { FilePdfOutlined, FileImageOutlined, BellTwoTone } from '@ant-design/icons';
import PostModal from './post';
import ImageUpload from './post/ImageUpload';
import FileUpload from './post/fileUpload';
import LoadMoreCheckboxSelect from '../common/checkbox';

const Jobs = () => {
  const [mode, setMode] = useState('current');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [requirments, setRequirement] = useState([]);
  const [visible, setVisible] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [skills, setSkills] = useState();
  const [pendingCount, setTotalPendingCount] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    getPendingCount();
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const shareOn = () => {
    setVisible(true)
  }

  useEffect(() => {
    if (hasMore) {
      getRequirments();
    }

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [page, hasMore])

  const getRequirments = async (skils = []) => {
    const resData = await getData(routes.jobs.listAll, { current: page, skils: skils });
    if (resData.code == 200) {
      const data = decryptData(resData.data)
      if (page == resData.pagination.current) {
        if (data.length < 14) {
          setHasMore(false);
          setRequirement(prevData => {
            const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
            return [...prevData, ...newData];
          });
        } else {
          setRequirement(prevData => {
            const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
            return [...prevData, ...newData];
          });
        }
      }
    }
  }

  const getPendingCount = async () => {
    const resData = await getData(routes.jobs.pendingCount);
    if (resData.code == 200) {
      const data = decryptData(resData.data)
      setTotalPendingCount(data)
    }
  }

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight === container.scrollHeight
    ) {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    getRequirments(skills)
  }, [skills])

  useEffect(() => {
    if (mode == 'current') {
      getRequirments(skills)
    }
  }, [mode])

  const onSkills = (e) => {
    console.log("eeeeeeeeeeee", e)
    // setPage(1)
    // setRequirement([])
    // setHasMore(false)
    // setSkills(e)
  }

  return (
    <div className='padding-box'>
      <div className='header'>
        <h6 className='heading pb-2'>Requirement's</h6>
        {pendingCount && <div className='alert-box' style={{ marginTop: '-10px' }}>
          <Alert type='warning' message={<><BellTwoTone /> Your {pendingCount} posts are under review. They will be live soon.</>} />
        </div>}
      </div>
      <PostModal isVisible={visible} onClose={() => {
        getPendingCount()
        setVisible(false)
      }} />
      <div>
        <Row gutter={10}>
          <Col span={6}>
            <FilterSkills onFilter={onSkills} />
          </Col>
          <Col span={18}>
            <div style={{ maxHeight: (windowHeight - 145), overflowY: 'auto', }} ref={containerRef} className='requirment-main-box'>
              <div className='div-1' >
                <div className='view-box bg-white job-dscription' style={{ margin: "1px 10px 10px 0px", marginTop: '1px', paddingBottom: '10px' }}>
                  <div className='share-post' onClick={() => shareOn()}>Share your post and receive profiles within 60 minutes.</div>
                  <div className='d-f upload-buttons'>
                    <div>
                      <ImageUpload onClose={getPendingCount}>
                        <FileImageOutlined style={{ color: 'blueviolet', fontSize: '16px' }} /> Upload Image
                      </ImageUpload>
                    </div>
                    <div>
                      <FileUpload onClose={getPendingCount}>
                        <FilePdfOutlined style={{ color: 'blueviolet', fontSize: '16px' }} /> Upload File
                      </FileUpload>
                    </div>
                  </div>
                </div>
                {requirments.length > 0 && requirments.map(ele => <CurrentOpenings item={ele} style={{ margin: "1px 10px 10px 0px", marginTop: '1px', paddingBottom: '10px' }} />)}
                {requirments.length == 0 && <div className='no_result_div'>
                  <img className='no_result' src={require('./../../assets/images/no_result.png')} />
                </div>}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Jobs;
