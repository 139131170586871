import React from 'react';
import { Form, Input, Button, InputNumber, Row, Col, Card } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
const { Item, List } = Form;

const SlotBookingForm = () => {
  const onFinish = (values) => {
    console.log('Received values:', values);
  };

  return (
    <Card>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span={8}>
            <Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
              <Input />
            </Item>
          </Col><Col span={8}>
            <Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input />
            </Item>
          </Col><Col span={8}>
            <Item label="Phone" name="phone" rules={[{ required: true, message: 'Please input your phone number!' }]}>
              <Input />
            </Item>
          </Col><Col span={8}>
            <Item label="Alternative Phone" name="alternativePhone">
              <Input />
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Item label="Education" name="education" rules={[{ type: 'array', required: true, message: 'Please input your education details!' }]}>
              <List name="education">
                {(fields, { add, remove }) => (
                  <>
                    <Row gutter={16}>
                      <Col span={22}>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <div key={key}>
                            <Row gutter={16}>
                              <Col span={7}>
                                <Item
                                  {...restField}
                                  label="College Name"
                                  name={[name, 'collegeName']}
                                  fieldKey={[fieldKey, 'collegeName']}
                                  rules={[{ required: true, message: 'Please input college name!' }]}
                                >
                                  <Input />
                                </Item>
                              </Col>
                              <Col span={7}>
                                <Item
                                  {...restField}
                                  label="Course Name"
                                  name={[name, 'courseName']}
                                  fieldKey={[fieldKey, 'courseName']}
                                  rules={[{ required: true, message: 'Please input course name!' }]}
                                >
                                  <Input />
                                </Item>
                              </Col>
                              <Col span={4}>
                                <Item
                                  {...restField}
                                  style={{ width: '100%' }}
                                  label="Year of Passing"
                                  name={[name, 'yearOfPassing']}
                                  fieldKey={[fieldKey, 'yearOfPassing']}
                                  rules={[{ required: true, message: 'Please input year of passing!' }]}
                                >
                                  <InputNumber style={{ width: '100%' }} />
                                </Item>
                              </Col>
                              <Col span={4}>
                                <Item
                                  {...restField}
                                  label="Percentage"
                                  name={[name, 'percentage']}
                                  fieldKey={[fieldKey, 'percentage']}
                                  rules={[{ required: true, message: 'Please input percentage!' }]}
                                >
                                  <InputNumber style={{ width: '100%' }} />
                                </Item>
                              </Col>
                              <Col span={2}>
                                <Button type="dashed" onClick={() => remove(name)} >
                                  <DeleteOutlined />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </Col>
                      <Col span={2}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                        >
                          Add Education
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </List>
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Item label="Address" name="address" rules={[{ type: 'object', required: true, message: 'Please input your address!' }]}>
              <Item label="Address Line 1" name={['address', 'addressLine1']}>
                <Input />
              </Item>

              <Item label="Address Line 2" name={['address', 'addressLine2']}>
                <Input />
              </Item>

              <Item label="City" name={['address', 'city']}>
                <Input />
              </Item>

              <Item label="State" name={['address', 'state']}>
                <Input />
              </Item>

              <Item label="Pincode" name={['address', 'pincode']}>
                <Input />
              </Item>
            </Item>
          </Col><Col span={8}>
            <Item label="Internship" name="internship">
              <Input.TextArea />
            </Item>
          </Col><Col span={8}>
            <Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Item>
          </Col>
        </Row>
      </Form >
    </Card >
  );
};

export default SlotBookingForm;
