import React from 'react';
import { Form, Select, Button, message, Upload, Space, Divider, Row, Col, Input, DatePicker, Typography } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import MobileFooter from './../mobile-footer/mobile.footer';
import { getData, uploadFile } from '../../../../actions/middleware';
import { routes } from '../../../../actions/api-routes';
import { MinusCircleOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { getApplicantData } from '../../../../actions/auth';
import dayjs from 'dayjs';
import './index.css';
import moment from 'moment/moment';

const { Option } = Select;
const keys = ['experienceLetter', 'internshipLatter', 'relievingLetter'];

const Experiance = ({ data, next, previous, id }) => {
    const [form] = Form.useForm();
    const [selectedOption, setSelectedOption] = useState([]);
    const [apiResponse, setResponse] = useState({});
    const [fileList, setFileList] = useState({});


    const onFinish = (values) => {
        const formData = new FormData();
        const data = getApplicantData();
        formData.append('folderName', data.email);

        Object.keys(values).forEach((key) => {
            if (Array.isArray(values[key])) {
                values[key].forEach((item, index) => {
                    Object.keys(item).forEach((field) => {
                        if (!keys.includes(field)) {
                            formData.append(`${key}[${index}][${field}]`, item[field]);
                        }
                    });
                });
            } else {
                if (!keys.includes(key)) {
                    formData.append(key, values[key]);
                }
            }
        });
        values.workItems.forEach((item, index) => {
            keys.forEach(key => {
                if (key in item) {
                    try {
                        item[key]?.fileList?.forEach((file) => {
                            if (file.originFileObj) {
                                formData.append(`workItems[${index}][${key}]`, file.originFileObj);
                            }
                        });
                    } catch (error) {
                        delete item[key]
                    }
                }
            })
        })

        uploadFile(routes.applicant.uploadExperiance, formData).then(resData => {
            if (resData && resData.code == 200) {
                message.success("Successfully updated your response")
            } else {
                message.error("Something went wrong, please try again")
            }
        })
    };


    useEffect(() => {
        getAllDoccuments();
    }, [])

    const getAllDoccuments = async () => {
        const resData = await getData(routes.applicant.uploadExperiance)
        if (resData.code == 200) {
            let file_list = {};
            const initialValues = {
                workItems: resData.data.map((item, index) => {
                    file_list[index] = {};
                    keys.forEach(ele => {
                        file_list[index][ele] = [];
                        if (item[ele]) {
                            file_list[index][ele].push({
                                uid: item[ele] + '_' + index,
                                name: item[ele].originalname,
                                status: item[ele].approvalStatus === 'Rejected' ? 'error' : 'done',
                                url: routes.fileServer + '/applicant/' + item[ele].parentFolder + '/' + item[ele].filename,
                                size: item[ele].size,
                            });
                        }
                    })

                    return {
                        _id: item._id,
                        workType: item.workType,
                        companyName: item.companyName,
                        jobTitle: item.jobTitle,
                        startDate: item.startDate ? dayjs(item.startDate) : null,
                        endDate: item.endDate ? dayjs(item.endDate) : null,
                        experienceLetter: item.experienceLetter ? item.experienceLetter.filename : null,
                        relievingLetter: item.relievingLetter ? item.relievingLetter.filename : null,
                        internshipLatter: item.internshipLatter ? item.internshipLatter.filename : null,
                        hrNumber: item.hrNumber,
                        hrEmail: item.hrEmail,
                        approvalStatus: item.approvalStatus,
                        message: item.message
                    }
                }),
            };
            setFileList(file_list);
            setResponse(resData.data)
            setSelectedOption(resData.data.map(ele => ele.workType))
            form.setFieldsValue({ workItems: initialValues.workItems.length > 0 ? initialValues.workItems : [{ workType: null }] })
        } else {
            form.setFieldsValue({ workItems: [{ workType: null }] })
        }
    }

    const onSubmit = () => {
        form.submit();
    }

    const getFileList = (field, apiResponse, index) => {
        if (fileList[index] && fileList[index][field]) {
            return fileList[index][field]
        }
        return [];
    };

    const handleFileChange = (field, index, info) => {
        setFileList((prev) => ({
            ...prev,
            [index]: {
                ...prev[index],
                [field]: info.fileList
            },
        }));
    };

    const removeIndex = (index) => {
        if (index >= 0 && index < selectedOption.length) {
            setSelectedOption(prev => {
                prev.splice(index, 1);
                return prev
            })
        }
        setFileList(prev => {
            let sortedState = Object.keys(prev)
                .sort((a, b) => parseInt(a) - parseInt(b))
                .reduce((acc, key) => {
                    acc[key] = prev[key];
                    return acc;
                }, {});

            let updatedState = {};
            let currentIndex = 0;

            for (let key in sortedState) {
                if (parseInt(key) !== index) {
                    updatedState[currentIndex] = prev[key];
                    currentIndex++;
                }
            }
            return updatedState
        })
    }


    return (
        <>
            <div className='row'>
                <div className='col-lg-8 scroll-view-box justify-bet'>
                    <div>
                        <div className='heading-padding'>
                            <h6>Experiance</h6>
                            <div>
                                <Button className='button-view margin-lr' onClick={previous}>Previous</Button>
                                <Button className='button-view d-dextop' onClick={onSubmit} type='primary'>Save & Next</Button>
                            </div>
                        </div>
                        <Divider className='divider' />
                        <div className='scroll-view'>
                            <div className='row interview-form'>
                                <Row gutter={16}>
                                    <Col span={22}>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            onFinish={onFinish}
                                            style={{ margin: 20 }}
                                        >
                                            <Form.List
                                                name={'workItems'}
                                            >
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                                                            const approvalStatus = form.getFieldValue(['workItems', index, 'approvalStatus']);
                                                            const message = form.getFieldValue(['workItems', index, 'message']);

                                                            return (
                                                                <>
                                                                    <Row gutter={16}>
                                                                        <Col span={22}>
                                                                            <div className='form_fields'>
                                                                                <Row gutter={[16, 16]} key={key}>
                                                                                    {approvalStatus == 'Rejected' && <Col span={24}>
                                                                                        <Typography.Text type='danger'>{message}</Typography.Text>
                                                                                    </Col>}
                                                                                    <Col md={12} lg={12} sm={24}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "workType"]}
                                                                                            fieldKey={[fieldKey, "workType"]}
                                                                                            label="Select work type"
                                                                                            rules={[{ required: true, message: "Please select work type" }]}
                                                                                        >
                                                                                            <Select placeholder="Select work type"
                                                                                                disabled={approvalStatus == 'Approved'}
                                                                                                onChange={(e) => {
                                                                                                    console.log("-------", e)
                                                                                                    setSelectedOption(prev => ({ ...prev, [index]: e }))
                                                                                                }}
                                                                                            >
                                                                                                <Option value={'Experience'} >Experiance</Option>
                                                                                                <Option value={'Internship'}>Internship</Option>
                                                                                            </Select>
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col md={12} lg={12} sm={24}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "companyName"]}
                                                                                            fieldKey={[fieldKey, "companyName"]}
                                                                                            label="Company Name"
                                                                                            rules={[{ required: true, message: "Please enter the company name" }]}
                                                                                        >
                                                                                            <Input placeholder="Enter the company name"
                                                                                                disabled={approvalStatus == 'Approved'}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col md={12} lg={12} sm={24}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "jobTitle"]}
                                                                                            fieldKey={[fieldKey, "jobTitle"]}
                                                                                            label="Job Title"
                                                                                            rules={[{ required: true, message: "Please enter the job title" }]}
                                                                                        >
                                                                                            <Input
                                                                                                disabled={approvalStatus == 'Approved'}
                                                                                                placeholder="Enter your job title" />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col md={12} lg={12} sm={24}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "startDate"]}
                                                                                            fieldKey={[fieldKey, "startDate"]}
                                                                                            label="Start Date"
                                                                                            dependencies={[['workItems', name, 'endDate']]}
                                                                                            rules={[{ required: true, message: "Please select the start date" },
                                                                                            ({ getFieldValue }) => ({
                                                                                                validator(_, value) {
                                                                                                    const endDate = getFieldValue(['workItems', name, 'endDate']);
                                                                                                    if (!value || !endDate || value.isBefore(endDate)) {
                                                                                                        return Promise.resolve();
                                                                                                    }
                                                                                                    return Promise.reject(
                                                                                                        new Error('Start date must be before the end date.')
                                                                                                    );
                                                                                                },
                                                                                            }),
                                                                                            ]}
                                                                                        >
                                                                                            <DatePicker
                                                                                                disabled={approvalStatus == 'Approved'}
                                                                                                style={{ width: "100%" }}
                                                                                                disabledDate={(current) => current && current > moment().add(1, 'month').endOf('day')}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col md={12} lg={12} sm={24}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "endDate"]}
                                                                                            fieldKey={[fieldKey, "endDate"]}
                                                                                            label="End Date"
                                                                                            dependencies={[['workItems', name, 'startDate']]}
                                                                                            rules={[{ required: true, message: "Please select the end date" },
                                                                                            ({ getFieldValue }) => ({
                                                                                                validator(_, value) {
                                                                                                    const startDate = getFieldValue(['workItems', name, 'startDate']);
                                                                                                    if (!value || !startDate || value.isAfter(startDate)) {
                                                                                                        return Promise.resolve();
                                                                                                    }
                                                                                                    return Promise.reject(
                                                                                                        new Error('End date must be after the start date.')
                                                                                                    );
                                                                                                },
                                                                                            }),
                                                                                            ]}
                                                                                        >
                                                                                            <DatePicker
                                                                                                disabled={approvalStatus == 'Approved'}
                                                                                                style={{ width: "100%" }} disabledDate={(current) => current && current > moment().add(1, 'month').endOf('day')} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col md={12} lg={12} sm={24}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "hrNumber"]}
                                                                                            fieldKey={[fieldKey, "hrNumber"]}
                                                                                            label="HR Contact number"
                                                                                            rules={[{ required: true, message: "Please enter details" },
                                                                                            {
                                                                                                pattern: /^[0-9]{10}$/,
                                                                                                message: 'Phone number must be 10 digits!',
                                                                                            },
                                                                                            ]}
                                                                                        >
                                                                                            <Input disabled={approvalStatus == 'Approved'} addonBefore={'+91'} type='number' style={{ width: "100%" }} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col md={12} lg={12} sm={24}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "hrEmail"]}
                                                                                            fieldKey={[fieldKey, "hrEmail"]}
                                                                                            label="HR Email"
                                                                                            rules={[{ required: true, message: "Email is required" },
                                                                                            {
                                                                                                type: 'email',
                                                                                                message: 'The input is not a valid email!',
                                                                                            }
                                                                                            ]}
                                                                                        >
                                                                                            <Input disabled={approvalStatus == 'Approved'} type='email' style={{ width: "100%" }} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col md={12} lg={12} sm={24}>
                                                                                        {selectedOption[index] === "Experience" ? <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "experienceLetter"]}
                                                                                            fieldKey={[fieldKey, "experienceLetter"]}
                                                                                            label="Upload Experience Letter"
                                                                                            rules={[
                                                                                                { required: true, message: "Please upload the experience letter" },
                                                                                            ]}
                                                                                        >
                                                                                            <Upload
                                                                                                accept='.pdf'
                                                                                                maxCount={1}
                                                                                                fileList={getFileList("experienceLetter", apiResponse, index)}
                                                                                                // fileList={fileList[index]["experienceLetter"] || []}
                                                                                                onChange={(info) => handleFileChange('experienceLetter', index, info)}
                                                                                                beforeUpload={() => false}
                                                                                                disabled={approvalStatus == 'Approved'}
                                                                                                showUploadList={{
                                                                                                    showRemoveIcon: true
                                                                                                }}
                                                                                            >
                                                                                                <Button icon={<UploadOutlined />}>Upload Experience Letter</Button>
                                                                                            </Upload>
                                                                                        </Form.Item> :
                                                                                            <Form.Item
                                                                                                {...restField}
                                                                                                name={[name, "internshipLatter"]}
                                                                                                fieldKey={[fieldKey, "internshipLatter"]}
                                                                                                label="Upload Internship Letter"
                                                                                                rules={[
                                                                                                    { required: true, message: "Please upload the internship letter" },
                                                                                                ]}
                                                                                            >
                                                                                                <Upload
                                                                                                    accept='.pdf'
                                                                                                    disabled={approvalStatus == 'Approved'}
                                                                                                    maxCount={1}
                                                                                                    fileList={getFileList("internshipLatter", apiResponse, index)}
                                                                                                    // fileList={fileList[index]["internshipLatter"] || []}
                                                                                                    onChange={(info) => handleFileChange('internshipLatter', index, info)}
                                                                                                    beforeUpload={() => false}
                                                                                                    showUploadList={{
                                                                                                        showRemoveIcon: true
                                                                                                    }}
                                                                                                >
                                                                                                    <Button icon={<UploadOutlined />}>Upload Internship Letter</Button>
                                                                                                </Upload>
                                                                                            </Form.Item>
                                                                                        }
                                                                                    </Col>
                                                                                    {selectedOption[index] === "Experience" && <Col md={12} lg={12} sm={24}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, "relievingLetter"]}
                                                                                            fieldKey={[fieldKey, "relievingLetter"]}
                                                                                            label="Upload relieving Letter"
                                                                                            rules={[
                                                                                                { required: true, message: "Please upload the relieving letter" },
                                                                                            ]}
                                                                                        >
                                                                                            <Upload
                                                                                                accept='.pdf'
                                                                                                maxCount={1}
                                                                                                disabled={approvalStatus == 'Approved'}
                                                                                                // fileList={fileList[index]["relievingLetter"] || []}
                                                                                                onChange={(info) => handleFileChange('relievingLetter', index, info)}
                                                                                                fileList={getFileList("relievingLetter", apiResponse, index)}
                                                                                                beforeUpload={() => false}
                                                                                                showUploadList={{
                                                                                                    showRemoveIcon: true
                                                                                                }}
                                                                                            >
                                                                                                <Button icon={<UploadOutlined />}>Upload Relieving Letter</Button>
                                                                                            </Upload>
                                                                                        </Form.Item>
                                                                                    </Col>}
                                                                                </Row>
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            <Space direction='horizontal'>
                                                                                {fields.length > 1 && <Button type="dashed" danger onClick={() => {
                                                                                    remove(name)
                                                                                    removeIndex(index)
                                                                                }} block
                                                                                    disabled={approvalStatus == 'Approved'}
                                                                                >
                                                                                    <MinusCircleOutlined />
                                                                                </Button>}
                                                                                {fields.length == (index + 1) && <Button type="dashed" onClick={() => add()} block>
                                                                                    <PlusCircleOutlined />
                                                                                </Button>}
                                                                            </Space>
                                                                        </Col>
                                                                    </Row >
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </Form.List>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <MobileFooter nextButtonText='Save & Next' next={onSubmit} previous={previous} isPrevious={true} />
        </>
    );
};

export default Experiance;
