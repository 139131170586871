import React, { useEffect, useState } from 'react';
import { Form, Button, Space, Col, Row, notification } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { postData } from '../../../../../actions/middleware';
import { routes } from '../../../../../actions/api-routes';
import { encryptData } from '../../../../../actions/crypto';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css';

const TextAreaForm = ({ onSubmitFromHeader, onClear, fileType }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (onSubmitFromHeader === 'text_submit') {
            form.submit();
            onClear();
        }
    }, [onSubmitFromHeader])

    const onFinish = async (values) => {
        const data = values.jobDiscription.filter(ele => convertHtmlToText(ele.jobDiscription))
        console.log("valuesvaluesvalues", values, data)
        if (data.length == values.jobDiscription.length) {
            const payload = {
                ...values,
                fileType: fileType
            }
            const resData = await postData(routes.jobs.bulk_upload, { data: encryptData(payload) });
            if (resData && resData.code === 200) {
                notification.success({ message: 'Success', description: 'Job added successfully. It will be posted within 30 to 60 minutes.' })
            }
        } else {
            notification.error({ message: 'Validation Failed', description: 'Please enter data for all text area' })
        }
    };

    const convertHtmlToText = (htmlContent) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;
        const extractedText = tempElement.textContent || tempElement.innerText;
        return extractedText;
    };

    return (
        <Form
            name="dynamic_form_nest_item"
            form={form}
            layout='vertical'
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.List
                initialValue={[{ jobDiscription: '' }]}
                name="jobDiscription"
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                            <Row gutter={16} key={key}>
                                <Col span={8}>
                                    <Form.Item
                                        {...restField}
                                        label="Job Discription"
                                        name={[name, 'jobDiscription']}
                                        fieldKey={[fieldKey, 'jobDiscription']}
                                        rules={[{ required: true, message: 'Job discription is required' }]}
                                    >
                                        <ReactQuill
                                            theme='snow'
                                            placeholder='Enter jd...'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Space>
                                        {fields.length > 1 && (
                                            <Button
                                                type="text"
                                                onClick={() => remove(name)}
                                                icon={<DeleteOutlined />}
                                            />
                                        )}
                                        {fields.length === index + 1 && (
                                            <Button
                                                type="text"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusCircleOutlined />}
                                            />
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                        ))}
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default TextAreaForm;
