
import { useState } from 'react';
import './index.css';
import CountersView from '../../home/counters';
import Testimonials from '../../home/testimonial';
import ClientsView from '../../home/clients';
import ChooseUs from '../../home/choose-us';
import Technologies from '../../home/technologies';
function About() {
  const [date, setDate] = useState(new Date());

  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div className="about-banner-wrap banner-space about-us-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">About Us</h1>
                  <h6 className="font-weight--normal text-white">At Accelgrowth, we excel in technological and IT services, including product engineering, warranty management, cloud infrastructure, network solutions, and more, delivering cutting-edge solutions to fuel your business growth. </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-large-images-wrapper section-space--ptb_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title mb-20">Our company</h6>
                  <h4 className="heading">Experience the power of our comprehensive <br /> IT services designed to guarantee  your <br /> business's resounding <span className="text-color-primary"> success</span></h4>
                </div>
              </div>
            </div>
            <div className="cybersecurity-about-box section-space--pb_70 about-us">
              <div className="row">
                <div className="col-lg-4 offset-lg-1">
                  <div className="modern-number-01">
                    <h2 className="heading  me-5"><span className="mark-text">{date.getFullYear() - 2018}+</span>Years’ Experience in IT</h2>
                    <h6 className="heading mt-30">More About Our Success Stories</h6>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1">
                  <div className="cybersecurity-about-text">
                    <div className="text">
                      Accelgrowth Technology: Empowering Success through Innovative IT Solutions,
                      <span className='text-color-primary'> Accelgrowth Technology </span> is a leading IT company based in Nagpur, India. With a strong commitment to excellence and a passion for technology, we specialize in providing exceptional software development and outsourcing services to clients worldwide. Our goal is to empower businesses with innovative solutions that drive growth, efficiency, and success.
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-11 offset-lg-1">
                  <div className="cybersecurity-about-text">
                    <div className="text">
                      <h6 className="heading">Our Expertise:</h6>
                      <p>
                        At Accelgrowth Technology, we bring together a team of highly skilled professionals who possess extensive expertise in a wide range of technologies. From web and mobile application development to cloud infrastructure management, from automation and artificial intelligence to data analytics, we have the knowledge and experience to tackle diverse projects with precision and efficiency. Our deep understanding of industry best practices, emerging trends, and cutting-edge technologies allows us to deliver customized solutions that meet the unique needs of our clients.
                      </p>
                      <h6 className="heading">
                        Client-Centric Approach:
                      </h6>
                      <p>
                        We firmly believe in building strong and long-lasting relationships with our clients. We understand that each business is unique, and we take the time to listen and understand our clients' goals, challenges, and requirements. By gaining a deep understanding of our clients' businesses, we are able to develop tailored solutions that address their specific needs. Our client-centric approach is rooted in transparency, effective communication, and collaboration. We keep our clients informed at every stage of the project, ensuring that they have full visibility into the progress and are actively involved in the decision-making process.
                      </p>
                      <h6 className="heading">
                        Innovation and Agility:
                      </h6>
                      <p>
                        In today's rapidly evolving digital landscape, innovation and agility are crucial for businesses to stay ahead. At Accelgrowth Technology, we foster a culture of innovation and continuously invest in research and development. We stay up-to-date with the latest industry trends, technologies, and methodologies to ensure that our clients benefit from the most advanced solutions. Our agile development approach enables us to adapt quickly to changing project requirements, delivering results efficiently and effectively. We embrace new technologies and methodologies to drive innovation and provide our clients with a competitive edge.
                      </p><h6 className="heading">
                        Comprehensive Service Portfolio:
                      </h6><p>
                        Our comprehensive service portfolio caters to the diverse needs of businesses across various industries. Whether you require the development of a complex web application, a user-friendly mobile app, or a robust software solution, we have the expertise to deliver. Our team follows industry best practices and adheres to stringent quality standards to ensure that the solutions we deliver are of the highest quality, performance, and security. Additionally, we offer services in cloud infrastructure management, network solutions, automation, artificial intelligence, data analytics, and more, enabling businesses to harness the power of technology to drive their success.
                      </p><h6 className="heading">
                        Commitment to Excellence:
                      </h6><p>
                        At Accelgrowth Technology, excellence is at the core of everything we do. We are committed to delivering exceptional results and exceeding our clients' expectations. We understand that our clients' success is our success, and we take pride in the long-term partnerships we build with them. Our dedicated team goes above and beyond to ensure that projects are delivered on time, within budget, and with the utmost quality. We believe in continuous improvement, and we consistently strive to enhance our skills, processes, and methodologies to stay at the forefront of the industry.
                      </p><h6 className="heading">
                        Support and Maintenance:
                      </h6><p>
                        Our commitment to our clients extends beyond project delivery. We provide comprehensive support and maintenance services to ensure that the solutions we develop continue to perform at optimal levels and adapt to evolving business needs. Our support team is readily available to address any issues, provide technical assistance, and ensure smooth operations. We understand the importance of maintaining the stability and performance of the systems we develop, allowing our clients to focus on their core business activities with peace of mind.
                      </p><h6 className="heading">
                        Community Engagement:
                      </h6><p>
                        Accelgrowth Technology recognizes the importance of giving back to the community. We actively engage in various social initiatives and strive to make a positive impact on society. We support educational programs, mentor aspiring technologists,
                        and contribute to local development efforts. By nurturing talent and fostering growth, we aim to create a better future for individuals and communities.
                      </p><h6 className="heading">
                        Conclusion:
                      </h6><p>
                        Accelgrowth Technology Pvt Ltd is your trusted partner for innovative IT solutions. With our expertise, client-centric approach, commitment to excellence, and comprehensive service portfolio, we empower businesses to achieve their goals and drive success. Whether you are a small startup or a large enterprise, we have the skills, experience, and passion to transform your vision into reality. Let us embark on a journey of innovation, collaboration, and growth together. Contact us today to discuss your project requirements and experience the Accelgrowth Technology advantage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="feature-images__six">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-06-image-01-120x120.webp" alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">What we can do?</h5>
                            <div className="text">
                              Custom software development, web and mobile app development, IT consulting, automation solutions, cloud infrastructure management, and more.</div>
                            <a href="#" className="box-images-arrow">
                              <span className="button-text">Discover now</span>
                              <i className="fas fa-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-06-image-02-120x120.webp" alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Become our partners?</h5>
                            <div className="text">Take the leap and become our esteemed partners. Together, we'll navigate the realms of technology, innovation, and success, driving exponential growth for your business.
                            </div>
                            <a href="#" className="box-images-arrow">
                              <span className="button-text">Discover now</span>
                              <i className="fas fa-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp" alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Need a hand?</h5>
                            <div className="text">When you need a reliable and skilled partner, we are here to lend a helping hand and deliver exceptional solutions for your business challenges.
                            </div>
                            <a href="#" className="box-images-arrow">
                              <span className="button-text">Discover now</span>
                              <i className="fas fa-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="section-under-heading text-center section-space--mt_60">Challenges are just opportunities in disguise. <a href="#">Take the challenge!</a></div>

              </div>
            </div>
          </div>
        </div>

        <CountersView />



        {/* <div className="about-resources-wrapper">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="resources-left-box">
                  <div className="resources-inner">
                    <h6 className="sub-title mb-20">Resources</h6>
                    <h3 className="heading">Get a copy of brochure on <span className="text-color-primary"> Brand New IT Tech.</span></h3>
                    <div className="button mt-30">
                      <a href="#" className="ht-btn ht-btn-md">Download now (3MB)</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="resources-right-box">
                  <div className="resources-right-inner text-center">
                    <div className="resources-images">
                      <img className="img-fluid" src="assets/images/banners/managed-it-services-book-cover.webp" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Technologies/>

        <ChooseUs/>

        <Testimonials />
        <ClientsView hideText={true}/>
      </div>
    </div>
  )
}

export default About;
