import React, { useEffect, useState } from 'react';
import { Form, Button, Space, Col, Row, notification } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { postData } from '../../../../actions/middleware';
import { routes } from '../../../../actions/api-routes';
import { encryptData } from '../../../../actions/crypto';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css';

const TextAreaForm = ({ onSubmitFromHeader, onClear }) => {
    const [form] = Form.useForm();

    // <Radio.Button value="text">Text</Radio.Button>
    // <Radio.Button value="images">Images</Radio.Button>
    // <Radio.Button value="pdf_doc_excel">PDF/Doc/Excel</Radio.Button>

    useEffect(() => {
        if (onSubmitFromHeader === 'text_submit') {
            form.submit();
        }
    }, [onSubmitFromHeader])

    const onFinish = async (values) => {
        const payload = {
            jobDiscription: [values],
            fileType: 'text'
        }
        const resData = await postData(routes.jobs.bulk_upload, { data: encryptData(payload) });
        if (resData && resData.code === 200) {
            onClear();
            notification.success({ message: 'Success', description: 'Job added successfully. It will be posted within 30 to 60 minutes.' })
        } else {
            notification.error({ message: 'Error', description: 'Something went wrong, please try again later.' })
        }
    };

    const convertHtmlToText = (htmlContent) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;
        const extractedText = tempElement.textContent || tempElement.innerText;
        return extractedText;
    };

    return (
        <Form
            name="dynamic_form_nest_item"
            form={form}
            layout='vertical'
            onFinish={onFinish}
            autoComplete="off"

        >
            <Row gutter={16} >
                <Col span={24}>
                    <Form.Item
                        name={'jobDiscription'}
                        rules={[{ required: true, message: 'Job discription is required' }]}
                    >
                        <ReactQuill
                            theme='snow'
                            placeholder='Please enter your requirements. Ensure that your job description includes details such as price, experience, and required skills....'
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default TextAreaForm;
