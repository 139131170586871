import React, { useEffect, useState } from 'react';
import {
  DashboardOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import './index.css';

function getItem(label, key, icon, children) {
  return {
    key,
    icon: icon,
    children,
    label,
  };
}

const AdminSideNav = ({ children, items = [], current, activeKey }) => {
  const [mode, setMode] = useState('inline');
  const [theme, setTheme] = useState('dark');
  const [active, setActive] = useState(activeKey);

  const navigate = useNavigate();

  useEffect(() => {
    if (activeKey) {
      setActive(activeKey)
    }
  }, [activeKey])

  return (
    <Menu
      mode={mode}
      onClick={(e) => {
        navigate(`/admin/${current}/${e.key}`)
        setActive(e.key)
      }}
      selectedKeys={active}
      theme={theme}
    >
      {items.map((ele, index) => <Menu.Item key={ele.key} className='menu-item'>
        <div className='icon'>
          {ele.icon ? ele.icon : <DashboardOutlined />}
        </div>
        <div className='text'>
          {ele.label}
        </div>
      </Menu.Item>)}
      {/* <Menu.Item key={'/agreement'} className='menu-item agreement-box'>
        <div className='icon'>
          {<DashboardOutlined />}
        </div>
        <div className='text'>
          Agreement
        </div>
      </Menu.Item> */}
    </Menu>
  );
};
export default AdminSideNav;