import './index.css';
function ServicesList() {
  return (
    <div>
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title">IT Services</h2>
                <ul className="breadcrumb-list">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item active">IT Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div className="feature-images-wrapper section-space--ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-wrap text-center">
                    <h3 className="heading">Preparing for your success, <br /> we provide <span className="text-color-primary">  truly prominent IT solutions.</span></h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="feature-images__one">
                    <div className="row">

                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-images style-01">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-01-image-01-100x108.webp")} alt="" />
                            </div>
                            <div className="content">
                              <h5 className="heading">IT Design </h5>
                              <div className="text">We provide the most responsive and functional IT design for companies and businesses worldwide.
                              </div>
                              <div className="circle-arrow">
                                <div className="middle-dot"></div>
                                <div className="middle-dot dot-2"></div>
                                <a href="#">
                                  <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-images style-01">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-01-image-02-100x108.webp")} alt="" />
                            </div>
                            <div className="content">
                              <h5 className="heading">IT Management</h5>
                              <div className="text">It’s possible to simultaneously manage and transform information from one server to another.
                              </div>
                              <div className="circle-arrow">
                                <div className="middle-dot"></div>
                                <div className="middle-dot dot-2"></div>
                                <a href="#">
                                  <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-images style-01">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-01-image-03-100x108.webp")} alt="" />
                            </div>
                            <div className="content">
                              <h5 className="heading">Data Security</h5>
                              <div className="text">Back up your database, store in a safe and secure place while still maintaining its accessibility.
                              </div>
                              <div className="circle-arrow">
                                <div className="middle-dot"></div>
                                <div className="middle-dot dot-2"></div>
                                <a href="#">
                                  <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-images style-01">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-01-image-04-100x108.webp")} alt="" />
                            </div>
                            <div className="content">
                              <h5 className="heading">Business Reform</h5>
                              <div className="text">We propose feasible & practical plans for successfully transform businesses based on their needs.
                              </div>
                              <div className="circle-arrow">
                                <div className="middle-dot"></div>
                                <div className="middle-dot dot-2"></div>
                                <a href="#">
                                  <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-images style-01">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-01-image-05-100x108.webp")} alt="" />
                            </div>
                            <div className="content">
                              <h5 className="heading">Infrastructure Plan</h5>
                              <div className="text">Mitech takes into account all conditions and budgets needed for building infrastructure plan.
                              </div>
                              <div className="circle-arrow">
                                <div className="middle-dot"></div>
                                <div className="middle-dot dot-2"></div>
                                <a href="#">
                                  <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-images style-01">
                          <div className="image-box-wrap">
                            <div className="box-image">
                              <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-01-image-06-100x108.webp")} alt="" />
                            </div>
                            <div className="content">
                              <h5 className="heading">Firewall Advancement</h5>
                              <div className="text">Enhancing the strength and security of firewalls to protect online data from malicious sources.
                              </div>
                              <div className="circle-arrow">
                                <div className="middle-dot"></div>
                                <div className="middle-dot dot-2"></div>
                                <a href="#">
                                  <i className="fas fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section-under-heading text-center section-space--mt_80">Challenges are just opportunities in disguise. <a href="#">Take the challenge!</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesList;
