import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Upload, Button, Tabs } from 'antd';
import './../index.css';
import ListItem from './listItem';
import { getData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import { decryptData, encryptData } from '../../../actions/crypto';
import NewRequirments from './tabs/newRequirment';
import RejectedRequirment from './tabs/RejectedRequirment';
import ApprovedRequirment from './tabs/ApprovedRequirment';
import BulkApprovedRequirment from './bulk/ApprovedRequirment';
import BulkRejectedRequirment from './bulk/RejectedRequirment';
import BulkNewRequirments from './bulk/newRequirment';

const ManageRequirments = () => {
    const [listFor, setListFor] = useState('review');
    const items = [
        {
            key: 'review',
            label: 'New Requirements',
        },
        {
            key: 'rejected',
            label: 'Rejected Requirements',
        },
        {
            key: 'approved',
            label: 'Approved Requirements',
        },
        {
            key: 'BulkReq',
            label: 'Bulk Req',
        },
        {
            key: 'RejectedBulk',
            label: 'Rejected Bulk Req',
        },
        {
            key: 'ApprovedBulk',
            label: 'Approved Bulk Req',
        },
    ];

    const onChange = (key) => {
        setListFor(key)
    };

    return (
        <div>
            <div className='tabs-bg'>
                <Tabs size='large' defaultActiveKey="review" items={items} onChange={onChange} />
            </div>
            {listFor == 'review' && <NewRequirments />}
            {listFor == 'rejected' && <RejectedRequirment />}
            {listFor == 'approved' && <ApprovedRequirment />}

            {listFor == 'BulkReq' && <BulkNewRequirments />}
            {listFor == 'RejectedBulk' && <BulkRejectedRequirment />}
            {listFor == 'ApprovedBulk' && <BulkApprovedRequirment />}
        </div>
    );
}

export default ManageRequirments;
