import { useNavigate } from 'react-router-dom';
import './index.css';
import { Form, Input, Button, Checkbox } from 'antd';
import { validateEmail } from '../admin/common/validate';
import { postData } from '../actions/middleware';
import { error, success } from '../admin/common/Notify';
import { SetCookies, getUserData } from '../actions/auth';
import { useEffect, useState } from 'react';
import OTPLogin from './otp.login';
import { setLocalStorage } from '../actions/localStorage';
import constant from '../actions/constant';

function Login() {
  const navigate = useNavigate();
  const [isOTP, setOTP] = useState(false);

  const onFinish = async (values) => {
    const resData = await postData('/vendor/login', values)
    if (resData && resData.code == 200) {
      setLocalStorage(constant.LOGIN_KEY, values.workEmail);
      navigate('/auth/verify-otp')
    } else {
      error("Validation Faild", 'Please enter correct credentials.')
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [])


  const getUserDetails = () => {
    const data = getUserData();
    if (data) {
      navigate('/admin')
    }
  }

  return (
    <div className="row">
      <div className="col-8 center hide-mobile">
        <img src={require('./../assets/images/background/78.jpg')} className='bg_image_fit' />
      </div>
      <div className="col-lg-4 col-md-12 center p-10">
        {!isOTP ? <div className="projects-wrap style-04 wow move-up">
          <div className='login-form-box'>
            <div className='text-center p-10'>
              <h5 className='heading'>Sign in to start your session</h5>
            </div>
            <div>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                layout='vertical'
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="Enter Email Id"
                  name="workEmail"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                    {
                      validator: validateEmail
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType='submit' size={'large'} style={{ width: '100%' }}>
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className='text-center'>
            <div>Don't have an account ? <Button className='no-padding' type='link' onClick={() => navigate('/auth/register')} >Sign up</Button> </div>
            <div> Please <Button type='link' className='no-padding'>Contact Us</Button> if you require any assistance  </div>
          </div>
        </div> : <OTPLogin />
        }

      </div>
    </div>
  )
}

export default Login;
