
import React, { useEffect, useState } from 'react';
import ScreenshotComponent from '../Common/SnapShotCapturing/Snap';
import ScreenRecorderWithCamera from '../Common/RecordScreen';
import { Alert, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routing from '../routing';

const ProtectedComponent = ({ children }) => {
    const dispatch = useDispatch()
    const [warning, setWarning] = useState();
    const navigate = useNavigate();
    const IS_FULL_SCREEN = useSelector(({ app }) => app.IS_FULL_SCREEN);

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleResize = () => {
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isDesktop = screenSize.width >= 1024;
    useEffect(() => {
        if (!isDesktop) {
            Warning();
        }
    }, [isDesktop]);

    function Warning() {
        Modal.warning({
            title: 'Desktop Access Required',
            content: (
                <div>
                    <p>This test can only be taken on a desktop computer. For the best experience and functionality, please switch to a desktop device to proceed.</p>
                </div>
            ),
            onOk() {
                navigate(routing.about_test)
            },
        });
    }

    useEffect(() => {
        handleAccessRequest();
    }, [])

    const handleAccessRequest = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setWarning(null);
        } catch (error) {
            setWarning('Camera and microphone access is required for this application.');
            notification.error({
                message: 'Camera and microphone access is required',
                description: `Camera and microphone access are required for accelgrowth.in. Please click on 'Allow Access.' If it is not working, please check your browser settings and allow the camera and microphone for https://accelgrowth.in.`
            })
        }
    };

    return (
        <div>
            <ScreenshotComponent>
                <ScreenRecorderWithCamera >
                    {children}
                </ScreenRecorderWithCamera>
            </ScreenshotComponent>
        </div>
    );
};

export default ProtectedComponent;
