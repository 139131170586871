import './index.css';
function WhyWe() {
  return (
    <div className="gradation-process-area section-space--ptb_40">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="gradation-title-wrapper section-space--mb_60">
              <div className="gradation-title-wrap ">
                <h6 className="section-sub-title text-black mb-20">How we works</h6>
                <h4 className="heading">Why we <span className="text-color-primary">Stand Out <br /> from others</span></h4>
              </div>
              <div className="gradation-sub-heading">
                <h3 className="heading"><mark>10</mark> Reasons</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ht-gradation style-01">
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.1s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">1</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Expertise &amp; Experience</h6>
                  <div className="text">We bring a wealth of expertise and years of experience in the industry, ensuring that we deliver top-notch solutions tailored to your unique business needs.</div>
                  {/* <a className="gradation-btn" href="#">
                  <span className="button-text" data-text="Look more">
                    Look more </span>
                  <span className="button-icon fas fa-arrow-right"></span>
                </a> */}
                </div>
              </div>
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.15s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">2</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Customized Solutions</h6>
                  <div className="text">We understand that every business is unique. Our team works closely with you to understand your requirements and provide customized solutions that align with your goals and objectives.</div>
                  {/* <a className="gradation-btn" href="#">
                  <span className="button-text" data-text="Look more">
                    Look more </span>
                  <span className="button-icon fas fa-arrow-right"></span>
                </a> */}
                </div>
              </div>
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.20s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">3</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Agile &amp; Efficient</h6>
                  <div className="text">We follow agile development methodologies, enabling us to adapt quickly to changing project requirements and deliver results efficiently. We prioritize effective communication and collaboration to ensure project success.</div>
                  {/* <a className="gradation-btn" href="#">
                  <span className="button-text" data-text="Look more">
                    Look more </span>
                  <span className="button-icon fas fa-arrow-right"></span>
                </a> */}
                </div>
              </div>
              <div className="item item-1 animate wow fadeInRight" data-wow-delay="0.25s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">4</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Skilled Team</h6>
                  <div className="text">Our team comprises highly skilled professionals who are experts in their respective fields. They stay updated with the latest industry trends and technologies to deliver cutting-edge solutions.</div>
                  {/* <a className="gradation-btn" href="#">
                  <span className="button-text" data-text="Look more">
                    Look more </span>
                  <span className="button-icon fas fa-arrow-right"></span>
                </a> */}
                </div>
              </div>
              <div className="item item-1 animate wow fadeInRight" data-wow-delay="0.25s">
                <div className="line"></div>

                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">5</h6>
                </div>
                <div className="line-right"></div>
                <div className="content-wrap">
                  <h6 className="heading">Quality &amp; Precision</h6>
                  <div className="text">We have a strong focus on delivering high-quality solutions. Our rigorous quality assurance processes ensure that the end product meets the highest standards of performance, functionality, and security.</div>
                  {/* <a className="gradation-btn" href="#">
                  <span className="button-text" data-text="Look more">
                    Look more </span>
                  <span className="button-icon fas fa-arrow-right"></span>
                </a> */}
                </div>
              </div>
            </div>

            <div className="ht-gradation style-01">
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.1s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">10</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Positive Track Record</h6>
                  <div className="text"> Our track record speaks for itself. We have a history of successful projects and satisfied clients who have achieved their business goals with our assistance.</div>
                </div>
              </div>
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.15s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">9</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Long-Term Partnership</h6>
                  <div className="text">We believe in building long-term relationships with our clients. Our goal is to become your trusted technology partner, supporting your growth and providing ongoing support and maintenance.</div>
                </div>
              </div>
              <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.20s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">8</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Client-Centric Approach</h6>
                  <div className="text">Your satisfaction is our priority. We take the time to listen to your needs, provide regular updates, and offer excellent customer support throughout the project lifecycle.</div>
                </div>
              </div>
              <div className="item item-1 animate wow fadeInRight" data-wow-delay="0.25s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">7</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Competitive Pricing</h6>
                  <div className="text">We offer competitive pricing structures to provide you with the best value for your investment. Our cost-effective solutions help you optimize your budget without compromising on quality.</div>
                </div>
              </div>
              <div className="item item-1 animate wow fadeInRight" data-wow-delay="0.25s">
                <div className="line"></div>
                <div className="circle-wrap">
                  <div className="mask">
                    <div className="wave-pulse wave-pulse-1"></div>
                    <div className="wave-pulse wave-pulse-2"></div>
                    <div className="wave-pulse wave-pulse-3"></div>
                  </div>
                  <h6 className="circle">6</h6>
                </div>
                <div className="content-wrap">
                  <h6 className="heading">Timely Delivery</h6>
                  <div className="text">We value your time and understand the importance of meeting deadlines. Our team is committed to delivering projects on time, ensuring that you can launch and implement your solutions without delay.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyWe;
