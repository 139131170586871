// AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TextToSpeech from '../text-voice/index';

const TextToSpeechRouting = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<TextToSpeech />} />
      </Routes>
    </>
  );
};

export default TextToSpeechRouting;
