import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button, Col, Pagination, Radio, Row, Table, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import './../index.css';
import ProfileReceived from '../../profileRecived';

const MyRequirment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [tab, setTab] = useState('APPROVED');
    const [subTab, setSubTab] = useState('OPEN');

    const [selectedReq, setSelectedReq] = useState(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const onChange = (key) => {
        setTab(key)
    };

    const items = [
        {
            key: 'APPROVED',
            label: 'Approved',
        },
        {
            key: 'REJECTED',
            label: 'Rejected',
        }, {
            key: 'WAITING_FOR_APPROVAL',
            label: 'Waiting for Approval',
        },
        {
            key: 'DRAFT',
            label: 'Draft',
        }
    ];

    const onChangeSubTabs = (value) => {
        setSubTab(value)
    };

    const subTabs = [
        {
            key: 'OPEN',
            label: 'Open',
        },
        {
            key: 'CLOSED',
            label: 'Closed',
        },
    ];


    return (
        <div className=''>
            <div className='d-f j-sb table-header-setting'>
                <Tabs defaultActiveKey="1" size='large' items={items} onChange={onChange} />
                <Button type='primary' className='margin-top-button' onClick={() => navigate('/admin/requirements/add')} >Create Requirement</Button>
            </div>
            {tab == 'APPROVED' && <div className='sub-tab'>
                <Tabs defaultActiveKey="1" type='card' size='small' items={subTabs} onChange={onChangeSubTabs} />
            </div>}
            {tab && <ProfileReceived subTab={subTab} mainTab={tab} />}
        </div>
    );
};

export default MyRequirment;
