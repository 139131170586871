import moment from 'moment';
import './index.css';

function ProjectDetails({ item }) {
  return (<>
    <div>
      <div className='d-f space-between'>
        <div className='heading-text-1'>Description</div>
        <div className='set-date text'>{moment(item.startDate).format('MMMM YYYY')} to {moment(item.endDate).format('MMMM YYYY')}</div>
      </div>
      <div className='text'>{item.projectDescription}</div>
      <div className='text capitalise bold'>Roles and Responsibilites</div>
      <div className='text'>{item.responsibilities}</div>
      <div className='text capitalise bold'>Technologies</div>
      <div className='text'>{item.technologiesUsed.join(', ')}</div>
      <div className='text capitalise bold'>Industry</div>
      <div className='text'>{item.industry}</div>
    </div>
  </>)
}


export default ProjectDetails;