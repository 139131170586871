import React from 'react';
import { Checkbox, Col, Row } from 'antd';
const CheckboxGroup = Checkbox.Group;

const CheckboxGroups = ({ plainOptions = [], onChange }) => {
  return (
    <div>
      <Checkbox.Group
        style={{
          width: '100%',
        }}
      >
        <Row>
          <Checkbox.Group options={plainOptions}  onChange={onChange} />

          {/* {plainOptions.map((ele, index) => <Col span={24} key={index}>
            <Checkbox onChange={onChange} value={ele}>{ele}</Checkbox>
          </Col>)} */}
        </Row>
      </Checkbox.Group>
    </div>
  );
}

export default CheckboxGroups;
