import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ApplicantProfile from '../pages/apply/job-discriptions/Tabs/profile';

const ApplyRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/:id" element={<ApplicantProfile />} />
      </Routes>
    </>
  );
};

export default ApplyRoutes;
