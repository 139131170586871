import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Col, Divider, Input, Modal, Row, Space, Steps, message } from 'antd';
import { useState, useEffect } from 'react';
import ProfileForm from './profile';
import Experience from './Experiance';
import DynamicForm from './Skills';
import ProjectForm from './Projects';
import EmploymentForm from './Employement';
import EducationForm from './Educations';
import FinishForm from './Finish';
import SkillForm from './Skills';

import { useLocation } from 'react-router-dom';
import { getData, postData } from '../../actions/middleware';
import './index.css';
import ButtonSection from '../common/layout/ButtonSection';
import ContentSection from '../common/layout/ContentSection';
import { CopyOutlined } from '@ant-design/icons';
import { decryptData, encryptData } from '../../actions/crypto';
import { routes } from '../../actions/api-routes';

const UploadProfile = ({ pinned, isPublic = false }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [submitForm, setSubmitForm] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [emailToSendLink, setEmailToSendLink] = useState('');
  const [emailLinkError, setEmailSendLinkError] = useState('');

  const paramValue = queryParams.get('developer');
  const publicValue = queryParams.get('public');
  const currentTab = queryParams.get('tab');
  const [isUserAllowed, setAllowUser] = useState(false)

  const [current, setCurrent] = useState(currentTab || 0);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!publicValue) {
      if (paramValue) {
        navigate(`/admin/profiles/upload-profile?developer=${paramValue}&tab=${currentTab}`)
        getProfileData()
      } else {
        navigate(`/admin/profiles/upload-profile?tab=${current}`)
      }
    } else {
      if (paramValue) {
        navigate(`/public/profiles/upload-profile?public=${publicValue}&developer=${paramValue}&tab=${currentTab}`)
        getProfileData()
      } else {
        navigate(`/public/profiles/upload-profile?public=${publicValue}&tab=${current}`)
      }
    }
  }, [paramValue, publicValue, current])

  const getProfileData = async () => {
    const resData = await getData(`/vendor/developers/profile/${paramValue}`);
    if (resData && resData.code == 200) {
      setUserData(decryptData(resData.data))
    }
  }

  const onChange = (value) => {
    setCurrent(value);
  };

  const onSubmit = () => {
    switch (parseInt(current)) {
      case 0:
        setSubmitForm('PROFILE')
        break;
      case 1:
        setSubmitForm('EXPERIANCE')
        break;
      case 2:
        setSubmitForm('SKILLS')
        break;
      case 3:
        setSubmitForm('PROJECTS')
        break;
      case 4:
        setSubmitForm('EMPLOYMENT')
        break;
      case 5:
        setSubmitForm('EDUCATION')
        break;
      case 6:
        setSubmitForm('FINISH')
        break;
    }
  }

  const askDeveloperTofill = () => {
    setVisible(true)
  }

  const copyToClipboard = async () => {
    try {
      getPubliclink()
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const getPubliclink = async () => {
    console.log("isUserAllowedisUserAllowed", isUserAllowed)
    const resData = await getData(routes.public.link, { access: encryptData(isUserAllowed) });
    if (resData && resData.code == 200) {
      await navigator.clipboard.writeText(`/public/profiles/upload-profile?public=${resData.data}`);
      message.success('Copied to clipboard')
    }
  }

  const validateEmail = (e) => {
    setEmailToSendLink(e.target.value)
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(e.target.value)) {
      setEmailSendLinkError(null)
    } else {
      setEmailSendLinkError("Enter valid email")
    }
  }

  const onSendLink = async () => {
    const resData = await postData(routes.send.link, { data: encryptData() });
    if (resData.code == 200) {

    } else {
      message.error('Something went wrong please try again later.')
    }
  }

  return (
    <div className='admin-view bg-fff full-bg'>
      <Modal
        onCancel={() => {
          setEmailSendLinkError(null)
          setEmailToSendLink(null)
          setVisible(false)
        }}
        open={isVisible}
        title={'Create a public link'}
        footer={<>
          {emailToSendLink &&
            <div>
              <Button disabled={emailLinkError} onClick={() => onSendLink(emailToSendLink)} type='primary'>Send Link</Button>
            </div>
          }
        </>}
      >
        <div>
          <div className='check-box-price'>
            <Checkbox value={isUserAllowed} onChange={(e) => setAllowUser(e.target.checked)}>Allow user to add price</Checkbox>
            <div className='hint'>The link will open with a pricing option, and users can add a price for this profile using the link.</div>
          </div>
          <div>
            Give access to email
          </div>
          <Input type='email' value={emailToSendLink} onChange={(e) => validateEmail(e)} />
          {emailLinkError && <div style={{ color: 'red' }}>{emailLinkError}</div>}
        </div>
        <div>
          <Divider type='horizontal'>Or</Divider>
        </div>
        <div onClick={() => copyToClipboard()} className='copylink'>
          Copy public Link <CopyOutlined />
        </div>
      </Modal>
      <ButtonSection pinned={pinned} isPublic={isPublic}>
        <div className='flex-view'>
          <div>
            <h6>Developer Resume</h6>
          </div>
          <div>
            <Space>

              <Button type="default" size='middle' onClick={() => navigate(`/${publicValue ? 'public' : 'admin'}/profiles/quik/upload-profile${publicValue ? '?public=' + publicValue : ''}`)}>
                Quik Upload
              </Button>

              {!publicValue && <Button type="default" size='middle' onClick={() => askDeveloperTofill()}>
                Genrate public link
              </Button>}

              <Button type="default" size='middle' onClick={() => onChange(current ? current - 1 : 0)} disabled={!current} >
                Previous
              </Button>

              <Button type="default" size='middle' disabled>
                Preview Resume
              </Button>

              <Button type="default" size='middle'>
                Cancel
              </Button>

              <Button type="primary" size='middle' onClick={() => onSubmit()}>
                Save
              </Button>
            </Space>
          </div>
        </div>
      </ButtonSection>
      <ContentSection style={{ height: windowHeight - (150 - 15) }}>
        <Row gutter={16} style={{ margin: 0 }}>
          <Col span={3}>
            <div className='steps-box' style={{ position: 'fixed', height: isPublic ? '100%' : windowHeight - (150 - 15) }}>
              <Steps
                current={current}
                size='default'
                labelPlacement='horizontal'
                direction="vertical"
                type='default'
                items={[
                  {
                    title: 'Profile',
                  },
                  {
                    title: 'Experience',
                  },
                  {
                    title: 'Skills',
                  },
                  {
                    title: 'Projects',
                  },
                  {
                    title: 'Employment',
                  },
                  {
                    title: 'Education',
                  },
                  {
                    title: 'Finish',
                  },
                ]} />
            </div>
          </Col>
          <Col className='formView' span={21} >
            <div className='p-5-10 formView' style={{ height: windowHeight - 140 }}>
              {current == 0 && <ProfileForm
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}
              {current == 1 && <Experience
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}
              {current == 2 && <SkillForm
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}
              {current == 3 && <ProjectForm
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}
              {current == 4 && <EmploymentForm
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}
              {current == 5 && <EducationForm
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}
              {current == 6 && <FinishForm
                nextCall={(e) => onChange(e)}
                data={userData}
                onSubmitFromHeader={submitForm}
                onClear={() => setSubmitForm(null)}
              />}
            </div>
          </Col>
        </Row>
      </ContentSection>
    </div>
  );
};

export default UploadProfile;
