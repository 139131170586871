import React from 'react';
import { useState } from 'react';
import { Alert, Avatar, Button, Checkbox, Col, Divider, Image, Modal, Radio, Row, Space, Table, Tag, message } from 'antd';
import { UserAddOutlined, GlobalOutlined, HomeOutlined } from '@ant-design/icons';
import './index.css';
import { routes } from '../../../../actions/api-routes';
import { encryptData } from '../../../../actions/crypto';
import { putData } from '../../../../actions/middleware';
import { useNavigate } from 'react-router-dom';

const ListItem = ({ style, item, reloadPage }) => {
    const navigate = useNavigate()

    const approveReject = async (_id, status) => {
        const data = encryptData({ approvalStatus: status })
        const resData = await putData(routes.profiles.admin_update + encryptData(_id), { data })
        if (resData && resData.code == 200) {
            message.success('Successfully updated')
            reloadPage();
        } else {
            message.error('Something went wrong, please try again later')
        }
    }

    const prompt = (_id, status) => {
        Modal.confirm({
            title: 'Confirm',
            content: 'Are you sure you want to reject this item?',
            onOk() {
                approveReject(_id, status)
            },
            onCancel() { },
        });
    }

    return (
        <div className='view-box bg-white job-dscription profile' style={style}>
            <div className='d-f justify-between'>
                <div>
                    <div className='job-title-view'>
                        <div className='img-container-developer'>
                            <Image src={item?.photo ? routes.fileServer + item?.photo : require('./../../../../assets/images/logo/logo-w.png')}
                                onError={(e) => { e.target.src = require('./../../../../assets/images/logo/logo-w.png') }}
                            />
                        </div>
                        <div className='heading-view-for-rol padding-left'>
                            <div className='job-title-box'>
                                <div className='main-text'> {[item?.firstName, item?.lastName].join(' ')}</div>
                                <div className='sub-text'> {item?.Experiance}+ Year</div>
                            </div>
                            <div className='price-view'>
                                {item?.JobTitle}
                            </div>
                            <div className='price-view'>
                                <Tag color='magenta' bordered={false}>{item?.locations.join(' | ')}</Tag> | <Tag color='cyan' bordered={false}>{item?.currency} {item?.minimum}LPM - {item?.maximum}LPM</Tag>
                            </div>
                            {item?.onsiteOptions.length > 0 && <div>
                                <Tag color='magenta' bordered={false}>{item?.onsiteOptions.join(',')}</Tag>
                            </div>}
                            <div className=''>
                                {item?.skills?.map(ele => <span className='tag'> #{ele.skillName}</span>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Button type='link' className='edit-button' onClick={() => {
                        navigate(`/admin/profiles/upload-profile?developer=${encryptData(item._id)}`)
                    }}>Edit</Button>
                </div>
            </div>
            <div className='d-f justify-between'>
                <Space>
                    <div><Space><GlobalOutlined />Accelgrowth Technology</Space></div>
                </Space>
                <Space>
                    {item.approvalStatus !== 'REJECTED' && <Button type='link' danger onClick={() => prompt(item._id, 'REJECTED')}>Reject</Button>}
                    {item.approvalStatus !== 'APPROVED' && <Button type='link' onClick={() => approveReject(item._id, 'APPROVED')}>Appove</Button>}
                </Space>
            </div>
        </div>
    );
};

export default ListItem;
