import React from 'react';
import { Form, Select, Input, Checkbox, DatePicker, Radio, Divider, Button, Descriptions } from 'antd';
import Dailog from '../../../components/modal';
import PdfViewer from '../../../components/PdfViewer';
import { useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import MobileFooter from './mobile-footer/mobile.footer';
import { routes } from '../../../actions/api-routes';
import ProviewComponent from './profileView';
const { Option } = Select;

const JobDetails = ({ data, previous, next }) => {

  return (
    <>
      <div className='row'>
        <div className='col-lg-3 profile-view-box'>
          <ProviewComponent data={data} />
        </div>
        <div className='col-lg-9 scroll-view-box'>
          <div>
            <div className='heading-padding'>
              <h6>Job Discriptions</h6>
              <div className='button-box d-dextop'>
                <Button className='button-view margin-lr' onClick={previous}>Previous</Button>
                <Button className='button-view d-dextop' onClick={next} type='primary'>Next</Button>
              </div>
            </div>
            <Divider className='divider' />
            <p dangerouslySetInnerHTML={{ __html: data?.appliedFor?.jobDescription }} className='scroll-view'>
            </p>
          </div>
        </div>
      </div>
      <MobileFooter previous={previous} next={next} isPrevious={true} />
    </>
  );
};

export default JobDetails;
