import './index.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useEffect, useState } from 'react';

function Dailog({ isOpen, onClose, children, title, ...props }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      onOpenModal();
    }
  }, [isOpen]);

  const onOpenModal = () => {
    setOpen(true)
  };
  const onCloseModal = () => {
    setOpen(false)
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        {...props}
      >
        <div className='modal-head heading'>{title}</div>
        <div className='divider'></div>
        {children}
      </Modal >
    </>
  )
}

export default Dailog;