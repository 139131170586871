import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Tag, Divider, Button, Collapse } from 'antd';
import { routes } from '../../../actions/api-routes';
import Skills from '../../company/careers/showSkils';
import commonFnc from '../../../admin/common/commonFunction';
import { EyeOutlined, MoreOutlined, LinkOutlined, CheckCircleTwoTone, CheckCircleOutlined } from '@ant-design/icons';
import RequestForm from './RequestForm';

const GetHeading = ({ title }) => {
  return <div>
    <div className='heading'>{title}</div>
    <Divider className='no-margin' />
  </div>
}

const GetHeader = () => {
  return <Row>
    <Col span={8}>
      <GetHeading title={"Developer Details"} />
    </Col>
    <Col span={6}>
      <GetHeading title={"Skills and Experiance"} />
    </Col>
    <Col span={7}>
      <GetHeading title={"Budget Monthly"} />
    </Col>
    <Col span={3}>
      <GetHeading title={"Action"} />
    </Col>
  </Row>
}

const GetTableBody = ({ isExpand, data, type, onRemove, onSelect, onClick }) => {
  if (data && !data._id) {
    return <></>
  }

  return <Row gutter={40} onClick={onClick} className='pointer'>
    <Col span={8}>
      <div className='center-box'>
        <div className='center-text'>
          <div className='d-f'>
            <div>
              <div className={isExpand ? 'avatar-container-1' : 'avatar-container-1 minimum-width'}>
                <img src={data.photo ? routes.fileServer + data.photo : require('./../../../assets/images/logo/logov1.png')} className='avatar-image-1' />
              </div>
              {/* <div className='checked-profile'>
            <CheckCircleOutlined />
          </div> */}
            </div>
            <div className='name-sections'>
              <div className='name'>
                {data.firstName} {data.lastName}
                <span className='profile-id'> AGPF{data.profile}</span>
              </div>
              <div className='text jobtitle'>
                {data.JobTitle}, {data.Experiance}+ Years
              </div>
              {isExpand && data.skills && data.skills.length && <div>
                <Skills ele={{ skils: data.skills.map(e => e.skillName) }} />
              </div>}
              {isExpand && <div>
                <Button type='link' className='no-padding' icon={<LinkOutlined />}>View Resume</Button>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </Col>
    <Col span={6}>
      <div className='center-box'>
        <div className='center-text'>
          {!isExpand ? <div className='text-center'>
            <Skills ele={{ skils: data.skills.map(e => e.skillName) }} />
          </div> :
            <>
              <div>
                {data.skills && data.skills.length && data.skills.map((ele, index) => <div className='d-f space-between' key={index}>
                  <div>
                    {ele?.skillName}
                  </div>
                  <div>
                    {ele?.years}.{commonFnc.formatNumber(ele?.months)} Years
                  </div>
                </div>)}
              </div>
              <div>
                <Button
                  icon={<EyeOutlined />}
                  type='link' className='no-padding'>See More</Button>
              </div>
            </>}
        </div>
      </div>
    </Col>
    <Col span={7}>
      <div className='center-box'>
        <div className='center-text'>
          {!isExpand && <>
            <div className='text-center'>
              {data.currency} {commonFnc.ThousendSeprator(data.minimum)} -  {commonFnc.ThousendSeprator(data.maximum)}/-
            </div>
          </>}
          {isExpand && <>
            <div className='d-f space-between'>
              <div>
                Minumum
              </div>
              <div>
                {data.currency} {commonFnc.ThousendSeprator(data.minimum)}/-
              </div>
            </div>
            <div className='d-f space-between'>
              <div>
                Maximum
              </div>
              <div>
                {data.currency} {commonFnc.ThousendSeprator(data.maximum)}/-
              </div>
            </div>
          </>}
        </div>
      </div>
    </Col>
    <Col span={3}>
      <div className='center-box'>
        <div className='center-text'>
          {type == 'remove' && <Button onClick={() => onSelect(data._id)} type='link' className='no-padding'>Select</Button>}
          {type == 'selected' && <Button type='link' onClick={() => onRemove(data._id)} className='no-padding'>Remove</Button>}
        </div>
      </div>
    </Col>
    <Divider className='no-margin' />
  </Row>
}

const HireDeveloperModal = ({ isVisible, onClose, data }) => {
  const [showMore, setShowMore] = useState(false)
  const [selectedProfiles, setSelectedProfiles] = useState([data])
  const profiles = [
    {
      "locations": [
        "remote"
      ],
      "onsiteOptions": [],
      "softSkills": [
        "singing",
        "Dancing"
      ],
      "currency": "INR",
      "pdfGenrated": true,
      "approvalStatus": "APPROVED",
      "status": "active",
      "_id": "65574694f465cc6260f687b2",
      "firstName": "Maroti",
      "lastName": "KathokeKK",
      "country": "Amerika",
      "city": "Nagpur",
      "citizenship": "Indian",
      "englishProficiency": "beginner",
      "profileID": "60749504_Maroti_KathokeK",
      "addedByVendor": "655105ade2119714dc166284",
      "skills": [
        {
          "_id": "65589ec1ab78a4662c416add",
          "skillName": "React Native",
          "years": "3",
          "months": "2"
        },
        {
          "_id": "65589ec1ab78a4662c416ade",
          "skillName": "Nodejs",
          "years": "3",
          "months": "2"
        },
        {
          "_id": "65589ec1ab78a4662c416adf",
          "skillName": "Java",
          "years": "2",
          "months": "1"
        }
      ],
      "projects": [
        {
          "technologiesUsed": [
            "Spring Boot",
            "Nodejs"
          ],
          "_id": "65589efcab78a4662c416af4",
          "projectTitle": "Sgk Android App &Website",
          "startDate": "2023-01-18T11:24:16.601Z",
          "endDate": "2023-03-18T11:24:18.218Z",
          "industry": "ERP",
          "projectDescription": "In this Project I worked on Project it is Fintech Based product in this I worked on various store procedure converted Various stored procedures into Java Code and created backend for various statements such as income statement and Finance Statement.",
          "responsibilities": "I worked on Project it is Fintech Based product in this I worked on various store procedure converted Various stored procedures into Java Code and created backend for various statements such as income statement and Finance Statement. Created Various apis for it."
        }
      ],
      "employment": [
        {
          "_id": "65589f1bab78a4662c416b09",
          "jobTitle": "Nodejs Developer",
          "employmentType": "Part Time",
          "startDate": "2023-10-18T11:24:50.233Z",
          "endDate": "2023-11-18T11:24:51.506Z",
          "responsibilities": "Tech Stack: Java, Spring boot, React js • Worked On SS7 Web based telecom Project Creation Simulator Connection For both Server Side and Client UI creation for Both Side Admin Side and Client Side • Software Upgradation • Backend Api Creation For Data View, insertion & StackUP",
          "companyName": "Accelgrowth Technology"
        }
      ],
      "educations": [
        {
          "_id": "65589f30ab78a4662c416b4e",
          "degree": "BTech",
          "major": "Information Technology",
          "university": "maharashtra",
          "startDate": "2019-11-18T11:25:22.288Z",
          "endDate": "2021-11-18T11:25:23.347Z"
        },
        {
          "_id": "65589f30ab78a4662c416b4f",
          "degree": "12th",
          "major": "hsc",
          "university": "RTMNU",
          "startDate": "2028-11-18T11:25:29.159Z",
          "endDate": "2030-11-18T11:25:30.474Z"
        }
      ],
      "certificates": [
        {
          "_id": "65589f30ab78a4662c416b50",
          "name": "12th",
          "issuer": "Board",
          "year": "2029-11-18T11:25:34.889Z"
        }
      ],
      "createdAt": "2023-11-17T10:55:16.764Z",
      "updatedAt": "2023-12-02T11:28:30.513Z",
      "profile": 115,
      "__v": 0,
      "Experiance": "6",
      "JobTitle": "Java Developer",
      "comittmentType": "intermediate",
      "maximum": "3000000",
      "minimum": "2000000",
      "photo": "/static/1701083381208-istockphoto-1278976856-612x612.jpg",
      "shortBio": "Backend development of a Company relation management• Wrote a crawler to parse through a pre-defined set of ~1 Mn web pagesaday • Wrote a script to analyze the parsed data and insert it in toCrmsoftware • Which has many modules such as attendance System,salary ",
      "pdfFile": "http://localhost:3170/files/resumes/2023/11/AGPF115_Maroti_K_Java_Developer_6+years.pdf",
      "approvalNote": ""
    },
    ...[1, 3, 3, 3].map(ele => ({
      "locations": [
        "remote",
        "onsite"
      ],
      "onsiteOptions": [
        "anywhereWithWorkPermit",
        "anywhereInCountry"
      ],
      "softSkills": [
        "singing",
        "Dancing"
      ],
      "currency": "INR",
      "pdfGenrated": true,
      "approvalStatus": "APPROVED",
      "status": "active",
      "_id": commonFnc.getRandomString(24),
      "firstName": "Maroti",
      "lastName": "Kathoke 2",
      "country": "Amerika",
      "city": "Nagpur",
      "citizenship": "Indian",
      "englishProficiency": "beginner",
      "profileID": "14758027_Maroti_Kathoke",
      "addedByVendor": "655105ade2119714dc166284",
      "skills": [
        {
          "_id": "6557314ff465cc6260f68643",
          "skillName": "ReactJS",
          "years": "2",
          "months": "2"
        },
        {
          "_id": "6557314ff465cc6260f68644",
          "skillName": "ReactJS",
          "years": "3",
          "months": "4"
        },
        {
          "_id": "6557314ff465cc6260f68645",
          "skillName": "Nodejs",
          "years": "2",
          "months": "4"
        }
      ],
      "projects": [
        {
          "technologiesUsed": [
            "ReactJS",
            "Java",
            "Nodejs"
          ],
          "_id": "65579e216eae943cd05e1422",
          "startDate": "2023-01-17T17:07:57.862Z",
          "endDate": "2023-05-17T17:07:59.928Z",
          "industry": "Heath Care",
          "projectTitle": "Vicyber Project",
          "projectURL": "https://test.com",
          "projectDescription": "This Project Is cyber security project in this I worked on Aws presing Url upload Health score check score. Insure Module and quotation module and many more feature",
          "responsibilities": "I worked on Aws presing Url upload Health score check score. Insure Module and quotation module and many more feature"
        },
        {
          "technologiesUsed": [
            "Java",
            "Nodejs",
            "ReactJS"
          ],
          "_id": "65589de0ab78a4662c4169d0",
          "projectTitle": "SS7 Project",
          "projectDescription": "This Project is telecom based project in this Worked On SS7 Web based telecom Project Creation Simulator Connection For both Server Side and Client UI creation for Both Side Admin Side and Client Side . Software Upgradation .Backend Api Creation For Data View, insertion & StackUP.",
          "responsibilities": "Worked On SS7 Web based telecom Project Creation Simulator Connection For both Server Side and Client UI creation for Both Side Admin Side and Client Side . Software Upgradation .Backend Api Creation For Data View, insertion & StackUP.",
          "startDate": "2023-10-18T11:18:17.930Z",
          "endDate": "2023-12-18T11:18:19.733Z",
          "industry": "ERP"
        },
        {
          "technologiesUsed": [
            "PHP",
            "Spring Boot"
          ],
          "_id": "65589de0ab78a4662c4169d1",
          "projectTitle": "Visual Vid Project",
          "projectDescription": "This Project is for video edit sell of video edit on subscription basis This Project is for video edit sell of video edit on subscription basis",
          "responsibilities": "I worked on licensing module. Subscription Part Upload Part and delete Part for backend. I worked on licensing module. Subscription Part Upload Part and delete Part for backend.",
          "industry": "Educations",
          "startDate": "2023-01-18T11:19:33.268Z",
          "endDate": "2023-03-18T11:19:34.649Z"
        }
      ],
      "employment": [
        {
          "_id": "65579e4c6eae943cd05e145e",
          "jobTitle": "React Js Developer",
          "employmentType": "Part Time",
          "startDate": "2023-01-17T17:09:04.293Z",
          "endDate": "2023-02-17T17:09:05.691Z",
          "responsibilities": "Tech Stack: Java, Spring boot, React js • Worked On SS7 Web based telecom Project Creation Simulator Connection For both Server Side and Client UI creation for Both Side Admin Side and Client Side • Software Upgradation • Backend Api Creation For Data View, insertion & StackUP",
          "companyName": "Accelgrowth Tech",
          "location": "Nagpur"
        }
      ],
      "educations": [
        {
          "_id": "65579e606eae943cd05e14a3",
          "degree": "12th",
          "major": "hsc",
          "university": "maharashtra",
          "startDate": "2019-11-17T17:09:39.064Z",
          "endDate": "2021-11-17T17:09:40.163Z"
        },
        {
          "_id": "65579e606eae943cd05e14a4",
          "degree": "BTech",
          "major": "hsc",
          "university": "Nagpur",
          "startDate": "2025-11-17T17:09:45.393Z",
          "endDate": "2030-11-17T17:09:46.691Z"
        },
        {
          "_id": "65589e99ab78a4662c416a80",
          "degree": "10th",
          "major": "ssc",
          "university": "maharashtra",
          "startDate": "2028-11-18T11:22:59.188Z",
          "endDate": "2030-11-18T11:23:00.754Z"
        }
      ],
      "certificates": [
        {
          "_id": "65579e606eae943cd05e14a5",
          "name": "BTech",
          "issuer": "Board",
          "year": "2029-11-17T17:09:51.154Z"
        },
        {
          "_id": "65589e99ab78a4662c416a82",
          "name": "12th",
          "issuer": "Board",
          "year": "2029-11-18T11:22:37.216Z"
        }
      ],
      "createdAt": "2023-11-17T09:23:13.411Z",
      "updatedAt": "2023-12-02T12:38:13.941Z",
      "profile": 114,
      "__v": 0,
      "Experiance": "5",
      "JobTitle": "Java Developer",
      "comittmentType": "intermediate",
      "linkedIn": "https://ss",
      "maximum": "30000",
      "minimum": "20000",
      "photo": "/static/1700305115887-avtar.jpg",
      "shortBio": "This experienced software developer has a Bachelor's degree in Computer Application and specializes in Java, Spring Boot, and a range of other technologies. They have worked on various projects in different domains, including Android app development and e-commerce websites. Their skills extend to React JS, Python, MySQL, and more. This developer is also an active Tech Mentor, sharing knowledge in programming languages and data structures with college students. Their expertise includes React JS, Java, Spring Boot, Microservices, and a solid foundation in computer science fundamentals.",
      "pdfFile": "http://localhost:3170/files/resumes/2023/11/AGPF114_Maroti_K_Java_Developer_5+years.pdf",
      "approvalNote": ""
    }))
  ]
  const [profileData, setProfileData] = useState(profiles)
  const [tempData, setTempData] = useState(profiles)
  const [selectedRecord, setSelected] = useState({})

  useEffect(() => {
    if (data) {
      onProfileRemove(data._id)
      setSelectedProfiles([data])
    }
  }, [data])

  const onRemove = (id) => {
    const _profileData = selectedProfiles.filter(ele => ele._id !== id)
    const selected = tempData.filter(ele => ele._id == id);
    setProfileData([...profileData, selected[0]])
    setSelectedProfiles(_profileData)
  }

  const onProfileRemove = (id) => {
    const _profileData = profileData.filter(ele => ele._id !== id)
    setProfileData(_profileData)
  }

  const onAddNew = (id) => {
    const selected = tempData.filter(ele => ele._id == id);
    onProfileRemove(id)
    setSelectedProfiles([...selectedProfiles, selected[0]])
  }

  return (
    <>
      <Modal
        open={isVisible}
        onCancel={onClose}
        footer={false}
        title={'Hire Developers'}
        width={1000}
      >
        {data && <div className='developer-profile'>
          <Divider className='no-margin' />
          <GetHeader />
          {selectedProfiles && selectedProfiles.length > 0 && selectedProfiles.map((ele, index) => <GetTableBody
            key={index}
            data={ele}
            onClick={() => setSelected(ele)}
            isExpand={selectedRecord._id == ele._id}
            type={'selected'}
            onRemove={onRemove}
            onSelect={onAddNew}
          />)}
          <Row>
            {profileData.length > 0 && <Col span={24}>
              <div className='d-f space-between heading-box'>
                <div onClick={() => setShowMore(!showMore)}>
                  <div className='bold heading-title'>{profileData.length} more developers are available who match with similar skills and experience.</div>
                  <div>Having more profiles will expedite your onboarding process.</div>
                </div>
                <div>
                  {!showMore && <Button type='link' className='no-padding' onClick={() => setShowMore(true)}>Show More</Button>}
                  {showMore && <Button type='link' className='no-padding' onClick={() => setShowMore(false)}>Hide</Button>}
                </div>
              </div>
            </Col>}
            {showMore && profileData && profileData.length > 0 && profileData.map((ele, index) => <Col key={index} span={24}>
              <GetTableBody data={ele}
                onClick={() => setSelected(ele)}
                isExpand={selectedRecord._id == ele._id}
                type={'remove'}
                onRemove={onRemove}
                onSelect={onAddNew}
              />
            </Col>)}
            <Col span={24}>
              <div>
                <RequestForm />
              </div>
            </Col>
          </Row>
        </div>}
      </Modal>
    </>
  )
}

export default HireDeveloperModal;