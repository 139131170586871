import { Image, Space, Tag } from "antd";
import { timeAgo } from "../../../common/functions";
import { getCurrency, getDescountBudget } from "../../../common/validate";
import { useState } from "react";
import { routes } from "../../../../actions/api-routes";

const DetailView = ({ item, isDrower }) => {
    const [maxChars, setmaxChars] = useState(250);

    const extractTextFromHTML = (htmlContent) => {
        const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
        return doc.body.textContent || '';
    }
    return <>
        <div className='d-f justify-between detail_drawer'  >
            <div>
                <div className='posted-time'>Posted {timeAgo(item?.createdAt)}</div>
                <div className='job-title-view'>
                    <div className='heading-view-for-rol'>
                        <div className='job-title-box'>
                            <div className='main-text main-heading text-20'> {item?.jobTitle}</div>
                        </div>
                        <div className='job-details-view'>
                            <div className=''><spam></spam>{item?.experiance}+ Year</div>
                            <div>{item?.jobLocationType}</div>
                            <div className="bold_text">Budget: {item?.currency} {getDescountBudget(item?.minimum)}{getCurrency(item.interval)} - {getDescountBudget(item?.maximum)}{getCurrency(item.interval)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='jd-text-box'>
            <div className={`text-container}`}>
                <div className={'text'}>
                    {item?.typeOfRequirment == 'Text' && (isDrower ? <div className='jd' dangerouslySetInnerHTML={{ __html: item?.jobDescription }}></div> :
                        <div className='jd'> {extractTextFromHTML(item?.jobDescription).slice(0, maxChars)} </div>
                    )}
                    {item?.typeOfRequirment == "Image" && <div className='img-container margin-bottom'>
                        <Image src={routes.fileServer + item?.requirmentFile} />
                    </div>}
                </div>
            </div>
        </div>
        <div className='skills'>
            {item?.skils.map(ele => <span className='tag custom-tag'>{ele}</span>)}
        </div>
    </>
}

export default DetailView;