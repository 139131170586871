import { useNavigate } from 'react-router-dom';
import './index.css';
import { Form, Input, Button, Typography, Space, Card, Flex, notification } from 'antd';
import { postData } from '../actions/middleware';
import { SetApplicantCookies, getApplicantData } from '../actions/auth';
import { useEffect, useState } from 'react';
import { routes } from '../actions/api-routes';
import ResendOTPButton from '../onlineTest/Home/ResendOTP';
import { encryptData } from '../actions/crypto';
const { Title, Text } = Typography;
const { useForm } = Form;

function LoginEmployee() {
  const [emailVerified, setEmailVerified] = useState(false);
  const [form] = useForm();

  const navigate = useNavigate()

  useEffect(() => {
    getUserDetails();
  }, [])


  const getUserDetails = async () => {
    const data = getApplicantData();
    if (data) {
      navigate('/interview/applicant/' + 'status/' + encryptData(data._id))
    }
  }

  const onFinish = async (values) => {
    if (values.otp && values.email) {
      login(values);
    } else {
      sendOTP(values)
    }
  }

  const login = async (values) => {
    const resData = await postData(routes.applicant.login, values)
    if (resData.code == 200) {
      notification.success({
        message: 'login successfully',
        type: 'success'
      })
      SetApplicantCookies(resData.data);
      const data = getApplicantData();
      navigate('/interview/applicant/' + 'status/' + encryptData(data._id))
    } else {
      notification.error({
        message: 'Please enter valid email and otp',
        type: 'error'
      })
    }
  }

  const sendOTP = async (values) => {
    const resPodata = await postData(routes.applicant.sendOTP, values)
    if (resPodata.code == 200) {
      notification.success({
        message: 'OTP sent to your email, please check your inbox',
        type: 'success'
      })
      setEmailVerified(true)
    } else {
      notification.error({
        message: 'Something went wrong please try again later',
        type: 'error'
      })
    }
  }

  const handleResendClick = () => {
    sendOTP({ email: form.getFieldValue('email') })
  }

  const onChange = () => { }

  return (
    <div className='interview_login'>
      <Card style={{ width: 350, margin: 'auto' }} >
        <Flex justify='center' style={{ width: '100%' }}>
          <Space direction='vertical' style={{ width: '100%' }}>
            <div className='welcome_title'>
              <Title level={4} style={{ margin: 0 }}>Welcome back</Title>
              <Text type='secondary'>Enter your email ID where you received the confirmation email from Accelgrowth. After clicking on "Continue," you will receive an OTP for email verification. </Text>
            </div>
            <Form
              form={form}
              layout='vertical'
              onFinish={onFinish}
              autoComplete="off"
            >
              <Space direction='vertical' style={{ width: '100%' }}>
                <Form.Item
                  name={'email'}
                  label={'Email'}
                  rules={[{ required: true, message: 'Email is required' }]}
                >
                  <Input type='email'></Input>
                </Form.Item>
                {emailVerified && <><Form.Item
                  name={'otp'}
                  label={'OTP'}
                  rules={[{ required: true, message: 'OTP is required' }]}
                >
                  <Input.OTP length={4} onChange={onChange} />
                </Form.Item>
                  <div className="resend-time">
                    <ResendOTPButton onClick={handleResendClick} disabled={false} />
                  </div>
                </>
                }
                <Button type='primary' style={{ width: '100%' }} htmlType='submit'>Continue</Button>
              </Space>
            </Form>
          </Space>
        </Flex>
      </Card>
    </div>
  )
}

export default LoginEmployee;
