import React, { useState } from 'react';
import { Alert, Avatar, Button, Col, Drawer, Form, Progress, Row, Tabs, Tooltip } from 'antd';
import { CloseOutlined, LogoutOutlined } from '@ant-design/icons';
import './index.css';

const NotificationDrawer = ({ isVisible, width, onClose, onSave }) => {

  const drawerHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className='my-info'>
        Notifications
      </div>
      <Button type="text" className='closeButton_notification' onClick={onClose}>
        <CloseOutlined />
      </Button>
    </div>
  );

  const items = [{
    key: 'Unread',
    label: 'Unread'
  }, {
    key: 'Read',
    label: 'Read'
  }]

  return (
    <>
      <Drawer
        title={drawerHeader}
        placement="right"
        onClose={onClose}
        open={isVisible}
        closable={false}
        maskClosable={true}
        className='notification-box'
      >
        <Tabs items={items} />
        <Alert
          className='alert-box-notification'
          message={<div className='heading-3'>Profile Shared</div>}
          description={<div className='text-wrap'>You have recived a java developer, You have recived a java developer You have recived a java developer You have recived a java developer</div>}
          closable={true}
        />

      </Drawer >
    </>
  );
};
export default NotificationDrawer;