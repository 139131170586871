import { useEffect } from 'react';
import './index.css';
function SolutionsList() {

  return (
    <div>
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title">FAQs </h2>
                <ul className="breadcrumb-list">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item active">FAQs </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div className="bg-white">
            <div className="about-banner-wrap banner-space bg-img" data-bg="../../../assets/images/bg/business-solution-hero-bg.webp">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 m-auto">
                    <div className="about-banner-content text-center">
                      <h1 className="mb-15 text-white">Business solution</h1>
                      <h5 className="font-weight--normal text-white">Reach out to the world’s most reliable IT services.</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-large-images-wrapper section-space--ptb_100">
              <div className="container">
                <div className="cybersecurity-about-box">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="conact-us-wrap-one managed-it">
                        <h5 className="heading ">Mitech specializes in <span className="text-color-primary"> technological and IT-related services</span> such as product engineering, warranty management, building cloud, infrastructure, network, etc. </h5>
                      </div>
                    </div>
                    <div className="col-lg-7 offset-lg-1">
                      <div className="cybersecurity-about-text">
                        <div className="text">The IT services that Mitech are running can be segmented by the type of skills employed to deliver the service (design, build, run). Technically, these fall into three main categories: business process services, application services, and infrastructure services.</div>
                        <div className="button-group-wrap">
                          <a href="#" className="ht-btn ht-btn-md me-md-3 mb-20">Get a FREE no-obligation quote</a>
                          <a href="#" className="ht-btn ht-btn-md ht-btn--solid">Live chat with a consultant</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="feature-images-wrapper bg-gray section-space--ptb_100">
              <div className="container">

                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-wrap text-center section-space--mb_30">
                      <h3 className="heading">Reliable IT Services for <span className="text-color-primary"> Businesses</span></h3>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="feature-images__four">
                      <div className=" row">
                        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up">
                          <a href="#" className="ht-box-images style-04">
                            <div className="image-box-wrap">
                              <div className="box-image">
                                <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-05-image-01-60x60.webp")} alt="" />
                              </div>
                              <div className="content">
                                <h5 className="heading">IT Design</h5>
                                <div className="text">We provide the most responsive and functional IT design for companies and businesses worldwide.</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up">
                          <a href="#" className="ht-box-images style-04">
                            <div className="image-box-wrap">
                              <div className="box-image">
                                <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-05-image-02-60x60.webp")} alt="" />
                              </div>
                              <div className="content">
                                <h5 className="heading">IT Management </h5>
                                <div className="text">It’s possible to simultaneously manage and transform information from one server to another.</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up">
                          <a href="#" className="ht-box-images style-04">
                            <div className="image-box-wrap">
                              <div className="box-image">
                                <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-05-image-03-60x60.webp")} alt="" />
                              </div>
                              <div className="content">
                                <h5 className="heading">Data Security</h5>
                                <div className="text">Back up your database, store in a safe and secure place while still maintaining its accessibility.</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up">
                          <a href="#" className="ht-box-images style-04">
                            <div className="image-box-wrap">
                              <div className="box-image">
                                <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-05-image-04-60x60.webp")} alt="" />
                              </div>
                              <div className="content">
                                <h5 className="heading">Workforce Management</h5>
                                <div className="text">Suspe ndisse suscipit sagittis leo sit met condime ntum esti laiolainx bulum iscipit sagittis leo sit met con ndisse suscipit sagittis leo sit met cone su</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up">
                          <a href="#" className="ht-box-images style-04">
                            <div className="image-box-wrap">
                              <div className="box-image">
                                <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-05-image-05-60x60.webp")} alt="" />
                              </div>
                              <div className="content">
                                <h5 className="heading">Business Reform</h5>
                                <div className="text">We provide the most responsive and functional IT design for companies and businesses worldwide.</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up">
                          <a href="#" className="ht-box-images style-04">
                            <div className="image-box-wrap">
                              <div className="box-image">
                                <img className="img-fulid" src={require("../../../assets/images/icons/mitech-box-image-style-05-image-06-60x60.webp")} alt="" />
                              </div>
                              <div className="content">
                                <h5 className="heading">Infrastructure Plan</h5>
                                <div className="text">Mitech takes into account all conditions and budgets needed for building infrastructure plan..</div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="section-under-heading text-center section-space--mt_60">Challenges are just opportunities in disguise. <a href="#">Take the challenge!</a></div>

                  </div>
                </div>
              </div>
            </div>
            <div className="about-delivering-wrapper section-space--ptb_100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-wrap text-center section-space--mb_20">
                      <h3 className="heading">Business IT Support Features</h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="delivering-optimal-wrap">
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">IT Design </h5>
                          <div className="desc">We provide the most responsive and functional IT design for companies and businesses worldwide. </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title"> Data Security </h5>
                          <div className="desc">Back up your database, store in a safe and secure place while still maintaining its accessibility. </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">Infrastructure Plan </h5>
                          <div className="desc">Mitech takes into account all conditions and budgets needed for building infrastructure plan. </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="delivering-optimal-wrap">
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">IT Management </h5>
                          <div className="desc">It’s possible to simultaneously manage and transform key information from one server to another. </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">Business Reform</h5>
                          <div className="desc">We propose feasible & practical plans for successfully transform businesses based on their needs. </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="marker"></div>
                        <div className="title-wrap">
                          <h5 className="title">Firewall Advancement </h5>
                          <div className="desc">Enhancing the strength and security of firewalls to protect online data from malicious sources. </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-resources-wrapper">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-lg-6 order-2 order-lg-1">
                    <div className="resources-left-box">
                      <div className="resources-inner">
                        <h6 className="sub-title mb-20">Resources</h6>
                        <h3 className="heading">Get a copy of brochure on <span className="text-color-primary"> Brand New IT Tech.</span></h3>
                        <div className="button mt-30">
                          <a href="#" className="ht-btn ht-btn-md">Download now (3MB)</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="resources-right-box">
                      <div className="resources-right-inner text-center">
                        <div className="resources-images">
                          <img className="img-fluid" src={require("../../../assets/images/banners/managed-it-services-book-cover.webp")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-slider-area section-space--pt_100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-wrap text-center section-space--mb_40">
                      <h6 className="section-sub-title mb-20">Testimonials</h6>
                      <h3 className="heading">What do people praise about <span className="text-color-primary"> Mitech?</span></h3>
                    </div>
                    <div className="testimonial-slider">
                      <div className="swiper-container testimonial-slider__container">
                        <div className="swiper-wrapper testimonial-slider__wrapper">
                          <div className="swiper-slide">
                            <div className="testimonial-slider__one wow move-up">

                              <div className="testimonial-slider--info">
                                <div className="testimonial-slider__media">
                                  <img src={require("../../../assets/images/testimonial/mitech-testimonial-avata-02-90x90.webp")} className="img-fluid" alt="" />
                                </div>

                                <div className="testimonial-slider__author">
                                  <div className="testimonial-rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                  </div>
                                  <div className="author-info">
                                    <h6 className="name">Abbie Ferguson</h6>
                                    <span className="designation">Marketing</span>
                                  </div>
                                </div>
                              </div>

                              <div className="testimonial-slider__text">I’ve been working with over 35 IT companies on more than 200 projects of our company, but @Mitech is one of the most impressive to me.</div>

                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="testimonial-slider__one wow move-up">

                              <div className="testimonial-slider--info">
                                <div className="testimonial-slider__media">
                                  <img src={require("../../../assets/images/testimonial/mitech-testimonial-avata-03-90x90.webp")} className="img-fluid" alt="" />
                                </div>

                                <div className="testimonial-slider__author">
                                  <div className="testimonial-rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                  </div>
                                  <div className="author-info">
                                    <h6 className="name">Monica Blews</h6>
                                    <span className="designation">Web designer</span>
                                  </div>
                                </div>
                              </div>

                              <div className="testimonial-slider__text">I’ve been working with over 35 IT companies on more than 200 projects of our company, but @Mitech is one of the most impressive to me.</div>

                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="testimonial-slider__one wow move-up">

                              <div className="testimonial-slider--info">
                                <div className="testimonial-slider__media">
                                  <img src={require("../../../assets/images/testimonial/mitech-testimonial-avata-04-90x90.webp")} className="img-fluid" alt="" />
                                </div>

                                <div className="testimonial-slider__author">
                                  <div className="testimonial-rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                  </div>
                                  <div className="author-info">
                                    <h6 className="name">Abbie Ferguson</h6>
                                    <span className="designation">WEB DESIGNER</span>
                                  </div>
                                </div>
                              </div>

                              <div className="testimonial-slider__text">I’ve been working with over 35 IT companies on more than 200 projects of our company, but @Mitech is one of the most impressive to me.</div>

                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="testimonial-slider__one wow move-up">

                              <div className="testimonial-slider--info">
                                <div className="testimonial-slider__media">
                                  <img src={require("../../../assets/images/testimonial/mitech-testimonial-avata-01-90x90.webp")} className="img-fluid" alt="" />
                                </div>

                                <div className="testimonial-slider__author">
                                  <div className="testimonial-rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                  </div>
                                  <div className="author-info">
                                    <h6 className="name">Abbie Ferguson</h6>
                                    <span className="designation">WEB DESIGNER</span>
                                  </div>
                                </div>
                              </div>

                              <div className="testimonial-slider__text">I’ve been working with over 35 IT companies on more than 200 projects of our company, but @Mitech is one of the most impressive to me.</div>

                            </div>
                          </div>
                        </div>
                        <div className="swiper-pagination swiper-pagination-t01 section-space--mt_30"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brand-logo-slider-area section-space--ptb_60">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="brand-logo-slider__container-area">
                      <div className="swiper-container brand-logo-slider__container">
                        <div className="swiper-wrapper brand-logo-slider__one">
                          <div className="swiper-slide brand-logo brand-logo--slider">
                            <a href="#">
                              <div className="brand-logo__image">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-01.webp")} className="img-fluid" alt="" />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-01-hover.webp")} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                          <div className="swiper-slide brand-logo brand-logo--slider">
                            <a href="#">
                              <div className="brand-logo__image">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-02.webp")} className="img-fluid" alt="" />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-02-hover.webp")} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                          <div className="swiper-slide brand-logo brand-logo--slider">
                            <a href="#">
                              <div className="brand-logo__image">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-03.webp")} className="img-fluid" alt="" />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-03-hover.webp")} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                          <div className="swiper-slide brand-logo brand-logo--slider">
                            <a href="#">
                              <div className="brand-logo__image">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-04.webp")} className="img-fluid" alt="" />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-04-hover.webp")} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                          <div className="swiper-slide brand-logo brand-logo--slider">
                            <a href="#">
                              <div className="brand-logo__image">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-05.webp")} className="img-fluid" alt="" />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-05-hover.webp")} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                          <div className="swiper-slide brand-logo brand-logo--slider">
                            <a href="#">
                              <div className="brand-logo__image">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-06.webp")} className="img-fluid" alt="" />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-06-hover.webp")} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                          <div className="swiper-slide brand-logo brand-logo--slider">
                            <a href="#">
                              <div className="brand-logo__image">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-07.webp")} className="img-fluid" alt="" />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-07-hover.webp")} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                          <div className="swiper-slide brand-logo brand-logo--slider">
                            <a href="#">
                              <div className="brand-logo__image">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-08.webp")} className="img-fluid" alt="" />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-08-hover.webp")} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                          <div className="swiper-slide brand-logo brand-logo--slider">
                            <a href="#">
                              <div className="brand-logo__image">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-09.webp")} className="img-fluid" alt="" />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img src={require("../../../assets/images/brand/mitech-client-logo-09-hover.webp")} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SolutionsList;
