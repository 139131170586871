import React, { useEffect } from 'react';
import { useState } from 'react';
import { Avatar, Button, Checkbox, Col, Divider, Image, Radio, Row, Space, Table, Tag, notification } from 'antd';
import '../index.css';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import DrawerView from '../../common/drawer/drawer';
import DragAndDropLists from './draggableList';
import './index.css';
import { routes } from '../../../actions/api-routes';
import { getCurrency } from '../../common/validate';
import { postData } from '../../../actions/middleware';
import { encryptData } from '../../../actions/crypto';
import { timeAgo } from '../../common/functions';
import DetailView from './job-detail/detailView';
import ShareProfiles from './shareProfile';

const CurrentOpenings = ({ style, item }) => {
    const [shareProfile, setShareProfile] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [developers, setDevelopers] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [isView, setIsView] = useState(false);
    const [isAddProfile, setAddProfile] = useState(false);


    const selectedDevelopers = (e) => {
        setDevelopers(e)
    }

    const onApply = (data) => {
        setSelectedItem(data)
        setVisible(true)
    }

    const onSave = async () => {
        try {
            const payload = {
                requirment: selectedItem._id,
                developers: developers.map(ele => ele._id)
            }
            const resData = await postData(routes.applied.profileApplied, { data: encryptData(payload) });
            if (resData) {
                notification.success({ message: 'Applied successfully' })
                setVisible(false)
            }
        } catch (error) {
            notification.error({ message: 'Something went wrong please try again later.' })
        }
    }

    const onCloseDrawer = async (flag) => {
        setVisible(false)
    }

    const onCloseAddProfile = () => {
        setAddProfile(false);
    }

    const onSaveAddProfile = () => {
        setAddProfile(false);
    }


    return (
        <>
            <DrawerView
                width={'70%'}
                isVisible={isVisible}
                onClose={onCloseDrawer}
                onSave={() => onSave()}
            >
                <div className='content-job'>
                    <Row>
                        <Col span={15}>
                            <DetailView item={selectedItem} isDrower={true} />
                        </Col>
                        <Col span={1}>
                            <Divider style={{ height: '100%' }} type='vertical' />
                        </Col>
                        <Col span={8}>
                            <div >
                                {[1, 2, 4].map(ele => <div className='list-item list-box-developer'>
                                    <label className='list_box' >
                                        <div className='avatar-name'>
                                            <Avatar />
                                            <div style={{ marginLeft: '10px' }}>
                                                <Space>
                                                    <div className='ellipsis' style={{ width: '110px' }}>{'Developer'}</div>
                                                </Space>
                                                <div style={{ fontSize: '10px', lineHeight: 0, color: 'gray' }}>
                                                    {'java Developer'}
                                                </div>
                                            </div>
                                        </div>
                                        <div >
                                            <small style={{ fontSize: '10px' }}>5+ Year</small>
                                        </div>
                                        <Space>
                                            <Button type='link' icon={<DeleteOutlined />} danger />
                                            <Button type='link' icon={<EditOutlined />} />
                                        </Space>
                                    </label>
                                </div>)}
                            </div>
                            <Button onClick={() => setAddProfile(true)}>Add Profile</Button>
                            <DrawerView
                                width={'60%'}
                                isVisible={isAddProfile}
                                onClose={() => onCloseAddProfile()}
                                onSave={() => onSaveAddProfile()}
                            >
                                <ShareProfiles />
                            </DrawerView>
                        </Col>
                    </Row>
                </div>
                {/* {selectedItem && <DragAndDropLists setTabs={setTabs} items={selectedItem} selectedDevelopers={selectedDevelopers} />} */}
            </DrawerView>
            <div className={'view-box bg-white job-dscription public_post bg-active'} style={style} onClick={() => onApply(item)}>
                <DetailView item={item} />
            </div>
        </>
    );
};

export default CurrentOpenings;
