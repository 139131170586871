import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Upload, Button, Tabs } from 'antd';
import './index.css';
import { routes } from '../../actions/api-routes';
import { decryptData, encryptData } from '../../actions/crypto';
import ListMyRequirmentItem from './listItem';
import { getData } from '../../actions/middleware';

const MyRequirmentList = ({ subTab, tab }) => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [requirments, setRequirement] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [activeItem, setActiveItem] = useState(null);
    const containerRef = useRef(null);
    const [deductSize, setDeductSize] = useState(190)

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (hasMore) {
            getRequirments();
        }
        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [page, hasMore])

    useEffect(() => {
        setRequirement([])
        setHasMore(true)
        setPage(1)
        getRequirments();
        if (tab !== 'APPROVED') {
            setDeductSize(150)
        } else {
            setDeductSize(190)
        }
    }, [subTab, tab])

    const reloadPage = () => {
        setRequirement([])
        setHasMore(true)
        setPage(1)
        getRequirments();
    }

    const getRequirments = async () => {
        const mainFilter = {
            subTab,
            tab
        }
        const resData = await getData(routes.jobs.listWithFilter, { current: page, listFor: encryptData(mainFilter) });
        if (resData.code == 200) {
            const data = decryptData(resData.data)
            if (page == resData.pagination.current) {
                if (data.length < 14) {
                    setHasMore(false);
                    setRequirement(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                } else {
                    setRequirement(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                }
            }
        }
    }

    const handleScroll = () => {
        const container = containerRef.current;
        if (
            container.scrollTop + container.clientHeight === container.scrollHeight
        ) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const activeItems = (e) => {
        setActiveItem(e._id)
    }

    return (
        <div style={{ maxHeight: windowHeight - deductSize, overflowY: 'auto', padding: '10px', marginTop: '10px' }} ref={containerRef}>
            {requirments.length > 0 && requirments.map(ele => <ListMyRequirmentItem
                reloadPage={reloadPage}
                item={ele}
                activeId={activeItem}
                setActiveItem={(e) => activeItems(e)}
                style={{
                    margin: "10px 10px",
                    marginTop: '1px',
                    marginLeft: '3px',
                    paddingBottom: '10px'
                }}
            />)}
        </div>
    );
}

export default MyRequirmentList;
