import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Upload, Button, Tabs, Image } from 'antd';
import './../index.css';
import BulkApprovedProfile from './bulk/ApprovedRequirment';
import BulkRejectedProfile from './bulk/RejectedRequirment';
import PendingProfiles from './bulk/newRequirment';
import WaitingForApproval from './bulk/WaitingForApproval';

const PublicBulkProfiles = () => {
    const [listFor, setListFor] = useState('approved');

    const items = [
        {
            key: 'approved',
            label: 'Approved',
        },
        {
            key: 'rejected',
            label: 'Rejected',
        },
        {
            key: 'pending',
            label: 'Pending',
        },
        {
            key: 'waiting_for',
            label: 'Waiting for approval',
        },
    ];

    const onChange = (key) => {
        setListFor(key)
    };

    return (
        <div>
            <div className='tabs-bg'>
                <Tabs size='large' defaultActiveKey="review" items={items} onChange={onChange} />
            </div>
            {listFor == 'approved' && <BulkApprovedProfile />}
            {listFor == 'rejected' && <BulkRejectedProfile />}
            {listFor == 'pending' && <PendingProfiles />}
            {listFor == 'waiting_for' && <WaitingForApproval />}

        </div>
    );
}

export default PublicBulkProfiles;
