import React, { useState, useEffect, useRef } from "react";
import './index.css';
import { Button, Col, Row, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import routing from "../routing";
const { Title } = Typography;

function AboutTest() {
  const navigate = useNavigate();

  const onNavigate = () => {
    navigate(routing.instructions)
  }


  return <>
    <div className="about-test">
      <div className="row-box">
        <Row>
          <Col span={12}>
            <div className="left-box">
              <Row className="text-with-image">
                <Col span={12}>
                  <img className="" src={require('./../../assets/images/onlineTest/home-page.png')} />
                </Col>
                <Col span={12}>
                  <div className="text-view">
                    <Title level={3} className="text-right">Welcome to our online testing platform!</Title>
                    <div className="role-view"><Title level={5}>Test for the role of JAVA developer</Title></div>
                    <Title className="details-about-test" level={5}>This test will determine whether you are capable for this role or not, so be prompt and give it your 100% presence.</Title>
                  </div>
                </Col>
              </Row>
              <div className="instruction-text">
                <Title className="text-color" level={4}>Please read all the instructions carefully and start your test. We wish you Good luck for your test!</Title>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="image-box-for-fix">
              <img src={require('./../../assets/images/onlineTest/men-with-laptop.png')} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="incstruction-levels">
        <div className="heading-about-test">
          <Title level={3}>Instructions</Title>
        </div>
        <Row>
          <Col span={8}>
            <Space direction="vertical" align="center">
              <img src={require('./../../assets/images/onlineTest/done.png')} />
              <Title level={4}>Select Correct</Title>
              <Title level={5}>You need to select correct options</Title>
            </Space>
          </Col>
          <Col span={8}>
            <Space direction="vertical" align="center">
              <img src={require('./../../assets/images/onlineTest/no.png')} />
              <Title level={4}>Select Correct</Title>
              <Title level={5}>You need to select correct options</Title>
            </Space>
          </Col>
          <Col span={8}>
            <Space direction="vertical" align="center">
              <img src={require('./../../assets/images/onlineTest/one.png')} />
              <Title level={4}>Select Correct</Title>
              <Title level={5}>You need to select correct options</Title>
            </Space>
          </Col>
        </Row>
      </div>
      <div className="next-button">
        <Button type="primary" size="large" onClick={onNavigate}>Next</Button>
      </div>
    </div>
  </>
}

export default AboutTest;
