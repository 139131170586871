const capitalizeString = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const getInitive = (str) => {
  return capitalizeString(str).split(" ").map(ele => ele[0]).join('')
}

const ThousendSeprator = (number) => {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : number;
}

function formatNumber(number) {
  let formattedNumber = number.toString();
  if (formattedNumber.length === 1) {
    formattedNumber = '0' + formattedNumber;
  }
  return formattedNumber
}

function getRandomString(length) {
  const characters = '0123456789abcdef';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

const commonFnc = { capitalizeString, getInitive, ThousendSeprator, formatNumber, getRandomString }

export default commonFnc