import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminRoutes from '../routings/AdminRoutes';
import AdminSideNav from './sideNav';
import { Layout, Button, Space, Avatar, Tooltip, Badge, Menu, Dropdown, FloatButton, Modal } from 'antd';
import {
  MenuUnfoldOutlined, MenuFoldOutlined, WhatsAppOutlined, BellOutlined,
  SettingOutlined, UserOutlined, UserSwitchOutlined,
  UnorderedListOutlined, CloudUploadOutlined,
  CommentOutlined, ContainerOutlined,
  PlusCircleOutlined, UploadOutlined, CheckCircleOutlined, ShoppingOutlined
} from '@ant-design/icons';
const { Sider, Header, Content, Footer } = Layout;

import './index.css'
import './table.css'

import { useEffect } from 'react';
import { getUserData, logout } from '../actions/auth';
import commonFnc from './common/commonFunction';
import ProfileDrawer from './common/drawer/profile-drawer';
import Loading from './loading';
import Chat from './chat';
import NotificationDrawer from './common/drawer/notiofication-drawer';
import SettingDrawer from './common/drawer/setting-drawer';
import DocumentViewer from './common/modal/agreement';

const Admin = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState()
  const [profileVisible, setProfileVisible] = useState(false)
  const [settingVisible, setSettingVisible] = useState(false)
  const [notificationVisible, setNotificationVisible] = useState(false)
  const [isAgreementVisible, setAgreementVisible] = useState(false)


  const [collapsed, setCollapsed] = useState(true);
  const [pinned, setPinned] = useState(false);
  const [current, setCurrent] = useState('Profiles');
  const [chieldActiveKey, setChieldActiveKey] = useState();
  const [viewChat, setIsOpenChat] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const containerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const togglePin = () => {
    setPinned(!pinned);
  };

  useEffect(() => {
    getUserDetails();
  }, [])

  const getUserDetails = () => {
    const data = getUserData();
    if (!data) {
      navigate('/auth')
    } else {
      setUserData(data)
    }
  }

  const getName = (sub = false) => {
    let name = '';
    if (sub) {
      if (userData && userData.companyName) {
        name = commonFnc.getInitive(userData.companyName)
      }
      return name
    } else {
      if (userData && userData.companyName) {
        name = commonFnc.capitalizeString(userData.companyName)
      }
      return name
    }
  }

  const items = [{
    label: 'Requirements',
    key: 'requirements',
    chiled: [
      {
        label: 'Openings',
        key: 'openings',
        icon: <UnorderedListOutlined />
      },
      {
        label: 'My Openings',
        key: 'my/openings',
      },
      {
        label: 'Bulk Openings',
        key: 'bulk/openings',
      }
    ]
  },
  {
    label: 'Profiles',
    key: 'profiles',
    chiled: [
      {
        label: 'Resources',
        key: 'available/resources',
        icon: <UserOutlined />
      },
      {
        label: 'My Developers',
        key: 'developers',
        icon: <UserOutlined />
      },
      {
        label: 'Bulk Requests',
        key: 'bulk/developers',
        icon: <UserSwitchOutlined />
      },
      {
        label: 'Shortlisted',
        key: 'shortlisted',
        icon: <CheckCircleOutlined />
      },
      {
        label: 'Reports',
        key: 'Report',
        icon: <CheckCircleOutlined />
      },
    ]
  },
  {
    label: 'Company',
    key: 'Company',
    chiled: [
      {
        label: 'Home',
        key: 'Home',
      },
      {
        label: 'Staff',
        key: 'Staff',
      }
    ]
  },
  {
    label: 'Manage',
    key: 'manage',
    chiled: [
      {
        label: 'Requirments',
        key: 'requirements',
      },
      {
        label: 'Profiles',
        key: 'profiles',
      },
      {
        label: 'Vendors',
        key: 'vendors',
      },
    ]
  },
  {
    label: 'CMS',
    key: 'cms',
    chiled: [
      {
        label: 'Leads',
        key: 'leads',
      },
      {
        label: 'Groups',
        key: 'groups',
      },
      {
        label: 'Bulk Email',
        key: 'bulk_email',
      },
      {
        label: 'Email Templates',
        key: 'email_templates',
      },
    ]
  },
  {
    label: 'Manage Exam',
    key: 'manage/exam',
    chiled: [
      {
        label: 'Dashboard',
        key: 'dashboard',
      },
      {
        label: 'Results',
        key: 'results',
      },
      {
        label: 'Questions Bank',
        key: 'question/bank',
      },
      {
        label: 'Test Config',
        key: 'testconfig',
      },
      {
        label: 'Alloted',
        key: 'alloted',
      },
    ]
  }
  ];

  const getCurrentKey = () => {
    let match = null;
    items.forEach(item => {
      item.chiled.forEach(childItem => {
        const fullPath = `/admin/${item.key}/${childItem.key}`;
        if (fullPath === location.pathname) {
          match = { parentKey: item.key, childKey: childItem.key };
        }
      });
    });
    return match;
  }

  const getDefaultKey = (itemKey) => {
    const menu = items.filter(ele => ele.key == itemKey);
    const fullPath = `/admin/${itemKey}/${menu[0].chiled[0].key}`;
    return fullPath;
  }

  useEffect(() => {
    const keys = getCurrentKey();
    if (keys) {
      setChieldActiveKey(keys.childKey)
      setCurrent(keys.parentKey);
    }
  }, [location.pathname])


  const onClick = (e) => {
    navigate(getDefaultKey(e.key));
  };

  const dropDownItems = [
    {
      key: '/admin/profiles/upload-profile',
      label: (
        <Space className='icon-name' ><PlusCircleOutlined /> Profile</Space>
      ),
    },
    {
      key: '/admin/requirements/add',
      label: (
        <Space className='icon-name'><PlusCircleOutlined /> Requirement</Space>
      ),
    },
    {
      key: '/admin/requirements/quik-upload',
      label: (
        <Space className='icon-name'><PlusCircleOutlined />Bulk Requirement</Space>
      ),
    },
    {
      key: '/admin/profiles/quik/upload-profile',
      label: (
        <Space className='icon-name'><PlusCircleOutlined />Bulk Profiles</Space>
      ),
    },
  ];

  const getCurrentSideBar = () => {
    const data = items.filter(ele => ele.key == current)
    if (data && data.length) {
      return data[0].chiled
    }
  }

  const openChat = () => {
    setIsOpenChat(!viewChat)
  }

  const onChange = (e) => {
    navigate(e.key)
  }

  const onSettingClick = () => {
    setNotificationVisible(false)
    setProfileVisible(false)
    setSettingVisible(true)
  }

  const onNotificationClick = () => {
    setNotificationVisible(true)
    setProfileVisible(false)
    setSettingVisible(false)
  }


  const onProfileClick = () => {
    setNotificationVisible(false)
    setProfileVisible(true)
    setSettingVisible(false)
  }

  return (
    <Layout style={{ minHeight: '100vh' }} className='admin-v'>
      <Loading />
      <ProfileDrawer isVisible={profileVisible} onClose={() => setProfileVisible(false)}></ProfileDrawer>
      <SettingDrawer isVisible={settingVisible} onClose={() => setSettingVisible(false)}></SettingDrawer>
      <NotificationDrawer isVisible={notificationVisible} onClose={() => setNotificationVisible(false)}></NotificationDrawer>
      <Modal
        width={'80%'}
        title={'Agreement'}
        open={isAgreementVisible}
        onCancel={() => setAgreementVisible(false)}
      >
        <DocumentViewer />
      </Modal>
      <Header className="site-layout-background" >
        <div className='flex-division'>
          <div style={{ width: '100%' }} className='flex-division'>
            <Button
              type="text"
              onClick={togglePin}
              className='icon-button'
            >
              {pinned ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <Menu className='top-menu menu-class' style={{ width: '100%' }} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items}>
            </Menu>
          </div>
          <Space align='end'>
            <Dropdown
              menu={{
                items: dropDownItems,
                onClick: onChange
              }}
              arrow={true}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space size={'large'}>
                  <Button icon={<PlusCircleOutlined />}></Button>
                </Space>
              </a>
            </Dropdown>
            <Button type="text" className='header-button' onClick={onNotificationClick} icon={<Badge count={5} dot><BellOutlined className='header-button' /></Badge>} />
            <Button type="text" className='header-button' icon={<SettingOutlined />} onClick={() => onSettingClick()} />
            <Avatar size="large" shape='circle' className='cursor-pointer' onClick={onProfileClick} >{getName(true)}</Avatar>
          </Space>
        </div>
      </Header>
      <Layout style={{ marginTop: 50 }}>
        <Sider
          theme="dark"
          collapsible={false}
          collapsed={collapsed}
          collapsedWidth={pinned ? 0 : 80}
        >
          <AdminSideNav items={getCurrentSideBar()}
            chieldActiveKey={location.pathname}
            current={current}
            activeKey={chieldActiveKey} />
        </Sider>
        <Layout className="site-layout" style={{
          marginLeft: 0,
          transition: 'all 0.2s',
        }}>
          <Content className="bg" style={{ maxHeight: (windowHeight - 95), overflowY: 'hidden', }} ref={containerRef}>
            <AdminRoutes pinned={pinned} />
          </Content>
          <Footer style={{ borderTop: '1px solid lightgray' }}>
            <div className='footer-division'>
              <div>
                <Space.Compact block size='large'>
                  <Tooltip title="Agreement">
                    <Button type='text' icon={<ContainerOutlined />} onClick={() => setAgreementVisible(true)} ></Button>
                  </Tooltip>
                </Space.Compact>
              </div>
              <div>
                {viewChat && <Chat />}
                <Space.Compact block size='large'>
                  <Tooltip title="Upload Requirements">
                    <Button type='text' icon={<CloudUploadOutlined />} onClick={() => navigate('/admin/requirements/add')}></Button>
                  </Tooltip>
                  <Tooltip title="Upload Profile">
                    <Button type='text' icon={<UploadOutlined />} onClick={() => navigate('/admin/profiles/upload-profile')}></Button>
                  </Tooltip>

                  <Tooltip title="Live Chat">
                    <Button type='text' icon={<CommentOutlined />} onClick={() => openChat()}></Button>
                  </Tooltip>
                  <Tooltip title="Connect with us on whatsapp">
                    <Button type='text' icon={<WhatsAppOutlined />} ></Button>
                  </Tooltip>
                </Space.Compact>
              </div>
            </div>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Admin;
