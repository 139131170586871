import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Upload, Button, Tabs, Image } from 'antd';
import './../index.css';
import ApprovedRequirment from './tabs/ApprovedProfiles';
import NewProfiles from './tabs/newProfiles';
import RejectedProfiles from './tabs/RejectedProfiles';
import ApprovedProfiles from './tabs/ApprovedProfiles';
import BulkApprovedProfile from './bulk/ApprovedRequirment';
import BulkRejectedProfile from './bulk/RejectedRequirment';
import BulkNewProfile from './bulk/newRequirment';

const ManageProfiles = () => {
    const [listFor, setListFor] = useState('review');

    const items = [
        {
            key: 'review',
            label: 'New Profiles',
        },
        {
            key: 'rejected',
            label: 'Rejected Profiles',
        },
        {
            key: 'approved',
            label: 'Approved Profiles',
        },
        {
            key: 'Bulk_Review',
            label: 'Bulk Profiles',
        },
        {
            key: 'Bulk_Reject',
            label: 'Bulk Rejected Profiles',
        },
        {
            key: 'Bulk_Approve',
            label: 'Bulk Approved Profiles',
        },
    ];

    const onChange = (key) => {
        setListFor(key)
    };

    return (
        <div>
            <div className='tabs-bg'>
                <Tabs size='large' defaultActiveKey="review" items={items} onChange={onChange} />
            </div>
            {listFor == 'review' && <NewProfiles />}
            {listFor == 'rejected' && <RejectedProfiles />}
            {listFor == 'approved' && <ApprovedProfiles />}

            {listFor == 'Bulk_Review' && <BulkNewProfile />}
            {listFor == 'Bulk_Reject' && <BulkRejectedProfile />}
            {listFor == 'Bulk_Approve' && <BulkApprovedProfile />}

        </div>
    );
}

export default ManageProfiles;
