import { useEffect, useState } from 'react';
import Dailog from '../../../components/modal';
import './index.css';
import { Tag, Progress, Pagination, Input, Table, Button, Breadcrumb } from 'antd';
const { Search } = Input;
import {
  CloudUploadOutlined,
  FundOutlined,
  InsertRowLeftOutlined,
  ClockCircleOutlined,
  UngroupOutlined,
  HomeOutlined,
  UserOutlined
} from '@ant-design/icons';
import { getData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getUserData } from '../../../actions/auth';

function CareerDetails() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState({});
  const [listData, setListData] = useState({});
  const [userData, setUserData] = useState(null)

  const [dataSource, setDataSource] = useState([{
    id: 'AG-7887',
    name: "Shubham Kanfade",
    match: '20'
  }]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('job');

  useEffect(() => {
    getJobDetails();
    const userData = getUserData();
    setUserData(userData)
  }, [paramValue])

  const getJobDetails = async () => {
    const jobIdMatch = paramValue.match(/AGJ(\d+)/);
    const jobId = jobIdMatch ? parseInt(jobIdMatch[1], 10) : null;
    const resData = await getData([routes.public.jobList, jobId].join('/'));
    if (resData && resData.code == 200) {
      setListData(resData.data)
    }
  }


  const conicColors = { '0%': '#87d068', '50%': '#ffe58f', '100%': '#ffccc7' };

  const getFilter = (text) => {
    return filter[text] ? 'blue' : '';
  }

  const columns = [
    {
      title: 'Unique Id',
      dataIndex: 'id',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Match%',
      dataIndex: 'match',
      render: (text) => {
        return <Progress
          type="circle"
          size={[30, 30]}
          percent={93}
          title={`${93}% Match`}
          strokeColor={conicColors}
        />
      },
    },
    {
      title: 'Actions',
      render: () => {
        return <Button type="primary" shape="round" icon={<CloudUploadOutlined />} size={'middle'}>
          Apply
        </Button>
      }
    },
  ];

  return (
    <div>
      <Dailog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={"Choose a profile to apply for this job"}
      >
        <Table columns={columns} dataSource={dataSource}>
        </Table>
      </Dailog>
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title ">Job Description</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div className="careers-experts-wrapper pt-10">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="projects-wrap">
                    <div className='row section-space--mb_30'>
                      <div className="col-9">
                        <div className='filter'>
                          <div className='tags'>
                            <Breadcrumb
                              items={[
                                {
                                  href: '',
                                  title: <HomeOutlined />,
                                  onClick: () => navigate('/')
                                },
                                {
                                  href: '',
                                  title: (
                                    <>
                                      <UserOutlined />
                                      <span>Career</span>
                                    </>
                                  ),
                                  onClick: () => navigate('/career')
                                },
                                {
                                  title: 'Job Details',
                                },
                                {
                                  title: paramValue ? paramValue.split('-')[0] : ''
                                }
                              ]}
                            />
                            {/* {paramValue && paramValue.split('-')[0]} */}
                            {/* Home / <Link to={'/career'} className='link-text'> Career </Link> / Job Details / {paramValue && paramValue.split('-')[0]} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8 col-md-8 center">
                        {listData && listData.jobTitle && <div className="projects-wrap style-04 wow move-up">
                          <div className="projects-image-box">
                            <div className="content">
                              <div className='text-center ptb-15'>
                                <h4 className="heading ">{listData.jobTitle}, {listData.experiance}+ years</h4>
                                <div className='experianceView'>
                                  <div>
                                    <ClockCircleOutlined /> {listData.employmentType}
                                  </div>
                                  <div>
                                    <FundOutlined /> {listData.experiance}+ year
                                  </div>
                                  <div>
                                    <InsertRowLeftOutlined /> {listData.jobLocationType}
                                  </div>
                                  <div>
                                    <UngroupOutlined /> {listData.workLocation}
                                  </div>
                                </div>
                              </div>
                              <div className='dates'>
                                <div>
                                  <div>
                                    <h6>Mandatory Technical Skills</h6>
                                  </div>
                                  <div>
                                    {listData.skils.map((ele, key) => <Tag key={key} color="blue">{ele}</Tag>)}
                                  </div>
                                </div>
                                <div className='d-f'>
                                  {userData && userData._id !== listData.addedByVendor && <div className='mr-5'>
                                    <Progress type="circle" size={'small'} percent={93} title={`${93}% Match`} strokeColor={conicColors} />
                                  </div>}
                                  {/* <div>
                                    <a href="#" onClick={() => setIsOpen(true)} className="ht-btn ht-btn-md ht-btn--no-round"><CloudUploadOutlined /> Apply </a>
                                  </div> */}
                                </div>
                              </div>
                              <div >
                                <h6>Job Description</h6>
                              </div>
                              <div className='jobDitails' dangerouslySetInnerHTML={{ __html: listData.jobDescription }}>
                              </div>
                              <div className=''>
                                <div className='d-f budgetView'>
                                  <div><h6 className='content'>Budget</h6></div>
                                  <div>{listData.interval} {listData.currency} {listData.minimum} - {listData.currency} {listData.maximum}</div>
                                </div>
                                <div className='d-f budgetView'>
                                  <div><h6 className='content'>Valid Till</h6></div>
                                  <div>{moment(listData.validTill).format('DD MMM YYYY')}</div>
                                </div>
                              </div>
                              <div className='d-f'>
                                {/* <div>
                                  <a href="#" onClick={() => setIsOpen(true)} className="ht-btn ht-btn-md ht-btn--no-round"><CloudUploadOutlined /> Apply </a>
                                </div> */}
                                {userData && userData._id !== listData.addedByVendor && <div className='ml-5'>
                                  <Progress type="circle" title={`${93}% Match`} size={'small'} percent={93} strokeColor={conicColors} />
                                </div>}
                              </div>
                            </div>
                          </div>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  )
}

export default CareerDetails;
