import { useNavigate, useParams } from 'react-router-dom';
import './index.css';
import { Alert, Empty, Select, Steps } from 'antd';
import { getData, postData } from '../../actions/middleware';
import { useEffect } from 'react';
import { routes } from '../../actions/api-routes';
import { useState } from 'react';
import JobDetails from './Tabs/JobDetails';
import InterviewConfirmation from './message';
import Profile from './Tabs/profile';
import Doccuments from './Tabs/doccuments';
import Experiance from './Tabs/experiance';

function JobDiscriptions() {
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [resData, setResData] = useState();

  useEffect(() => {
    if (id) {
      getResponse(id);
    } else {
      location.href = '/'
    }
  }, [id, currentTab])

  const getResponse = async (id) => {
    const resData = await getData(routes.public.ownProfile + id)
    if (resData) {
      setResData(resData.data)
    }
  }

  const next = () => {
    setCurrentTab(currentTab + 1)
  }

  const previous = () => {
    setCurrentTab(currentTab - 1)
  }

  return (
    <>
      <div id="main-wrapper">
        {(resData && !resData.interviewBooked) ? <div className="site-wrapper-reveal">
          <Steps
            className='bg-white d-dextop'
            current={currentTab}
            responsive={true}
            type='navigation'
            direction='horizontal'
            items={[
              {
                title: 'Profile'
              },
              {
                title: 'Job Discriptions',
              },
              {
                title: 'Doccuments',
              },
              {
                title: 'Experiance',
              }
            ]}
          />
          <div className='container-fluid'>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className='viewBox'>
                  {currentTab == 0 && <div>
                    <Profile data={resData} next={next} id={id} />
                  </div>}
                  {currentTab == 1 && <div>
                    <JobDetails data={resData} next={next} previous={previous} />
                  </div>}
                  {currentTab == 2 && <div>
                    <Doccuments data={resData} previous={previous} next={next} id={id} />
                  </div>}
                  {currentTab == 3 && <div>
                    <Experiance data={resData} previous={previous} id={id} refresh={() => getResponse(id)} />
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div> :
          <div className='messageBox'>
            {resData && resData.email &&
              <InterviewConfirmation
                candidateName={resData.candidateName}
                interviewDate={resData.bookedSlot.date}
                interviewTime={resData.bookedSlot.time}
              />
            }
          </div>}
      </div>
      {!resData && <Empty style={{ marginTop: '10%' }} description="Page not found" />}
    </>
  )
}

export default JobDiscriptions;
