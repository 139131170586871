import { Avatar, Button, Col, Row, Space, Tabs } from 'antd'
import './index.css'
import LabelCard from '../../../common/label-card';

const VendorsCompany = () => {

    return <>
        <div className='formView vendor-details'>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <LabelCard lable={'Company Name'} text={'Accelgrowth Technology'} />
                </Col>
                <Col span={8}>
                    <LabelCard lable={'Company Website'} text={'https://accelgrowth.in'} />
                </Col>
                <Col span={8}>
                    <LabelCard lable={'Company CIN/GST'} text={'SUJSBSJHS4555255SHGHS'} />
                </Col>
                <Col span={8}>
                    <LabelCard lable={'Company Profile'} text={<Button>View Pdf</Button>} />
                </Col>
            </Row>
        </div>
    </>
}

export default VendorsCompany;