import React, { useEffect, useState } from 'react';
import { Avatar, Button, Col, Drawer, Modal, Progress, Row, Tooltip } from 'antd';
import { CloseOutlined, LogoutOutlined } from '@ant-design/icons';
import './index.css';
import { getUserData, logout } from '../../../actions/auth';
import LogOutComponent from '../../../components/logout';

const ProfileDrawer = ({ isVisible, width, onClose, onSave }) => {
  const [userData, setUserData] = useState();

  useEffect(() => {
    const userData = getUserData();
    setUserData(userData);
  }, [])

  const drawerHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {userData && <div className='my-info'>
        <div className='avatar-view'>
          <Avatar size={'large'} shape='square'>SK</Avatar>
        </div>
        <div className='personal-details'>
          <div className='my-name'>{userData?.firstName} {userData?.lastName}</div>
          <div className='sub-title'>{userData?.jobTitle}</div>
          {/* <div className='sub-title'> Accelgroth Technolohy</div> */}
          <div className='sub-title'>{userData?.workEmail}</div>
          <div className='sub-title'>{userData?.phoneNumber}</div>
        </div>
      </div>}
      <Button type="text" className='closeButton' onClick={onClose}>
        <CloseOutlined />
      </Button>
    </div>
  );

  return (
    <>
      <Drawer
        title={drawerHeader}
        placement="right"
        onClose={onClose}
        open={isVisible}
        // width={'100%'}
        closable={false}
        maskClosable={false}
        footer={<LogOutComponent />}
      >
        <div>
          <div className='profile-drawer-card'>
            <div className='title'>My Profiles</div>
            <div className='status-profiles'>
              <div>
                <Tooltip title="3 approved / 3 in progress / 4 to do">
                  <Progress
                    percent={100}
                    success={{
                      percent: 30,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='profile-drawer-card'>
            <div className='title'>Test Status</div>
            <div className='status-profiles'>
              <div>
                <Tooltip title="3 approved / 3 in progress / 4 to do">
                  <Progress
                    percent={100}
                    success={{
                      percent: 30,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='profile-drawer-card'>
            <div className='title'>Developer Ownboarded</div>
            <div className='status-profiles'>
              <div>
                <Tooltip title="3 approved / 3 in progress / 4 to do">
                  <Progress
                    percent={100}
                    success={{
                      percent: 30,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='profile-drawer-card'>
            <div className='title'>Current Opening based on your profiles</div>
            <div className='status-profiles'>
              <div>
                <Tooltip title="3 approved / 3 in progress / 4 to do">
                  <Progress
                    percent={100}
                    success={{
                      percent: 30,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </Drawer >
    </>
  );
};
export default ProfileDrawer;