import './index.css';
function ClientsView(props) {

  return (
    <div className="brand-logo-slider-area section-space--ptb_80">
      <div className="container">
        <div className="row">
          {props && !props.hideText && <div className="col-lg-12">
            <div className="section-title section-space--mb_60">
              <h5 className="heading-text text-center">Take a step forward and join our roster of successful clients. Experience our transformative solutions and unlock your business's full potential today.</h5>
            </div>
          </div>}
          <div className="col-lg-12">
            <div className="brand-logo-slider__container-area">
              <div className="swiper-container brand-logo-slider__container">
                <div className="swiper-wrapper brand-logo-slider__one">
                  <div className="swiper-slide brand-logo brand-logo--slider">
                    <a href="#">
                      <div className="brand-logo__image">
                        <img src={require("../../../assets/images/clients/smartdata.png")} className="img-fluid" alt="" />
                      </div>
                      <div className="brand-logo__image-hover">
                        <img src={require("../../../assets/images/clients/smartdata.png")} className="img-fluid" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="swiper-slide brand-logo brand-logo--slider">
                    <a href="#">
                      <div className="brand-logo__image">
                        <img src={require("../../../assets/images/clients/techm.png")} className="img-fluid" alt="" />
                      </div>
                      <div className="brand-logo__image-hover">
                        <img src={require("../../../assets/images/clients/techm.png")} className="img-fluid" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="swiper-slide brand-logo brand-logo--slider">
                    <a href="#">
                      <div className="brand-logo__image">
                        <img src={require("../../../assets/images/clients/tcs.png")} className="img-fluid" alt="" />
                      </div>
                      <div className="brand-logo__image-hover">
                        <img src={require("../../../assets/images/clients/tcs.png")} className="img-fluid" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="swiper-slide brand-logo brand-logo--slider">
                    <a href="#">
                      <div className="brand-logo__image">
                        <img src={require("../../../assets/images/clients/hdfc.png")} className="img-fluid" alt="" />
                      </div>
                      <div className="brand-logo__image-hover">
                        <img src={require("../../../assets/images/clients/hdfc.png")} className="img-fluid" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="swiper-slide brand-logo brand-logo--slider">
                    <a href="#">
                      <div className="brand-logo__image">
                        <img src={require("../../../assets/images/clients/globalsoft.webp")} className="img-fluid" alt="" />
                      </div>
                      <div className="brand-logo__image-hover">
                        <img src={require("../../../assets/images/clients/globalsoft.webp")} className="img-fluid" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="swiper-slide brand-logo brand-logo--slider">
                    <a href="#">
                      <div className="brand-logo__image">
                        <img src={require("../../../assets/images/clients/ts.png")} className="img-fluid" alt="" />
                      </div>
                      <div className="brand-logo__image-hover">
                        <img src={require("../../../assets/images/clients/ts.png")} className="img-fluid" alt="" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientsView;
