import React, { useEffect, useState } from 'react';
import { MessageBox, MessageList } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import './index.css';
import { SendOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Space } from 'antd';

const Chat = () => {
    const [messages, setMessages] = useState([
        {
            position: 'right',
            type: 'text',
            text: 'Hello, how can I help you?',
            date: new Date(),
        },
    ]);
    const [input, setInput] = useState('');
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const ws = new WebSocket('ws://localhost:3002');
        ws.onopen = () => {
            console.log('Connected to WebSocket server');
        };
        ws.onmessage = (message) => {
            console.log(`Received message: ${message.data}`);
        };
        setSocket(ws);
        return () => {
            ws.close();
        };
    }, []);

    const sendMessage = (message) => {
        if (socket) {
            socket.send(message);
        }
    };

    const handleSendMessage = () => {
        if (input.trim() !== '') {
            const newMessage = {
                position: 'right',
                type: 'text',
                text: input,
                date: new Date(),
            };
            sendMessage(input)
            setMessages([...messages, newMessage]);
            setInput('');
        }
    };

    return (
        <div className="chat-container">
            <div className='chat-header'>
                <div>Live Chat</div>
            </div>
            <div className="chat-window">
                <div className="message-list-container">
                    <MessageList
                        className="message-list"
                        lockable={true}
                        toBottomHeight={'90%'}
                        dataSource={messages}
                    />
                </div>
                <Divider />
                <div className="message-box-container">
                    <div className="message-box textbox-input">
                        <Space.Compact block style={{ width: '100%' }}>
                            <Input.TextArea
                                value={input}
                                rows={1}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Type here..."
                            />
                            <Button icon={<SendOutlined />} type='primary' onClick={handleSendMessage}></Button>
                        </Space.Compact>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
