import { Button, Col, Input, Row, Select, Space, Switch, Table, Tag } from 'antd';

import { SearchOutlined, EditOutlined, DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import './index.css'
import { profileColumns } from '../../../actions/table-configs/profile';
import { useEffect, useRef, useState } from 'react';
import { getData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import { decryptData } from '../../../actions/crypto';
import LoadMoreCheckboxSelect from '../../common/checkbox';
import DrawerView from '../../common/drawer/drawer';
import QuestionForm from './questionForm';
const { Option } = Select;

const QuestionBank = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [hasMore, setHasMore] = useState(true);
    const [isVisible, setVisible] = useState(false);
    const [savingForm, setSavingForm] = useState(false);
    const [page, setPage] = useState(1);
    const [skill, setSkill] = useState(null);
    const [questionType, setQuestionType] = useState(null);

    const [questionsBank, setQuestionsBank] = useState([]);
    const [pagination, setPagination] = useState();
    const [searchKey, setSearchKey] = useState();

    const containerRef = useRef(null);



    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (hasMore) {
            getQuestions();
        }

        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [page, hasMore])

    const getHtml = (html) => JSON.parse(html)

    const getQuestions = async () => {
        const resData = await getData(routes.onlineTest.questions, { current: page, skills: skill, questionType: questionType, search: searchKey, nameKey: 'question' });
        if (resData.code == 200) {
            const data = decryptData(resData.data)
            if (page == resData.pagination.current) {
                setPagination(resData.pagination)
                if (data.length < 14) {
                    setHasMore(false);
                    setQuestionsBank(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                } else {
                    setQuestionsBank(prevData => {
                        const newData = data.filter(newItem => !prevData.some(prevItem => prevItem._id === newItem._id));
                        return [...prevData, ...newData];
                    });
                }
            }
        }
    }

    const handleScroll = () => {
        const container = containerRef.current;
        if (
            container.scrollTop + container.clientHeight === container.scrollHeight
        ) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const onSkills = (e) => {
        setSkill(e.value)
        reloadPage();
    }

    const selectBefore = (
        <LoadMoreCheckboxSelect onSelect={(e) => onSkills(e)} isMulti={true} />
    );

    const onSave = () => {
        setSavingForm(true)
    }

    const onCloseModel = () => {
        setVisible(false);

    }

    const reloadPage = () => {
        setHasMore(true);
        setQuestionsBank([])
        setPage(1)
    }

    const onSearch = (e) => {
        setSearchKey(e.target.value)
    }

    const onEnterCall = (e) => {
        if (e.code == 'Enter') {
            reloadPage();
        }
    }

    const onChangeSwitch = (e) => {
        if (e) {
            setQuestionType(null)
        } else {
            setQuestionType('Code')
        }
        reloadPage();
    }

    return <>
        <div className='quetionPaper'>
            <div className='filter-quetionPaper'>
                <div>
                    <Space>
                        <div className='select-box-for-skills'>
                            {selectBefore}
                        </div>
                        <Input
                            addonAfter={<SearchOutlined />}
                            onInput={(e) => onSearch(e)}
                            onKeyDown={(e) => onEnterCall(e)}
                        />
                        <Switch
                            checkedChildren={'MCQ'}
                            unCheckedChildren={'Coding'}
                            defaultChecked
                            onChange={(e) => onChangeSwitch(e)}
                        />
                    </Space>
                </div>
                <div>
                    <Space>
                        <div>
                            <Tag>Total Questions: {pagination?.total}</Tag>
                        </div>
                        <div>
                            <Button icon={<PlusSquareOutlined />} type='primary' onClick={() => setVisible(true)}> Question</Button>
                        </div>
                    </Space>
                </div>
            </div>
            <DrawerView
                onClose={() => setVisible(false)}
                isVisible={isVisible}
                onSave={() => onSave()}
            >
                <QuestionForm
                    onSubmit={savingForm}
                    onClickOnSave={() => setSavingForm(false)}
                    onClose={() => onCloseModel()} />
            </DrawerView>
            <div className='questions-bank' style={{ maxHeight: (windowHeight - 145), overflowY: 'auto', }} ref={containerRef}>
                {questionsBank.map(ele => <div className='question-list'>
                    <div className='display-quetions'>
                        <Space className='question-heading'>Que: <div dangerouslySetInnerHTML={{ __html: getHtml(ele?.question) }}></div> <Tag>{ele?.marks} Mark</Tag></Space>
                        <Space>
                            <Tag color='blue' bordered={false}>{ele?.skills}</Tag>
                            <Tag color='pink' bordered={false}>Type - {ele?.questionType}</Tag>
                            <Button icon={<EditOutlined />}></Button>
                            <Button icon={<DeleteOutlined />} danger></Button>
                        </Space>
                    </div>

                    <div style={{ padding: '10px' }}>
                        <Row>
                            <Col lg={18} md={18} sm={24}>
                                <Row gutter={[16, 16]}>
                                    {ele.options.map((ele, index) => <Col span={12} className='options-list'>{index + 1}. <div className={ele?.answer ? 'active-option' : ''} dangerouslySetInnerHTML={{ __html: getHtml(ele?.option) }}></div></Col>)}
                                </Row>
                            </Col>
                            <Col lg={6}>
                            </Col>
                        </Row>
                    </div>
                </div>)}
            </div>
        </div >
    </>
}

export default QuestionBank;