// AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PaymentComponent from '../pages/Extra/Payment';

const ExtraRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/payment" element={<PaymentComponent />} />
      </Routes>
    </>
  );
};

export default ExtraRoutes;
