import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Upload, Button, Tabs } from 'antd';
import './../index.css';
import BulkList from './newRequirment';

const BulkRequirments = () => {
    const [listFor, setListFor] = useState('approved');
    const items = [
        {
            key: 'approved',
            label: 'Approved',
        },
        {
            key: 'rejected',
            label: 'Rejected',
        },
        {
            key: 'review',
            label: 'Waiting for approval',
        },
    ];

    const onChange = (key) => {
        setListFor(key)
    };

    return (
        <div>
            <div className='tabs-bg'>
                <Tabs size='large' defaultActiveKey="review" items={items} onChange={onChange} />
            </div>
            <BulkList listFor={listFor} />
        </div>
    );
}

export default BulkRequirments;
